import React, {useEffect, useState} from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";
import { setTutoringSubNav, setProfileSubNav } from "redux/actions/actionTypes";

import defaultLinkAction from "Utilities/LinkActions";
import {
	SUB_MENU_TYPES_IMAGES, SUB_MENU_TYPES_ALTS, SUB_MENU_TYPES_LABELS
} from "templates/utilities/PageSubMenu";


function PageLayout(props) {

	const history = useHistory();
	const location = useLocation();
	const SUB_MENU_REDUX_ACTIONS = {
		profile: props.setProfileSubNav,
		tutoring: props.setTutoringSubNav
	};

	const [pageClass, setPageClass] = useState("");
	const [subPageItems, setSubPageItems] = useState();

	useEffect(() => {

		const unlisten = history.listen((location) => {
			const locationSearch = location.search;
			const validUrlKeys = Object.keys(SUB_MENU_TYPES_IMAGES[props.subPageType]);
			const subPage = getCurrentSubPage(locationSearch);

			if (validUrlKeys.includes(subPage) && !locationSearch.includes("sub")) {
				changePageClassName(subPage);
				pageClickHandler(null, subPage);
			}
		});

		changePageClassName(getCurrentSubPage());
		generateSubMenuItems();

		return () => {
			unlisten();
		}
	}, []);

	function changePageClassName(subPage) {
		if (["tutorRequests", "tutorsList", "messages", "savedTutors"].includes(subPage)) {
			setPageClass("tutoring-list");
		}
		else {
			setPageClass("");
		}
	}

	function generateSubMenuItems() {
		setSubPageItems(
			generateSubMenu(props.subPageType, pageClickHandler)
		);
	}

	function generateSubMenu(menuType, pageClickHandler) {
		let subMenus = [];

		const subMenuOptions = SUB_MENU_TYPES_IMAGES[menuType];
		const subMenuOptionAlts = SUB_MENU_TYPES_ALTS[menuType];
		const subMenuOptionsLabels = SUB_MENU_TYPES_LABELS[menuType];
		const currentSubPage = getCurrentSubPage();

		for (const [key, value] of Object.entries(subMenuOptions)) {
			if (props.menuKeysToExclude && props.menuKeysToExclude.length && props.menuKeysToExclude.includes(key)) {
				continue;
			}

			let subPageClassName = "page-sub-menu-button";
			if (key === currentSubPage) {
				subPageClassName = "page-sub-menu-button chosen";
			}

			const link = props.useShow ? `/${menuType}?show=${key}` : `/${menuType}/${key}`;
			subMenus.push(
				<Link to={link} className={subPageClassName}
					key={key} domkey={key}
					onClick={e => {
						defaultLinkAction();
						pageClickHandler(e, null)
					}} >
					<img src={value} alt={subMenuOptionAlts[key]} domkey={key}></img>
					{subMenuOptionsLabels[key]}{props.customMenuItemsDom && props.customMenuItemsDom.current && props.customMenuItemsDom.current[key]}
				</Link>
			);
		}
	
		return subMenus;
	}

	// BE VERY CAREFUL WHEN MESSING WITH THIS CODE. IT CAN LEAD TO INFINITE PAGE REFRESH.
	function getCurrentSubPage(urlToTest = location.search) {
		let subPage;
		if (props.useShow) {
			const urlParams = new URLSearchParams(urlToTest);
			subPage = urlParams.get("show");
		}
		else {
			const pathnames = window.location.pathname.split("/");
			subPage = pathnames[2];
		}

		return subPage;
	}

	function pageClickHandler(e, urlSubPage) {
		let domKey = urlSubPage;

		if (e) {
			domKey = e.target.getAttribute("domkey");
		}

		let clickedPageLinks = document.querySelectorAll(`a[class="page-sub-menu-button chosen"]`);
		clickedPageLinks.forEach(pageLink => {
			pageLink.className = "page-sub-menu-button";
		});
		clickedPageLinks = document.querySelectorAll(`a[class="page-sub-menu-button"]`);
		clickedPageLinks.forEach(pageLink => {
			if (pageLink.getAttribute("domkey") === domKey) {
				pageLink.className = "page-sub-menu-button chosen";
			}
		});

		props.pageItemClickHandler(domKey);
		SUB_MENU_REDUX_ACTIONS[props.subPageType](domKey);
	}

	return (
		<div className={`page-layout ${props.customClass} ${pageClass}`}>
			<div className="menu-options">
				{subPageItems}
			</div>

			<div className="menu-contents">
				{props.children}
			</div>
		</div>
	);
}

PageLayout.defaultProps = {
	useShow: false,
	menuKeysToExclude: [],
	customMenuItemsDom: {}
};

PageLayout.propTypes = {
	useShow: PropTypes.bool,
	customClass: PropTypes.string,
	customMenuItemsDom: PropTypes.object,
	subPageType: PropTypes.string.isRequired,
	pageItemClickHandler: PropTypes.func.isRequired,
	children: PropTypes.object.isRequired,
	menuKeysToExclude: PropTypes.array,

	// redux props
	ownerId: PropTypes.string,
	setTutoringSubNav: PropTypes.func.isRequired,
	setProfileSubNav: PropTypes.func.isRequired
};

export default connect(
	account,
	{
		setTutoringSubNav, setProfileSubNav
	}
)(PageLayout);

