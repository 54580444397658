import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import StripeCheckoutSubmit from './StripeCheckoutSubmit';

import { createPaymentIntentAPI } from "apis/controllers/transactions/PaymentsController";


export default function StripeCheckoutForm(props) {

	const [renderCustomCheckout, setRenderCustomCheckout] = useState();

	useEffect(() => {
		if (props.clientSecret != null && props.returnUrl) {
			renderActualCheckoutStripeForm(props.clientSecret, props.returnUrl);
		}
		else {
			const payload = {
				ownerId: props.ownerId,
				paymentAmount: props.price,
				quantityPurchased: props.quantity,
				paymentDescription: props.paymentDescription,
				paymentPurpose: props.paymentPurpose,
				sessionId: props.sessionId
			};

			createPaymentIntentAPI(payload, resp => {
				renderActualCheckoutStripeForm(resp.clientSecret, resp.baseUrl, resp.chargeId);
			});
		}
	}, [props.price, props.quantity]);

	function renderActualCheckoutStripeForm(clientSecret, baseUrl, chargeId) {
		const options = {
			clientSecret: clientSecret,
			loader: "always",
			// reference: https://docs.stripe.com/elements/appearance-api
			appearance: {
				theme: "stripe",
				disableAnimations: false
			}
		};

		setRenderCustomCheckout(
			<Elements stripe={loadStripe(props.stripePubKey)} options={options}>
				<StripeCheckoutSubmit
					confirmationRedirection={baseUrl} paymentPurpose={props.paymentPurpose} paymentAmount={props.price}
					clientSecret={clientSecret} chargeId={chargeId} ownerId={props.ownerId}
					tutorId={props.tutorId} sessionId={props.sessionId}
					directTutoringRequestMatchingObject={props.directTutoringRequestMatchingObject}
					paymentDetails={props.paymentDetails} />
			</Elements>
		);
	}

	return (
		// need this div to help record 'pay' actions from users
		<Fragment>
			{renderCustomCheckout}
		</Fragment>
	);
}

StripeCheckoutForm.defaultProps = {
	sessionId: null,
	paymentPurpose: "shinyNeurons"
};

StripeCheckoutForm.propTypes = {
	stripePubKey: PropTypes.string.isRequired,
	price: PropTypes.number.isRequired,
	quantity: PropTypes.number.isRequired,
	paymentDescription: PropTypes.string.isRequired,
	paymentDetails: PropTypes.object,
	ownerId: PropTypes.string.isRequired,

	// props that will only get passed to StripeCheckoutForm if a user's saved payment method gets declined
	paymentPurpose: PropTypes.string,
	returnUrl: PropTypes.string,
	clientSecret: PropTypes.string,
	tutorId: PropTypes.string,
	sessionId: PropTypes.string,
	directTutoringRequestMatchingObject: PropTypes.object
};
