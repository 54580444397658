export function initializeGoogleDataLayer() {
	window.dataLayer = window.dataLayer || [];
}

export function gtag(){
	if (window.dataLayer == null) {
		initializeGoogleDataLayer();
	}
	else {
		window.dataLayer.push(arguments);
	}
}

export const SIGN_UP_CONVERSION = "SIGN_UP_CONVERSION";
export const ADD_TO_CART_CONVERSION = "ADD_TO_CART_CONVERSION";
export const CHECKOUT_CONVERSION = "CHECKOUT_CONVERSION";
export const PURCHASE_CONVERSION = "PURCHASE_CONVERSION";
export const SUBSCRIBE_CONVERSION = "SUBSCRIBE_CONVERSION";
const CONVERSION_ID_MAP = {
	[SIGN_UP_CONVERSION]: "c51UCIull_sYENHMtMkq",
	[ADD_TO_CART_CONVERSION]: "PEE9CJv516UaENHMtMkq",
	// this will be unused for now since we only allow one checkout at a time
	[CHECKOUT_CONVERSION]: "tGf4CKjdxaUaENHMtMkq",
	[PURCHASE_CONVERSION]: "2x1pCKvdxaUaENHMtMkq",
	[SUBSCRIBE_CONVERSION]: "9mAuCK7dxaUaENHMtMkq"
};

export function recordConversion(conversionType, url) {
	var callback = function () {
		if (typeof(url) != 'undefined') {
			window.location = url;
		}
	};
	gtag("event", "conversion", {
		"send_to": `AW-11428243025/${CONVERSION_ID_MAP[conversionType]}`,
		"event_callback": callback
	});

	return false;
}



