import React from "react";

import { 
	ADD_TO_CART_CONVERSION, PURCHASE_CONVERSION,
	recordConversion
} from "scripts/google/recordConversion";

import Spinner from "templates/Spinner";

import messageMap from "Utilities/MessageMaps";
import { AUTO_REDIRECT_TIMEOUT } from "Utilities/Constants/TimeoutConstants";

export const CART_ITEM_CATEGORY_KEY = "cartItemCategory";
export const CART_ITEM_DESCRIPTION_KEY = "cartItemDescription";

export function handleTutoringPaymentIntentResponse(
	decision, sessionPrice, isSubscriber, paymentDescription,
	tutorId, sessionId,
	returnUrl, directTutoringRequestMatchingObject,
	resp, 
	saveDecision, setSpinner, setPaymentInfo, historyPusher
) {
	recordConversion(ADD_TO_CART_CONVERSION);

	if (resp.status === "succeeded") {
		saveDecision && saveDecision(decision);
		setSpinner && setSpinner(null);

		recordConversion(PURCHASE_CONVERSION);
	}
	// offload work to Stripe for any other payment status
	else {
		setSpinner && setSpinner(
			<Spinner key="invalidPayment" containerClass="invalid-payment-method"
				loadingText={messageMap("tutoringPage.request.invalidPaymentOrAuthNeeded", "generic")} />
		);

		setTimeout(() => {
			const price = isSubscriber ? sessionPrice - (sessionPrice * .1) : sessionPrice;

			setPaymentInfo({
				imgRef: 5,
				price: price,
				quantity: 1,
				description: paymentDescription,
				purpose: "tutoringSession"
			});
			historyPusher({
				pathname: "/checkout",
				state: {
					stripePubKey: resp.stripePubKey,
					returnUrl: returnUrl,
					clientSecret: resp.clientSecret,
					tutorId: tutorId,
					sessionId: sessionId,
					directTutoringRequestMatchingObject: directTutoringRequestMatchingObject
				}
			});
			setSpinner && setSpinner(null);
		}, AUTO_REDIRECT_TIMEOUT * 1.25);
	}
}