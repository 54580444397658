import { SET_ENV_MODE } from "redux/actions/environmentConstants";

const initialState = {
	envMode: null
};

export default function (state = initialState, action) {
	if (action.type === SET_ENV_MODE) {
		return {
			...state,
			envMode: action.payload
		};
	}
	else {
		return state;
	}
}