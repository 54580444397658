// Will be mainly used for the 'alt' attribute
export const imageMessageMap = {
	account: {
		profile: "User icon",
		profilePicture: "Profile picture",
		profileView: "User account icon for viewing user's profile. Click to display a dropdown of additional user actions.",
		passwordLock: "Lock icon. Click to enter password.",
		username: "User icon. Click to enter username.",
		email: "User icon for email.",
		settings: "A settings icon. Click to go to user profile page.",
		logout: "Door with left-pointing arrow.",
		edit: "Pencil icon. Click to edit field"
	},
	bookmark: {
		notSaved: "Bookmark icon. Click to add to list of bookmarked videos."
	},
	groups: {
		addMember: "Icon showing a plus character. Click to show a pop-up with the group invite code.",
		leaveGroup: "Icon showing an arrow next to a door. Click to leave group.",
		page: "Icon showing multiple people in a circle. Click to visit the groups page."
	},
	ellipsisVertical: "Vertical Ellipsis icon. Click to open up a menu.",
	checkMark: "Check mark icon enclosed in a circle",
	information: "Information icon",
	moreInfo: "Information icon. Click for more information",
	mentalModelExample: "Mental model of the components of procedural system",
	presentation: "Presentation icon",
	viewers: "An icon of a monitor with the play button on the center",
	accessibility: "A handicap icon",
	miscellaneous: "An icon of a box containing different things to represent miscellaneous",
	alerts: {
		check: "Check alert icon",
		warning: "Warning alert icon",
		cancel: "Cancel alert icon",
		information: "Information alert icon",
		close: "Close icon. Click to close alert"
	},
	rowActions: {
		delete: "Delete icon showing a garbage can. Click to delete selected videos.",
		edit: "Edit icon showing a pencil on a paper. Click to edit a single video.",
		module: "Icon of a book showing a page  with a video icon. Click to group videos into a chapter playlist.",
		class: ""
	},
	closeThin: "Cross icon. Click to cancel",
	learn: {
		details: "Details icon. Three lines, with the bottom line half the length of the previous lines. Click to display video details.",
		editVideos: "Icon of a pencil on a paper. Click to go to your edit videos page.",
		editReport: "Icon of a pencil on a paper. Click to report an issue",
		videoList: "Company's logo inside a house icon. Click to go to video list page.",
		practice: "Game controller icon. Click to go to practice pages.",
		topicsInfoList: "Textbook icon. Click to go to textbook page.",
		networks: "Circles connected to each other. Click to go journeys page.",
		mentalModel: {
			text: "An interconnected node icon. Click to show video's mental model.",
			alt: "A mental model of the concepts discussed in the video"
		},
		play: "Icon for the play button of a video.",
		upload: "Icon of an arrow pointing upwards. Click to go to upload page.",
	},
	calendar: "Calendar icon.",
	clock: "Clock icon",
	singleHuman: "Person icon",
	location: "Location icon",
	details: "Details icon",
	status: "Status icon",
	filter: "Filter icon. Click to view filters",
	hamburger: "Hamburger icon for opening and closing side navigation.",
	xIcon: "Ex icon for closing side navigation",
	companyLogo: "Company logo of flower of life containing a gradient of all colors. Click to go back to homepage.",
	arrows: {
		rightGreen: "Right-pointing green arrow"
	},
	chevron: {
		text: "Chevron icon",
		up: "Chevron icon pointing up",
		down: "Chevron icon pointing down",
		left: {
			text: "Chevron icon pointing to the left",
			actions: {
				prevMonth: "Click to go to the previous month"
			}
		},
		right: {
			text: "Chevron icon pointing to the right",
			actions: {
				nextMonth: "Click to go to the next month"
			}
		}
	},
	landing: {
		learner: {
			discoverTutors: "A picture of a list of tutors in the 'Tutors' sub page.",
			videosArticles: "A picture of the video learning page that shows a math video on top of another picture showing an the title of an article.",
			practiceProblems: "A picture the practice problems page that shows a multiple choice shapes question."
		},
		tutor: {
			upTo90: "Three ascending columns showing the text earning 80%, 85%, and 90% respectively.",
			pricePerTopic: "Varying sizes of green buttons with a topic on each button.",
			fifteenMinuteSlot: "A calendar with a dropdown on the right containing different 15-minute slots."
		},
		testimonials: {
			rondaWilliams: "A headshot of a English language arts teacher who has 31 years in teaching and who holds a Master in curriculum and instruction named Ronda Williams.",
			dallasCarter: "A headshot of a Principal and former High School Director, Vice Principal, and Teacher named Dallas Carter.",
			kevinQuinlan: "A headshot of Director of Education at Sentiment AI named Kevin Quinlan."
		}
	},
	marketing: {
		brain: "Picture of a 2d brain made of circuits."
	},
	checkUsername: {
		check: "Check icon indicating that the username is available",
		cross: "Cross icon indicating that the username is not available"
	},
	knowledge: {
		locate: "Crosshair icon. Click to locate a node.",
		tree: "Nodes icon. Click to display a node's tree.",
		depth: "Double-sided vertical arrows. Click to set tree depth.",
		hamburger: "Hamburger icon. Click to show hidden menu.",
		view: {
			root: "Reset icon. Click to go back to the root view.",
			previous: "Left chevron icon. Click to go back to the previous view",
			deeper: "Plus icon. Click to go 1 level deeper into the current view.",
			higher: "Minus icon. Click to go 1 level higher from the current view."
		}
	},
	navigation: {
		first: "Icon for going to first page.",
		previous: "Icon for page left arrow (previous).",
		next: "Icon for page right arrow (previous).",
		last: "Icon for going to last page.",
		tableOfContents: "List icon. Click to show page's table of contents.",
		videoList: "Company's logo inside a house icon. Click to go to video list page.",
		practice: "Game controller icon. Click to go to practice pages.",
		topicsInfoList: "Textbook icon. Click to go to textbook page.",
		tutoring: "Tutoring icon. Click to go to tutoring pages.",
		networks: "Circles connected to each other. Click to go journeys page."
	},
	subPages: {
		profile: {
			account: "Stick figure inside circle for account icon. Click to navigate to user profile sub-page.",
			learner: "A graduation cap. Click to navigate to learner profile sub-page.",
			tutoring: "Person icon inside a monitor. Click to navigate to tutoring sub-page.",
			subscription: "Two concentric circles. Click to navigate to subscription sub-page.",
			groups: "Three stick figures. Click to navigate to groups sub-page.",
			financials: "Bank icon. Click to navigate to financials sub-page."
		},
		tutoring: {
			sessions: "Stop clock with one hand pointing to 2. Click to navigate to tutor sessions sub-page.",
			requests: "Person icon with a plus sign. Click to navigate to request a page sub-page.",
			applications: "Pencil icon on a paper. Click to navigate to tutor applications sub-page.",
			savedTutors: "Save icon. Click to navigate to a list of saved tutors sub-page.",
			tutorsList: "Person icon. Click to navigate to a list of tutors sub-page.",
			messages: "Text bubble icon. Click to navigate to chat sub-page.",
			tutorFAQs: "Question mark inside a circle. Click to navigate to tutor FAQs sub-page.",
			tutorPolicies: "Lowercase i inside a circle. Click to navigate to view tutoring policies.",
			whyExer: "Star icon. Click to navigate to why Exer is the best tutoring platform."
		},
		learner: {
			plus: "Plus icon. Click to add another block of availability for ",
			ex: "X icon. Click to remove current block of availability for "
		}
	},
	tutoring: {
		tutorList: {
			linkedIn: "Linkedin icon. Click to view tutor's linkedIn page.",
			website: "Website icon. Click to view tutor's personal website.",
			resume: "Attachment icon. Click to download tutor's resume",
			save: "Hollow save icon. Click to save tutor",
			saved: "Filled-in save icon. Click to unsave tutor.",
			share: "Share icon. Click to create tutor profile link.",
			greenCheck: "green check inside a green hollow circle.",
			tutorPicture: "Tutor's head shot"
		},
		whyExer: {
			penOnPaper: "Hand-drawn pen on paper",
			ribbon: "Hand-drawn ribbon",
			togaHat: "Hand-drawn toga hat",
			security: "Hand-drawn security lock inside a shield",
			money: "Hand-drawn stacks of money",
			personTraining: "Hand-drawn stick figure flexing its right arm"
		}
	},
	stripeConnect: {
		camera: "Camera icon"
	},
	ratings: {
		empty: "Hollow star icon",
		half: "Yellow half full star icon",
		full: "Yellow full star icon"
	},
	pageSwitching: {
		video: "Play icon. Click to go to corresponding video page on the same topic.",
		practice: "Game controller icon. Click to go to corresponding practice page on the same topic.",
		info: "Opened book icon. Click to go to corresponding information article on the same topic."
	},
	notifications: {
		bell: "Icon of a bell. Click to show notifications.",
		star: "A star icon used when announcing company promotions."
	},
	tbd: "Website under construction coming soon page. Toy robot looks at a set of hand wrenches for maintenance repairs and service works. Blue background.",
	search: "Magnifying glass icon. Click to search for videos and classes.",
	shinyNeurons: { 
		hexagonEnclosedShinyNeurons: " shiny neuron(s) enclosed in a hexagon" 
	},
	practice: {
		bag: "Bag",
		tutorial: "Red textbook.",
		answer: "A text bubble with the letter A at the center",
		hint: "A lightbulb",
		level: "Three stars with different colors"
	},
	subscription: {
		apple: "Apple",
		play: "Play button",
		bulb: "Light bulb",
		cash: "Stack of cash",
		browser: "A browser tab",
		smilers: "Two smiling emojis",
	},
	videoUpload: {
		information: "Information icon. Click or hover to show tooltip."
	},
	thirdParty: {
		google: "Google's colorful icon of red, yellow, green, and blue"
	}
};