/* eslint-disable no-template-curly-in-string */
import request, {GET, POST, callThirdPartyAPI} from "Utilities/Fetches";
import {$replace} from "Utilities/Strings";

const BASE = "/services/api";

const GET_WOLFRAM_ALPHA_STEP_BY_STEP_ANSWER = BASE + "/get/wolframalpha/stepByStep/answer?input=${input}";

export const GET_PUBNUB_KEYSETS = BASE + "/get/pubNub/keys";

export const GET_STRIPE_PUB_KEY = BASE + "/get/stripe/pubKey";

export const GOOGLE_PLACES_KEY = BASE + "/get/google/placesKey";


/**
 * @param {String} payload = ownerId
 * @param {Object} pathVariables = {
 * 	input: String
 * }
 */
export function getWolframAlphaStepByStepAnswerAPI(payload, pathVariables, callback) {
	const api = $replace(GET_WOLFRAM_ALPHA_STEP_BY_STEP_ANSWER, pathVariables);
	request(api, POST, payload, callback);
}

export function getPubNubKeysetAPI(callback) {
	request(GET_PUBNUB_KEYSETS, GET, null, callback);
}

export function getStripePubKeyAPI(callback) {
	request(GET_STRIPE_PUB_KEY, GET, null, callback);
}

export function getGooglePlacesKeyAPI(callback) {
	request(GOOGLE_PLACES_KEY, GET, null, callback);
}
export async function getGoogleValidatedAddressAPI(apiKey, payload, callback) {
	const url = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${apiKey}`;
	callThirdPartyAPI(POST, null, url, null, null, payload, callback);
}
export async function getGoogleAddressLatitudeLongitudeAPI(apiKey, payload, callback) {
	const url = `https://maps.googleapis.com/maps/api/geocode/json?key=${apiKey}&address=${payload}`;
	const customHeaders = {
		remove: "Content-Type"
	};
	callThirdPartyAPI(GET, null, url, null, customHeaders, payload, callback);
}