import React from "react";

import PropTypes from "prop-types";

import Modal from "templates/Modal";

import messageMap from "Utilities/MessageMaps";
import { topicsInSubjects } from "../../utilities/TutoringSubPageConstants";

import closeAsset from "assets/icons/common/close.svg";


function CancellableSubjectTopicUpload(props) {

	const {subject, topic, subjectTopicReviewStatus} = props;
	const hasSubjectTopicReviewStatus = subjectTopicReviewStatus != null && Object.keys(subjectTopicReviewStatus).length && subjectTopicReviewStatus[`${subject}-${topic}`] != null;
	const hideXButton = hasSubjectTopicReviewStatus && subjectTopicReviewStatus[`${subject}-${topic}`] === "approved";
	const reviewStatus = !hasSubjectTopicReviewStatus ? "" : `(${messageMap("profilePage.tutoring.modal.application.review", "generic")} ${messageMap(`profilePage.tutoring.modal.application.${subjectTopicReviewStatus[`${subject}-${topic}`]}`, "generic")})`;

	function saveSubjectExpertiseLink(e) {
		props.addSubjectTopicProof(`${subject}-${topic}`, e.target.value);
	}

	function confirmBeforeRemoving() {
		if (props.previouslySavedLink == null) {
			deleteProof();
		}
		else {
			props.setModal(
				<Modal closeHandler={closeModal} modalClass="become-tutor-modal"
					submitText={messageMap("delete.text", "button")} submitHandler={deleteProof}>
					<div className="multi-dom-line">
						{messageMap("profilePage.tutoring.modal.application.deleteProof", "generic")}
					</div>
				</Modal>
			);
		}
		
	}
	function closeModal() {
		props.setModal(null);
	}
	function deleteProof() {
		props.removeSubjectTopicProof(`${subject}-${topic}`);
	}

	return (
		<div className="">
			<div className="subject-topic-proof-container">
				<div className="subject-topic">
					{`${subject} - ${topicsInSubjects[subject][topic]} ${reviewStatus}`}
				</div>
				<button className={`remove-subject-topic ${hideXButton && "hide"}`}
					onClick={confirmBeforeRemoving}>
					<img className="x-icon" src={closeAsset} alt="ex icon"></img>
				</button>
			</div>

			<div className="page-field">
				<div className="inline-elements">
					<input className="page-field-value" type="text"
						required aria-required="true" maxLength={200}
						onChange={saveSubjectExpertiseLink}
						placeholder={props.previouslySavedLink ? props.previouslySavedLink : messageMap("profilePage.tutoring.modal.application.subjectTopicProof", "generic")}>
					</input>
				</div>
			</div>
		</div>
	);
}

CancellableSubjectTopicUpload.defaultProps = {
	previouslySavedLink: null
};

CancellableSubjectTopicUpload.propTypes = {
	// parent setters
	setModal: PropTypes.func,
	removeSubjectTopicProof: PropTypes.func,
	addSubjectTopicProof: PropTypes.func,

	previouslySavedLink: PropTypes.string,
	subjectTopicReviewStatus: PropTypes.object,

	subject: PropTypes.string,
	topic: PropTypes.string
};

export default CancellableSubjectTopicUpload;