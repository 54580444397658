import { 
	SET_PAYMENT_INFO, SET_PAYMENT_CONFIRMATION_INFO, CLEAR_PAYMENT_INFO
} from "redux/actions/paymentConstants";

const initialState = {
	imgRef: null,
	price: null,
	quantity: null,
	description: null,
	purpose: null,
	details: null,

	// miscellaneous data for payment confirmation
	ownerId: null,
	chargeId: null,
	paymentPurpose: null,
	tutorId: null,
	sessionId: null,
	directTutoringRequestMatchingObject: null,
	subscriptionId: null,
	subscriptionPlan: null,
	productId: null,
	productPriceId: null
};

export default function(state = initialState, action) {
	if (action.type === SET_PAYMENT_INFO) {
		return {
			...state,
			...action.payload
		};
	}
	else if (action.type === SET_PAYMENT_CONFIRMATION_INFO) {
		const payload = action.payload;
		Object.keys(payload).forEach(key => {
			localStorage.setItem(key, payload[key]);
		});

		return {
			...state,
			ownerId: payload.ownerId,
			price: payload.price,
			chargeId: payload.chargeId,
			paymentPurpose: payload.paymentPurpose,
			tutorId: payload.tutorId,
			sessionId: payload.sessionId,
			directTutoringRequestMatchingObject: payload.directTutoringRequestMatchingObject,
			subscriptionId: payload.subscriptionId,
			subscriptionPlan: payload.subscriptionPlan,
			productId: payload.productId,
			productPriceId: payload.productPriceId
		};
	}
	else if (action.type === CLEAR_PAYMENT_INFO) {
		Object.keys(initialState).forEach(key => {
			if (key !== "ownerId") {
				localStorage.removeItem(key);
			}
		});

		return {
			imgRef: null,
			price: null,
			quantity: null,
			description: null,
			purpose: null,

			// miscellaneous data for payment confirmation
			ownerId: null,
			chargeId: null,
			paymentPurpose: null,
			tutorId: null,
			sessionId: null,
			directTutoringRequestMatchingObject: null,
			subscriptionId: null,
			subscriptionPlan: null,
			productId: null,
			productPriceId: null
		};
	}
	else {
		return state;
	}
}

