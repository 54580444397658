import messageMap from "Utilities/MessageMaps";
import { convertArrayToObject } from "Utilities/ObjectUtility";

// top 100 languages from
// http://www2.harpercollege.edu/mhealy/g101ilec/intro/clt/cltclt/top100.html
const AMHARICH = "Amharic";
const ARABIC_ALGERIAN = "Arabic - Algerian";
const ARABIC_EGYPTIAN = "Arabic - Egyptian";
const ARABIC_MESOPOTAMIAN = "Arabic - Mesopotamian";
const ARABIC_MOROCCAN = "Arabic - Moroccan";
const ARABIC_NAJDI = "Arabic - Najdi";
const ARABIC_NORTHERN_LEVANTIN = "Arabic - Northern Levantine";
const ARABIC_SAIDI = "Arabic - Saidi";
const ARABIC_SANAANI = "Arabic - Sanaani";
const ARABIC_SUDANESE = "Arabic - Sudanese";
const ARABIC_TUNISIAN = "Arabic - Tunisian";
const ASSAMESE = "Assamese";
const AWADHI = "Awadhi";
const AZERBAIJANI = "Azerbaijani";

const BELARUSAN = "Belarusan";
const BENGALI = "Bengali";
const BHOJPURI = "Bhojpuri";
const BULGARIAN = "Bulgarian";
const BURMESE = "Burmese";

const CEBUANO = "Cebuano";
const CHHATTISGARHI = "Chhattisgarhi";
const CHINESE_GAN = "Chinese Gan";
const CHINESE_HAKKA = "Chinese Hakka";
const CHINESE_MANDARIN = "Chinese Mandarin";
const CHINESE_MIN_BEI = "Chinese Min Bei";
const CHINESE_MIN_NAN = "Chinese Min Nan";
const CHINESE_JINYU = "Chinese Jinyu";
const CHINESE_XIANG = "Chinese Xiang";
const CHINESE_WU = "Chinese Wu";
const CHINESE_YUE = "Chinese Yue";
const CHITTAGONIAN = "Chittagonian";
const CZECH = "Czech";

const DECCAN = "Deccan";
const DUTCH = "Dutch";

const ENGLISH = "English";
const FARSI = "Farsi";
const FRENCH = "French";
const FULFULDE = "Fulfulde";

const GERMAN = "German";
const GREEK = "Greek";
const GUJARATI = "Gujarati";

const HAITIAN_CREOLE = "Haitian Creole";
const HARYANVI = "Haryanvi";
const HAUSA = "Hausa";
const HINDI = "Hindi";
const HUNGARIAN = "Hungarian";

const IGBO = "Igbo";
const ILOCANO = "Ilocano";
const INDONESIAN = "Indonesian";
const ITALIAN = "Italian";

const JAPANESE = "Japanese";
const JAVANESE = "Javanese";

const KANNADA = "Kannada";
const KAZAKH = "Kazakh";
const KOREAN = "Korean";

const LOMBARD = "Lombard";

const MADURA = "Madura";
const MAGAHI = "Magahi";
const MAITHILI = "Maithili";
const MALAGASY = "Malagasy";
const MARATHI = "Marathi";
const MARWARI = "Marwari";
const MALAY = "Malay";
const MALAYALAM = "Malayalam";

const NEPALI = "Nepali";

const ORIYA = "Oriya";
const OROMO = "Oromo";

const PANJABI = "Panjabi";
const PASHTO = "Pashto";
const POLISH = "Polish";
const PORTUGUESE = "Portuguese";

const ROMANIAN = "Romanian";
const RUSSIAN = "Russian";
const RWANDA = "Rwanda";

const SARAIKI = "Saraiki";
const SERBO_CROATIAN = "Serbo-Croatian";
const SINDHI = "Sindhi";
const SINHALA = "Sinhala";
const SOMALI = "Somali";
const SPANISH = "Spanish";
const SUNDA = "Sunda";
const SWEDISH = "Swedish";

const TAGALOG = "Tagalog";
const TAMIL = "Tamil";
const TATAR = "Tatar";
const TELUGU = "Telugu";
const THAI = "Thai";
const TURKISH = "Turkish";

const UKRANIAN = "Ukranian";
const URDU = "Urdu";
const UYGHUR = "Uyghur";
const UZBEK = "Uzbek";

const VIETNAMESE = "Vietnamese";

const YORUBA = "Yoruba";

const ZHUANG = "Zhuang";
const ZULU = "Zulu";

const spokenLanguagesWithouDefault = convertArrayToObject([
	AMHARICH,
	ARABIC_ALGERIAN, ARABIC_EGYPTIAN, ARABIC_MESOPOTAMIAN, ARABIC_MOROCCAN, ARABIC_NAJDI, ARABIC_NORTHERN_LEVANTIN, ARABIC_SAIDI, ARABIC_SANAANI, ARABIC_SUDANESE, ARABIC_TUNISIAN,
	ASSAMESE, AWADHI, AZERBAIJANI,

	BELARUSAN, BENGALI, BHOJPURI,
	BULGARIAN, BURMESE,

	CEBUANO, CHHATTISGARHI,
	CHINESE_GAN, CHINESE_HAKKA, CHINESE_MANDARIN, CHINESE_MIN_BEI, CHINESE_MIN_NAN, CHINESE_JINYU, CHINESE_XIANG, CHINESE_WU, CHINESE_YUE,
	CHITTAGONIAN, CZECH,

	DECCAN, DUTCH,

	ENGLISH,
	FARSI, FRENCH, FULFULDE,

	GERMAN, GREEK, GUJARATI,

	HAITIAN_CREOLE, HARYANVI, HAUSA, HINDI, HUNGARIAN,

	IGBO, ILOCANO, INDONESIAN, ITALIAN,

	JAPANESE, JAVANESE,

	KANNADA, KAZAKH, KOREAN,
	LOMBARD,

	MADURA, MAGAHI, MAITHILI, MALAGASY, MARATHI,
	MARWARI, MALAY, MALAYALAM,

	NEPALI,
	ORIYA, OROMO,

	PANJABI, PASHTO, POLISH, PORTUGUESE,

	ROMANIAN, RUSSIAN, RWANDA,

	SARAIKI, SERBO_CROATIAN, SINDHI, SINHALA,
	SOMALI, SPANISH, SUNDA, SWEDISH,

	TAGALOG, TAMIL, TATAR, TELUGU,
	THAI, TURKISH,

	UKRANIAN, URDU, UYGHUR, UZBEK,

	VIETNAMESE,
	YORUBA,
	ZHUANG, ZULU
]);
export const spokenLanguages = {
	...{chooseLanguage: messageMap("tutoring.dropdowns.language", "button")},
	...spokenLanguagesWithouDefault,
};