import React from "react";

import Alert, {ERROR, SUCCESS} from "templates/Alert";
import { 
	SESSIONS_UPCOMING_SESSIONS, REQUESTS_OPEN_REQUESTS
} from "templates/utilities/PageTabs";

import messageMap from "Utilities/MessageMaps";

export function parseDecisionResponse(resp) {
	const responseCode = resp.responseCode;
	let msg = messageMap(responseCode, "api");

	if (responseCode.includes("accepted.success")) {
		if (resp.urgency !== "now") {
			let date = new Date(Number(resp.urgency));
			date = date.toLocaleString().split(" ");
			let time = date[1].split(":");
			time = [time[0], time[1]].join(":");

			msg = messageMap(responseCode, "api") + " " + date[0] + " " + time + " " + date[2] + ". " + messageMap("tutor.application.accepted.success.scheduleNextSteps", "api");
		}
		else {
			msg = messageMap("tutor.application.accepted.success.text1", "api") + " " + messageMap("tutor.application.accepted.success.scheduleNextSteps", "api");
		}
	}

	return {
		msg: msg,
		executionStatus: responseCode.includes("success") ? SUCCESS : ERROR
	};
}

export function parseNewRequestResponse(
	resp,
	historyPush, reRenderListHandler, setAlert, closeAlert
) {
		let alertType;
		let alertMsgs = [];

		if (resp["SUCCESS"]) {
			alertType = SUCCESS;
			let hasScheduledInstantBooking = false;
			resp["SUCCESS"].split("_").forEach(msg => {
				let localAlertMsg = messageMap(msg, "api");

				if (msg.includes("successDirect")) {
					localAlertMsg += (resp["TUTOR_NAME"] + messageMap("tutor.requests.automatic.finished.successDirect1", "api"));
					hasScheduledInstantBooking = true;
				}
				
				alertMsgs.push(localAlertMsg);
			});

			if (reRenderListHandler) {
				reRenderListHandler(Date.now(), true);
			}

			if (hasScheduledInstantBooking) {
				historyPush("/tutoring/messages");
			}
			else {
				historyPush(`/tutoring/requests?sub=${REQUESTS_OPEN_REQUESTS}`, { shallow: true });
			}
		}
		else if (resp["ERROR"]) {
			alertType = ERROR;
			resp["ERROR"].split("_").forEach(msg => {
				alertMsgs.push(messageMap(msg, "api"));
			});
		}

		if (setAlert) {
			setAlert(
				<Alert type={alertType} closeHandler={closeAlert} customClass="tutoring" 
					msg={alertMsgs.join(" ")} />
			);
		}
		else {
			return alertMsgs.join(" ");
		}
}

