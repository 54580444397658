// KEEP FIRST-LEVEL KEYS IN ALPHABETIC ORDER
// will be used as a catch-all for any remaining text that doesn't belong to the other message map types
export const genericMessageMap = {
	account: {
		pageTitle: "User Profile",
		fillRequiredFields: "Please fill the required fields to create a Stripe Connect account: ",
		fields: {
			profilePicture: "Profile picture",
			username: "Username",
			usernameAvailability: "Check availability",
			password: "Password",
			passwordConfirm: "Confirm password",
			name: "Name",
			nameKana: "Name kana",
			nameKanji: "Name kanji",
			firstName: "First name",
			lastName: "Last name",
			dob: "Date of birth",
			email: "Email address",
			emailConfirm: "Confirm email address",
			phone: "Phone number",
			address: "Address",
			streetAddress: "Street address",
			postalCode: "Postal code",
			postalCodeKana: "Postal code kana",
			postalCodeKanji: "Postal code kanji",
			city: "City",
			state: "State",
			country: "Country",
			countryNotSupported: " is not supported",
			fillCountry: `Please specify your country and update your account, so we can determine which 
				information is required for your country. NOTE*: Your country CANNOT be changed later.`,
			accessKey: "Access Key",
			modal: {
				// login
				editUsername: "Change your username",
				newUsername: "New username",
				editPassword: "Change your password",
				newPassword: "New password",
				reTypePassword: "Retype password",
				// personal information
				editName: "Change your name",
				newFirstName: "First name",
				newLastName: "Last name",
				editNameKana: "Change your name kana",
				newFirstNameKana: "First name kana",
				newLastNameKana: "Last name kana",
				editNameKanji: "Change your name kanji",
				newFirstNameKanji: "First name kanji",
				newLastNameKanji: "Last name kanji",
				editDob: "Change your date of birth",
				newDob: "Date of birth",
				// contact information
				editEmail: "Change your email address",
				newEmail: "Email address",
				reTypeEmail: "Retype email address",
				editPhone: "Change your phone number",
				newPhone: "Phone number",
				// location
				editAddress: "Change your address",
				newStreetAddress1: "Street address / PO Box",
				newStreetAddress2: "Apartment, suite, unit, or building",
				newPostalCode: "ZIP / Postal code",
				newPostalCodeKana: "Postal code kana",
				newPostalCodeKanji: "Postal code kanji",
				newCity: "City / district / suburb / town / village",
				newState: "State / county / province / region",
				newCountry: "Country",
				invalidAddressComponentsHeader: "Found invalid address components",
				invalidAddressComponentsDirective: "Please correct the following:"
			}
		},
		access: {
			attempts: "You've already attempted to login 3x. Please try again later.",
			betaKey: "Get Beta Key",
			earlyAccess: {
				header: "Early Access",
				get: "Get Early Access"
			},
			futureAndSharingAccess: {
				subHeader: "If you're interested in receiving early access for future releases, and if you would like to share early access to others, please ",
				later: "You can also do this later by creating an account, going to your profile page, and checking the appropriate boxes."
			}
		},
		login: {
			attempts: "You've already attempted to login 3x. Please try again later.",
			confirm: "You've already attempted to confirm 3x. Please try again later.",
			header: "Login",
			noAccount: "Create account:",
			google: {
				error: "Failed to login using Google. Received the following error: "
			}
		},
		forgotPassword: {
			header: "Recover account",
			inputLabel: "Email",
			resetPasswordHeader: "Reset password",
			inputNewPassword: "New password"
		},
		signUp: {
			header: "Sign Up",
			errorMsg: {
				msg: "Please correct the fields in ",
				highlight: "red *"
			},
			agreement: {
				text: "By signing up, you are indicating that you have read and agreed to the",
				termOfUse: "Terms & Conditions ",
				and: "and ",
				privacyPolicy: "Privacy Policy"
			},
			privacy: {
				data: "Our company strongly believes in giving you control of your data, but that can be compromised if we integrate 3rd party sign on. We hope you understand."
			},
			signUpFirst: "Let's get you signed up first",
			creditSignUp: {
				text: "You can complete the entire sign up process later to get school credit, or you can do it \t",
				now: "now"
			},
			existingAccount: "Already have an account?",
			google: {
				error1: "Failed to sign in using Google. Received the following error: "
			},
			or: "OR"
		},
		issue: {
			reportTitle: "Report an issue",
			describeIssue: "Please describe the issue"
		}
	},
	footer: {
		groupCategories: {
			platform: "Platform",
			company: "Company",
			resources: "Resources"
		}
	},
	company: {
		careers: {
			title: "Careers"
		},
		contact: {
			title: "Contact Us"
		},
		help: {
			title: "Help Center"
		},
		feedback: {
			title: "Feedback"
		}
	},
	groups: {
		copyCode: "Join group code successfully copied to clipboard.",
		pageTitle: "Group Accounts",
		ellipsis: {
			removeMember: "Remove member",
			assignRole: "Assign role"
		},
		joinGroupPopUp: {
			title: "Invite people to your group"
		},
		removeMemberPopUp: {
			subtextPrefix: "Are you sure you want to remove",
			subtextMiddle: "from",
			titlePrefix: "Remove"
		},
		tableHeaders: {
			username: "Account",
			currentLevel: "Current Level",
			subject: "Latest Quest",
			topic: "Latest Topic",
			totalPoints: "Total Points"
		}
	},
	landing: {
		learners: {
			headline: "Accelerate your learning with expert tutors",
			atExer: "At Exer Institute",
			detailsHeader: "We have all your learning needs",
			detail1: {
				header: "Discover tutors",
				info: [
					"Screened and vetted tutors",
					"Growing subject support",
					"Flexible pricing per topic"
				]
			},
			detail2: {
				header: "Easy-to-follow videos and interactive articles",
				info: [
					"Straight-to-the-point videos",
					"Bite-sized information",
					"Research-backed presentation"
				]
			},
			detail3: {
				header: "Unlimited practice problems",
				info: [
					"Improve your topic mastery",
					"Earn points for each problem solved",
					"Level up and get ahead of the competition"
				]
			},
			footer: {
				header: "Let's grow together",
				subHeader: "Start your learning journey today!",
			}
		},
		tutors: {
			headline: "Make an impact on someone's education",
			atExer: "At Exer Institute",
			detailsHeader: "We offer the best pricing flexibility compared to all our competitors",
			detail1: {
				header: "Earn up to 90% of your rate",
				info: "Our tiered payment system increases your take rate per session to up to 90% the more tutoring sessions you finish"
			},
			detail2: {
				header: "Set different prices per topic",
				info: "Maximize your growth and improve your reach by setting different prices per topic."
			},
			detail3: {
				header: "15-minute session slots",
				info: "Fill your schedule, maximize your earnings, and reach all learner's tutoring needs with our 15-minute session slots. Learners are able to book tutoring sessions in 15-minute blocks."
			},
			footer: {
				header: "Let's unlock learners' potential",
				subHeader: "Try Exer Institute today and we'll help you get your learning journey started",
			},
			signUpFirst: "After creating an account, go to Profile settings > Tutor profile > Become a tutor"
		},
		testimonials: {
			header: "Hear from experts",
			rondaWilliams: {
				name: "Ronda Williams",
				title: "31 years English teacher, Master in curriculum and instruction",
				review: `Exer Institute is student-centered. The What Do You Want to Learn Today video library, Read On, 
					Practice Categories, and Tutoring Sessions are excellent resources for students to strengthen, build, 
					and expand their understanding, knowledge, and self-confidence.`
			},
			dallasCarter: {
				name: "Dallas Carter",
				title: "Principal and former High School Director, Vice Principal, and Teacher",
				review: `Exer Institute combines usability and quality content. With an emphasis on academic and well-being 
					resources, it offers an exciting tool for students, teachers, and tutors. The role of the platform here is 
					different: enabling an engaging and supportive educational experience.`
			},
			kevinQuinlan: {
				name: "Kevin Quinlan",
				title: "Director of Education, Sentiment AI, Inc., Teacher in Springfield School District",
				review: `Exer Institute is a prominent platform that brings together tutors and tutees with the 
					common goal of continued learning. The platform has the potential to make its mark on the Education
					industry by simultaneously providing additional learning opportunities for tutees and tutors 
					to carry out their passion for teaching in a rewarding way that benefits everyone involved.`
			}
		},
		videoTutoringHeader: "How tutoring works"
	},
	home: {
		generalMarketing: `Supercharge your learning using the latest research in education!`,
		action: "Let's do it!",
		learners: {
			title: "For Learners",
			category1: {
				title: "Growing subject list",
				description: "Choose from a growing number of topics created, maintained, and improved by a community of educators and learners."
			},
			category2: {
				title: "Research-backed",
				description: "Accelerate your growth and learning by using our effective and comprehensive methods, backed by psychological, neurological, social, and pedagogical research."
			}
		},
		educators: {
			title: "For Educators",
			category1: {
				title: "Create premium content",
				description: "Collaborate with our talented and experienced educators to produce high-quality content that many would enjoy."
			},
			category2: {
				title: "Help educate the world!",
				description: "Be part of a growing community that will redefine what it means to learn online!"
			}
		},
		team: {
			meet: "Meet the team"
		},
		cta: {
			message: "Start your learning journey today"
		}
	},
	class: {
		request: {
			title: "Class Request",
			subheader: "You need to be registered to request for classes"
		}
	},
	classPage: {
		tooltips: {
			checklist: {
				text: "Requirements",
				alt: "A checklist icon. Click to show course requirements."
			},
			relevance: {
				text: "Relevance",
				alt: "An icon of a person with the exclamation mark. Click to show course relevance."
			},
			coverage: {
				text: "Conceptual Coverage",
				alt: "A bar chart icon. Click to see how well the course covers all related concepts."
			},
			structure: {
				text: "Structure",
				alt: "A structure icon. Click to show course structure."
			},
			pacing: {
				text: "Recommended Pacing",
				alt: "An icon of a person running. Click to show recommended course pacing."
			},
			goals: {
				text: "Goals",
				alt: "A treasure chest icon. Click to show what you'll understand and receive after course completion."
			},
			guidepost: {
				text: "Things to Keep in Mind",
				alt: "A guidepost icon. Click to show course assistance."
			},
			instructor: {
				text: "Instructor",
				alt: "An instructor icon. Click to show instructor's credentials."
			},
			updates: {
				text: "Latest Class Updates",
				alt: "An update icon. Click t show course's latest updates."
			},
			reviews: {
				text: "Reviews",
				alt1: "An image of 2 people talking to each other. Click to leave us a feedback.",
				alt2: "A star icon to symbolize reviews."
			},
			videoIntro: {
				text: "Introduction",
				alt: "A video icon to show the video introduction."
			},
			node: {
				text: "Concept Map",
				alt: "A node icon to signify the course concept map."
			}
		},
		feedback: {
			question: "Care to leave a review of your experience so far?",
			placeholder: "Tell us what you think of the course."
		},
		details: {
			text: "Course Details"
		}
	},
	knowledgeTree: {
		uploadMissingVideoTopic: "No videos found for this topic. Upload video about '",
		learn: "Learn '",
		itemType: {
			subjects: "Missing Subjects for ",
			topics: "Missing Topics for ",
			classes: "Missing Classes for ",
			degrees: "Missing Degrees for ",
			jobs: "Missing Jobs for ",
			profileItems: "Missing Profile Items for ",
			skillsAndKnowledgeItems: "Missing Skills and Knowledge Items for ",
			skillsKnowledgeSubtree: "Missing",
			interviewItems: "Missing Interview Items for "
		},
		assistance: {
			grades: {
				subject: "We're still compiling classes for this subject. Have some suggestions? Let us know.",
				class: "We're still compiling modules for this class. Have some suggestions? Let us know.",
				topic: "We're still compiling topics for this class. Have some suggestions? Let us know."
			},
			degrees: {
				degree: "We're still compiling classes for this degree. Have some suggestions? Let us know.",
				class: "We're still compiling modules for this class. Have some suggestions? Let us know.",
				module: "We're still compiling topics for this module. Have some suggestions? Let us know."
			},
			jobs: {
				profile: "We're still compiling types of online profiles for this job. Have some suggestions? Let us know.",
				skillsKnowledge: "We're still compiling background skills and knowledge for this job. Have some suggestions? Let us know.",
				skillsKnowledgeSubtree: "We're still compiling classes for this skill. Have some suggestions? Let us know.",
				interview: "We're still compiling interview preparations for this job. Have some suggestions? Let us know."
			}
		},
		feedback: {
			grades: {
				module1: "Missing topic(s) for '",
				module2: "'? Let us know.'",
				subtree1: "Missing classes(s) for '",
				subtree2: "'? Let us know.",
				root: "Missing subject(s)? Let us know"
			},
			degrees: {
				class1: "Missing topic(s) for '",
				class2: "'? Let us know.",
				subtree1: "Missing class(es) for '",
				subtree2: "'? Let us know.",
				root: "Missing degree(s)? Let us know"
			},
			jobs: {
				profile: "Missing profile requirement(s)? Let us know.",
				skillsKnowledge: "Missing skills and knowledge requirement(s)? Let us know.",
				skillsKnowledgeSubtree1: "Missing class(es) for '",
				skillsKnowledgeSubtree2: "'? Let us know.",
				interview: "Missing interview preparations? Let us know.",
				root: "Missing job(s)? Let us know"
			}
		},
		placeholders: {
			saveView: "View name"
		},
		quest: {
			construction: "Under Construction"
		},
		workbook: {
			gradeSchool: "Grade School",
			degrees: "Degrees",
			jobs: "Jobs",
			setTreeType: "Set network"
		}
	},
	learnPage: {
		tooltips: {
			comments: {
				alt: "Comments icon. Small textbox containing ellipses. Click to display questions, answers, and general comments."
			},
			nodes: {
				alt: "Node icon. Five nodes connecting to a central node in the center. Click to display how this topic relates to other topics within the subject."
			},
			notes: {
				alt: "Notes icon. Sheet of paper with pencil signifying that it's writing something. Click to display your notes on the video."
			},
			videoList: {
				alt: "Video search icon. Magnifying glass on top of video icon. Click to display subject's list of videos."
			},
			transcript: {
				alt: "Transcript icon. Sheet of paper with a microphone icon next to it. Click to display video's transcript."
			},
			bookmark: {
				alt: "Bookmark icon that looks like a flag. Click to add to list of bookmarked videos."
			},
			bookmarkList: {
				alt: "Bookmarks icon. Click to display list of bookmarked videos."
			}
		},
		relatedVideos: "Related videos",
		mentalModel: {
			title: "Mental Model"
		},
		courseContent: {
			text: "Course Content"
		},
		navigation: {
			previousTopic: "Previous",
			nextTopic: "Next"
		},
		notes: {
			modalTitle: "New note at"
		},
		prompt: {
			ending: "What did you learn?",
			tooltip1: "This helps solidify your understanding and you can refer to this later when reviewing.",
			tooltip2: "Answering prompts helps to mentally engage you in the topic and improves conceptual retention."
		},
		transcript: {
			text: "Transcript"
		}
	},
	payments: {
		checkout: {
			proSubscriptionPlan: "Exer Pro Subscription Plan",
			shinyNeuronPack: "Shiny Neuron Pack",
			tutoringSession: "Tutoring session with ",
			subtotal: "Subtotal",
			total: "Total",
			usd: "USD ",
			selected: "selected",
			unexpectedError: "An unexpected error occurred.",
			subscriptionDiscount: "Because you're a subscriber, enjoy your 10% discount for this tutoring session!"
		},
		saveMethod: {
			title: "Save payment method",
			card: "Card",
			cashapp: "Cash App Pay",
			amazon_pay: "Amazon Pay",
			bancontact: "Bancontact",
			ideal: "iDEAL"
		},
		confirmation: {
			checkoutThankYou: "An email payment confirmation has been sent. You're now being redirected back to the home page. Happy learning!",
			savedPaymentThankYou: "Payment method saved. You are now being redirected back to the financials page.",
			tutoringPaymentThankYou: "An email payment confirmation has been sent. You are now being redirected back to tutoring. Good luck in your studies!",
			subscriptionThankYou1: "Your are now a power learner!",
			subscriptionThankYou2: " An email payment confirmation has been sent regarding your subscription. You are now being redirected back to the home page. Happy learning!"
		},
		quotes: {
			bonusString: "% bonus!",
			continue: "Continue",
			quotesHeader: "Let's Supercharge Your Journey",
			paymentDescription: " shiny neurons"
		}
	},
	payouts: {
		stripeConnect: {
			title: "Stripe Connect",
			subheader1: `When providing ID verification, please make sure your full legal name and date of birth matches your 
				account profile. If available, include your picture when providing your proof of ID.`,
			subheader2: `For your security, we do not save and store the information you provide here. We pass them to Stripe to 
				confirm your identity and to help facilitate payout transfers to you.`,
			subheader3: `Starred * fields are required. Stripe may require additional information after creating your Connect 
				account. After filling this form, when you visit the profile page, you will see a pop-up indicating any additional 
				information Stripe requires to verify your identity`,
			subheader4: "Please allow 3-5 business days for Stripe to review your documents.",
			subheader5: `You can monitor the verification status by going to the "Financials" sub page. On the "Payout method" 
				field, if the button says "Create account", this means that Stripe is still verifying your account. If it says "Change 
				payout method", this means your Connect account is verified and you can start receiving payments. At this point, you 
				can also choose to update your payout method.`,
			fields: {
				urlBusinessProfile: "Business profile url",
				govtIdNumber: "Gov't ID number",
				ssn: "Last 4 of your ssn",
				payoutMethod: "Payout method",
				cardInformation: "Card information",
				bankInformation: "Bank information",
				idVerification: "ID verification",
				addressVerification: "Address verification",
			},
			provided: "Provided "
		}
	},
	profilePage: {
		subHeader: {
			groupCode: "Group code",
		},
		subscription: {
			fields: {
				subscriptionPlan: "Subscription Plan"
			}
		},
		groups: {
			code: "Code",
			createGroup: {
				groupName: "Group name",
				name: "Name",
				subtext: "Users are allowed to be in one group. Once you create or join a group, you can no longer join any other groups.",
				title: "Create group"
			}
		},
		financials: {
			fieldValues: {
				card: "Card",
				bank: "Bank"
			},
			fields: {
				paymentMethod: "Payment method",
				payoutMethod: "Payout method",
				withdrawWStripeConnect: "Withdraw with Stripe Connect",
				earnings: "Total earnings"
			}
		},
		tutoring: {
			fields: {
				tutorWithUs: "Tutor with us"
			},
			modal: {
				becomeATutor: "Become a tutor",
				application: {
					header: "Application",
					location: "Location",
					screeningQuestions: "Screening questions",
					accountStatus: "Account status",
					stripeAccount: "Create Stripe Connect account",
					acceptPaymentsWStripeConnect: "Accept payouts with Stripe Connect",
					profilePicture: "Profile picture",
					fullName: "Full name",
					occupation: "Title",
					bio: "Bio",
					phoneNumber: "Phone number",
					notificationPreference: "Receive notifications for tutoring requests via",
					fluentLanguages: "Fluent languages besides English",
					disabilities: "Disabilities you're willing to accommodate",
					resume: "Resume",
					review: "review: ",
					"in-progress": "in-progress",
					approved: "approved",
					denied: "denied",
					subjectTopicProof: "Video link of you demonstrating relevant work, skills, or experience. Your face needs to be shown in the video.",
					deleteProof: "Permanently delete proof of credibility? This will affect the topics you can tutor.",
					teachingCredentials: "Teaching certification / licenses",
					professionalExperience: "Proof of professional experience",
					education: "Proof of education",
					linkedIn: "LinkedIn url",
					website: "Website url",
					topicSubject: "Subject topic proofs",
					rate: "Your price rate",
					sameRate: "Same rate for all subjects & topics",
					fifteenMinuteTrials: "Allow 15-minute trials",
					instantBooking: "Allow instant booking",
					inPersonTutoring: "Allow in-person tutoring",
					tutoringRadius: "In-person tutoring radius",
					miles: "miles",
					missingCompleteAddress1: "Please provide a complete address ",
					missingCompleteAddress2: " before allowing in-person tutoring.",
					cancellationPolicy: "Cancellation policy",
					schedule: "Schedule"
				},
				country: {
					notSupported: " is not supported"
				},
				accountVerification: {
					verified: "Account verified"
				},
				stripe: {
					linkAccount: "Create account",
					updateAccount: "Update account",
					linkedAccount: "Stripe Connect account verified",

					accountRequirements: "Stripe verification requirements",
					personalInformationRequired: "Personal information due: ",
					verificationErrors: "Verification errors:",
				},
				price: {
					label: "Your hourly price rate",
					setPriceTitle: "Set hourly price for: ",
					variablePriceInfo: "(Any unpriced topics will match your highest price)"
				},
				cancellation: {
					over: "Over",
					between: "Between",
					to: "to",
					under: "Under",
					hours: "hours",
					hoursIs: "hours is",
					noShow: "hours and no-shows",
					deductedCost: "% off the total session cost"
				},
				schedule: {
					setScheduleTitle: "Set schedule for: ",
					unavailable: "Unavailable",
					to: "to",
					unscheduledDays: "(Any unscheduled days will be considered unavailable)"
				},
				uploads: {
					photo: "Upload photo",
					changePhoto: "Change photo",
					resume: "Upload resume",
					licenseCertification: "Upload certification / license",
					experience: "Upload proof of experience",
					education: "Upload proof of education"
				},
				agreement: {
					title: "I have read and agreed to the",
					contract: "Tutor Policy ",
					stripServicesAgreement: ", Stripe Services Agreement, ",
					stripeRecipientAgreement: " Stripe Recipient Agreement."
				},
				requirements: {
					header: "Requirements",
					p1: `Be at least one of the following: a teacher, professor, adjunct, has a master's degree in field of expertise, has experience in relevant industry, or is studying, or hast studied, the subject for their major.`,
					p2: `Strong oral and writing fluency in English`,
					p4: `Be at least 18 years old.`,
					p5: `Have access to and are comfortable using your own computer - including working speakers, microphone, and webcam - and high-speed internet connection.`,
					p5a: "Use a professional head shot picture for your profile picture.",
					header1: "Application process",
					p6: "Fill the 'Become a tutor' form. Make sure that your 273px x 273px picture is clear. Please note that all countries are not supported.",
					p7: `For each subject topic you want to tutor for, provide a link to a video demonstrating relevant work, skills, or 
						experience. Your face needs to be shown in the video as you demonstrate your knowledge and/or skills on the topic. 
						We recommend starting with one subject topic, since providing proof for more than one subject topic can lengthen your 
						application review, especially if one subject topic proof is deemed lacking as proof of experience.`,
					p8: `Once you fill all required fields and provide at least one proof of knowledge and/or skills on a topic and you save your profile, 
						our support team will review your application.`,
					p9: `After our team has confirmed that you have demonstrated sufficient evidence of your knowledge and/or skills on a topic, you 
						will receive an email informing you that your tutoring account is active and that you are able to tutor on the topic you have 
						provided proof for. Please allow up to two weeks after you have finished filling out your application.`,
					p10: `If you have not demonstrated sufficient evidence, you will receive an email informing you regarding this decision. You have 
						unlimited attempts to demonstrate your knowledge and/or skills on the topic.`,
					p101: `If you later wish to teach multiple subjects and/or topics, you will need to provide evidence for those as well.`,
					header2: "Pricing structure",
					p11: `Depending on the number of tutoring sessions you've done, we take a 20%, 15%, or 10% commission`,
					p12: ` from the session's total cost, not from your posted rate. We use this to cover our operational costs  and attract more 
						learners, so you don't have to worry about finding your clients. Once you've done 75 sessions, we take 15% 
						commission, and once you've done 200 sessions, which includes the previous 75 sessions, we take 10% commission.`,
					p13: `Each session is a multiple of 15 minutes, meaning a session can be 15 minutes, 30 minutes, 45 minutes, 1 hour, or longer, 
						depending on how much time a client needs.`,
					p14: "To further compensate for the low 10% commission we receive from your sessions,",
					p15: ` clients will shoulder an additional 15%, 10%, or 5% of your total rate. The more sessions you log, the 
						lower the additional percentage clients will shoulder.`,
					p16: "For example, for a given $50/hr rate ",
					p17: ` depending on how many sessions a tutor already has, an hour session's total cost can be $50 + (50 * 0.15) = `,
					p18: `$57.50,`,
					p19: ` $50 + ($50 * 0.1) = `,
					p20: `$55,`,
					p21: ` OR $50 + ($50 * 0.05) = `,
					p22: `$52.50. For all scenarios, your take will be $50 - ($50 * 0.1) = $45.`,
					p23: `If the client is a subscribing user, they will receive 10% off the total session cost. `,
					p24: ` Because we don't want to undercut tutors and since the platform is already benefiting from the subscription 
						price, `,
					p25: "tutors will instead receive 85%, 90%, or 95% of the remaining session cost, ",
					p26: " depending on the number of tutoring sessions they've finished.",
					p30: `For odd multiples of 15 minutes, for example 15 minutes, 45 minutes, 1 hour 15 minutes, etc, 
						the session cost will be a fraction of your hourly rate (i.e. 15 minutes for a quarter of your hourly rate) 
						and an additional 10% of that divided rate. This is to protect tutors from possible lost time, since tutees 
						don't usually book tutoring session at odd multiples of 15 minutes and to incentivize tutees to book even
						multiples of 15 minutes instead.`,
					header3: "Pay out structure",
					p32: `All payouts are provided through Stripe. We handle the charge fees, so you can keep the remaining. 
						Please visit the following link to learn more: https://stripe.com/connect/pricing`,
					p33: "To enable pay outs, simply follow the Stripe Connect onboarding process when creating your tutor profile.",
					p33a: `Every time a Tutee hires you for a Session, you receive a charge on your Stripe Connect account. 
						Stripe pays out charges to your Connect account on a rolling basis, usually daily. Please allow up to 7 business 
						days for pay outs to come through after a session finishes. For risk mitigation, your first payout can take
						7 - 14 business days. If you still have not received your payout after 14 days, please contact support@exerinstitute.com 
						by clicking on 'Report an issue' at the bottom of this page.`,
					header4: "Tutoring process",
					p34: `All tutoring will be conducted via a Zoom call and using the interactive whiteboard.`,
					p35: `You can receive tutoring requests by either having clients directly requesting you, or you can 
						apply to jobs posted by clients. You can apply to as many job postings as you desire for free.`,
					p36: `You can also use our chat to communicate with clients to prepare for your sessions.`,
					p37: "Further details regarding our tutoring policies can be found at the bottom of website.",
					// only shown in active profile
					p38: "Your profile would be automatically shown to the public when all required fields are filled and when you have at least one available day."
				}
			},
			page: {
				requestTitle: "Tell us more"
			}
		},
		learner: {
			subheader: "Providing these optional information helps us improve the tutors you match with.",
			grade: "Grade",
			country: "Country",
			fluentLanguages: "Fluent languages",
			disabilities: "Disabilities",
			timeOfStudy: "Preferred study times",
			timeOfStudyOn: "Preferred time to study on",
			hobbiesIntegers: "Hobbies / interests",
			habitsRoutines: "Habits / routines"
		}
	},
	tutoringPage: {
		request: {
			subject: "Subject",
			topic: "Topic",
			place: "How do you want to be helped?",
			miles: "miles from you",
			kilometers: "kilometers from you",
			findInPerson1: "Find tutors within ",
			findInPerson2: "miles from you",
			virtual: "Virtual",
			inPerson: "In-person",
			assistanceUrgency: "When do you need the help?",
			matching: "How do you want to be matched?",
			sessionWith: "session with ",
			receivedFrom: "Received from:",
			sendTo: "Send request to",
			sentTo: "Sent to:",
			description: "Description",
			status: "Status",
			acceptedPending: "Accepted. Pending on requester...",
			assistanceDescription: "Describe what you need help on",
			setFirstLastNamePaymentMethodFirst1: "Please sign up ",
			setFirstLastNamePaymentMethodFirst2: ` and set your first and last name and add a payment method under 
				profile settings before booking your first tutoring session.`,
			firstLastOrChargeablePaymentMethodMissing1: `Please set your first and last name `,
			firstLastOrChargeablePaymentMethodMissing2: `Please add a payment method  `,
			here: "here",
			alertFirstLastOrChargeablePaymentMethodMissing: `Please set your first and last name and a payment 
				method before booking your tutoring session.`,
			setFirstLastName: "Please set your first and last name under profile settings before booking your first tutoring session.",
			setPaymentMethodFirst: "Please set a payment method before booking your first tutoring session",
			setRequestDescription: "Please provide a brief description of what you need help on.",
			processingPayment1: "Processing ",
			processingPayment2: "on your preferred payment method.",
			invalidPaymentOrAuthNeeded: "Found invalid payment method or need additional authentication. Redirecting you to the checkout page.",
			verifyStripeConnectAccountFirst: "Please create and verify your Stripe Connect account on your tutor profile before applying.",
			modal: {
				schedule: "Schedule",
				scheduleNow: "Schedule now for",
				scheduleAt: "Schedule at",
				for: "for",
				dateTime: "Date and time:",
				duration: "Duration:",
				minutes: "minutes",
				deletion: "Deleting tutoring request for: ",
				titleNoFirstLastPaymentMethod: "Please set your first and last name and save a payment method before booking your first tutoring session",
				titleConfirmDeletion: "Confirm request deletion",
				titleAcceptTutors: "Accept tutor's application?",
				acceptSessionCost: "Session cost is $",
				acceptAutomaticDeletion: "Accepting this tutoring request will automatically delete tutoring requests on the same date and time.",
				titleDeclineTutors: "Decline tutor's application?",
				titleAcceptTutee: "Accept student's request?",
				titleDeclineTutee: "Decline student's request?",
				titleApplyToRequest: "Apply to this tutoring request?",
				titleApplyToRequest1: "Apply to ",
				titleApplyToRequest2: "'s tutoring request?",
				titleDeleteChat: "Delete chat permanently?"
			},
			limitReached: "Request limit of 10 has been reached.",
			allTutors: "All tutors",
			savedTutors: "Saved tutors",
			userType: {
				default: "Student mode",
				tutor: "Tutor mode"
			},
			noRequestsSentAndReceived1: "You did not receive and have sent any session requests. Please click",
			noRequestsSentAndReceived2: "to apply to tutoring requests."
		},
		session: {
			modal: {
				titleCancelSession: "Cancelling Session",
				cancelSubheader1: "Cancelling this session with ",
				cancelSubheader2: "on ",
				cancelSubheader3: "for ",
				reminder: "Reminder",
				tuteeFullRefund: "Depending on your cancellation policy, cancelling this session can issue a full refund to the tutee.",
				cancelling1a: "If you cancel at least ",
				cancelling1b: "hours before the session, ",
				cancelling1ca: "you'll receive a full refund.",
				cancelling1cb: "there will be no fee.",
				cancelling2a: "If you cancel between ",
				cancelling2b: "and ",
				cancelling2c: "hours before the session, ",
				cancelling2da: "you'll pay ",
				cancelling2db: "you'll pay a ",
				cancelling2ea: "of the session's total cost.",
				cancelling2eb: "fee as compensation to your tutee.",
				cancelling3a: "If you cancel less than ",
				cancelling3ba: "hours before the session, or if you're a no-show in the first 5 minutes, you'll pay ",
				cancelling3bb: "hours before the session, or if you're a no-show in the first 5 minutes, you'll pay a",
				cancelling3ca: "of the session's total cost.",
				cancelling3cb: "fee as compensation to your tutee.",
				titleJoiningSession: "Joining session",
				joiningSubheader1: "Starting this session with ",
				joiningSubheader2: "on ",
				joiningSubheader3: "for ",
				joiningAdvice1: "We highly recommend adults to be present during the tutoring session, especially if you are under 18 years old.",
				joiningAdvice2: `To protect both parties, we strongly discourage the exchange of personal information, 
					including social media profiles, and the exchange of money outside the platform. This is against our terms 
					of service. If the other person violates these rules, please report them immediately by navigating to the 'Live sessions' 
					tab, clicking on the ellipsis icon, and choosing the report option.`,
				joiningAdvice3: `If you get logged out, or lose connection, please navigate to the 'Live sessions' to rejoin the meeting.`,
				joiningAdvice4: `Lastly, please check your speakers, microphone, and webcam settings on the following screen, 
					and please make sure that you have access to high-speed internet connection to prevent any lag.`,
				joiningAdvice5: `This session may be monitored and recorded for record-keeping, training, and 
					quality assurance purposes. If you experience any technical difficulties, please report the issue to support@exerinstitute.com 
					by clicking on 'Report an issue' at the bottom of this page.`,
				titleNoShow: "No-show",
				failedToShowUp: `The other party have failed to show up within the first 5 minutes of the 
					session. You're now free to leave the session if you wish.`,
				report: "Report ",
				chooseFollowing: "Cause of the report",
				additionalDetails: "Additional details",
				guidelinesPolicies: "Guidelines and policies",
				giveReview: "Give a review",
				rateSession: "How would you rate your session?",
				rateDetails: "Tell us about your session"
			},
			timeElapsed: "Time elapsed:",
			cannotJoin: "Cannot join more than 5 minutes early.",
			status: "Status:",
			disputeResolved: "dispute resolved",
			no: "No",
			sessions1: "sessions. A session would appear here once a ",
			sessions2a: "pending request gets scheduled.",
			sessions2b: "scheduled session goes live.",
			sessions2c: "live session completes.",
			pending: "pending",
			scheduled: "scheduled",
			live: "live",
			completed: "completed",
			disputed: "disputed",
			tuteeCanceled: "tutee canceled",
			tuteeNoShow: "tutee no-show",
			tutorNoShow: "tutor no-show",
			overallRating: "Your overall rating:",
			notRated: "Not rated",
			sessionCount: "Session count:",
			helpViaFeedback: "Help the other person by giving them a review",
			seeOtherPartyReview: "Give a review to get the other person's review of you",
			pendingReview: "Pending on the other person's review"
		},
		publicTutorProfile: {
			sortBy: "Sort by",
			highestRating: "Highest rating",
			lowestRating: "Lowest rating",
			recentRating: "Recent rating",
			noReviews: "No tutee reviews",
			savedCopy: "Copied tutor profile's link",
			// don't change these keys or move them. Their keys are being dynamically used
			noscheduled: "No scheduled sessions",
			nolive: "No live sessions",
			nocompleted: "No past sessions",
		},
		tutorList: {
			schedule: "Schedule",
			modal: {
				viewFullDetails: "View full tutor details",
				"240": "4 hours",
				"225": "3hrs 45 minutes",
				"210": "3hrs 30 minutes",
				"195": "3hrs 15 minutes",
				"180": "3 hours",
				"165": "2hrs 45 minutes",
				"150": "2hrs 30 minutes",
				"135": "2hrs 15 minutes",
				"120": "2 hours",
				"105": "1hr 45 minutes",
				"90": "1hr 30 minutes",
				"75": "1hr 15 minutes",
				"60": "1 hour",
				"45": "45 minutes",
				"30": "30 minutes",
				"15": "15 minutes",
				fifteenTrial: "15-minute trial available",
				services: {
					fifteenTrial: "15-minute trial",
					inPersonAvailable: "In-person tutoring",
					instantBookingAvailable: "Instant booking"
				},
				instantBookingAvailable: "Instant booking available",
				compareTutors: "Compare tutors",
				tutorOverview: "Overview",
				credentials: "Credentials",
				offerings: "Offerings",
				availability: "Availability",
				policy: "Policy",
				changeCapacity: "Change capacity",
				maxApplicants: "Change the maximum number of applicants before job gets hidden."
			},
			pane: {
				close: "Close",
				availableServices: "Available services",
				availableSessions: "Available sessions",
				availability: "Availability",
				cancellationPolicy: "Cancellation policy",
				unavailable: "Unavailable",
				perHour: "per hour",
				resume: "Resume",
				modal: {
					continue: "Continue",
					step12: "Step 1 of 2",
					confirmBooking: "Confirm booking",
					continueToPayment: "Continue to Payment",
					step22: "Step 2 of 2",
					bookAn: "Book an",
					session: "session",
					minSlots: "min slots",
					minSession: "min session",
					cost: "Cost",
					total: "Total",
					taxesFees: "Includes taxes and fees",
					chargeTime1: "Your account will be charged if ",
					chargeTime2: "accepts your request.",
					addMessage: "Add a message to ",
					required: "Required",
					validDay: "Please choose a day."
				}
			},
			emptyOpenRequestsTutor: "No open tutoring requests matching your tutor profile's topics. Please check back later.",
			emptyOpenRequestsTutee: "Please create a tutoring request by going to the 'New request' tab. Once created, you'll see your open requests here. When tutors apply to your request, you can view their applications in the 'Tutor applications' page ",
			emptyApplications1: `Received no tutor applications. Applications will appear here once tutors have applied to your session request. 
				Please create a request `,
			emptyApplications2: "if you haven't already done so.",
			emptyTutoringRequests: "No tutoring requests on topics you can teach.",
			emptyTutoringApplicants: "No applications to your tutoring requests. Please check back later.",
			emptyTutoringList: "No results. Please adjust your filters to see available listings.",
			noAddressForInPerson1: "Please indicate your current address ",
			noAddressForInPerson2: " before looking for tutors near you.",
			indicateAddressAfterSigningUp: "Please indicate your address in profile settings after signing up to find nearby tutors",
			emptySession1: "No ",
			emptySession2: "No ",
			here: "here ",
			signUpFirstNewRequests: "Please sign up or login to see your open requests by clicking this ",
			signUpFirstApplication: "Please sign up or login to see tutor applications by clicking this ",
			signUpFirstTutoringRequests: "Please sign up or login to see tutoring requests by clicking this ",
			signUpFirstSaved: "Please sign up or login to see saved tutors by click this ",
			signUpFirstSessions1: "Please sign up or login first ",
			signUpFirstSessions2: " and create a tutoring request in the 'Request a tutor' sub page to see tutoring sessions."
		},
		chat: {
			noMessages: "No messages",
			tutor: "If you're a tutor, please apply to tutoring session requests in the 'Tutor applications' menu to talk to tutees.",
			tutee: "If you're a tutee, please create a tutoring session request in the 'Request a tutor' menu to talk to tutors."
		},
		faqs: {
			header: "How can we help?",
			tutors: {
				tutoringRequirements: "Tutoring requirements",
				tutoringRequirementsDescription: "Find out the requirements to be eligible to become a tutor.",
				applicationProcess: "Application process",
				applicationProcessDescription: "Learn the process to become a tutor and what you need to do to prepare.",
				pricingStructure: "Pricing structure",
				pricingStructureDescription: "Understand how much of your rate you receive for every tutoring session.",
				paymentStructure: "Pay out structure",
				paymentStructureDescription: "Learn the mechanism that we use to send you payments.",
				tutoringProcess: "Tutoring process",
				tutoringProcessDescription: "Learn the process from receiving clients, communicating with them, and teaching them."
			},
			tutees: {
				fifteenMinuteTrials: "15-minute trials",
				fifteenMinuteTrialsDescription: "Learn how 15-minute trials work and how it benefits tutors and tutees.",
				fifteenMinuteTrialsDescriptionFull1: `If a tutor offers fifteen-minute trials, this means that the first tutoring session on the first 
					fifteen minutes between the tutor and tutee would be completely free. There are no additional 
					actions needed from both the tutor and the tutee, since the system automatically keeps track of 
					the minutes spent.`,
				fifteenMinuteTrialsDescriptionFull2: `This allows tutees to determine if the tutor works well with them and this gives the tutor 
					the opportunity to showcase their capability to teach without significant monetary loss.`,
				userSafety: "Child safety",
				userSafetyDescription: "Learn best practices to protect your children.",
				userSafetyDescriptionFull1: `Depending on your child's age, we recommend that parents are present when their child uses our 
					tutoring services, especially if the child is under 18. For further assurance, it is important 
					to educate your children on the dos and don'ts of being online. This includes keeping their personal 
					information private and being careful with sharing what type of information with whom online via 
					chat and other forms of communication.`,
				userSafetyDescriptionFull2: `Additionally, because your Exer Institute account belongs to the kid's parent, or guardian, it is 
					important to remember that any purchases and scheduled lessons should only be carried out by the 
					kid's parent, or guardian.`,
				lessonUrgency: "Lesson urgency",
				lessonUrgencyDescription: "Learn the best times to schedule a tutoring request.",
				lessonUrgencyDescriptionFull1: `While we understand the benefits in finding a tutor at a moment's notice, tutors 
					would have the most time to prepare when a tutoring request is instead scheduled. 
					By allowing tutors to prepare ahead of time, not only will the tutoring session's time 
					be optimally used, but the tutor can also create plans specifically suited for the tutee.`,
				lessonUrgencyDescriptionFull2: `Scheduled tutoring requests also allows the tutee to more carefully decide which tutor to
					learn from, in addition to considering the tutor's rates, background, cancellation policies.`,
				lessonUrgencyDescriptionFull3: `According to data, it is best to schedule a tutoring requests at least 24 hours before 
					the appointment, and that it is best when the tutee is most ready to study and learn. While 
					preferred study times vary per tutee, we found that scheduling a tutoring session after 
					a tutee's routine regular break is best.`
			}
		},
		policies: {
			header: "Policies",
			tutors: {
				independentTutorPolicy: "Independent Tutor Policy",
				independentTutorPolicyDescription: "Full requirements to tutor, including information on payments, code of conduct, and resolving disputes.",
				cancellationPolicy: "Cancellation Policy",
				cancellationPolicyDescription: "Properly reschedule and cancel a tutoring session without any penalties.",
				policyViolations: "Policy Violations",
				policyViolationsDescription: "What constitutes as policy violations and repercussions.",
				sessionPolicy: "Session Policy",
				sessionPolicyDescription: "Your responsibilities and duties as a tutor both for yourself and for your student."
			},
			tutees: {
				tuteePolicy: "Full Tutee Policy",
				tuteePolicyDescription: "Full requirements to use the tutoring services, including information on payments, code of conduct, and resolving disputes.",
				cancellationPolicy: "Cancellation Policy",
				cancellationPolicyDescription: "Properly reschedule and cancel a tutoring session without any penalties.",
				policyViolations: "Policy Violations",
				policyViolationsDescription: "What constitutes as policy violations and repercussions.",
				sessionPolicy: "Session Policy",
				sessionPolicyDescription: "Expectations as a tutee both for yourself and for the Tutor."
			}
		},
		whyExer: {
			header: "Why we're the best tutoring platform",
			tutoringExperience: "Tutoring experience",
			qualityTutors: {
				text: "We have quality tutors",
				screening: "We rigorously screen our tutors using a questionnaire and a video call interview; the screening process is designed to identify experienced and skilled tutors.",
				pedigree: "All our tutors are either a teacher, a professor, has a master's degree in their field of expertise, has experience in a relevant industry, or is studying, or hast studied, the subject for their major.",
				degree: "All our tutors either hold at least a 4-year degree or are in the process of completing a 4-year degree.",
				backgroundCheck: "We background check all our tutors."
			},
			studentBenefits: {
				text: "Great benefits",
				grades: "Improve your grades",
				progress: "Catch up and even surpass your class",
				acceleratedLearning: "Accelerate your learning (up to 2x)",
				assistance: "Homework help and subject test preparation",
				highDosage: "Another option for high-dosage tutoring"
			},
			wereBetter: {
				text: "More reasons why we're better...",
				collaboration: "Multiple collaborative options: interactive whiteboard, tutoring session recording, video chat, chat messaging, and application sharing",
				analytics: "We're also incorporating data analytics in our process to further optimize learner outcomes.",
			},
			amazingForTutors: "Amazing for tutors",
			whyUse: {
				text: "Why we're better",
				pricingFlexibility: "We offer the most pricing flexibility that both maximizes returns for our tutors and optimizes efficient spending for our tutees.",
				weCare: "Because we want to treat our tutors fairly, our tutors can keep up to 95% of their posted rate.",
				upToDate: "We regularly provide optional training to make sure that you're up-to-date with the most effective teaching method."
			}
		}
	},
	sideFilter: {
		filterBy: "Filter by:"
	},
	calendar: {
		months: {
			january: "January",
			february: "February",
			march: "March",
			april: "April",
			may: "May",
			june: "June",
			july: "July",
			august: "August",
			september: "September",
			october: "October",
			november: "November",
			december: "December"
		},
		days: {
			sunday: "Sunday",
			monday: "Monday",
			tuesday: "Tuesday",
			wednesday: "Wednesday",
			thursday: "Thursday",
			friday: "Friday",
			saturday: "Saturday"
		},
		minSlots: "min slots"
	},
	metadata: {
		browser: {
			notDetected: "Browser not detected."
		},
		platform: {
			notDetected: "Platform not detected."
		}
	},
	video: {
		browserSupport: "Your browser does not support HTML video.",
		processing: "Processing...",
		upload: {
			video: "Upload Video",
			thumbnail: "Upload Thumbnail",
			subtitles: "Upload Subtitles",
			mentalModel: "Upload Mental Model",
			topic: "Assign Topic",
			topicSuggestion: {
				none: "No Topics Yet Identified for ",
				suggest: "Suggest a Topic:"
			},
			pricing: "Pricing",
			topicExercises: {
				none: "No Exercise Yet Created for ",
				suggest: "Suggest an Exercise?:"
			}
		},
		placeholder: {
			title: "Video title",
			moduleTitle: "Module title",
			thumbnail: "Thumbnail description - for user accessibility & search engine crawlers",
			videoDescription: "Video description...",
			moduleDescription: "Module description...",
			files: "No file chosen",
			topic: "No associated topic",
			searchTopic: "Search topic"
		},
		uploadPage: {
			title: "Let's First See What Research Says About Making Awesome Videos",
			subheader: "The following are some suggestions and requirements to make high quality educational content",
			note: "Note*: These guidelines apply to learners age 12 and up.",
			uploadTitle: "Let's Upload Your Content",
			uploadSubheader: "Please fill the following form fields.",
			guidelines: {
				information: {
					title: "Gathering and Organizing Information"
				},
				delivery: {
					title: "Delivery"
				},
				viewers: {
					title: "Attracting Viewers"
				},
				accessibility: {
					title: "Accessibility Requirements & Suggestions"
				},
				miscellaneous: {
					title: "Miscellaneous"
				}
			}
		},
		editPage: {
			title: "Channel Content",
			videosTableHeaders: {
				title: "Title",
				mentalModel: "Mental Model",
				uploadDate: "Upload Date",
				viewCount: "View Count",
				pricing: "Pricing",
				earnings: "Earnings"
			},
			modulesTableHeader: {
				title: "Title",
				uploadDate: "Upload Date"
			},
			dropdown: {
				videos: "Videos",
				modules: "Modules",
				classes: "Classes"
			},
			totalEarnings: "Total Earnings"
		},
		listPage: {
			featured: "Featured Video",
			title: "What Do You Want to Learn Today?",
			views: "Views",
			ownTheVideo: "Want to own this Video?",
			placeholder: {
				search: "Search videos"
			}
		}
	},
	subscription: {
		title: {
			lineOne: "The best packages for any",
			lineTwo: "learning needs",
			exerInstituteBringsYou: "Exer Institute brings you"
		},
		contactSales: {
			title: "Contact Sales",
			name: {
				label: "Name",
				placeholder: "Full name"
			},
			email: {
				label: "Email",
				placeholder: "Email"
			},
			phone: {
				label: "Phone",
				placeholder: "Phone"
			},
			organization: {
				label: "Organization Name",
				placeholder: "Organization name"
			},
			potentialUsers: {
				label: "Number of potential users",
				placeholder: "Number"
			}
		},
		cards: {
			free: {
				buttonText: "Sign up",
				price: "$0",
				period: "/month",
				subHeader: "What's included",
				title: "Free",
				features: [
					"Unlimited practice problems",
					"Some free videos and articles"
				]
			},
			pro: {
				buttonText: "Get started",
				price: "$8.99",
				period: "/month",
				subHeader: "Everything in the Free plan plus",
				title: "Pro",
				features: [
					"Discounted tutoring",
					// "380 monthly credits",
					// "Monthly practice resources",
					// "Discounted in-game cosmetics",
					// "Free 3 groups with 15 seats",
					"No ads"
				]
			},
			enterprise: {
				buttonText: "Contact us",
				price: "Custom price",
				period: "",
				subHeader: "Everything in the Pro plan plus",
				title: "Enterprise",
				features: [
					// "Unlimited access to videos, articles",
					// "Unlimited access to practice problems",
					"Unlimited access to tutoring",
					// "Unlimited class groups",
					// "Higher discount on in-game cosmetics",
					"24/7 support"
				]
			}
		},
		featureMatrix: {
			keyFeatures: "Key Features",
			comparePlans: "Compare plans",
			rowLabels: {
				support: "Support",
				videos: "Videos",
				articles: "Articles",
				practiceProblems: "Practice Problems",
				tutoring: "Tutoring",
				studyGroups: "Study Groups",
				practiceResources: "Practice Resources",
				cosmetics: "Cosmetics",
				adFree: "Ad-free"
			},
			rowValues: {
				// Generic Values
				247: "24/7",
				"140monthlyCredits": "140 monthly credits",
				answers: "answers",
				blank: "-",
				freePaid: "Free/paid",
				higher: "Higher",
				hints: "hints",
				levels: "levels",
				noAds: "No ads",
				paid: "Paid",
				someDelay: "Some Delay",
				unlimited: "Unlimited",
				// Subscription-dependent values
				proSubscription: {
					// "tutoring": "10% discount\n100 monthly credits",
					"tutoring": "10% discount",
					"practiceResources": "88 hints, 64 answers, 8 levels",
					"cosmetics": "20% discount"
				},
				enterpriseSubscription: {
					"cosmetics": "Higher discount"
				}
			}
		},
		exerInstituteBringsYou: {
			topTeachers: {
				title: "Top Teachers",
				description: ["All have at least a bachelor's degree",
					"Have years of experience teaching",
					"Are trained on the most effective teaching methodologies according to research"
				]
			},
			qualityVideos: {
				title: "Quality Videos",
				description: ["Structured according to research in neuroscience, psychology, and pedagogy to optimize learning",
					"Produced for simplicity to maximize quality and information retention"
				]
			},
			accessible: {
				title: "Accessible Use & Cost",
				description: ["Highly accessible for disabled people",
					"Designed for quality while being extremely affordable (at least 50% cheaper compared to other tutoring platforms and 80% cheaper compared to other video-based learning platforms)"]
			},
			comprehensive: {
				title: "Comprehensive Quality Coverage",
				description: ["Comprehensive coverage and one of the top online resources for learning math",
					"Unique interactive articles that clearly illustrates new concepts"]
			},
			design: {
				title: "Design",
				description: ["Everything in the platform is based on decades of research to optimizing learning, while maximizing fun"]
			},
			fun: {
				title: "Fun",
				description: ["The only learning platform that successfully combines an entire course with an immersive and fun game that people enjoy playing for hours."]
			}
		},
		account: {
			pro: "Pro : $8.99",
			cancelTitle: "Cancel subscription?",
			cancelSubheader: "Your subscription service will end on this period's billing cycle: ",
		}
	},
	topicsInfoListPage: {
		title: "Read On"
	},
	practicePage: {
		pageTitle: "Practice Categories",
		subHeader: "Choose a category to start practicing",
		solvedAll: "You solved all the problems for this topic! Let's go to the next one.",
		topicOptions: {
			resumeQuest: "Resume Quest",
			startQuest: "Start Quest",
			practice: "Practice"
		},
		options: {
			trueFalse: "True - False",
			multipleChoice: "Multiple Choice",
			fillInTheBlank: "Fill-In-The-Blank"
		},
		answerComponent: {
			true: "True",
			false: "False"
		},
		stats: {
			startQuest: "Start quest for stats",
			resumeQuest: "Resume quest for stats",
			signUpQuest: "Sign up for quest stats",
			totalPoints: "total points",
			level: "Level",
			bag: "Bag",
			experience: "Experience",
			topicStats: "Topic Stats",
			pointsToLevelUp: " points needed to level up"
		},
		shop: {
			text: "Shop",
			title: "Purchase Items"
		},
		bag: {
			text: "Bag",
			tutorials: "Tutorials",
			answers: "Answers",
			hints: "Hints",
			levels: "Levels",
			shinyNeurons: " shiny neurons"
		},
		useResources: {
			answer: "Get the answer",
			hint: "Get the hint",
			level: "Level up",
			or: "or",
			using: "using"
		},
		resourceResp: {
			hint: "Hint",
			answer: "Answer"
		}
	},
	timer: {
		hour: "hour(s)",
		minute: "minute(s)",
		second: "second(s)"
	}
};