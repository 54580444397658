import React from 'react';

import messageMap from "Utilities/MessageMaps";

import detailsAsset from "assets/icons/common/details.svg";
import commentsAsset from "assets/icons/class/learn/comments.svg";
import nodesAsset from "assets/icons/class/learn/nodes.svg";
import notesAsset from "assets/icons/class/learn/notes.svg";
import videoSearchAsset from "assets/icons/class/learn/video_search.svg";
import transcriptAsset from "assets/icons/class/learn/transcript.svg";
import bookmarkAsset from "assets/icons/class/learn/bookmark.svg";
import bookmarkListAsset from "assets/icons/class/learn/bookmarkList.svg";


/**
 * @param {Function} clickHandler click handler of Icon tabs
 * @param {Object} refList key-value tuples of imgKey in iconList to their DOM
 * @param {Array} missingAssets array of strings corresponding to an asset's imgKey to mute clickHandler and, if applicable, change hover text
 */
export default function Icons(props) {
	const iconList = [
		{
			img: detailsAsset,
			imgKey: "information",
			ariaDescribedBy: "informationTooltip",
			alt: messageMap("learn.details", "image"),
			clickHandler: props.clickHandler,
			label: messageMap("learn.details", "button")
		},
		// TODO: Only uncomment when all the necessary features for supporting, regulating, and checking comments are implemented
		// {
		// 	img: commentsAsset,
		// 	imgKey: "comments",
		// 	ariaDescribedBy: "commentsTooltip",
		// 	alt: messageMap("learnPage.tooltips.comments.alt", "generic"),
		// 	clickHandler: props.clickHandler,
		// },
		{
			img: nodesAsset,
			imgKey: "nodes",
			ariaDescribedBy: "nodesTooltip",
			alt: messageMap("learnPage.tooltips.nodes.alt", "generic"),
			clickHandler: props.clickHandler,
			label: messageMap("learn.mentalModel.buttonLocked", "button"),
			ref: props.refList.nodes.ref
		},
		{
			img: notesAsset,
			imgKey: "notes",
			ariaDescribedBy: "notesTooltip",
			alt: messageMap("learnPage.tooltips.notes.alt", "generic"),
			clickHandler: props.clickHandler,
			label: messageMap("learn.notes", "button")
		},
		// TODO: only show this if the current video belongs to a class
		// {
		// 	img: videoSearchAsset,
		// 	imgKey: "videoSearch",
		// 	ariaDescribedBy: "videoSearchTooltip",
		// 	alt: messageMap("learnPage.tooltips.videoList.alt", "generic"),
		// 	clickHandler: props.clickHandler,
		// },
		// TODO: replace action handler with downloading the transcript
		// {
		// 	img: transcriptAsset,
		// 	imgKey: "transcript",
		// 	ariaDescribedBy: "transcriptTooltip",
		// 	alt: messageMap("learnPage.tooltips.transcript.alt", "generic"),
		// 	clickHandler: props.clickHandler,
		// },
		// TODO: convert this to creating and displaying user's playlists
		// {
		// 	img: bookmarkAsset,
		// 	imgKey: "bookmark",
		// 	ariaDescribedBy: "bookmarkTooltip",
		// 	alt: messageMap("learnPage.tooltips.bookmark.alt", "generic"),
		// 	clickHandler: props.clickHandler,
		// 	imgRef: props.refList.bookmark.imgRef
		// },{
		// 	img: bookmarkListAsset,
		// 	imgKey: "bookmarkList",
		// 	ariaDescribedBy: "bookmarkListTooltip",
		// 	alt: messageMap("learnPage.tooltips.bookmarkList.alt", "generic"),
		// 	clickHandler: props.clickHandler,
		// }
	];
	let iconsDom = [];

	iconList.forEach(icon => {
		let cssClass = "icon-div";
		if (icon.imgKey === "nodes") {
			cssClass = "icon-div-disabled";
		}

		if (!props.missingAssets.includes(icon.imgKey)) {
			iconsDom.push(
				<button key={icon.img} ref={icon.ref} type={icon.img}
								className={cssClass} onClick={(e) => icon.clickHandler(icon.imgKey, e)}>
					
					<img ref={icon.imgRef} src={icon.img} alt={icon.alt}></img>
					<p>
						{icon.label}
					</p>

				</button>
			);
		}
	});

	return iconsDom;
}