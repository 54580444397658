import React, { Fragment, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";
import {
	fullCountriesList, availableCountries,
	countriesToAlphaCode, countryAlphaCodeToName
} from "Utilities/Constants/CountriesConstants";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";
import { verifiableGoogleAddressCountries } from "Utilities/Constants/GoogleConstants";

import Modal from "templates/Modal";
import Alert, {ERROR} from "templates/Alert";
import Dropdown from "templates/Dropdown";

import {
	getGooglePlacesKeyAPI,
	getGoogleValidatedAddressAPI, getGoogleAddressLatitudeLongitudeAPI
} from "apis/controllers/thirdParty/APIKeyController";


function EditAddressModalContent(props) {

	const [newStreetAddress1, setNewStreetAddress1] = useState(props.streetAddress1),
		[newStreetAddress2, setNewStreetAddress2] = useState(props.streetAddress2),
		[newPostalCode, setNewPostalCode] = useState(props.postalCode),
		[newPostalCodeKana, setNewPostalCodeKana] = useState(props.postalCodeKana),
		[newPostalCodeKanji, setNewPostalCodeKanji] = useState(props.postalCodeKanji),
		[newCity, setNewCity] = useState(props.city),
		[newState, setNewState] = useState(props.state),
		[newCountry, setNewCountry] = useState(props.country);
	
	const googlePlacesKeyRef = useRef();

	const onChangeMap = {
		street1: setNewStreetAddress1,
		street2: setNewStreetAddress2,
		postal: setNewPostalCode,
		postalKana: setNewPostalCodeKana,
		postalKanji: setNewPostalCodeKanji,
		city: setNewCity,
		state: setNewState
	};

	useEffect(() => {
		getThirdPartyKeys();
	}, []);

	function getThirdPartyKeys() {
		getGooglePlacesKeyAPI(resp => {
			googlePlacesKeyRef.current = resp;
		});
	}

	function onChangeField(e, changeType) {
		onChangeMap[changeType](e.target.value);
	}

	function checkBeforeUpdatingForm() {
		props.updateField("streetAddress1", newStreetAddress1);
		props.updateField("streetAddress2", newStreetAddress2);
		props.updateField("postalCode", newPostalCode);
		props.updateField("postalCodeKana", newPostalCodeKana);
		props.updateField("postalCodeKanji", newPostalCodeKanji);
		props.updateField("city", newCity);
		props.updateField("state", newState);
		props.updateField("country", newCountry);

		const stringAddress = [
			newStreetAddress1, newStreetAddress2, newPostalCode, newPostalCodeKana, newPostalCodeKanji,
			newCity, newState, newCountry
		].filter(addr => addr !== null).join(", ");
		if (verifiableGoogleAddressCountries.includes(newCountry)) {
			const addressLine = newStreetAddress2 != null ? `${newStreetAddress1}, ${newStreetAddress2}` : newStreetAddress1;
			const postalCode = props.country !== "JP" ? newPostalCode : newPostalCodeKana;

			const payload = {
				address: {
					regionCode: newCountry,
					locality: newCity,
					addressLines: [addressLine],
					postalCode: postalCode,
				}
			};
			getGoogleValidatedAddressAPI(googlePlacesKeyRef.current, payload, resp => {
				const addressResults = resp.result.address;
				if (addressResults.unconfirmedComponentTypes != null && addressResults.unconfirmedComponentTypes.length !== 0) {
					let potentiallyInvalidComponent = [];
					addressResults.addressComponents.forEach((component, index) => {
						if (component.confirmationLevel !== "CONFIRMED") {
							potentiallyInvalidComponent.push(
								<li key={`address_${index}`}>
									{component.componentName.text}
								</li>
							);
						}
					});

					props.setModal1(
						<Modal closeType="xButton" modalClass="account" closeHandler={closeModal}
							title={messageMap("account.fields.modal.invalidAddressComponentsHeader", "generic")}>
							<div className="invalid-address-component">
								<div className="directive">
									{messageMap("account.fields.modal.invalidAddressComponentsDirective", "generic")}
								</div>
								<ul className="components">
									{potentiallyInvalidComponent}
								</ul>
							</div>
						</Modal>
					);
				}
				else {
					getAddressLatitudeLongitude(stringAddress);
				}
			});
		}
		else {
			getAddressLatitudeLongitude(stringAddress);
		}
	}

	function getAddressLatitudeLongitude(address) {
		// Do this additional call even though 'getGoogleValidatedAddressAPI' already returns the latitude and 
		// longitude, because 'getGoogleValidatedAddressAPI' doesn't have global coverage
		getGoogleAddressLatitudeLongitudeAPI(googlePlacesKeyRef.current, encodeURIComponent(address), resp => {
			if (resp.results.length) {
				const location = resp.results[0].geometry.location;

				props.updateField("latitude", location.lat);
				props.updateField("longitude", location.lng);
	
				setTimeout(() => {
					props.setModal(null);
				}, MODAL_CLOSE_TIMEOUT);
			}
		});
	}

	function closeModal(e, inputType) {
		if ((e != null && ["modal-block", "cancel", "icon", "close-button"].includes(e.target.className))) {
			props.setModal1(null);
		}
	}

	function checkCountryAvailability(e) {
		const chosenCountry = e.target.getAttribute("countrykey");

		if (chosenCountry === "chooseCountry") {
			return;
		}
		if (availableCountries.includes(chosenCountry)) {
			const newSetCountry = countriesToAlphaCode[chosenCountry];
			setNewCountry(newSetCountry);
		}
		else {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={`${chosenCountry} ${messageMap("profilePage.tutoring.modal.country.notSupported", "generic")}`} />
			);
		}
	}
	function closeAlert() {
		props.setAlert(null);
	}

	const street1 = {
		msg: "newStreetAddress1",
		prop: "streetAddress1",
		changeType: "street1"
	};
	const street2 = {
		msg: "newStreetAddress2",
		prop: "streetAddress2",
		changeType: "street2"
	};
	const postal = {
		msg: "newPostalCode",
		prop: "postalCode",
		changeType: "postal"
	};
	const postalKana = {
		msg: "newPostalCodeKana",
		prop: "postalCodeKana",
		changeType: "postalKana"
	};
	const postalKanji = {
		msg: "newPostalCodeKanji",
		prop: "postalCodeKanji",
		changeType: "postalKanji"
	};
	const city = {
		msg: "newCity",
		prop: "city",
		changeType: "city"
	};
	const state = {
		msg: "newState",
		prop: "state",
		changeType: "state"
	};
	const domMap = {
		line1Address: street1,
		line2Address: street2,
		postalCodeAddress: postal,
		postalCodeAddressKana: postalKana,
		postalCodeAddressKanji: postalKanji,
		cityAddress: city,
		stateAddress: state
	};
	const nonJapaneseAddressFields = [
		"line1Address", "line2Address", "postalCodeAddress",
		"cityAddress", "stateAddress"
	];
	const japaneseAddressFields = [
		"postalCodeAddressKana", "postalCodeAddressKanji"
	];
	let dynamicAddressMap = {};
	[
		"line1Address", "line2Address", "postalCodeAddress", "postalCodeAddressKana", "postalCodeAddressKanji",
		"cityAddress", "stateAddress"
	].forEach(addr => {
		if (props.country !== "JP" && nonJapaneseAddressFields.includes(addr)) {
			dynamicAddressMap = {
				...dynamicAddressMap,
				[addr]: {
					...domMap[addr]
				}
			};
		}
		else if (props.country === "JP" && japaneseAddressFields.includes(addr)) {
			dynamicAddressMap = {
				...dynamicAddressMap,
				[addr]: {
					...domMap[addr]
				}
			};
		}
	});
	return (
		<Fragment>
			{
				Object.keys(dynamicAddressMap).map(domKey => 
					<Fragment key={domKey}>
						<div className="field-generic">
							{messageMap(`account.fields.modal.${domMap[domKey]["msg"]}`, "generic")}
						</div>
						<input placeholder={props[domMap[domKey]["prop"]]} className="page-field-input"
							maxLength={50}
							onChange={e => onChangeField(e, domMap[domKey]["changeType"])} >
						</input>
					</Fragment>
				)
			}

			<div className="field-generic">
				{messageMap("account.fields.modal.newCountry", "generic")}
			</div>
			<Dropdown customDropdownItemAttribute="countrykey"
				customContainerClass="personal-information no-right-margin not-allowed"
				dropdownOptions={fullCountriesList} dropdownItemClickHandler={checkCountryAvailability}
				preselectedKey={newCountry ? countryAlphaCodeToName[newCountry] : null} />

			<button className="update-address" onClick={checkBeforeUpdatingForm} >
				{messageMap("account.changeAddress", "button")}
			</button>
		</Fragment>
	);
}

EditAddressModalContent.propTypes = {
	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,

	streetAddress1: PropTypes.string,
	streetAddress2: PropTypes.string,
	postalCode: PropTypes.string,
	postalCodeKana: PropTypes.string,
	postalCodeKanji: PropTypes.string,
	city: PropTypes.string,
	state: PropTypes.string,
	country: PropTypes.string,
	requiredFields: PropTypes.arrayOf(PropTypes.string).isRequired,

	updateButtonKey: PropTypes.string.isRequired,
	updateField: PropTypes.func.isRequired
};

export default EditAddressModalContent;
