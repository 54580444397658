import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

import defaultLinkAction from "Utilities/LinkActions";
import messageMap from "Utilities/MessageMaps";
import { onKeyDown } from "Utilities/Accessibility";


export default class VideoList extends React.Component {
	constructor(props) {
		super(props);

		// dynamically create states for toggles and chevron direction
		let tempState = {};
		this.props.moduleList.forEach((obj, index) => {
			tempState[`toggleState-${index}`] = "module-list close";
			tempState[`chevron-${index}`] = "chevron down";
		});

		this.state = tempState;

		this.toggleList = this.toggleList.bind(this);
	}

	toggleList(curState, index) {
		curState === "module-list close" 
		?
		this.setState({
			[`toggleState-${index}`]: "module-list open",
			[`chevron-${index}`]: "chevron up"
		})
		:
		this.setState({
			[`toggleState-${index}`]: "module-list close",
			[`chevron-${index}`]: "chevron down"
		});
	}

	render() {
		let videoArr = [];

		this.props.moduleList.forEach((module, index) => {
			let videoList = [];
	
			module.videoList.forEach((video, ind) => {
				videoList.push(
					<div key={video.id} className="video">
						<Link to={{
							pathname: `/learn/${video.id}?title=${video.title}`,
							state: {
								videoId: video.id,
								classId: this.props.classId
							}
						}} onClick={defaultLinkAction}>
							{`${++ind}. ${video.title}`}
						</Link>
					</div>
				);
			});
	
			videoArr.push(
				<div key={index} className="video-container">
					<div className="module-header" role="button" tabIndex={0}
								onClick={() => this.toggleList(this.state[`toggleState-${index}`], index)}
								onKeyPress={e => onKeyDown(e, this.toggleList, [this.state[`toggleState-${index}`], index])}>
						<div className="module-title">
							{module.moduleTitle}
						</div>
						<div className={this.state[`chevron-${index}`]}></div>
					</div>
					<div className={this.state[`toggleState-${index}`]}>
						{videoList}
					</div>
				</div>
			);
		});

		return (
			<div className="video-list">
				<div className="video-title">
					{messageMap("learnPage.courseContent.text", "generic")}
				</div>
				<hr className="line-bar"></hr>
				{videoArr}
			</div>
		);
	}
}

VideoList.propTypes = {
	moduleList: PropTypes.arrayOf(PropTypes.object).isRequired,
	classId: PropTypes.string.isRequired
};