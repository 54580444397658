import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";

import { connect } from "react-redux";

import { listGroupsAPI, listGroupMembersAPI, removeMemberAPI, leaveGroupAPI } from 'apis/controllers/GroupsController';

import leaveAsset from "assets/icons/common/leave.svg";
import ellipsisVerticalAsset from "assets/icons/common/ellipsis_vertical.svg";
import plusAsset from "assets/icons/knowledge/plus.svg";

import { UsernameComponentWithoutRedux } from 'templates/components/Username';
import Alert, {SUCCESS} from "templates/Alert";
import Modal from "templates/Modal";

import messageMap from "Utilities/MessageMaps";
import { AUTO_REDIRECT_TIMEOUT, MODAL_CLOSE_TIMEOUT } from 'Utilities/Constants/TimeoutConstants';
import { findWorkbookFromSubject, ID_TO_OBJECT_KEYS_MAP_MAP } from 'diagrams/utilities/NetworkGenerator';

function Groups(props) {
	const [members, setMembers] = useState([]),
		[alert, setAlert] = useState(null),
		[modal, setModal] = useState(null),
		[pageSize, setPageSize] = useState(25),
		[pageCount, setPageCount] = useState(0);
	const useEffectTimer = useRef(null),
		groupRef = useRef({}),
		openEllipsisRef = useRef(null);
	const history = useHistory();
	// Other Constants
	const tableHeaders = ["username", "subject", "topic", "currentLevel", "totalPoints"];
	const endOfRowClassName = "end-of-row-wrapper";

	useEffect(() => {
		if (props.account.ownerId == null && useEffectTimer.current == null) {
			useEffectTimer.current = setTimeout(() => history.push("/tutoring/tutorsList"), AUTO_REDIRECT_TIMEOUT);
		}
		else if (props.account) {
			let payload = {
				pageCount: 0,
				groupsFilter: []
			};
			clearTimeout(useEffectTimer.current);
			listGroupsAPI(payload, props.account.ownerId, (resp) => {
				let firstGroup = resp[0];// This will get changed later.
				groupRef.current = {
					...firstGroup,
					isFirstPurchase: firstGroup.capacity === 3
				};

				const groupId = groupRef.current.groupId;
				if (groupId) {
					payload = {
						groupId: groupId
					};
					const pathVariables = {
						pageCount,
						pageSize
					}
					listGroupMembersAPI(pathVariables, payload, (resp) => {
						setMembers(resp);
					});
				}
			});
		}
	}, [props.account]);

	function openInvitePopUp() {
		let copyCode =
			<div className="do-not-close">
				<div className="do-not-close group-code">{groupRef.current.joinGroupCode}</div>
				<button className="do-not-close copy-to-clipboard-button" onClick={copyToClipboard}>
					{messageMap("groups.copyCode", "button")}
				</button>
			</div>;

		setModal(
			<Modal closeHandler={closeModal} title={messageMap("groups.joinGroupPopUp.title", "generic")}
				closeType={"xButton"}>
				{copyCode}
			</Modal>
		);
	}

	function openRemoveMemberPopUp(username) {
		const subtext = <div className="subtext">
			{`${messageMap("groups.removeMemberPopUp.subtextPrefix", "generic")} 
      ${username}
      ${messageMap("groups.removeMemberPopUp.subtextMiddle", "generic")} 
      ${groupRef.current.groupName}?`
			}
		</div>;
		setModal(
			<Modal closeHandler={closeModal} title={`${messageMap("groups.removeMemberPopUp.titlePrefix", "generic")} ${username}`}
				submitText={messageMap("yes.remove", "button")} submitHandler={() => removeUserByUsername(username)}>
				{subtext}
			</Modal>
		);
	}

	function copyToClipboard() {
		navigator.clipboard.writeText(groupRef.current.groupCode);
		setAlert(
			<Alert msg={messageMap("groups.copyCode", "generic")} closeHandler={closeAlert}
				type={SUCCESS} />
		);
	}

	function closeAlert() {
		setAlert(null);
	}

	function closeModal(e) {
		if (e != null && !e.target.className.includes("do-not-close")) {
			hideModal();
		}
	}
	function hideModal() {
		setTimeout(() => {
			setModal(null);
		}, MODAL_CLOSE_TIMEOUT);
	}

	function generateLastCellInGroupDataRow(memberData, username) {
		return groupRef.current.isAdmin
			? (
				<div className={endOfRowClassName}>
					<div className="end-of-row-container">
						<span>
							{memberData}
						</span>
						<button onClick={toggleEllipsisMenu}>
							<img src={ellipsisVerticalAsset} alt={messageMap("ellipsisVertical", "image")} />
						</button>
					</div>
					<div className="ellipsis-click-toggles-display-dom-hides-display hide">
						<div>
							<button onClick={() => openRemoveMemberPopUp(username)}>
								{messageMap("groups.ellipsis.removeMember", "generic")}
							</button>
						</div>
						{// This is for re-assigning group roles.
						/* <div> 
              <button>
                {messageMap("groups.ellipsis.assignRole", "generic")}
              </button>
            </div> */}
					</div>
				</div>
			)
			:
			memberData;
	}

	function generateLabel(member, colIdx) {
		const rawMemberData = member[tableHeaders[colIdx]];
		if (colIdx) {
			if (colIdx !== 2) {
				return (colIdx !== tableHeaders.length - 1
					?
					rawMemberData
					:
					generateLastCellInGroupDataRow(rawMemberData, member[tableHeaders[0]])
				);
			}
			else {
				// TO-DO: Make fn. impervious to be prefix-length agnostic in the slice param.
				return (rawMemberData != null) ? (ID_TO_OBJECT_KEYS_MAP_MAP[findWorkbookFromSubject(member[tableHeaders[1]])][rawMemberData].label.slice(3)) : null;
			}
		}
		else {
			return <UsernameComponentWithoutRedux username={member[tableHeaders[0]]} />;
		}
	}

	function isEllipsisRelatedClick(event) {
		let currentTarget = event.target;
		let foundEllipsisContainer = currentTarget.className.includes(endOfRowClassName);
		while (currentTarget.parentNode !== null && !foundEllipsisContainer) {
			currentTarget = currentTarget.parentNode;
			foundEllipsisContainer = foundEllipsisContainer || (currentTarget.className || "").includes(endOfRowClassName);
		}
		return {
			currentTarget,
			foundEllipsisContainer
		};
	}

	function toggleEllipsisMenu(event) {
		const { currentTarget, foundEllipsisContainer } = isEllipsisRelatedClick(event);
		if (foundEllipsisContainer) {
			const additionalOptionsMenu = currentTarget.children[1];
			toggleClassName(additionalOptionsMenu);
		}
	}

	function toggleClassName(element) {
		const oldClassName = element.classList[1];
		let newClassName;
		if (oldClassName === "hide") {
			newClassName = "unhide";
			if (openEllipsisRef.current) {
				toggleClassName(openEllipsisRef.current);
			}
			openEllipsisRef.current = element;
		}
		else {
			newClassName = "hide";
			openEllipsisRef.current = null;
		}
		element.classList.replace(oldClassName, newClassName);
	}

	function removeUserByUsername(username) {
		const pathVariables = {
			username
		};
		const payload = {
			ownerId: props.account.ownerId,
			groupId: groupRef.current.groupId
		};
		removeMemberAPI(pathVariables, payload, (resp) => {
			const { type, message } = resp;
			hideModal();
			setAlert(
				<Alert msg={messageMap(message, "api")} closeHandler={closeAlert}
					type={type} />
			);
		});
	}

	function leaveGroup() {
		const payload = {
			ownerId: props.account.ownerId,
			groupId: groupRef.current.groupId
		};
		leaveGroupAPI(payload, (resp) => {
			const { type, message } = resp;
			hideModal();
			setAlert(
				<Alert msg={messageMap(message, "api")} closeHandler={closeAlert}
					type={type} />
			);
		});
	}

	function generalGroupsPageClickHandler(event) {
		const { foundEllipsisContainer } = isEllipsisRelatedClick(event);
		if (openEllipsisRef.current && !foundEllipsisContainer) {
			toggleClassName(openEllipsisRef.current);
		}
	}

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div className="groups-page" onClick={generalGroupsPageClickHandler}>
			<Helmet>
				<title>{messageMap("groups.title", "headerTag")}</title>
				<meta name="description" content={messageMap("groups.description", "headerTag")}></meta>
			</Helmet>

			{alert}

			<h1 className="groups-title">
				{messageMap("groups.pageTitle", "generic")}
			</h1>

			<div className="table-buttons-container">
				{
					groupRef.current.isAdmin
						?
						<button className="button-action-container" onClick={openInvitePopUp}>
							<img src={plusAsset} alt={messageMap("groups.addMember", "image")}></img>
							<div>
								{messageMap("groups.addMember", "button")}
							</div>
						</button>
						:
						<button className="button-action-container" onClick={leaveGroup}>
							<img src={leaveAsset} alt={messageMap("groups.leaveGroup", "image")}></img>
							<div>
								{messageMap("groups.leaveGroup", "button")}
							</div>
						</button>
				}
			</div>

			<table>
				<thead>
					<tr>
						{tableHeaders.map((tableHeader) => (
							<th key={tableHeader}>{messageMap("groups.tableHeaders." + tableHeader, "generic")}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{members.map((member, memberIdx) => (
						<tr key={memberIdx}>
							{
								tableHeaders.map((tableHeader, colIdx) => (
									<td key={`${tableHeader}-${memberIdx}-${colIdx}`}>
										{generateLabel(member, colIdx)}
									</td>
								))
							}
						</tr>
					))}
				</tbody>
			</table>

			{modal}

		</div>);
}

Groups.propTypes = {
	// redux props
	account: PropTypes.object
};

function mapStateToProps(state) {
	const { account } = state;
	return { account };
}

export default connect(
	mapStateToProps,
	null
)(Groups);