import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { updateShinyNeuronsBalance } from "redux/actions/actionTypes";
import payingAccount from "redux/selectors/payingAccountSelector";

import { purchaseVideoAPI } from "apis/controllers/transactions/ResourcePurchaseController";

import Alert, {SUCCESS, ERROR} from 'templates/Alert';

import { getPriceInShinyNeurons } from './VideoListUtilities';
import { AUTO_REDIRECT_TIMEOUT } from 'Utilities/Constants/TimeoutConstants';
import messageMap from "Utilities/MessageMaps";
import { getAlt, importShinyNeuron } from 'Utilities/ShinyNeuronImages';

import closeAsset from "assets/icons/common/close.svg";


function PurchasePopUp(props) {
  const [alert, setAlert] = useState(null),
    [showExerShopButton, setShowExerShopButton] = useState(false);
  const history = useHistory();

  const purchaseButtonRef = useRef();

  useEffect(() => {
    const priceInShinyNeurons = getPriceInShinyNeurons(props.price, props.shinyNeuronsConversionRate);
    if (priceInShinyNeurons !== "Free" && props.shinyNeurons < priceInShinyNeurons) {
      if (props.autoRedirect) {
        props.setShowPopUp(false);
        redirectToExerShop();
      }
      else {
        setShowExerShopButton(true);
        purchaseButtonRef.current.disabled = true;
        purchaseButtonRef.current.className = "purchase-button-buy-button not-allowed";
      }
    }
  }, []);

  function closeAlert() {
    setAlert(null);
  }

  function redirectToExerShop() {
    history.push("/quotes");
  }

  function purchaseVideo() {
    if (!purchaseButtonRef.current.disabled) {
      let pathVariables = {
        videoId: props.videoId
      };
      let payload = {
        ownerId: props.ownerId,
        filters: [
          "price",
          "earningsRate"
        ]
      };
  
      purchaseVideoAPI(pathVariables, payload, (resp) => {
        const alertContainerStyle = {
          "position": "fixed",
          "left": "0px",
          "maxWidth": "100vw",
          "top": "50px",
          "width": "100vw"
        };
        let alertMsg = messageMap(resp["MESSAGES"][0], "api");
  
        if (resp["shinyNeurons"]) {
          const videoURL = `/learn/${props.videoId}?id=${props.videoId}&channel=${props.channel}&title=${props.videoTitle.replaceAll(" ", "_")}`;
  
          props.updateShinyNeuronsBalance(resp["shinyNeurons"]);
  
          // TO-DO: Optimize Message
          setAlert(
            <Alert alertContainerStyle={alertContainerStyle}
              type={SUCCESS} closeHandler={closeAlert}
              msg={alertMsg} timeout={AUTO_REDIRECT_TIMEOUT} />
          );
  
          if (props.postPurchaseLogic) {
            props.postPurchaseLogic();
          }
          else {
            setTimeout(() => history.push(videoURL), AUTO_REDIRECT_TIMEOUT);
          }
        }
        else {
          props.setShowPopUp(false);
          setAlert(
            <Alert alertContainerStyle={alertContainerStyle}
              type={ERROR} closeHandler={closeAlert}
              msg={alertMsg} timeout={AUTO_REDIRECT_TIMEOUT} />
          );
        }
      });
    }
	}

  return (
    <div className="purchase-button-sub-container">
      <div className="purchase-button-pop-up">
        <button className="close-button" onClick={e => props.setShowPopUp(false)}>
          <img className="x-button" src={closeAsset} alt={messageMap("alerts.close", "image")}></img>
        </button>
        <div className="purchase-button-blurb">
          {messageMap("video.listPage.ownTheVideo", "generic")}
        </div>
        <img className="purchase-button-image" src={props.thumbnail} alt={props.thumbnailAlt} />
        <div className="purchase-button-buttons-container">
          {
            showExerShopButton ?
            (
              <button className="exer-shop-button" onClick={redirectToExerShop}>
                <img className="icon" src={importShinyNeuron(1)}
                  alt={getAlt(0)}
                ></img>
                {messageMap("links.exerShop", "button")}
              </button>
            )
            : null
          }
          <button className="purchase-button-buy-button" onClick={purchaseVideo}
            ref={purchaseButtonRef}>
            {getPriceInShinyNeurons(props.price, props.shinyNeuronsConversionRate, " Neurons")}
          </button>
        </div>
        {alert}
      </div>
    </div>
  );
}

PurchasePopUp.defaultProps = {
  autoRedirect: false // True -> Redirects user to quotes page on useEffect. False -> Keeps user on page.
};

PurchasePopUp.propTypes = {
  // Required
  channel: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  setShowPopUp: PropTypes.func.isRequired,
  thumbnail: PropTypes.string.isRequired,
  thumbnailAlt: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  // Optional
  autoRedirect: PropTypes.bool,
  postPurchaseLogic: PropTypes.func,
  shinyNeurons: PropTypes.number,
  // redux props
  ownerId: PropTypes.string,
  updateShinyNeuronsBalance: PropTypes.func,
  shinyNeuronsConversionRate: PropTypes.number
}

export const ConnectedPurchasePopUp = connect(
  payingAccount,
  { updateShinyNeuronsBalance }
)(PurchasePopUp);