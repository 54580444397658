import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import accountProgressSelector from "redux/selectors/accountProgressSelector";
import { setCurrentTopic } from "redux/actions/actionTypes";
import store from "redux/store";

import { ConnectedPurchasePopUp } from '../Videos/PurchasePopUp';

import Alert, {SUCCESS, INFORMATION, ERROR} from "templates/Alert";
import Modal from "templates/Modal";
import SignUpModal from "templates/customModals/SignUpModal";
import LoginModal from "templates/customModals/LoginModal";
import Spinner from "templates/Spinner";
import PageSwitcher, {VIDEO_PAGE, PRACTICE_PAGE, INFO_PAGE} from "pages/Classes/Components/PageSwitcher";
import TopicNavigator from "pages/Classes/Components/TopicNavigator";

import BookmarkList from "pages/Classes/Learn/components/BookmarkList";
import Icons from "pages/Classes/Learn/components/Icons";
import NoteList from "pages/Classes/Learn/components/NoteList";
import VideoList from "pages/Classes/Learn/components/VideoList";
import TranscriptText from "pages/Classes/Learn/components/TranscriptText";
import VideoPlayer from "pages/Classes/Learn/components/VideoPlayer";

import { openPromptSchema, noteGetApiSchema, modalContainerStyleSchema } from "./schemas/learnSchemas";

import {
	listAPI, listAllVideosAPI, getVideoTranscriptAPI, getVideoMentalModelAPI, isVideoWatchableAPI,
	getVideoMetadataAPI, GET_VIDEO_HLS_RESOURCE, GET_VIDEO_METADATA
} from "apis/controllers/video/VideoController";
import { listAllModulesAPI } from "apis/controllers/video/VideoModuleController";
import { saveBookmarkAPI, checkIfBookmarkedAPI, deleteBookmarkAPI, getBookmarkListAPI } from "apis/controllers/video/VideoBookmarkController";
import { saveNoteAPI, deleteNoteAPI, notesGetAPI, noteGetAPI } from "apis/controllers/video/VideoNoteController";
import { commentsAPI, postCommentAPI } from "apis/controllers/video/VideoCommentController";
import { getAllKnowledgeMetadata } from "apis/controllers/knowledge/KnowledgeMetadataController";
import { saveQuestProgressAPI } from "apis/controllers/person/PersonProgressController";

import defaultLinkAction from 'Utilities/LinkActions';
import {
	sortResponse, randomizeColorPerUser, generateLearningVideoStructuredData,
	recurseToFindPreviousTopic, recurseToFindNextTopic
} from "pages/Classes/Learn/LearnUtilities";
import { getViewCount } from "pages/Classes/Videos/VideoListUtilities";
import { promiseAll, POST } from "Utilities/Fetches";
import { $replace } from "Utilities/Strings";
import { getUniversalDateFromTimestamp, getMinuteSecondTimestamp } from "Utilities/Time";
import { downloadFile } from 'Utilities/FileUtility';
import messageMap from "Utilities/MessageMaps";
import { getQuestDetailsFromId, findWorkbookFromSubject } from "diagrams/utilities/NetworkGenerator";
import { MODAL_CLOSE_TIMEOUT } from 'Utilities/Constants/TimeoutConstants';

import playAsset from "assets/icons/class/learn/play.svg";
import greenAsset from "assets/images/classes/common/green.png";
import closeAsset from "assets/icons/common/close.svg";

function Learn(props) {
	const history = useHistory();
	const urlLocation = window.location.href;
	const entityIdRegEx = new RegExp("(?<=id=)[a-z0-9]{24}(?=&channel)");
	const moduleIdRegEx = new RegExp("(?<=module=)[a-z]{4}");
	const ownerId = store.getState().account && store.getState().account.ownerId ? store.getState().account.ownerId : localStorage.getItem("ownerId");

	// DOM refs
	const videoControllerRef = useRef(),
		playlistButtonToggleRef = useRef(),
		videoPlaylistRef = useRef(),
		transcriptRef = useRef(),
		nodeIconRef = useRef(),
		bookmarkRef = useRef(),
		newCommentRef = useRef(),
		iconBlockRef = useRef(),
		scrollbarRef = useRef();
	// local values refs
	const questionCue = useRef(0),
		videoInformation = useRef(null),
		videoPurchaseInformation = useRef(null),
		currentVideoCue = useRef(null),
		startTimeToTextMultiMap = useRef(null),
		// transcriptDisplay = useRef("hidden"),
		transcriptDisplay = useRef(),
		isOnJourney = useRef(getStateProp("isOnJourney")),
		classId = useRef(
			getStateProp("classId") ? props.routerProp.location.state.classId : entityIdRegEx.exec(urlLocation)[0]
		),
		moduleId = useRef(
			getStateProp("moduleId") ? props.routerProp.location.state.moduleId : (moduleIdRegEx.exec(urlLocation) && moduleIdRegEx.exec(urlLocation)[0] ? entityIdRegEx.exec(urlLocation)[0] : null)
		),
		videoId = useRef(
			getStateProp("videoId") ? props.routerProp.location.state.videoId : (moduleIdRegEx.exec(urlLocation) && moduleIdRegEx.exec(urlLocation)[0] ? null : entityIdRegEx.exec(urlLocation)[0])
		),
		videoPlaylist = useRef(
			getStateProp("videoIds") && (props.routerProp.location.state.videoIds || (moduleIdRegEx.exec(urlLocation) && moduleIdRegEx.exec(urlLocation)[0] ? null : [entityIdRegEx.exec(urlLocation)[0]]))
		),
		videoTopic = useRef(
			props && props.currentTopic
		),
		questDetailsRef = useRef(),
		questTreeVideoBelongsTo = useRef(
			props && props.quest
		),
		questNameVideoBelongsTo = useRef(),
		videoViewCount = useRef(0),
		videoUploadDate = useRef(null),
		iconRefs = useRef({
			nodes: {
				ref: nodeIconRef
			},
			bookmark: {
				imgRef: bookmarkRef
			}
		});
	// const skipQuestionCue = useRef(false);

	const [pageSwitcher, setPageSwitcher]= useState(),
		[topicNavigator, setTopicNavigator]= useState(),
		[videoPlayer, setVideoPlayer] = useState(null),
		[videoTabs, setVideoTabs] = useState(null),
		[videoDescriptionDisplay, setVideoDescriptionDisplay] = useState(null),
		[videoTitleDisplay, setVideoTitleDisplay] = useState(""),
		[uploaderInitial, setUploaderInitial] = useState(""),
		[uploaderUsername, setUploaderUsername] = useState(""),
		[pageTabName, setPageTabName] = useState(""),
		[pageDescriptionMetaTag, setPageDescriptionMetaTag] = useState(""),
		[commentDisplay, setCommentDisplay] = useState(null),
		[noteDisplay, setNoteDisplay] = useState(null),
		[noteListDisplay, setNoteListDisplay] = useState(null),
		[nodeDisplay, setNodeDisplay] = useState(null),
		[videoListDisplay, setVideoListDisplay] = useState(null),
		[bookmarkListDisplay, setBookmarkListDisplay] = useState(null),
		[followingVideos, setFollowingVideos] = useState(null),
		[selectedVideo, setSelectedVideo] = useState(
			videoPlaylist.current ? videoPlaylist.current[0] : (videoId.current ? videoId.current : null)
		),
		[relatedVideosDisplay, setRelatedVideosDisplay] = useState("hide"),
		[transcript, setTranscript] = useState(null),
		[alert, setAlert] = useState(null),
		[promptDisplay, setPromptDisplay] = useState(null),
		[signUpModal, setSignUpModal] = useState(null),
		[loginModal, setLoginModal] = useState(null),
		[spinner, setSpinner] = useState(""),
		[showPurchasePopUp, setShowPurchasePopUp] = useState(false),
		// json-ld
		[structuredLearningVideoJSON, setStructuredLearningVideoJSON] = useState();

	useEffect(() => {
		if (videoId.current === null) {
			const pathVariables = {
				pageCount: 0,
				pageSize: 12
			};
			const payload = {
				// in the BE, this is actually treated as moduleIds
				videoIds: [moduleId.current]
			};

			listAllModulesAPI(pathVariables, payload, resp => {
				const modulePlaylist = resp[0].itemIds;
				videoId.current = modulePlaylist[0];
				videoPlaylist.current = modulePlaylist;
				setSelectedVideo(modulePlaylist[0]);
			});
		}
	}, []);

	useEffect(() => {
		if (selectedVideo) {
			if (moduleId.current || (videoPlaylist.current && videoPlaylist.current.length)) {
				videoId.current = selectedVideo;
				renderVideoPlayList();
			}
			const pathVariables = {
				videoId: videoId.current
			};
			const payload = {
				ownerId: ownerId,
				filters: []
			};
			isVideoWatchableAPI(pathVariables, payload, (resp) => {
				resp ? renderVideoLogic() : renderPurchasePopUpLogic();
			});
		}

	}, [selectedVideo]);

	function getStateProp(propName) {
		return props && props.routerProp && props.routerProp.location.state && props.routerProp.location.state[propName];
	}

	function goToLink(videoId, videoTitle) {
		const channelRegEx = new RegExp("(?<=channel=)[a-z0-9]*(?=&title)");
		const channel = channelRegEx.exec(urlLocation)[0];

		window.scrollTo(0, 0);
		history.push({
			pathname: `/learn/${videoId}?id=&channel=${channel}&title=${videoTitle.replaceAll(" ", "_")}`,
			state: {
				videoId: videoId,
				classId: classId.current
			}
		});
	}

	function renderVideoLogic() {
		// if (!videoInformation.current || (videoInformation.current && videoInformation.current.id !== videoId.current)) {
		videoListDisplay && setVideoListDisplay(null);

		const pathVariables = {
			videoId: videoId.current
		};

		setSpinner(
			<Spinner />
		);
		getVideoTranscriptAPI(pathVariables, ownerId, resp => {
			// TODO: include logic changing "srcLang"'s value depending on the transcript's language
			const dataUrl = "data:text/" + resp.fileType + ";base64," + resp.file;
			transcriptRef.current.src = dataUrl;
		});
		getVideoHLSNVideoMetadata();

		if (ownerId != null && ownerId !== "undefined") {
			checkIfBookmarkedAPI(pathVariables, ownerId, resp => {
				if (resp) {
					bookmarkRef.current.src = "/" + process.env.PUBLIC_URL + "icons/class/learn/bookmarked.svg";
					bookmarkRef.current.setAttribute("saved", true);
				}
			});
		}
		// }
	}

	function renderVideoPlayList() {
		if (videoPlaylist.current) {
			if (playlistButtonToggleRef.current) {
				positionPlaylistButtonToggle("left");
			}

			const pathVariables = {
				pageCount: 1,
				pageSize: 20,
				downloadTypes: ["thumbnail"]
			};
			const payload = {
				ownerId: ownerId,
				// fields that the UI wants to display
				// although not indicated, we're also going to allow the user to replace their uploaded video file
				filters: [
					"id",
					"title",
					"uploadDate",
					"duration",
					"thumbnailUploadLocation",
					"thumbnailDescription",
					"thumbnailFileType",
					"viewCount"
				],
				videoIds: videoPlaylist.current
			};

			listAllVideosAPI(pathVariables, payload, resp => {
				let followingVideos = [];
				const videoIds = resp.map(obj => obj.videoId);

				Array.isArray(resp) && resp.forEach(videoMeta => {
					const curVideoId = videoMeta.videoId;
					const userName = videoMeta.uploaderUsername ? videoMeta.uploaderUsername : "unknown";
					const fileObj = videoMeta.downloadTypeToFiles["thumbnail"][0];
					let dataUrl = fileObj.file;
					let localDate = new Date(videoMeta.uploadDate * 1000);
					localDate = localDate.toLocaleString(
						'en-US',
						{
							dateStyle: "short"
						}
					);

					let playIcon = null;
					let videoItemCLass = "following-video";
					if (curVideoId === videoId.current) {
						videoItemCLass = "following-video current";
						playIcon = (
							<img className="cur-video-indicator"
								alt={messageMap("learn.play", "image")}
								src={playAsset}></img>
						);
					}

					let videoTitle = videoMeta.title;
					if (videoTitle.length > 50) {
						videoTitle = videoTitle.slice(0, 45) + "...";
					}

					followingVideos.push(
						<Link to={{
							pathname: `/learn/${curVideoId}?id=&channel=${userName}&title=${videoTitle}`,
							state: {
								videoIds: videoIds
							}
						}} key={curVideoId} className={videoItemCLass}
						onClick={e => goToVideo(curVideoId, userName, videoMeta.title.replaceAll(" ", "_"), videoIds, false)}>
							{playIcon}
							<img className="thumbnail"
								alt={videoMeta.thumbnailDescription ? videoMeta.thumbnailDescription : ""}
								src={dataUrl}></img>

							<div className="video-description">
								<h2 className="video-title" title={videoMeta.title}>
									{videoTitle}
								</h2>
								<div className="video-uploader">
									{userName}
								</div>
								<div className="count-publish-date-container">
									{/* TODO: uncomment both 'div' blocks when the platform hits 
									some critical threshold of traffic. 
									NOTE*: there are 3 other blocks similar to this that are also 
									commented out. They have the same comment text. So if you're 
									going to uncomment this, uncomment the others as well. */}
									{/* <div className="video-view-count">
										{getViewCount(videoMeta.viewCount)}
									</div> */}
									{/* <div className="video-since-published">
										{localDate}
									</div> */}
								</div>
							</div>
						</Link>
					);
				});

				setFollowingVideos(followingVideos);
			});
		}
	}

	function renderPurchasePopUpLogic() {
		let pathVariables = {
			videoId: videoId.current
		};
		let payload = {
			ownerId: ownerId,
			filters: []
		};
		getVideoMetadataAPI(pathVariables, payload, (resp) => {
			videoPurchaseInformation.current = {
				channel: resp.uploaderUsername,
				price: resp.price,
				setShowPopUp: setShowPurchasePopUp,
				thumbnail: resp.thumbnailUploadLocation,
				thumbnailAlt: resp.thumbnailDescription,
				videoId: videoId.current,
				videoTitle: resp.title,
				postPurchaseLogic: postPurchaseLogic
			};
			setShowPurchasePopUp(true);
		});
	}

	function postPurchaseLogic() {
		renderVideoLogic();
		setShowPurchasePopUp(false);
	}

	function togglePlaylistDisplay() {
		if (videoPlaylistRef.current.className === "following-videos-container") {
			videoPlaylistRef.current.className = "following-videos-container hidden";
			positionPlaylistButtonToggle("right");
		}
		else {
			videoPlaylistRef.current.className = "following-videos-container";
			positionPlaylistButtonToggle("left");
		}
	}

	function positionPlaylistButtonToggle(changeToDirection) {
		playlistButtonToggleRef.current.className = `chevron-${changeToDirection}`;
		setRelatedVideosDisplay("");
	}

	function goToVideo(videoId, username, videoTitle, videoIds, replace=true) {
		defaultLinkAction();
		setVideoDescriptionDisplay(null);
		setCommentDisplay(null);
		setSelectedVideo(videoId);

		videoPlaylist.current = videoIds;

		const location = {
			pathname: `/learn/${videoId}?id=&channel=${username}&title=${videoTitle}`,
			state: {
				videoIds: videoIds
			}
		};
		if (replace) {
			history.replace(location);
		}
	}

	function goToPreviousTopic() {
		const previousTopic = getPreviousTopic();

		if (previousTopic) {
			videoTopic.current = previousTopic;
			goToTopicVideos(previousTopic);
		}
	}

	function getPreviousTopic() {
		const foundPreviousTopic = recurseToFindPreviousTopic(null, null, questTreeVideoBelongsTo.current, videoTopic.current);
		return foundPreviousTopic ? foundPreviousTopic[0] : null;
	}

	function goToNextTopic() {
		const nextTopic = getNextTopic();

		if (nextTopic) {
			videoTopic.current = nextTopic;
			goToTopicVideos(nextTopic);
		}
	}

	function getNextTopic() {
		const foundNextTopic = recurseToFindNextTopic(null, null, questTreeVideoBelongsTo.current, videoTopic.current);
		return foundNextTopic ? foundNextTopic[0] : null;
	}

	function checkPreviousAndNextTopicAvailability() {
		if (questTreeVideoBelongsTo.current && videoTopic.current) {
			const previousTopic = getPreviousTopic();
			const nextTopic = getNextTopic();

			// TODO: update this later to also check for practices and info text
			const payload = {
				objectList: [previousTopic, nextTopic]
			};
			getAllKnowledgeMetadata(payload, resp => {
				let havePreviousVideoIds = false;
				let haveNextVideoIds = false;

				for (let i = 0; i < resp.length; ++i) {
					const resource = resp[i];
					const topicId = resource.topicID;
					const videoIds = resource.videoMetadataSet.map(videoMetadata => videoMetadata.id);

					if (topicId === previousTopic) {
						havePreviousVideoIds = videoIds.length;
					}
					else if (topicId === nextTopic) {
						haveNextVideoIds = videoIds.length;
					}
				}

				const hidePrevTopicButton = havePreviousVideoIds ? "" : "hide";
				const hideNextTopicButton = haveNextVideoIds ? "" : "hide";

				setTopicNavigator(
					<TopicNavigator goToPreviousTopic={goToPreviousTopic} goToNextTopic={goToNextTopic}
						showOrHideLeftButton={hidePrevTopicButton} showOrHideRightButton={hideNextTopicButton} />
				);
			});
		}
	}

	function goToTopicVideos(topic) {
		props.setCurrentTopic(topic);

		getAllKnowledgeMetadata({ objectList: [topic] }, resp => {
			let videoIds = [];
			let firstVideoUsername;
			let firstVideoTitle;
			let firstVideoId;

			for (let i = 0; i < resp.length; ++i) {
				const resource = resp[i];
				videoIds = videoIds.concat(resource.videoMetadataSet.map(obj => obj.id));

				if (i === 0) {
					const firstVideo = resource.videoMetadataSet[0];
					firstVideoUsername = firstVideo.uploaderUsername;
					firstVideoTitle = firstVideo.title;
					firstVideoId = firstVideo.id;
				}
			}

			goToVideo(firstVideoId, firstVideoUsername, firstVideoTitle.replaceAll(" ", "_"), videoIds);
		});
	}

	function getVideoHLSNVideoMetadata() {
		const metadataPayload = {
			ownerId: ownerId,
			filters: []
		};
		const pathVariables = {
			videoId: videoId.current
		};

		const apiArr = [
			{
				api: $replace(GET_VIDEO_HLS_RESOURCE, pathVariables),
				type: POST,
				payload: ownerId
			}, {
				api: $replace(GET_VIDEO_METADATA, pathVariables),
				type: POST,
				payload: metadataPayload
			}
		];

		promiseAll(apiArr, resp => {
			const endpoints = resp[0].data;
			const videoHLSLink = endpoints["hls"];

			const videoMetadata = resp[1].data;
			const videoTitle = videoMetadata.title;
			let missingAssets = videoMetadata.mentalModels.length === 0 ? ["nodes"] : [];
			videoInformation.current = videoMetadata;

			setVideoPlayer(
				<VideoPlayer hlsLink={videoHLSLink} transcriptSrc={endpoints["subtitles"]}
					controllerRef={videoControllerRef} transcriptRef={transcriptRef}
					videoTitle={videoMetadata.title} videoDescription={videoMetadata.description}
					onEndedHandler={unBlockNodeToggle} onCanPlayHandler={storeVideoMetaData}
					onSeekedHandler={syncQuestionCue} onTimeUpdateHandler={timeUpdateHandler} />
			);
			setSpinner(null);
			setVideoTitleDisplay(videoTitle);
			setPageTabName(videoTitle + " video | Exer Institute");
			setPageDescriptionMetaTag(videoMetadata.description);
			setVideoTabs(
				<Icons clickHandler={iconAction} refList={iconRefs.current} missingAssets={missingAssets} />
			);
			setUploaderInfo(videoMetadata);
			videoViewCount.current = videoMetadata.viewCount;
			let uploadDate = new Date(videoMetadata.uploadDate * 1000);
			videoUploadDate.current = uploadDate.toLocaleString(
				'en-US',
				{
					dateStyle: "short"
				}
			);

			// if video isn't part of a module and is attached to a topic
			// that is a part of a quest, show previous & next buttons
			if (videoMetadata.videoTopicID && !moduleId.current) {
				setSelectedVideo(videoMetadata.id);
				videoId.current = videoMetadata.id;
				videoTopic.current = videoMetadata.videoTopicID;

				if (questTreeVideoBelongsTo.current == null) {
					const questDetails = getQuestDetailsFromId(videoMetadata.videoTopicID);

					if (questDetails[1] && questDetails[2]) {
						questDetailsRef.current = questDetails[0];
						questTreeVideoBelongsTo.current = questDetails[1];
						questNameVideoBelongsTo.current = questDetails[2];

						savePersonProgress();

						if (videoPlaylist.current == null) {
							videoPlaylist.current = [videoId.current];
							renderVideoPlayList();
						}

						checkPreviousAndNextTopicAvailability();
						renderPageSwitcher();
					}
				}
				else if (questTreeVideoBelongsTo.current) {
					questNameVideoBelongsTo.current = questTreeVideoBelongsTo.current.label;
					savePersonProgress();

					if (videoPlaylist.current == null) {
						videoPlaylist.current = [videoId.current];
						renderVideoPlayList();
					}

					checkPreviousAndNextTopicAvailability();
					renderPageSwitcher();
				}
			}
			else {
				setTopicNavigator(
					<TopicNavigator goToPreviousTopic={goToPreviousTopic} goToNextTopic={goToNextTopic}
						showOrHideLeftButton={"hide"} showOrHideRightButton={"hide"} />
				);
			}

			const structuredData = generateLearningVideoStructuredData(resp, questDetailsRef.current[videoTopic.current].structuredData);
			setStructuredLearningVideoJSON(structuredData);
		});
	}

	function savePersonProgress() {
		if (ownerId && isOnJourney.current) {
			const payload = {
				ownerId: ownerId,
				stringToStringMap: {
					[questNameVideoBelongsTo.current]: videoTopic.current
				}
			};
			saveQuestProgressAPI(payload, resp => {
				// not showing <Alert/> because this can easily look spammy every time someone goes to the next topic
				console.log("Quest progress saved.: ", resp);
			});
		}
	}
	
	function renderPageSwitcher() {
		setPageSwitcher(
			<PageSwitcher key={videoTopic.current} curPage={VIDEO_PAGE} leftPage={PRACTICE_PAGE} rightPage={INFO_PAGE}
				workbook={findWorkbookFromSubject(questNameVideoBelongsTo.current)} subject={questNameVideoBelongsTo.current}
				topicId={videoTopic.current}  isOnJourney={isOnJourney.current} />
		);
	}

	function setUploaderInfo(videoMetadata) {
		const uploaderUsername = videoMetadata.uploaderUsername;
		setUploaderInitial(uploaderUsername[0]);
		setUploaderUsername(uploaderUsername);
	}

	function storeVideoMetaData(e) {
		const cueList = e.target.textTracks[0].cues;
		let tempStartTimeToTextMultiMap = {};

		// cueList is technically a TextTrackCueList, which can't be treated as an array
		for (var i = 0; i < cueList.length; ++i) {
			const time = getMinuteSecondTimestamp(cueList[i].startTime),
				formattedTime = `${time.minutes}:${time.seconds}`;

			tempStartTimeToTextMultiMap[formattedTime] = {
				text: cueList[i].text,
				videoStartTime: cueList[i].startTime
			}
		}

		// currentVideoCue.current = cueList[0].startTime;
		startTimeToTextMultiMap.current = tempStartTimeToTextMultiMap;
		// TODO: this is causing the infinite loading
		// setTranscript(
		// 	<TranscriptText startTimeToTextMultiMap={startTimeToTextMultiMap.current} videoRef={videoControllerRef} 
		// 									currentCue="0:00"/>
		// );
	}

	function timeUpdateHandler(e) {
		const activeCue = (e.target.textTracks[0].activeCues && e.target.textTracks[0].activeCues[0]) ? e.target.textTracks[0].activeCues[0].startTime : undefined,
			currentTime = e.target.currentTime;

		// TODO: fix this when user is searching through the video timeline
		// syncQuestionCue();

		if (questionCue.current !== -1 && e.target.duration === currentTime) {
			openPrompt(messageMap("learnPage.prompt.ending", "generic"), messageMap("learnPage.prompt.tooltip1", "generic"), "summary");
		}
		else if (questionCue.current !== -1 && videoInformation.current.questions[questionCue.current]
			&& (videoInformation.current.questions[questionCue.current].timestamp === currentTime || currentTime > videoInformation.current.questions[questionCue.current].timestamp)) {
			videoControllerRef.current.childNodes[0].pause();
			openPrompt(videoInformation.current.questions[questionCue.current].question, messageMap("learnPage.prompt.tooltip2", "generic"));
			++questionCue.current;
		}

		if (activeCue && startTimeToTextMultiMap.current && activeCue > currentVideoCue.current) {
			const time = getMinuteSecondTimestamp(activeCue),
				formattedTime = `${time.minutes}:${time.seconds}`;
			const scrollOptionsSchema = {
				top: 75,
				left: 75,
				behavior: "smooth"
			};

			setTranscript(
				<TranscriptText startTimeToTextMultiMap={startTimeToTextMultiMap.current} videoRef={videoControllerRef}
					currentCue={formattedTime} />
			);
			currentVideoCue.current = activeCue;

			scrollbarRef.current.scrollBy(scrollOptionsSchema);
		}
	}

	function clearNewCommentRef() {
		newCommentRef.current.value = ""
	}

	function syncQuestionCue() {
		if (questionCue.current !== -1 && videoInformation.current.questionIds != null) {
			const currentTime = videoControllerRef.current.childNodes[0].currentTime,
				videoQuestionList = videoInformation.current.questions;
			let i = 0;

			for (i = 0; i < videoQuestionList.length; ++i) {
				if (videoQuestionList[i].timestamp > currentTime) {
					questionCue.current = i;
					return;
				}
			}

			if (currentTime > videoQuestionList[i - 1].timestamp) {
				if (currentTime - videoQuestionList[i - 1].timestamp < 0.5) {
					questionCue.current = 0;
				}
				else {
					questionCue.current = ++i;
				}
			}
		}
	}

	function openPrompt(prompt, tooltip, prompType) {
		var skipAllPrompt = prompType !== "summary"
			?
			(<button onClick={() => skipPrompts("all")}>
				{messageMap("prompt.skipAll", "button")}
			</button>)
			:
			"";

		const openPromptSchemaObj = openPromptSchema(skipPrompts, skipAllPrompt, tooltip);

		setPromptDisplay(
			<Modal closeType="xButton" closeHandler={closeResponse} title={prompt}
				titleStyle={openPromptSchemaObj.titleStyle} icon={openPromptSchemaObj.icon}
				stateProps={openPromptSchemaObj.iconState} textArea={openPromptSchemaObj.textArea}
				submitHandler={saveResponse} customButtons={openPromptSchemaObj.customButtons}>
			</Modal>
		);
	}

	function closeResponse(e) {
		if (["icon", "modal-block", "close-button"].includes(e.target.className) || e.target.type === "submit") {
			setPromptDisplay(null);
			if (videoControllerRef.current.childNodes[0].currentTime !== videoControllerRef.current.childNodes[0].duration) {
				videoControllerRef.current.childNodes[0].play();
			}
		}
	}

	function skipPrompts(allPrompts) {
		allPrompts === "all" && (questionCue.current = -1);
		setPromptDisplay(null);
		videoControllerRef.current.childNodes[0].play();
	}

	// TODO: this will save any response from any questions
	function saveResponse(e) {
		setPromptDisplay(null);
		videoControllerRef.current.childNodes[0].play();
	}

	function iconAction(iconType, event, ref, ...args) {
		let target = event.target,
			activeIcons = [],
			targetClassName = "icon-div";

		if (target.classList.contains("icon-div-disabled") || target.parentNode.classList.contains("icon-div-disabled")) {
			return;
		}

		iconBlockRef.current.childNodes.forEach(el => {
			if (el.classList.contains("current")) {
				activeIcons.push(el.getAttribute("type"));
				el.classList.remove("current");
			}
		});

		if (target.tagName === "BUTTON" && !activeIcons.includes(target.getAttribute("type"))) {
			target.classList.add("current");
			targetClassName = "icon-div current";
		} else if (["IMG", "P"].includes(target.tagName) && !activeIcons.includes(target.parentNode.getAttribute("type"))) {
			target.parentNode.classList.add("current");
			targetClassName = "icon-div current";
		}

		switch (iconType) {
			case "information":
				openInformation(targetClassName);
				break;
			case "comments":
				toggleComments();
				break;
			case "notes":
				openNotes();
				break;
			case "nodes":
				toggleNodesDisplay(targetClassName);
				break;
			case "video_search":
				toggleVideoList(targetClassName);
				break;
			case "transcript":
				openTranscript(args);
				break;
			case "bookmark":
				createBookmark();
				break;
			case "bookmarkList":
				openBookmarkList(targetClassName);
				break;
			default:
				break;
		}
	}

	function openInformation(targetClassName) {
		setCommentDisplay(null);

		if (videoDescriptionDisplay == null && targetClassName === "icon-div current") {
			setVideoDescriptionDisplay(
				<div className="video-info">
					{/* TODO: uncomment both 'div' blocks when the platform hits 
					some critical threshold of traffic. 
					NOTE*: there are 3 other blocks similar to this that are also 
					commented out. They have the same comment text. So if you're 
					going to uncomment this, uncomment the others as well. */}
					{/* <div className="view-date-container">
						<div>
							{getViewCount(videoViewCount.current)}
						</div>
						<div>
							{videoUploadDate.current.toString()}
						</div>
					</div> */}
					<div className="description-title">
						{videoTitleDisplay ? videoTitleDisplay : videoInformation.current.title}
					</div>
					<div className="description" dangerouslySetInnerHTML={{ __html: videoInformation.current.description }}>
					</div>
				</div>
			);
		}
		else if (targetClassName === "icon-div") {
			setVideoDescriptionDisplay(null);
		}
	}

	function toggleComments() {
		setVideoDescriptionDisplay(null);
		if (commentDisplay == null) {
			commentsAPI({ videoId: videoId.current }, resp => {
				let comments = [];
				resp = sortResponse(resp);
				resp = randomizeColorPerUser(resp);
				resp.forEach(el => {
					comments.push(
						<div className="comment-block">
							<div className="pic-div">
								<div className="user-pic" style={{ "backgroundColor": el.color }}></div>
							</div>
							<div className="comments">
								<div>
									<span className="name">{el.name}</span>
									<span className="timestamp">{getUniversalDateFromTimestamp(el.timestamp)}</span>
								</div>
								<div className="message">
									{el.feedback}
								</div>
							</div>
						</div>
					);
				});
				setCommentDisplay(
					<div className="comment-section">
						<div className="comment-block">
							<div className="pic-div">
								<img className="user-pic" src={greenAsset} alt="Green circle for indicating user's color."></img>
							</div>
							<div className="comment-insert-container">
								<textarea ref={newCommentRef} className="comment-insert" cols="100" type="text"
									placeholder={messageMap("comment", "labelPlaceholder")}
									onChange={adjustTextAreaHeight}></textarea>
								<button className="comment-button" onClick={submitComment}>
									{messageMap("comment.text", "button")}
								</button>
								<button className="comment-cancel" onClick={clearNewCommentRef}>
									{messageMap("cancel.text", "button")}
								</button>
							</div>
						</div>
						{comments}
					</div>
				);
			});
		}
		else {
			setCommentDisplay(null);
		}
	}

	function submitComment() {
		const payload = {
			ownerId: ownerId,
			feedback: newCommentRef.current.value,
			classId: classId.current,
			videoId: videoId.current
		};
		postCommentAPI(payload, () => {
			toggleComments();
			clearNewCommentRef();
		});
	}

	function openNotes() {
		!videoControllerRef.current.childNodes[0].paused && videoControllerRef.current.childNodes[0].pause();

		const timeObject = getMinuteSecondTimestamp(videoControllerRef.current.childNodes[0].currentTime);
		const pathVariables = {
			videoId: videoId.current,
			videoTs: `${timeObject.minutes}:${timeObject.seconds}`
		};

		if (ownerId != null && ownerId !== "undefined") {
			noteGetAPI(pathVariables, ownerId, resp => {
				const noteGetApiSchemaObj = noteGetApiSchema(timeObject, showSavedNotes, resp, deleteNote, closeNotes);

				setNoteDisplay(
					<Modal closeType={"xButton"} closeHandler={closeNotes}
						modalContainerStyle={noteGetApiSchemaObj.modalContainerStyle}
						title={`${messageMap("learnPage.notes.modalTitle", "generic")} ${timeObject.minutes}:${timeObject.seconds}`}
						titleStyle={noteGetApiSchemaObj.titleStyle} subHeader={noteGetApiSchemaObj.subHeader}
						subHeaderStyle={noteGetApiSchemaObj.subHeaderStyle}
						textArea={noteGetApiSchemaObj.textArea} textAreaStyle={noteGetApiSchemaObj.textAreaStyle}
						submitHandler={saveNote}
						submitHandlerStyle={{ borderRadius: "60px", fontSize: "16px" }}
						stateProps={noteGetApiSchemaObj.stateProps}
						buttonAltText={messageMap("save.notes", "button")}
						submitText={messageMap("save.notes", "button")}
						customButtons={noteGetApiSchemaObj.customButtons}>
					</Modal>
				);
			});
		}
		else {
			setSignUpModal(
				// TODO: use Redux to have NavBar handle this instead
				<SignUpModal title={messageMap("account.signUp.signUpFirst", "generic")}
					closeModal={closeSignUpModal}
					subHeader={(
						<Fragment>
							<div>
								{messageMap("account.signUp.existingAccount", "generic")}
								<button onClick={() => openAnotherModal("login", 1)}
									style={{ border: "none", color: "#007bff", backgroundColor: "white" }}>
									{messageMap("links.login", "button")}
								</button>
							</div>
						</Fragment>
					)} />
			);
		}
	}

	function openAnotherModal(type) {
		if (type === "login") {
			setLoginModal(
				<LoginModal closeHandler={closeLoginModal} submitHandler={closeLoginModal} />
			);
		}
	}

	function closeSignUpModal(e) {
		if (e == null || (e != null && ["modal-block", "cancel", "fullRegistration", "icon", "close-button"].includes(e.target.className))) {
			hideSignUpModal("signUp");
		}
	}

	function closeLoginModal(e, args) {
		if (e != null && ["modal-block", "cancel", "fullRegistration", "icon", "close-button"].includes(e.target.className)) {
			hideSignUpModal("login");
		}
		else if (e === null && args === "loginSuccess") {
			hideSignUpModal("login");
			hideSignUpModal("signUp");
		}
	}

	function hideSignUpModal(modalType) {
		const setModalMap = {
			login: setLoginModal,
			signUp: setSignUpModal
		};

		setTimeout(() => {
			setModalMap[modalType](null);
		}, MODAL_CLOSE_TIMEOUT);
	}

	function closeNotes(e) {
		if (["icon", "modal-block", "close-button"].includes(e.target.className) || e.target.type === "submit") {
			setNoteDisplay(null);
		}
	}

	function saveNote(note) {
		const payload = {
			ownerId: ownerId,
			videoId: videoId.current,
			note: note.textArea.value,
			videoTs: note.videoTs
		};
		saveNoteAPI(payload, resp => {
			setAlert(
				<Alert closeHandler={closeBookmarkAlert} type={SUCCESS} msg={messageMap(resp, "api")} />
			);
		});
	}

	function deleteNote(videoTs) {
		const payload = {
			ownerId: ownerId,
			videoId: videoId.current,
			videoTs: videoTs
		};
		deleteNoteAPI(payload, resp => {
			const type = resp === "note.delete.deleted" ? SUCCESS : ERROR;

			setAlert(
				<Alert closeHandler={closeBookmarkAlert} type={type} msg={messageMap(resp, "api")} />
			);
		});
	}

	function showSavedNotes() {
		notesGetAPI({ videoId: videoInformation.current.id }, ownerId, resp => {
			setNoteListDisplay(
				<Modal closeType={"xButton"} closeHandler={closeNotesList} modalContainerStyle={modalContainerStyleSchema}>
					<NoteList noteList={resp} ownerId={ownerId}
						alertHandler={setAlert} closeAlertHandler={closeBookmarkAlert}
						refresh={showSavedNotes} jumpToTimestamp={jumpToTimestamp} />
				</Modal>
			);
		});
	}

	function jumpToTimestamp(seconds) {
		const parsedTime = seconds.split(":"),
			secondsCount = parseInt(60 * parsedTime[0]) + parseInt(parsedTime[1]);

		videoControllerRef.current.childNodes[0].currentTime = secondsCount;
		setNoteListDisplay(null);
		setNoteDisplay(null);
	}

	function closeNotesList(e) {
		if (["icon", "modal-block"].includes(e.target.className)) {
			setNoteListDisplay(null);
		}
	}

	function toggleNodesDisplay(targetClassName) {
		if (typeof targetClassName === "object") {
			targetClassName = targetClassName.target.className;
		}

		if (["icon", "modal-block", "close-button"].includes(targetClassName)) {
			setNodeDisplay(null);
		}
		else {
			let mentalModels = [];
			videoInformation.current.mentalModels.forEach(model => {
				mentalModels.push(model);
			});

			const payload = {
				videoId: videoInformation.current.id,
				mentalModels: mentalModels
			};


			getVideoMentalModelAPI(payload, resp => {
				// TODO: change this logic once we support multiple mental models
				const mentalModelFile = resp[0].mentalModelFile;
				const titleStyle = {
					fontSize: "24px",
					lineHeight: "normal",
					marginBottom: "24px"
				};
				const modalContainerStyle = {
					maxWidth: 1280,
					padding: "32px",
					height: "auto",
					backgroundColor: "#fff",
					borderRadius: "16px"
				};
				const closeButtonStyle = {
					marginTop: "-20px",
					marginRight: "-20px"
				};
				const mentalModelStyle = {
					height: "auto",
					width: "auto",
					maxWidth: "1200px",
					maxHeight: "60vh",
					"marginBottom": "24px",
				};
				const downloadStyle = {
					display: "block",
					margin: "auto",
					padding: "8px 32px",
					borderRadius: "60px",
					border: "none",
					backgroundColor: "#2c8aff",
					color: "white",
					fontFamily: 'Montserrat-Regular'
				};

				setNodeDisplay(
					<Modal closeType={"xButton"} closeHandler={toggleNodesDisplay}
						modalContainerStyle={modalContainerStyle} titleStyle={titleStyle}
						closeButtonStyle={closeButtonStyle}
						title={messageMap("learnPage.mentalModel.title", "generic")}>
						<img style={mentalModelStyle} src={mentalModelFile}
							alt={messageMap("learn.mentalModel.alt", "image")}></img>
						<button style={downloadStyle} onClick={e => downloadFile(mentalModelFile)}>
							{messageMap("learn.mentalModel.download", "button")}
						</button>
					</Modal>
				);
			});
		}
	}

	function unBlockNodeToggle() {
		if (videoInformation.current.mentalModels.length) {
			nodeIconRef.current.className = "icon-div";
			nodeIconRef.current.children[1].innerHTML = messageMap("learn.mentalModel.buttonUnlocked", "button");
		}
	}

	function toggleVideoList(targetClassName) {
		if (["icon", "modal-block", "close-button"].includes(targetClassName)) {
			setVideoListDisplay(null);
		}
		else if (!videoListDisplay) {
			listAPI({ classId: classId.current }, resp => {
				setVideoListDisplay(
					<Modal closeType={"barClose"} closeHandler={toggleVideoList} modalContainerStyle={modalContainerStyleSchema}>
						<VideoList moduleList={resp} classId={classId.current} />
					</Modal>
				);
			});
		}
	}

	function openTranscript() {
		if (transcript !== null) {
			const transcriptDisplayClass = transcriptDisplay.current.className;
			transcriptDisplay.current.className = transcriptDisplayClass.includes("hidden") ? "transcript-container" : "transcript-container hidden";
		}
	}

	function createBookmark() {
		const textStyle = { color: "#000" };
		const pathVariables = {
			videoId: videoId.current
		};

		if (bookmarkRef.current.getAttribute("saved") === "true") {
			bookmarkRef.current.src = "/" + process.env.PUBLIC_URL + "icons/class/learn/bookmark.svg";
			bookmarkRef.current.setAttribute("saved", false);

			deleteBookmarkAPI(pathVariables, ownerId, resp => {
				setAlert(
					<Alert closeHandler={closeBookmarkAlert} type={INFORMATION} msg={messageMap(resp, "api")} showMsgType={false}
						textStyle={textStyle} />
				);
			});
		}
		else {
			bookmarkRef.current.src = "/" + process.env.PUBLIC_URL + "icons/class/learn/bookmarked.svg";
			bookmarkRef.current.setAttribute("saved", true);

			saveBookmarkAPI(pathVariables, ownerId, resp => {
				setAlert(
					<Alert closeHandler={closeBookmarkAlert} type={INFORMATION} msg={messageMap(resp, "api")}
						showMsgType={false} textStyle={textStyle} />
				);
			});
		}
	}

	function closeBookmarkAlert() {
		setAlert(null);
	}

	function openBookmarkList(targetClassName) {
		if (["icon", "modal-block", "close-button"].includes(targetClassName)) {
			setBookmarkListDisplay(null);
		}
		else if (!bookmarkListDisplay) {
			const pathVariables = {
				classId: classId.current
			};

			getBookmarkListAPI(pathVariables, ownerId, resp => {
				setBookmarkListDisplay(
					<Modal closeType={"barClose"} closeHandler={openBookmarkList} modalContainerStyle={modalContainerStyleSchema}>
						<BookmarkList moduleList={resp} classId={classId.current} navigationHandler={goToLink}
							deleteBookmarkHandler={deleteBookmarkHandler} />
					</Modal>
				);
			});
		}
	}

	function deleteBookmarkHandler(videoId) {
		const pathVariables = {
			videoId: videoId.current
		};

		deleteBookmarkAPI(pathVariables, ownerId, resp => {
			if (videoId === videoId.current) {
				bookmarkRef.current.src = "/" + process.env.PUBLIC_URL + "icons/class/learn/bookmark.svg";
				bookmarkRef.current.setAttribute("saved", false);
			}

			setAlert(
				<Alert closeHandler={closeBookmarkAlert} type={INFORMATION} msg={messageMap(resp, "api")} showMsgType={false}
					textStyle={{ color: "#000" }} />
			);

			openBookmarkList();
		});
	}

	return (
		<div className="learn">
			<Helmet>
				<title>{pageTabName}</title>
				<meta name="description" content={pageDescriptionMetaTag}></meta>
				<script type="application/ld+json">{structuredLearningVideoJSON}</script>
			</Helmet>

			{alert}

			<div className="learn-container">

				<div className="content-container">
					<div className={moduleId.current || videoPlaylist.current ? "left-container" : "left-container only-video"}>
						<div className="topic-resource-links-container">
							{pageSwitcher}

							{topicNavigator}
						</div>

						<div className="video-container">
							{spinner}

							{videoPlayer}

							<h1 className="video-title1">
								{videoTitleDisplay}
							</h1>

							<div className="uploader-container">
								<div className="uploader-initial">
									{uploaderInitial}
								</div>
								<div className="uploader-username">
									{uploaderUsername}
								</div>
							</div>
						</div>

						<div className="icon-container">
							<div className="icon-block" ref={iconBlockRef}>
								{videoTabs}
							</div>

							<div className="social-container">
								{videoDescriptionDisplay}

								{commentDisplay}
							</div>
						</div>

						<div className={`related-videos-container ${relatedVideosDisplay}`}>
							<div className="related-videos">
								<div className="related-text">
									{messageMap("learnPage.relatedVideos", "generic")}
								</div>
								<button className="chevron-left hide" ref={playlistButtonToggleRef}
									onClick={togglePlaylistDisplay}>
								</button>
							</div>

							<div className={moduleId.current || videoPlaylist.current ? "following-videos-container" : "following-videos-container hidden"} ref={videoPlaylistRef}>
								{followingVideos}
							</div>
						</div>
					</div>

					<div className={videoTopic.current ? "right-container on-quest" : "right-container"}>

						{/* <div className={`transcript-container ${transcriptDisplay.current}`}> */}
						<div ref={transcriptDisplay} className="transcript-container hidden">
							<div className="label-container">
								<span className="label">
									{messageMap("learnPage.transcript.text", "generic")}
								</span>
								<button className="close-button" onClick={() => openTranscript(true)}>
									<img className="icon" src={closeAsset}
										alt={messageMap("alerts.close", "image")}></img>
								</button>
							</div>
							<div ref={scrollbarRef} className="transcript">
								{transcript}
							</div>
						</div>
					</div>
				</div>
			</div>

			{noteDisplay}
			{noteListDisplay}

			{nodeDisplay}

			{videoListDisplay}
			{bookmarkListDisplay}
			{promptDisplay}
			{loginModal}
			{signUpModal}
			{showPurchasePopUp ? <ConnectedPurchasePopUp {...videoPurchaseInformation.current} /> : null}
		</div>
	);
}

function adjustTextAreaHeight(e) {
	const target = e.target;

	target.style.height = Math.ceil(target.value.length / 100) * 28 + "px";
}


Learn.propTypes = {
	routerProp: PropTypes.object,

	// Redux prop values
	ownerId: PropTypes.string,
	currentTopic: PropTypes.string,
	quest: PropTypes.object,
	// Redux prop functions
	setCurrentTopic: PropTypes.func
};

export default connect(
	accountProgressSelector,
	{ setCurrentTopic }
)(Learn);