import React, {useEffect, useState, Fragment} from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import profileSelector from "redux/selectors/profileSelector";

import PubNub from "pubnub";

import messageMap from "Utilities/MessageMaps";
import { isValidIdentity } from "Utilities/Validators/IdentityValidators";
import { promiseAll, POST, GET } from "Utilities/Fetches";

import Alert, {ERROR} from "templates/Alert";
import TutoringMessages from "pages/Tutoring/subPages/TutoringMessages";

import { GET_PUBNUB_KEYSETS } from "apis/controllers/thirdParty/APIKeyController";
import { GET_CHAT_CHANNELS } from "apis/controllers/tutoring/TutoringRecordController";


function TutoringMessagesWrapper(props) {

	const identityIsValid = isValidIdentity(props.ownerId);

	const [pubNubChat, setPubNubChat] = useState();

	useEffect(() => {
		renderPubNubChat();
	}, []);

	function renderPubNubChat() {
		if (identityIsValid) {
			const apiArr = [
				{
					api: GET_PUBNUB_KEYSETS,
					type: GET
				}, {
					api: GET_CHAT_CHANNELS,
					type: POST,
					payload: props.ownerId
				}
			];

			promiseAll(apiArr, resp => {
				const pubNubResp = resp[0].data;
				const chatChannelsResp = resp[1].data;

				if (Object.keys(pubNubResp).includes("error")) {
					props.setAlert(
						<Alert type={ERROR} closeHandler={closeAlert} customClass="tutoring"
							msg={messageMap(resp.error, "api")} />
					);
				}
				else if (chatChannelsResp.length === 0) {
					blockChat();
				}
				else {
					const clientCredentials = new PubNub({
						publishKey: pubNubResp.publishKey,
						subscribeKey: pubNubResp.subscribeKey,
						uuid: localStorage.getItem("ownerId")
					});

					let channelList = [];
					let allUsers = [];
					let allUserIds = [];
					let onUsers = [];
					if (chatChannelsResp.length) {
						chatChannelsResp.forEach(channel => {
							const ownerId = channel.ownerId;
							const otherParty = channel.name;
							const profilePicture = channel.profilePictureUrl;
							const eTag = channel.eTag;
							const updatedOn = channel.updatedOn;
							const sessionId = channel.channelId.split(".")[1];

							channelList.push(
								{
									id: channel.channelId,
									name: otherParty,
									description: channel.description,
									custom: {
										profileUrl: profilePicture
									},
									eTag: channel.eTag,
									updated: updatedOn,
									tutorOwnerId: channel.tutorOwnerId,
									tutorId: channel.tutorId,
									ownerId: ownerId,
									place: channel.place,
									sessionId: sessionId,
									sessionPrice: channel.sessionPrice
								}
							);

							if (!allUserIds.includes(ownerId)) {
								allUsers.push(
									{
										id: ownerId,
										name: otherParty,
										profileUrl: profilePicture,
										eTag: eTag,
										updated: updatedOn
									}
								);
							}

							if (channel.isLive && !onUsers.includes(ownerId)) {
								onUsers.push(ownerId);
							}
						});
					}
					const timestamp = new Date();

					setPubNubChat(
						<TutoringMessages key={timestamp.getTime()} clientCredentials={clientCredentials} channels={channelList}
							users={allUsers} onUsers={onUsers}
							reRenderChat={renderPubNubChat} setAlert={props.setAlert}
							setModal={props.setModal} setModal1={props.setModal1} setLoginModal={props.setLoginModal} />
					);
				}
			});
		}
		else {
			blockChat();
		}
	}

	function closeAlert() {
		props.setAlert(null);
	}

	function blockChat() {
		setPubNubChat(
			<div className="unavailable-chat">
				<div className="no-messages">
					{messageMap("tutoringPage.chat.noMessages", "generic")}
				</div>
				<div className="action-prompt">
					<div>
						{messageMap("tutoringPage.chat.tutor", "generic")}
					</div>
					<div>
						{messageMap("tutoringPage.chat.tutee", "generic")}
					</div>
				</div>
			</div>
		);
	}

	return (
		<Fragment>
			<Helmet>
				<title>{messageMap("tutoringMessages.title", "headerTag")}</title>
				<meta name="description" content={messageMap("tutoringMessages.description", "headerTag")}></meta>
			</Helmet>

			{pubNubChat}
		</Fragment>
	);
}

TutoringMessagesWrapper.propTypes = {
	setAlert: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func.isRequired,
	setLoginModal: PropTypes.func.isRequired,

	// Redux props
	ownerId: PropTypes.string
};

export default connect(
	profileSelector
)(TutoringMessagesWrapper);

