import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux/store";

import { GoogleOAuthProvider } from "@react-oauth/google";

import "./index.scss";
import HomeRoutes from "./routes/HomeRoutes";
import * as serviceWorker from "./serviceWorker";

// Landing
ReactDOM.render(
	<Provider store={store}>
		<GoogleOAuthProvider clientId="690425830045-du2v0q4enjsmoqct9jhnqa04kbegcu3s.apps.googleusercontent.com">
			<HomeRoutes />
		</GoogleOAuthProvider>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();
