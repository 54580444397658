import React, {useEffect, useState, useRef, Fragment} from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import Dropdown from "templates/Dropdown";
import TabbedPages from "templates/TabbedPages";
import Alert, {SUCCESS, ERROR, INFORMATION} from "templates/Alert";
import {askUserToSignUpFirst} from "templates/customModals/utilities/SignUpLoginUtilities";

import RequestList from "./subMenus/RequestList";
import SubjectTopicPriceBreakdown from "pages/Tutoring/subPages/components/SubjectTopicPriceBreakdown";

import messageMap from "Utilities/MessageMaps";
import {
	TUTORING_PROFILE_TABS,
	PROFILE_OVERVIEW, PROFILE_REVIEWS, PROFILE_SCHEDULED_SESSIONS, PROFILE_LIVE_SESSIONS, PROFILE_PAST_SESSIONS
} from "templates/utilities/PageTabs";
import {
	AUTH_TOKEN, OWNER_ID, GOOGLE_ID,
	SAVED_TUTORS
} from "Utilities/Constants/MediaConstants";
import { 
	TUTEE_USER, TUTOR_USER,
	SCHEDULED_STATE, LIVE_STATE, COMPLETED_STATE
} from "pages/Tutoring/utilities/TutoringRequestConstants";
import { topicsInSubjects } from "pages/Profile/subPages/utilities/TutoringSubPageConstants";
import { 
	ratingsSortMap, HIGHEST_RATING, LOWEST_RATING, MOST_RECENT_RATING,
	calculateTutorAvgRating, getTutorRatesRange, getTutorSchedule, normalizeExternalLink,
	transformCancellationPoliciesStringToObject, getTutorCancellationPolicy
} from "pages/Tutoring/utilities/TutorProfileUtility";
import { showPdfFileInTab } from "Utilities/FileUtility";

import { getReviewsAPI } from "apis/controllers/tutoring/TutoringReviewController";
import { saveAccountSettingsAPI } from "apis/controllers/person/AccountsController";

import saveAsset from "assets/icons/common/save.svg";
import saveFilledAsset from "assets/icons/common/saveFilled.svg";
import shareAsset from "assets/icons/common/share.svg";
import linkedInAsset from "assets/icons/thirdParty/linkedIn.svg";
import websiteAsset from "assets/icons/thirdParty/website.svg";
import attachmentAsset from "assets/icons/common/attachment.svg";
import greenCheckAsset from "assets/icons/common/green-check.svg";


function PublicTutorProfile(props) {

	const tutorDetails = props.tutorDetails;

	const [isSaved, setIsSaved] = useState(props.isSaved);

	const [tutorOverview, setTutorOverview] = useState(),
		[tutorRateRange, setTutorRateRange] = useState(),
		[ratings, setRatings] = useState(),
		[tutorSchedule, setTutorSchedule] = useState(),
		[cancellationPolicy, setCancellationPolicy] = useState(),
		[tutoringReviews, setTutoringReviews] = useState(null),
		[scheduledSessions, setScheduledSessions] = useState(),
		[liveSessions, setLiveSessions] = useState(),
		[pastSessions, setPastSessions] = useState(),
		[emptyScheduledSessions, setEmptyScheduledSessions] = useState(),
		[emptyLiveSessions, setEmptyLiveSessions] = useState(),
		[emptyPastSessions, setEmptyPastSessions] = useState();

	const overviewDomRef = useRef(),
		reviewsDomRef = useRef(),
		reviewReferenceByRatingRef = useRef(),
		reviewReferenceByDateRef = useRef(),
		scheduledSessionDomRef = useRef(),
		liveSessionsDomRef = useRef(),
		pastSessionsDomRef = useRef();

	const subjectTopicScrollingRef = useRef();

	useEffect(() => {
		createProfileContents();
	}, []);

	function createProfileContents() {
		const tutorOwnerId = tutorDetails.tutorOwnerId;
		const reviewPathVariables = {
			ownerId: tutorOwnerId,
			reviewFromWhichUserType: TUTEE_USER
		};

		getReviewsAPI(reviewPathVariables, resp => {
			let bio = [];
			tutorDetails.bio.split(/[\r\n]+/).forEach((bioDetails, indx) => {
				bio.push(
					<Fragment key={`bio_${indx}`}>
						<div>
							{bioDetails}
						</div>
						<br/>
					</Fragment>
				);
			});

			let teachingRates = tutorDetails.teachingRates;
			if (!teachingRates.includes("flatRate")) {
				teachingRates = getTutorRatesRange(teachingRates);
			}
			else {
				teachingRates = `$${teachingRates.split("_")[1]} ${messageMap("tutoringPage.tutorList.pane.perHour", "generic")}`;
			}
			setTutorRateRange(teachingRates);
			setRatings(calculateTutorAvgRating(resp));
			setTutorOverview(
				<div>
					{bio}
				</div>
			);
			setTutorSchedule(getTutorSchedule(tutorDetails.schedules, tutorDetails.timezone));
			setCancellationPolicy(
				getTutorCancellationPolicy(
					transformCancellationPoliciesStringToObject(tutorDetails.cancellationPolicies, TUTOR_USER)
				)
			);
			displayReviews(resp);

			const publicSessionsMap = {
				[SCHEDULED_STATE]: setScheduledSessions,
				[LIVE_STATE]: setLiveSessions,
				[COMPLETED_STATE]: setPastSessions
			};
			for (const [key, value] of Object.entries(publicSessionsMap)) {
				value(
					<RequestList key={key} intendedUser={TUTEE_USER} listType={key}
						isForPublic={true} tutorOwnerId={tutorOwnerId}
						emptyListHandler={setEmptyList}
						setModal={props.setModal} setModal1={props.setModal1} setAlert={props.setAlert}/>
				);
			}
		});
	}

	function setEmptyList(sentOrReceived, listLength, listType) {
		const emptyStateMap = {
			[SCHEDULED_STATE]: setEmptyScheduledSessions,
			[LIVE_STATE]: setEmptyLiveSessions,
			[COMPLETED_STATE]: setEmptyPastSessions,
		};

		emptyStateMap[listType](
			<div className="no-sessions half-page">
				{messageMap(`tutoringPage.publicTutorProfile.no${listType}`, "generic")}
			</div>
		);
	}

	function displayReviews(reviews) {
		let reviewsDom = [];
		let reviewReferenceRating = {};
		let reviewReferenceDate = {};

		reviews.forEach(review => {
			const reviewRating = review.rating;
			const reviewTimestamp = review.timestamp;
			if (reviewReferenceRating[reviewRating] == null) {
				reviewReferenceRating[reviewRating] = [];
			}
			if (reviewReferenceDate[reviewTimestamp] == null) {
				reviewReferenceDate[reviewTimestamp] = [];
			}

			reviewReferenceRating[reviewRating].push(review);
			reviewReferenceDate[reviewTimestamp].push(review);
			reviewsDom.push(
				<div key={review.sessionId} className="review-container">
					<div className="review-info">
						<div className="reviewer">
							<img className="tutor-picture" src={tutorDetails.profilePictureUploadLocation} alt={messageMap("tutoring.tutorPicture", "image")} />
							{review.requestFromFullName}
						</div>

					<div className="review-on">
							{`${review.subject}: ${topicsInSubjects[review.subject][review.topic]}`}
						</div>
						<div className="review-date">
							{(new Date(review.timestamp * 1000).toDateString())}
						</div>
					</div>

					<div className="review">
						<div className="star-container">
							{calculateTutorAvgRating([
								{
									rating: reviewRating
								}
							])}
						</div>
						<div className="review-comment">
							{review.reviewComment}
						</div>
					</div>
				</div>
			);
		});

		reviewsDom.length && setTutoringReviews(
			<div key={Date.now()}>
				{reviewsDom}
			</div>
		);
		reviewReferenceByRatingRef.current = reviewReferenceRating;
		reviewReferenceByDateRef.current = reviewReferenceDate;
	}

	function sortRatings(e) {
		const newSortCategory = e.target.getAttribute("ratingSort");

		if ([HIGHEST_RATING, LOWEST_RATING, MOST_RECENT_RATING,].includes(newSortCategory)) {
			let sortedKeys;
			if (newSortCategory === HIGHEST_RATING) {
				sortedKeys = Object.keys(reviewReferenceByRatingRef.current).sort((a, b) => {
					return Number(b) - Number(a);
				});
			}
			else if (newSortCategory === LOWEST_RATING) {
				sortedKeys = Object.keys(reviewReferenceByRatingRef.current).sort((a, b) => {
					return Number(a) - Number(b);
				});
			}
			else if (newSortCategory === MOST_RECENT_RATING) {
				sortedKeys = Object.keys(reviewReferenceByDateRef.current).sort((a, b) => {
					return Number(a) - Number(b);
				});
			}
	
			let orderedList = [];
			for (let i = 0; i < sortedKeys.length; ++i) {
				if ([HIGHEST_RATING, LOWEST_RATING].includes(newSortCategory)) {
					reviewReferenceByRatingRef.current[sortedKeys[i]].forEach(ratings => {
						orderedList.push(ratings);
					});
				}
				else {
					reviewReferenceByDateRef.current[sortedKeys[i]].forEach(ratings => {
						orderedList.push(ratings);
					});
				}
			}
	
			displayReviews(orderedList);
		}
	}

	function createSessionDomList(sessionList, sessionType) {
		let sessionsDom = [];

		sessionList.forEach(session => {
			let sessionUrgency = session.urgency;
			if (session.urgency !== "now") {
				sessionUrgency = new Date(Number(session.urgency));
				sessionUrgency = sessionUrgency.toLocaleString();
			}

			sessionsDom.push(
				<div key={session.id} className="session-container">
					<div>
						<div className="participant">
							{session.requestFromFullName}
						</div>
						<div className="session-schedule">
							{`${sessionUrgency} - ${session.duration}`}
						</div>
					</div>

					<div>
						<div>
							{session.subject}
						</div>
						<div>
							{session.topic}
						</div>
					</div>

					<div>

					</div>
				</div>
			);
		});

		return sessionsDom;
	}

	function changeContentVisibility(chosenProfileCategory) {
		const visibilityDomMap = {
			[PROFILE_OVERVIEW]: overviewDomRef,
			[PROFILE_REVIEWS]: reviewsDomRef,
			[PROFILE_SCHEDULED_SESSIONS]: scheduledSessionDomRef,
			[PROFILE_LIVE_SESSIONS]: liveSessionsDomRef,
			[PROFILE_PAST_SESSIONS]: pastSessionsDomRef
		};

		for (const [key, value] of Object.entries(visibilityDomMap)) {
			value.current.className = key === chosenProfileCategory ? key : "hide";
		}
	}

	function closePane() {
		window.history.replaceState(null, "", "/tutoring/tutorsList");

		props.closePane();
	}

	function toggleSaveTutor() {
		if (props.ownerId == null) {
			askUserToSignUpFirst(props.setModal, props.setModal1);
		}
		else {
			const payload = {
				[AUTH_TOKEN]: props.authToken,
				[SAVED_TUTORS]: [tutorDetails.tutorProfileId]
			};
			const pathVariables = {
				[OWNER_ID]: localStorage.getItem("ownerId"),
				[GOOGLE_ID]: localStorage.getItem("googleId"),
			};
			let fieldsToValidate = [];
			for (const [key, value] of Object.entries(payload)) {
				value && fieldsToValidate.push(key);
			}
	
			saveAccountSettingsAPI(pathVariables, payload, fieldsToValidate, resp => {
				const responseCodes = resp.responseCodes;
				let alertType = ERROR;
				let allMsgs = [];
				responseCodes.forEach(respCode => {
					if (respCode.includes("success")) {
						alertType = SUCCESS;
					}
					allMsgs.push(messageMap(respCode, "api"));
				});

				props.setAlert(
					<Alert closeHandler={closeAlert} customClass="tutoring" type={alertType} 
						msg={allMsgs} />
				);
				if (alertType === SUCCESS) {
					if (responseCodes.includes("accounts.update.savedTutor.success")) {
						setIsSaved(true);
					}
					else if (responseCodes.includes("accounts.update.unsavedTutor.success")) {
						setIsSaved(false);
					}
				}
			});
		}
	}
	function createTutorProfileShareLink() {
		navigator.clipboard.writeText(window.location.href);
		props.setAlert(
			<Alert closeHandler={closeAlert} customClass="tutoring" type={INFORMATION} 
				msg={messageMap("tutoringPage.publicTutorProfile.savedCopy", "generic")} />
		);
	}

	function closeAlert() {
		props.setAlert(null);
	}

	function toggleAdditionalOptionsVisibility() {

	}

	function getTutorProfileActions(deviceView) {
		return (
			<div className={`save-options-container ${deviceView}`}>
				<button className="profile-actions" onClick={createTutorProfileShareLink}>
					<img src={shareAsset} alt={messageMap("tutoring.tutorList.share", "image")} />
				</button>
				<button className="profile-actions" onClick={toggleSaveTutor}>
					<img src={isSaved ? saveFilledAsset : saveAsset} alt={isSaved ? messageMap("tutoring.tutorList.saved", "image") : messageMap("tutoring.tutorList.save", "image")} />
				</button>
				{/* TODO: replace later with report and share icons */}
				{/* <button className="profile-actions" onClick={toggleAdditionalOptionsVisibility}>
					<img src={ellipsisVerticalAsset} alt={messageMap("ellipsisVertical", "image")} />
				</button> */}
			</div>
		);
	}
	function navigateToAvailableSessions() {
		const scrollIntoViewOptions = {
			behavior: "smooth", 
			block: "start", 
			inline: "start"
		};
		subjectTopicScrollingRef.current.scrollIntoView(scrollIntoViewOptions);
	}

	return (
		<Fragment>

			<button className="close-pane-button" onClick={closePane}>
				{`<- ${messageMap("tutoring.tutorList.back", "button")}`}
			</button>

			<div className="tutor-profile-summary">
				<div className="headline">
					<div className="tutor-picture-container">
						<img className="tutor-picture" src={tutorDetails.profilePictureUploadLocation} alt={messageMap("tutoring.tutorPicture", "image")} />

						{getTutorProfileActions("mobile-view")}
					</div>
					<div className="tutor-header">
						<div className="full-name">
							{`${tutorDetails.firstName} ${tutorDetails.lastName}`}
						</div>
						<div className="occupation">
							{tutorDetails.occupation}
						</div>
						<div className="price-range">
							{tutorRateRange}
						</div>
						<div className="star-container">
							{ratings}
						</div>

						<button className="button-navigation" onClick={navigateToAvailableSessions}>
							{messageMap("tutoring.tutorList.schedule", "button")}
						</button>
					</div>
				</div>

				{getTutorProfileActions("pc-view")}
			</div>

			<TabbedPages tabType={TUTORING_PROFILE_TABS} tabClickHandler={changeContentVisibility} />

			<div className="tutor-profile-category-details">
				<div className="overview" ref={overviewDomRef}>
					<div className="overview-details">
						<div className="bio">
							{tutorOverview}
						</div>
						<div className="external-credentials">
							{
								(tutorDetails.linkedInUrl != null && tutorDetails.linkedInUrl !== "")
								&& (
									<a href={normalizeExternalLink(tutorDetails.linkedInUrl)} target="_blank" rel="noreferrer">
										<button className="link-button">
											<img src={linkedInAsset} alt={messageMap("tutoring.tutorList.linkedIn", "image")}/>
										</button>
									</a>
								)
							}
							{
								(tutorDetails.websiteUrl != null && tutorDetails.websiteUrl !== "")
								&& (
									<a href={normalizeExternalLink(tutorDetails.websiteUrl)} target="_blank" rel="noreferrer">
										<button className="link-button">
											<img src={websiteAsset} alt={messageMap("tutoring.tutorList.website", "image")}/>
										</button>
									</a>
								)
							}
							{
								(tutorDetails.resumeUploadLocationUploadLocation != null || tutorDetails.resumeUploadLocationUploadLocation !== "")
								&& (
									<button className="link-button attachment"
										onClick={e => showPdfFileInTab(tutorDetails.resumeUploadLocationUploadLocation)}>
											<img className="attachment-icon" src={attachmentAsset} alt={messageMap("tutoring.tutorList.resume", "image")}/>
											{messageMap("tutoringPage.tutorList.pane.resume", "generic")}
									</button>
								)
							}
						</div>

						<div className="schedule">
							<div className="header">
								{messageMap("tutoringPage.tutorList.pane.availability", "generic")}
							</div>
							<div>
								{tutorSchedule}
							</div>
						</div>

						<div className="cancellation-policy">
							<div className="header">
								{messageMap("tutoringPage.tutorList.pane.cancellationPolicy", "generic")}
							</div>
							{cancellationPolicy}
						</div>
					</div>

					<div className="right-side-overview">
						<div className="available-services-box">
							<div className="card-header">
								{messageMap("tutoringPage.tutorList.pane.availableServices", "generic")}
							</div>

							{
								tutorDetails.trialPreference === "true"
								? (
									<div className="fifteen-minute-trial">
										<img className="green-check" src={greenCheckAsset} alt={messageMap("tutoring.greenCheck", "image")} />
										{messageMap("tutoringPage.tutorList.modal.fifteenTrial", "generic")}
									</div>
								)
								: ""
							}
							{
								typeof tutorDetails.inPersonTutoringRange === "number"
								? (
									<div className="fifteen-minute-trial">
										<img className="green-check" src={greenCheckAsset} alt={messageMap("tutoring.greenCheck", "image")} />
										{messageMap("tutoringPage.tutorList.modal.services.inPersonAvailable", "generic")}
									</div>
								)
								: ""
							}
							{
								tutorDetails.instantBooking === "true"
								? (
									<div className="fifteen-minute-trial in-person-available">
										<img className="green-check" src={greenCheckAsset} alt={messageMap("tutoring.greenCheck", "image")} />
										{messageMap("tutoringPage.tutorList.modal.services.instantBookingAvailable", "generic")}
									</div>
								)
								: ""
							}
						</div>

						<div className="subject-topic-box" ref={subjectTopicScrollingRef}>
							<div className="card-header">
								{messageMap("tutoringPage.tutorList.pane.availableSessions", "generic")}
							</div>

							<SubjectTopicPriceBreakdown tutorFirstName={tutorDetails.firstName} tutorTimezone={tutorDetails.timezone}
								tutorTier={tutorDetails.tutorTier} schedule={tutorDetails.schedules}
								showBookOption={props.showBookOption} tutorProfileId={tutorDetails.tutorProfileId} tutorOwnerId={tutorDetails.tutorOwnerId}
								teachingRates={tutorDetails.teachingRates} subjectToTopicsMap={tutorDetails.subjectToTopicsMap}
								setModal={props.setModal} setModal1={props.setModal1} setAlert={props.setAlert} />
						</div>
					</div>

					<div className="overview-details mobile-view">
						<div className="cancellation-policy mobile-view">
							<div className="header">
								{messageMap("tutoringPage.tutorList.pane.cancellationPolicy", "generic")}
							</div>
							{cancellationPolicy}
						</div>
					</div>
				</div>

				<div className="hide" ref={reviewsDomRef}>
					{
						tutoringReviews != null ? (
							<Fragment>
								<div className="reviews-sorter">
									<Dropdown customDropdownItemAttribute="ratingSort" customContainerClass="ratings-sort"
										dropdownItemClickHandler={sortRatings} dropdownOptions={ratingsSortMap} />
								</div>
								{tutoringReviews}
							</Fragment>
						)
						: (
							<div className="no-reviews half-page">
								{messageMap("tutoringPage.publicTutorProfile.noReviews", "generic")}
							</div>
						)
					}
				</div>

				<div className="hide" ref={scheduledSessionDomRef}>
					{scheduledSessions}
					{emptyScheduledSessions}
				</div>

				<div className="hide" ref={liveSessionsDomRef}>
					{liveSessions}
					{emptyLiveSessions}
				</div>

				<div className="hide" ref={pastSessionsDomRef}>
					{pastSessions}
					{emptyPastSessions}
				</div>
			</div>
		</Fragment>
	);
}

PublicTutorProfile.defaultProps = {
	showBookOption: false
};

PublicTutorProfile.propTypes = {
	isSaved: PropTypes.bool.isRequired,
	tutorDetails: PropTypes.object.isRequired,
	showBookOption: PropTypes.bool,

	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,
	closePane: PropTypes.func.isRequired,

	// redux props
	ownerId: PropTypes.string,
	authToken: PropTypes.string.isRequired
};

export default connect(
	account
)(PublicTutorProfile);