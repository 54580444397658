import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import { 
	LANDING_PAGE_TABS, TUTORS_TAB, LEARNERS_TAB
} from "templates/utilities/PageTabs";
import TabbedPages from "templates/TabbedPages";

import HeadlineCTA from "./components/HeadlineCTA";
import LandingForLearners from "./subMenus/LandingForLearners";
import LandingForTutors from "./subMenus/LandingForTutors";

import messageMap from "Utilities/MessageMaps";
import { askUserToSignUpFirst } from "templates/customModals/utilities/SignUpLoginUtilities";

import blueWaveAsset from "assets/images/home/landingBlueWave.svg";


function LandingPage(props) {

	const history = useHistory();

	const [signUpModal, setSignUpModal] = useState(null);
	const [loginModal, setLoginModal] = useState(null);
	const [pageMetadataTitle, setPageMetadataTitle] = useState(null),
		[pageMetadataDescription, setPageMetadataDescription] = useState(null);

	const learnerHeaderRef = useRef(),
		learnerDetailsRef = useRef(),
		tutorHeaderRef = useRef(),
		tutorDetailsRef = useRef();

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const sub = urlParams.get("sub");
		if (sub != null) {
			showSubMenuPage(sub);
		}

		setPageMetadataTitle(
			<title>{messageMap("landingForLearners.title", "headerTag")}</title>
		);
		setPageMetadataDescription(
			<meta name="description" content={messageMap("landingForLearners.description", "headerTag")}></meta>
		);
	}, []);

	function showSubMenuPage(chosenMenuPage) {
		if (chosenMenuPage === LEARNERS_TAB) {
			learnerHeaderRef.current.className = "benefits-headline";
			learnerDetailsRef.current.className = "benefits-for-users";
			history.push(`/?sub=${LEARNERS_TAB}`, { shallow: true });

			tutorHeaderRef.current.className = "benefits-headline hide";
			tutorDetailsRef.current.className = "benefits-for-users hide";

			setPageMetadataTitle(
				<title>{messageMap("landingForLearners.title", "headerTag")}</title>
			);
			setPageMetadataDescription(
				<meta name="description" content={messageMap("landingForLearners.description", "headerTag")}></meta>
			);
		}
		else if (chosenMenuPage === TUTORS_TAB) {
			learnerHeaderRef.current.className = "benefits-headline hide";
			learnerDetailsRef.current.className = "benefits-for-users hide";

			tutorHeaderRef.current.className = "benefits-headline";
			tutorDetailsRef.current.className = "benefits-for-users";
			history.push(`/?sub=${TUTORS_TAB}`, { shallow: true });

			setPageMetadataTitle(
				<title>{messageMap("landingForTutors.title", "headerTag")}</title>
			);
			setPageMetadataDescription(
				<meta name="description" content={messageMap("landingForTutors.description", "headerTag")}></meta>
			);
		}
	}

	function openBecomeATutorModal() {
		askUserToSignUpFirst(setSignUpModal, setLoginModal, null, false,
			<div>
				{messageMap("landing.tutors.signUpFirst", "generic")}
			</div>
		);
	}

	return (
		<div className="landing-page">

			<Helmet>
				{pageMetadataTitle}
				{pageMetadataDescription}
			</Helmet>

			{signUpModal}
			{loginModal}

			<div className="landing-container" style={{backgroundImage: `url(${blueWaveAsset})`}}>
				<TabbedPages customClass="landing-page" tabType={LANDING_PAGE_TABS} tabClickHandler={showSubMenuPage} />

				<div className="benefits-headline" ref={learnerHeaderRef} >
					<HeadlineCTA headline={messageMap("landing.learners.headline", "generic")}
						ctaText={messageMap("landing.learner.headerCTA", "button")} ctaRedirect="/tutoring/tutorsList" />

					<div className="more-details-header">
						{messageMap("landing.learners.atExer", "generic")}
					</div>
					<div className="more-details-subHeader">
						{messageMap("landing.learners.detailsHeader", "generic")}
					</div>
				</div>

				<div className="benefits-headline hide" ref={tutorHeaderRef} >
					<HeadlineCTA headline={messageMap("landing.tutors.headline", "generic")}
						ctaText={messageMap("landing.tutor.headerCTA", "button")} ctaHandler={openBecomeATutorModal}/>

					<div className="more-details-header">
						{messageMap("landing.tutors.atExer", "generic")}
					</div>
					<div className="more-details-subHeader">
						{messageMap("landing.tutors.detailsHeader", "generic")}
					</div>
				</div>
			</div>

			<div className="benefits-for-users" ref={learnerDetailsRef} >
				<LandingForLearners setSignUpModal={setSignUpModal} setLoginModal={setLoginModal} />
			</div>

			<div className="benefits-for-users hide" ref={tutorDetailsRef}>
				<LandingForTutors setSignUpModal={setSignUpModal} setLoginModal={setLoginModal} />
			</div>

		</div>
	);
}

export default LandingPage;

