import { 
	ARGENTINA_CODE, AUSTRIA_CODE, AUSTRALIA_CODE,
	BELGIUM_CODE, BRAZIL_CODE, BULGARIA_CODE,
	CANADA_CODE, CHILE_CODE, COLOMBIA_CODE, CROATIA_CODE, CZECH_REPUBLIC_CODE,
	DENMARK_CODE, 
	ESTONIA_CODE, 
	FINLAND_CODE, FRANCE_CODE,
	GERMANY_CODE, 
	HUNGARY_CODE,
	INDIA_CODE, IRELAND_CODE, ITALY_CODE, 
	JAPAN_CODE, 
	LATVIA_CODE, LITHUANIA_CODE, LUXEMBOURG_CODE, 
	MALAYSIA_CODE, MEXICO_CODE, 
	NETHERLANDS_CODE, NEW_ZEALAND_CODE, NORWAY_CODE, 
	POLAND_CODE, PORTUGAL_CODE, PUERTO_RICO_CODE, 
	SINGAPORE_CODE, SLOVAKIA_CODE, SLOVENIA_CODE, SPAIN_CODE, SWEDEN_CODE, SWITZERLAND_CODE,
	UNITED_KINGDOM_CODE, UNITED_STATES_CODE
} from "./CountriesConstants";

// country support: https://developers.google.com/maps/documentation/address-validation/coverage
export const verifiableGoogleAddressCountries = [
	ARGENTINA_CODE, AUSTRIA_CODE, AUSTRALIA_CODE,
	BELGIUM_CODE, BRAZIL_CODE, BULGARIA_CODE,
	CANADA_CODE, CHILE_CODE, COLOMBIA_CODE, CROATIA_CODE, CZECH_REPUBLIC_CODE,
	DENMARK_CODE, 
	ESTONIA_CODE, 
	FINLAND_CODE, FRANCE_CODE,
	GERMANY_CODE, 
	HUNGARY_CODE,
	INDIA_CODE, IRELAND_CODE, ITALY_CODE, 
	JAPAN_CODE, 
	LATVIA_CODE, LITHUANIA_CODE, LUXEMBOURG_CODE, 
	MALAYSIA_CODE, MEXICO_CODE, 
	NETHERLANDS_CODE, NEW_ZEALAND_CODE, NORWAY_CODE, 
	POLAND_CODE, PORTUGAL_CODE, PUERTO_RICO_CODE, 
	SINGAPORE_CODE, SLOVAKIA_CODE, SLOVENIA_CODE, SPAIN_CODE, SWEDEN_CODE, SWITZERLAND_CODE,
	UNITED_KINGDOM_CODE, UNITED_STATES_CODE
];