import React from 'react';
import PropTypes from "prop-types";

import { Editor } from "@tinymce/tinymce-react";

// Config DOCS: https://www.tiny.cloud/docs/tinymce/6/copy-and-paste/
// API Props DOCS: https://www.tiny.cloud/docs/integrations/react/
/**
 * @param {String} placeholder
 * @param {Function} onChangeHandler for validation
 */
function TextEditor(props) {
	return (
		<Editor initialValue={props.placeholder}
			onEditorChange={props.onChangeHandler}
			init={{
				content_style: 'div { block: inline-block;}',

				menubar: 'edit format tools help',
				removed_menuitems: 'strikethrough spellcheckerlanguage codeformat backcolor align',
				// casechange linkchecker checklist tinymcespellchecker
				plugins: 'autolink lists',
				toolbar: [
					{ name: 'history', items: [ 'undo', 'redo' ] },
					{ name: 'styles', items: [ 'styles' ] },
					{ name: 'formatting', items: [ 'bold', 'italic' ] },
					{ name: 'alignment', items: [ 'alignleft', 'alignjustify' ] },
					{ name: 'indentation', items: [ 'outdent', 'indent' ] }
				],
				paste_as_text: true,
				paste_block_drop: true
			}}>
		</Editor>
	);
}

TextEditor.propTypes = {
	onChangeHandler: PropTypes.func,
	placeholder: PropTypes.string
};

export default TextEditor;