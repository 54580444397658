import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";
import { onKeyDown } from "Utilities/Accessibility";

import plusAsset from "assets/icons/common/plus.svg";
import closeAsset from "assets/icons/common/close.svg";


function DayAvailability(props) {

	const [dayAvailability1, setDayAvailability1] = useState(),
		[dayAvailability2, setDayAvailability2] = useState();

	const availabilityBlockCountRef = useRef(0);

	function addAnotherDayAvailability() {
		const availabilityDomMap = {
			1: setDayAvailability1,
			2: setDayAvailability2
		};

		if (availabilityBlockCountRef.current < 2) {

			const emptyAvailability = dayAvailability1 == null ? 1 : 2;
			availabilityDomMap[emptyAvailability](
				<div className="day-block-availability">
					<input className="page-field-value time" type="time"
						onChange={e => props.updateSchedule(e, props.day, "from", emptyAvailability)}>
					</input>
					<div className="dash">-</div>
					<input className="page-field-value time" type="time"
						onChange={e => props.updateSchedule(e, props.day, "to", emptyAvailability)}>
					</input>
					<img className="ex-icon" src={closeAsset} alt={messageMap("subPages.learner.ex", "image") + props.day}
						role="button" tabIndex={0}
						onClick={e => removeDayAvailability(emptyAvailability)}
						onKeyPress={(e) => onKeyDown(e, removeDayAvailability, [emptyAvailability])}></img>
				</div>
			);

			availabilityBlockCountRef.current += 1;
		}
	}

	function removeDayAvailability(blockToRemove) {
		const availabilityDomMap = {
			1: setDayAvailability1,
			2: setDayAvailability2
		};

		// this part should always go before the next 2 expressions
		props.updateSchedule(null, props.day, "to", blockToRemove, true);

		availabilityDomMap[blockToRemove](null);
		availabilityBlockCountRef.current = availabilityBlockCountRef.current - 1;
	}

	return (
		<div>
			<div className="day-block-availability">
				<input className="page-field-value time" type="time"
					onChange={e => props.updateSchedule(e, props.day, "from", 0)}>
				</input>
				<div className="dash">-</div>
				<input className="page-field-value time" type="time"
					onChange={e => props.updateSchedule(e, props.day, "to", 0)}>
				</input>
				<img className="plus-icon" src={plusAsset} alt={messageMap("subPages.learner.plus", "image") + props.day}
					role="button" tabIndex={0}
					onClick={e => addAnotherDayAvailability(e)}
					onKeyPress={(e) => onKeyDown(e, addAnotherDayAvailability, [e])}></img>
			</div>
			
			{dayAvailability1}
			{dayAvailability2}
		</div>
	);
}

DayAvailability.propTypes = {
	updateSchedule: PropTypes.func.isRequired,
	day: PropTypes.string.isRequired
};

export default DayAvailability;
