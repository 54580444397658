import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";

import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { connect } from "react-redux";
import { setPaymentConfirmationInfo } from "redux/actions/actionTypes";
import navigationSelector from "redux/selectors/navigationSelector";

import messageMap from "Utilities/MessageMaps";
import Spinner from "templates/Spinner";


function StripeCheckoutSubmit(props) {

	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [paymentInfo, setPaymentInfo] = useState();

	// payment tests: https://docs.stripe.com/testing#cards
	async function handleSubmit(e) {
		e.preventDefault();

		setIsLoading(true);

		// if stripe hasn't loaded, return
		if (!stripe || !elements) {
			return null;
		}

		async function confirmPaymentAsync() {
			try {
				const baseUrl = props.envMode === "dev" ? "http://localhost:3000/payment-confirmation" : "https://exerinstitute.com/payment-confirmation";
				const paymentPurpose = props.paymentPurpose;

				let reduxPaymentConfirmationState = {
					ownerId: props.ownerId,
					chargeId: props.chargeId,
					paymentPurpose: paymentPurpose,
					price: props.paymentAmount
				};
				let returnUrl = baseUrl + `?method=${paymentInfo.value.type}&confirmationRedirection=home`;
				if (props.directTutoringRequestMatchingObject != null) {
					reduxPaymentConfirmationState["directTutoringRequestMatchingObject"] = props.directTutoringRequestMatchingObject;
				}
				else if (props.tutorId != null && props.sessionId != null) {
					reduxPaymentConfirmationState["tutorId"] = props.tutorId;
					reduxPaymentConfirmationState["sessionId"] = props.sessionId;
					
					returnUrl = baseUrl + `?confirmationRedirection=${props.confirmationRedirection}`;
				}
				else if (paymentPurpose === "subscription" && props.paymentDetails != null) {
					const {subscriptionPlan, subscriptionId, productId, productPriceId} = props.paymentDetails;
					reduxPaymentConfirmationState["subscriptionPlan"] = subscriptionPlan;
					reduxPaymentConfirmationState["subscriptionId"] = subscriptionId;
					reduxPaymentConfirmationState["productId"] = productId;
					reduxPaymentConfirmationState["productPriceId"] = productPriceId;

					returnUrl = baseUrl + `?confirmationRedirection=${props.confirmationRedirection}`;
				}

				props.setPaymentConfirmationInfo(reduxPaymentConfirmationState);

				const { error } = await stripe.confirmPayment({
					elements,
					confirmParams: {
						return_url: returnUrl
					}
				});
				return error;
			}
			catch (error) {
				console.error("Error confirming payment: ", error);
				return error;
			}
		}

		const error = await confirmPaymentAsync();

		// Triggers if there is an immediate error when confirming the payment. 
		// Otherwise, your customer will be redirected to your `return_url`. For 
		// some payment methods like iDEAL, your customer will be redirected to 
		// an intermediate site first to authorize the payment, then redirected 
		// to the `return_url`.
		if (error && Object.keys(error).length) {
			setMessage(error.message);
		}

		setIsLoading(false);
	}

	function paymentElementReady() {
		setIsLoading(false);
	}

	function someChange(theChange) {
		setPaymentInfo(theChange);
	}

	return (
		<Fragment>
			{
				isLoading && <Spinner containerClass="pay-spinner" />
			}
			<form onSubmit={handleSubmit}>
				<PaymentElement onReady={paymentElementReady} onChange={someChange} />
				<button className="pay-button" disabled={isLoading}>
					<span id="button-text">
						{messageMap("checkout.pay", "button")}
					</span>
				</button>
				{
					message && <div className="payment-message">{message}</div>
				}
			</form>
		</Fragment>
	);
}

StripeCheckoutSubmit.propTypes = {
	confirmationRedirection: PropTypes.string.isRequired,
	paymentPurpose: PropTypes.string.isRequired,
	paymentDetails: PropTypes.object,
	paymentAmount: PropTypes.number.isRequired,
	clientSecret: PropTypes.string.isRequired,
	chargeId: PropTypes.string,
	ownerId: PropTypes.string.isRequired,

	tutorId: PropTypes.string,
	sessionId: PropTypes.string,
	directTutoringRequestMatchingObject: PropTypes.object,

	// redux props
	envMode: PropTypes.string,
	setPaymentConfirmationInfo: PropTypes.func.isRequired
};

export default connect(
	navigationSelector,
	{ setPaymentConfirmationInfo }
)(StripeCheckoutSubmit);