import {
	ALBANIA_CODE, ANTIGUA_AND_BARBUDA_CODE, ARGENTINA_CODE, ARMENIA_CODE, AUSTRALIA_CODE, AUSTRIA_CODE,
	BAHAMAS_CODE, BAHRAIN_CODE, BELGIUM_CODE, BENIN_CODE, BOLIVIA_CODE, BOSNIA_AND_HERZEGOVINA_CODE, BOTSWANA_CODE, BRAZIL_CODE, BRUNEI_CODE, BULGARIA_CODE,
	CAMBODIA_CODE, CANADA_CODE, CHILE_CODE, COLOMBIA_CODE, COSTA_RICA_CODE, COTE_D_IVOIRE_CODE, CROATIA_CODE, CYPRUS_CODE, CZECH_REPUBLIC_CODE,
	DENMARK_CODE, DOMINICAN_REPUBLIC_CODE, 
	ECUADOR_CODE, EGYPT_CODE, EL_SALVADOR_CODE, ESTONIA_CODE, ETHIOPIA_CODE, 
	FINLAND_CODE, FRANCE_CODE,
	THE_GAMBIA_CODE, GERMANY_CODE, GHANA_CODE, GREECE_CODE, GUATEMALA_CODE, GUYANA_CODE,
	HONG_KONG_CODE, HUNGARY_CODE,
	ICELAND_CODE, INDIA_CODE, INDONESIA_CODE, IRELAND_CODE, ISRAEL_CODE, ITALY_CODE,
	JAMAICA_CODE, JAPAN_CODE, JORDAN_CODE,
	KENYA_CODE, KUWAIT_CODE,
	LATVIA_CODE, LIECHTENSTEIN_CODE, LITHUANIA_CODE, LUXEMBOURG_CODE, 
	MACAO_CODE, MADAGASCAR_CODE, MALAYSIA_CODE, MALTA_CODE, MAURITIUS_CODE, MEXICO_CODE, MOLDOVA_CODE, MONACO_CODE, MONGOLIA_CODE, MOROCCO_CODE,
	NAMIBIA_CODE, NETHERLANDS_CODE, NEW_ZEALAND_CODE, NIGERIA_CODE, NORTH_MACEDONIA_CODE, NORWAY_CODE,
	OMAN_CODE, 
	PAKISTAN_CODE, PANAMA_CODE, PARAGUAY_CODE, PERU_CODE, PHILIPPINES_CODE, POLAND_CODE, PORTUGAL_CODE,
	QATAR_CODE,
	ROMANIA_CODE, RWANDA_CODE, 
	SAUDI_ARABIA_CODE, SENEGAL_CODE, SERBIA_CODE, SINGAPORE_CODE, SLOVAKIA_CODE, SLOVENIA_CODE, SOUTH_AFRICA_CODE, SOUTH_KOREA_CODE, SPAIN_CODE, SRI_LANKA_CODE, SAINT_LUCIA_CODE, SWEDEN_CODE, SWITZERLAND_CODE,
	TAIWAN_CODE, TANZANIA_CODE, THAILAND_CODE, TRINIDAD_AND_TOBAGO_CODE, TUNISIA_CODE, TURKEY_CODE,
	UNITED_ARAB_EMIRATES_CODE, UNITED_KINGDOM_CODE, UNITED_STATES_CODE, URUGUAY_CODE, UZBEKISTAN_CODE,
	VIETNAM_CODE
} from "Utilities/Constants/CountriesConstants";

// information was taken from: https://docs.stripe.com/acceptable-verification-documents?country=AL&document-type=identity
// NOTES*:
// 1.) not all countries would have both ID and address documentation, but every country would have an ID document
// 2.) Sometimes Stripe doesn't use the country's official language when indicating the type of document, so I used Google Translate for those cases
export const idDocumentExamples = {
	[ALBANIA_CODE]: [
		{key: "Passport - e preferuar"},
		{key: "Letërnjoftimi (National Identity Card)"},
		{key: "Leje Drejtimi (Driving License)"},
		{key: "çertifikatë regjistrimi (Business registration certificate)"}
	],
	[ANTIGUA_AND_BARBUDA_CODE]: [
		{key: "Passport - preferred"},
		{key: "Driving License"},
		{key: "Social Security Card"}
	],
	[ARGENTINA_CODE]: [
		{key: "Pasaporte - preferido (Passport)"},
		{
			key: "Documento Nacional de Identidad (DNI) (National ID)",
			sides: 2
		}, {
			key: "DNI para Extranjeros (Resident Permit)",
			sides: 2
		}
	],
	[ARMENIA_CODE]: [
		{key: "անձնագիր"},
		{key: "Ազգային ինքնության քարտ"},
	],
	[AUSTRALIA_CODE]: [
		{key: "Passport - preferred"},
		{
			key: "Driver Licence (Driver's license)",
			sides: 2
		}, {
			key: "Photo Card",
			sides: 2
		}, {
			key: "New South Wales Driving Instructor Licence",
			sides: 2
		}, {
			key: "Tasmanian Government Personal Information Card",
			sides: 2
		}, {
			key: "ImmiCard",
			sides: 2
		}, {
			key: "Proof of Age card",
			sides: 2
		}, {
			key: "Australian Defence Force (ADF) identification card (Military ID)",
			sides: 2
		}
	],
	[AUSTRIA_CODE]: [
		{key: "Reisepass (Passport)"},
		{key: "Führerschein (Driver's license)"},
		{
			key: "Personalausweis Republik Österreich (Identity card)",
			sides: 2
		},
		{key: "Aufenthaltstitel (Residence permit)"},
		{
			key: "Identitätskarte für Fremde (Identity card for foreigners)",
			sides: 2
		},
		{key: "Fremdenpässe (Alien's Passport)"},
		{key: "Konventionsreisepass (Passport for asylum seekers)"},
		{
			key: "Duldungskarte (Temporary residence permit)",
			sides: 2
		}
	],
	[BAHAMAS_CODE]: [
		{key: "Passport - preferred"},
		{key: "Driver's Licence (Driver's License)"},
	],
	[BAHRAIN_CODE]: [
		{key: "جواز السفر - مفضل"},
		{key: "بطاقة الهوية الوطنية"},
		{key: "رخصة القيادة"}
	],
	// Belgium has 3 official languages.
	[BELGIUM_CODE]: [
		{key: "Paspoort / Passeport / Reisepass (Passport)"},
		{
			key: "Rijbewijs / Permis de conduire / Führerschein (Driver's license)",
			sides: 2
		}, {
			key: "Elektronische identiteitskaart / Carte d'identité / eID (Identity card)",
			sides: 2
		}, {
			key: "Elektronische vreemdelingenkaart / Carte d’identité d’étranger / Elektronische Ausländerkarte (Identity card for foreigners)",
			sides: 2
		},
		{key: "Verblijfsvergunning / Permis de résidence / Aufenthaltsgenehmigung (Residence permit) - dated within 12 months"},
	],
	[BENIN_CODE]: [
		{key: "Passport - préféré"},
		{key: "Driver's license"},
		{key: "Carte nationale d'identité (National ID)"}
	],
	[BOLIVIA_CODE]: [
		{key: "Pasaporte - preferido (Passport)"},
		{
			key: "Cedula de Identidad (National ID)",
			sides: 2
		}, {
			key: "Cedula de identidad de Extranjero (Resident Permit)",
			sides: 2
		}
	],
	[BOSNIA_AND_HERZEGOVINA_CODE]: [
		{key: "Pasoš - poželjno (Passport)"},
		{key: "Nacionalna lična karta (National ID card)"}
	],
	[BOTSWANA_CODE]: [
		{key: "Passport - preferred"},
		{key: "Driving Licence (Driving License)"},
		{key: "Omang (Identity card)"}
	],
	[BRAZIL_CODE]: [
		{key: "Passaporte de estrangeiros (Non-Brazilian Passport)"},
		{key: "Carteira de Motorista (CNH) (Driver's license)"},
		{key: "Carteira de Identidade (RG) (National ID)"},
		{key: "Carteira de Registro Nacional Migratório (CRNM) (National Migration Registry Card)"}
	],
	[BRUNEI_CODE]: [
		{key: "Pasport - diutamakan (Passport)"},
		{key: "Lesen Memandu (Driving License)"},
		{key: "Kad Pengenalan Pendaftaran Kebangsaan (National Identity Card)"}
	],
	[BULGARIA_CODE]: [
		{key: "Задграничен паспорт - за предпочитане (Passport)"},
		{key: "лична карта (Identity card)"},
		{key: "Свидетелство за управление на мпс (Driver's license)"},
		{
			key: "Разрешение за пребиваване (Residence permit)",
			sides: 2
		}
	],
	[CAMBODIA_CODE]: [
		{key: "លិខិតឆ្លងដែន - ពេញចិត្ត (Passport)"},
		{key: "ប័ណ្ណបើកបរ (Driving License)"},
		{
			key: "អត្តសញ្ញាណប័ណ្ណសញ្ជាតិខ្មែរ (National Identity Card)",
			sides: 2
		}
	],
	[CANADA_CODE]: [
		{key: "Passport / Passeport - preferred"},
		{key: "Driver's licence / Permis de conduire"},
		{key: "Permanent Resident card / Carte de résident permanent"},
		{
			key: "Provincial or territorial issued photo identity cards / Cartes d'identité avec photo délivrées par une province ou un territoire",
			sides: 2
		},
		{key: "Certificate of Indian Status card / Certificat du statut d'Indien"},
		{key: "Birth certificate / Acte de naissance"},
		{key: "Marriage certificate / Acte de mariage"}
	],
	[CHILE_CODE]: [
		{key: "Pasaporte - preferido (Passport)"},
		{
			key: "Cedula de Identidad (National ID)",
			sides: 2
		}, {
			key: "Cédula de identidad para extranjeros (Resident Permit)",
			sides: 2
		}
	],
	[COLOMBIA_CODE]: [
		{key: "Pasaporte (Passport)"},
		{
			key: "Licencia de Conducion (Driver's license)",
			sides: 2
		}, {
			key: "Tarjeta de Identidad (National ID)",
			sides: 2
		}, {
			key: "Cedula de Ciudadania (ID card)",
			sides: 2
		}, {
			key: "Cedula de Extrajeria (Resident Permit)",
			sides: 2
		}
	],
	// not sure why it's empty in Stripe
	[COSTA_RICA_CODE]: [

	],
	[COTE_D_IVOIRE_CODE]: [
		{key: "Passeport - de préférence (Passport)"},
		{
			key: "Le permis de conduire (Driver's license)",
			sides: 2
		},
		{key: "Carte Nationale d'Identité (National ID)"}
	],
	[CROATIA_CODE]: [
		{key: "Putovnica - poželjna (Passport)"},
		{key: "Vozačka dozvola (Driver's license)"},
		{
			key: "Osobna iskaznica (ID card)",
			sides: 2
		},
		{key: "Dozvola boravka (Resident Permit)"}
	],
	[CYPRUS_CODE]: [
		{key: "Διαβατήριο / Pasaport (Passport)"},
		{
			key: "Δελτίου Ταυτότητας / Kimlik kartı (Identity card)",
			sides: 2
		}, {
			key: "Άδεια Οδήγησης / Ehliyet (Driver's license)",
			sides: 2
		}
	],
	[CZECH_REPUBLIC_CODE]: [
		{key: "Cestovní pas - preferováno (Passport)"},
		{
			key: "Občanský průkaz (Identity card)",
			sides: 2
		}, {
			key: "Řidičský průkaz (Driver's license)",
			sides: 2
		}, {
			key: "Povolení k pobytu (Residence permit)",
			sides: 2
		}
	],
	[DENMARK_CODE]: [
		{key: "Pas - foretrækkes (Passport)"},
		{
			key: "Kørekort (Driver's license)",
			sides: 2
		}, {
			key: "Identitetskort fra København (City of Copenhagen Identity card)",
			sides: 2
		}, {
			key: "Opholdstilladelse (Residence permit)",
			sides: 2
		},
		{key: "Militært identitetskort (Military identity card)"},
		{key: "Identitetskort fra politiet (Police identity card)"}
	],
	[DOMINICAN_REPUBLIC_CODE]: [
		{key: "Pasaporte - preferido (Passport)"},
		{
			key: "Cedula de identidad y electoral (ID card)",
			sides: 2
		}, {
			key: "Cedula de identidad y electoral para extranjeros (Resident Permit)",
			sides: 2
		}
	],
	[ECUADOR_CODE]: [
		{key: "Pasaporte - preferido (Passport)"},
		{key: "Cédula de Identidad (National Identity Card)"},
		{key: "Driver's License (Driving License)"},
	],
	[EGYPT_CODE]: [
		{key: "جواز السفر المصري, Egyptian passport/ جمهورية مصر العربية - Arab Republic of Egypt passport"}
	],
	[EL_SALVADOR_CODE]: [
		{key: "Pasaporte - preferido (Passport)"},
		{key: "Documento Nacional de Identidad (National ID card)"},
		{key: "Carnet de conducir (Driving License)"}
	],
	[ESTONIA_CODE]: [
		{key: "Pass - eelistatud (Passport)"},
		{key: "Juhiluba (Driver's license)"},
		{key: "ID-kaart (Identity card)"},
		{key: "Elamisluba (Residence permit)"},
	],
	[ETHIOPIA_CODE]: [
		{key: "ፓስፖርት - ይመረጣል (Passport)"},
		{key: "የመንጃ ፍቃድ (Driving Lincense)"},
		{key: "ብሔራዊ መታወቂያ (Identity card)"}
	],
	[FINLAND_CODE]: [
		{key: "Passi - mieluiten (Passport)"},
		{
			key: "Ajokortti Körkort (Driver's license)",
			sides: 2
		}, {
			key: "Henkilökortti / Identitetskort (Identity card)",
			sides: 2
		}, {
			key: "Oleskelukortti / Uppehållstillstånd (Residence card)",
			sides: 2
		}
	],
	[FRANCE_CODE]: [
		{key: "Passeport - de préférence (Passport)"},
		{
			key: "Permis de conduire (Driver's license)",
			sides: 2
		}, {
			key: "Carte d'identité (Identity card)",
			sides: 2
		},
		{key: "Carte Vitale avec photo (Health insurance card)"},
		{key: "Carte d'identité avec photographie délivrée par les autorités militaires (Military identity card)"},
		{key: "Carte d'identité de parlementaire (Parliamentary identity card)"},
		{key: "Carte d'identité d'élu local (Elected official identity card)"},
		{key: "Carte du combattant avec photographie (Veteran's identity card)"}
	],
	[THE_GAMBIA_CODE]: [
		{key: "Passport - preferred"},
		{key: "National Identity Card"},
		{key: "Driver's License"},
	],
	[GERMANY_CODE]: [
		{key: "Reisepass - bevorzugt (Passport)"},
		{
			key: "Führerschein (Driver's license)",
			sides: 2
		}, {
			key: "Personalausweis (Identity card)",
			sides: 2
		}, {
			key: "Aufenthaltstitel (Residence permit)",
			sides: 2
		}
	],
	[GHANA_CODE]: [
		{key: "Passport - preferred"},
		{key: "National Identity Card"},
		{key: "Driving License"}
	],
	[GREECE_CODE]: [
		{key: "Διαβατήριο - κατά προτίμηση (Passport)"},
		{
			key: "Δελτίο Ταυτότητας (Identity card)",
			sides: 2
		},
		{key: "Βεβαίωση Διαμονής (Residence Certificate)"},
		{key: "Άδεια οδήγησης (Driver's license)"}
	],
	[GUATEMALA_CODE]: [
		{key: "Pasaporte - preferido (Passport)"},
		{key: "Carnet de identidad (Identity Card)"},
		{key: "Carnet de conducir (Driving License)"}
	],
	[GUYANA_CODE]: [
		{key: "Passport - preferred"},
		{key: "Identity Card"},
		{key: "Driving License"}
	],
	[HONG_KONG_CODE]: [
		{key: "护照 - 优先 / Passport - preferred"},
		{
			key: "身份證 / Identity card",
			sides: 2
		},
		{key: "登記事項證明書 / Certificate of Registered Particulars"},
		{key: "豁免證明書 / Certificate of Exemption"},
		{key: "越南難民證 / Vietnamese refugee card"}
	],
	[HUNGARY_CODE]: [
		{key: "Útlevél - előnyben (Passport)"},
		{
			key: "Személyazonosító igazolvány (Identity card)",
			sides: 2
		}, {
			key: "Tartózkodási engedély (Residence permit)",
			sides: 2
		}
	],
	[ICELAND_CODE]: [
		{key: "Vegabréf - æskilegt (Passport)"},
		{
			key: "ökuskírteini (Driver's license)",
			sides: 2
		}
	],
	[INDIA_CODE]: [
		{key: "Individual PAN card"}
	],
	[INDONESIA_CODE]: [
		{key: "Paspor - lebih disukai (Passport)"},
		{
			key: "Kartu Tanda Penduduk (Identity Card)",
			sides: 2
		},
		{key: "Surat Izin Mengemudi (Driver's license)"}
	],
	[IRELAND_CODE]: [
		{key: "Passport - preferred"},
		{
			key: "Driver's license",
			sides: 2
		},
		{
			key: "National Age Card",
			sides: 2
		}
	],
	[ISRAEL_CODE]: [
		{key: "דרכון ישראלי, Darkon Yisre'eli (Passport)"},
		{
			key: "רשיון נהיגה, Rishion nehega (Driver's license)",
			sides: 2
		}
	],
	[ITALY_CODE]: [
		{key: "Passaporto - preferito (Passport)"},
		{
			key: "Patente di guida (Driver's license)",
			sides: 2
		},
		{
			key: "Carta di identità / Carta d'identità (Identity card)",
			sides: 2
		},
		{key: "Patente nautica (Boat license)"},
		{key: "Porto d'armi (Weapons license)"},
		{key: "Libretto di pensione (Pension book)"}
	],
	[JAMAICA_CODE]: [
		{key: "Passport - preferred"},
		{key: "Voter ID Card"},
		{key: "Driver's license"}
	],
	[JAPAN_CODE]: [
		{key: "日本旅券 (Japanese passport)"},
		{key: "運転免許証 (Driver's license)"},
		{key: "在留カード・特別永住者証明書 (Residence Card)"},
		{key: "マイナンバーカード (顔写真付き) (My Number Card, with photo)"},
		{key: "住民票 (Residence Certificate)"}
	],
	[JORDAN_CODE]: [
		{key: "جواز السفر - مفضل (Passport)"},
		{key: "بطاقة الهوية (National Identity Card)"},
		{key: "رخصة القيادة (Driving license)"}
	],
	[KENYA_CODE]: [
		{key: "Passport - preferred"},
		{key: "Kenya National Identity Card"},
		{key: "Driver's License"}
	],
	[KUWAIT_CODE]: [
		{key: "جواز السفر - مفضل (Passport)"},
		{key: "بطاقة الهوية (National Identity Card)"},
		{key: "رخصة القيادة (Driving license)"}
	],
	[LATVIA_CODE]: [
		{key: "Pase - vēlams (Driving license)"},
		{key: "Personas apliecība (Identity Card)"},
		{
			key: "Uzturēšanās atļauja (Residence Permit)",
			sides: 2
		}
	],
	[LIECHTENSTEIN_CODE]: [
		{key: "Reisepass - bevorzugt (Passport)"},
		{key: "Führerschein (Driver's license)"},
		{
			key: "Identitätskarte (Identity Card)",
			sides: 2
		}
	],
	[LITHUANIA_CODE]: [
		{key: "Pasas - pageidautina (Passport)"},
		{key: "Asmens tapatybės kortelė (Identity Card)"},
		{key: "Vairuotojo pažymėjimas (Driver's License)"}
	],
	[LUXEMBOURG_CODE]: [
		{key: "Passeport / Reisepass (Passport)"},
		{
			key: "Permis de conduire / Führerschein (Driver's license)",
			sides: 2
		}, {
			key: "Carte d'identité / Personalausweis (Identity card)",
			sides: 2
		}, {
			key: "Permis de conduire local / Aufenthaltserlaubnis (Resident permit ID)",
			sides: 2
		}
	],
	[MACAO_CODE]: [
		{key: "护照 - 优先 (Passport)"},
		{key: "居民身份证"}
	],
	[MADAGASCAR_CODE]: [
		{key: "Pasipaoro - aleony (Passport)"},
		{key: "Permis de conduire (Driving License)"},
		{key: "Kara-panondrom-pirenena (Identity card)"}
	],
	[MALAYSIA_CODE]: [
		{key: "Pasport - diutamakan (Passport)"},
		{key: "Kad Pengenalan / MyKad (Identity Card)"}
	],
	[MALTA_CODE]: [
		{key: "Passaport - preferut (Passport)"},
		{key: "Karta Tal-Identita (Identity Card)"},
		{key: "Liċenzja tas-Sewqan (Driver License)"},
		{
			key: "Permess ta' Residenza (Residence Permit)",
			sides: 2
		}
	],
	[MAURITIUS_CODE]: [
		{key: "Passport - preferred"},
		{key: "Identity Card"},
		{key: "Driving License"}
	],
	[MEXICO_CODE]: [
		{key: "Pasaporte - preferido (Passport)"},
		{
			key: "Tarjeta de identificación (ID card)",
			sides: 2
		},
		{key: "ID consular / ID matricular consular (Consular ID/Matricular Consular ID Card)"},
		{key: "Credencial de elector (Voter ID card)"},
		{key: "CURP, Clave Única de Registro de Población"},
		{key: "Acta de nacimiento (Birth Certificate)"},
		{key: "Licencia de conducir (Driver license)"}
	],
	[MOLDOVA_CODE]: [
		{key: "Pașaport - de preferat (Passport)"},
		{key: "Permis de conducere (Driving License)"},
		{key: "Cartea de identitate (National Identity Card)"}
	],
	[MONACO_CODE]: [
		{key: "Passeport - de préférence (Passport)"},
		{key: "Le permis de conduire (Drivers License)"},
		{key: "Carte d'identité monégasque électronique (National ID)"}
	],
	[MONGOLIA_CODE]: [
		{key: "Паспорт - илүүд үздэг (Passport)"},
		{
			key: "жолоочийн үнэмлэх (Driving License)",
			sides: 2
		}, {
			key: "Иргэний үнэмлэх (Identity card)",
			sides: 2
		}
	],
	[MOROCCO_CODE]: [
		{key: "جواز السفر - مفضل (Passport)"},
		{key: "رخصة السائق (Drivers license)"},
		{
			key: "البطاقة الوطنية للتعريف الإلكترونية / Carte nationale d'identité électronique (CNIE) (National ID) (Passport)",
			sides: 2
		}
	],
	[NAMIBIA_CODE]: [
		{key: "Passport - preferred"},
		{key: "Identity Card"},
		{key: "Driving License"}
	],
	[NETHERLANDS_CODE]: [
		{key: "Paspoort - voorkeur (Passport)"},
		{
			key: "rijbewijs (Driver license)",
			sides: 2
		},
		{
			key: "nationaal ID-bewijs (National ID)",
			sides: 2
		},
		{key: "verblijfsvergunning (Resident permit ID)"},
		{key: "Nederlands vreemdelingendocument (Dutch aliens document)"}
	],
	[NEW_ZEALAND_CODE]: [
		{key: "Passport - preferred"},
		{
			key: "Driver license",
			sides: 2
		},
		{key: "New Zealand emergency travel document"},
		{key: "New Zealand refugee travel document"},
		{key: "New Zealand certificate of identity"}
	],
	[NIGERIA_CODE]: [
		{key: "Passport - preferred"},
		{key: "Identity Card (National Identity Card)"},
		{key: "Driving License"}
	],
	[NORTH_MACEDONIA_CODE]: [
		{key: "Пасош - пожелно (Passport)"},
		{key: "Лична карта (Identity Card)"}
	],
	[NORWAY_CODE]: [
		{key: "Pass - foretrekkes (Passport)"},
		{
			key: "Førerkort (Driver License)",
			sides: 2
		},
		{key: "ID-kort (ID card)"},
		{key: "Oppholdskort - innen 12 måneder (Resident permit ID)"}
	],
	[OMAN_CODE]: [
		{key: "جواز السفر - مفضل(Passport)"},
		{key: "بطاقة الهوية (National Identity Card)"},
		{key: "رخصة القيادة (Driving License)"}
	],
	[PAKISTAN_CODE]: [
		{key: "Passport - preferred"},
		{key: "Driving License"},
		{key: "( قومی شناختی کار) Computerised National Identity Card"}
	],
	[PANAMA_CODE]: [
		{key: "Passport - preferred"},
		{key: "Driving License"}
	],
	[PARAGUAY_CODE]: [
		{key: "Pasaporte - preferido (Passport)"},
		{
			key: "Cedula de Identidad (National ID)",
			sides: 2
		},
		{key: "Cedula de identidad a Extranjero (Resident Permit)"}
	],
	[PERU_CODE]: [
		{key: "Pasaporte - preferido (Passport)"},
		{key: "Documento Nacional de Identidad (DNI) (National ID)"},
		{key: "Documento Nacional de Identidad Electrónico (DNIe) (Electronic National ID)"},
		{key: "Carné de Extranjería (Immigration Card)"},
		{key: "Licencia de conducir / Brevete (Driver License)"},
		{key: "DNI para Extranjeros (Resident Permit)"}
	],
	[PHILIPPINES_CODE]: [
		{key: "Passport - preferred"},
		{key: "Unified Multi-Purpose ID (UMID) (National ID)"},
		{key: "Philippine Identification System ID (PhilSys ID) (National ID)"},
		{key: "Driver's License"}
	],
	[POLAND_CODE]: [
		{key: "Paszport - preferowany (Passport)"},
		{key: "Prawo jazdy (Drivers License)"},
		{key: "Dowód osobisty (Identity Card)"},
		{
			key: "Zezwolenie na pobyt (Residence Permit)",
			sides: 2
		}
	],
	[PORTUGAL_CODE]: [
		{key: "Passaporte - preferencial (Passport)"},
		{key: "Carta de condução (Driver license)"},
		{
			key: "Cartão de identidade (ID card)",
			sides: 2
		},
		{key: "Cartão de cidadão (Citizen card)"},
		{
			key: "Título de residência (Residence Permit)",
			sides: 2
		}
	],
	[QATAR_CODE]: [
		{key: "جواز السفر - مفضل (Passport)"},
		{key: "بطاقة الهوية (National Identity Card)"},
		{key: "تصريح الإقامة (Residency Permit)"},
		{key: "رخصة القيادة (Driving License)"}
	],
	[ROMANIA_CODE]: [
		{key: "Pașaport - de preferat (Passport)"},
		{key: "Carte de identitate / Buletin (Identity Card)"},
		{
			key: "Permis de şedere (Residence Permit)",
			sides: 2
		}, {
			key: "Permis de conducere (Driver License)"
		}
	],
	[RWANDA_CODE]: [
		{key: "Passport - preferred"},
		{key: "Driving License"},
		{key: "eID (Identity card)"}
	],
	[SAUDI_ARABIA_CODE]: [
		{key: "جواز السفر - مفضل (Passport)"},
		{key: "رخصة السائق (Driver's License)"},
		{key: "بطاقة الأحوال المدنية (National ID)"}
	],
	[SENEGAL_CODE]: [
		{key: "Passeport - de préférence (Passport)"},
		{key: "Le permis de conduire (Drivers License)"},
		{
			key: "Carte Nationale d'identité CEDEAO (National ID)",
			sides: 2
		}
	],
	[SERBIA_CODE]: [
		{key: "Пасош - пожељно (Passport)"},
		{key: "Лична карта (National ID)"},
		{key: "Возачка дозвола (Driver's License)"}
	],
	[SINGAPORE_CODE]: [
		{key: "Passport - preferred"},
		{
			key: "Driver's license",
			sides: 2
		},
		{
			key: "National Registration Identity Card",
			sides: 2
		},
		{
			key: "Work Pass or Long Term Pass",
			sides: 2
		}
	],
	[SLOVAKIA_CODE]: [
		{key: "Cestovný pas - preferovaný (Passport)"},
		{key: "Občiansky preukaz (ID Card)"},
		{key: "Vodičský preukaz (Driver License)"},
		{
			key: "Povolenie na pobyt (Residence Permit)",
			sides: 2
		}
	],
	[SLOVENIA_CODE]: [
		{key: "Potni list - zaželeno (Passport)"},
		{key: "Osebna izkaznica (ID Card)"},
		{key: "Vozniško dovoljenje (Driver License)"},
		{
			key: "Dovoljenje za prebivanje (Residence Permit)",
			sides: 2
		}
	],
	[SOUTH_AFRICA_CODE]: [
		{key: "Passport - preferred"},
		{key: "Smart ID Card (National ID)"},
		{key: "Driver's License"}
	],
	[SOUTH_KOREA_CODE]: [
		{key: "여권 - 선호됨 (Passport)"},
		{key: "주민등록증 (National ID)"},
		{key: "운전면허증 (Driver's License)"}
	],
	[SPAIN_CODE]: [
		{key: "Pasaporte - preferido (Passport)"},
		{key: "Permiso de conducir (Driver license)"},
		{
			key: "Documento nacional de identidad (DNI) (National ID)",
			sides: 2
		},
		{key: "Tarjeta de Identidad de Extranjero Card (Resident permit ID)"}
	],
	[SRI_LANKA_CODE]: [
		{key: "විදේශ ගමන් බලපත්රය - කැමති (Passport)"},
		{key: "රියදුරු බලපත්රය (Driving Licence)"},
		{key: "ජාතික හැඳුනුම්පත (National Identity Card)"}
	],
	[SAINT_LUCIA_CODE]: [
		{key: "Passport - preferred"},
		{key: "National Identity Card"},
		{key: "Driving License"}
	],
	[SWEDEN_CODE]: [
		{key: "Pass - föredraget (Passport)"},
		{key: "Körkort (Driver license)"},
		{
			key: "Nationellt ID-kort (National ID)",
			sides: 2
		},
		{key: "Personbevis (Birth Certificate)"},
		{key: "ID-kort (Swedish Standards Institute (SIS) approved ID cards)"}
	],
	[SWITZERLAND_CODE]: [
		{key: "Reisepass / Passeport / Passaporto (Passport)"},
		{key: "Führerschein / Permis de conduire / Licenza di condurre (Driver license)"},
		{
			key: "Identitäskarte / Carte d'identité / Carta d'identità (National ID)",
			sides: 2
		},
		{key: "Aufenthaltsbewilligung / Permis de séjour / Libretto per stranieri (Residence permit)"}
	],
	[TAIWAN_CODE]: [
		{key: "护照 - 优先 (Passport)"},
		{key: "中華民國國民身分證 (National ID card)"}
	],
	[TANZANIA_CODE]: [
		{key: "Pasipoti - iliyopendekezwa (Passport)"},
		{key: "Kitambulisho cha Taifa (Citizen Identity Card)"},
		{key: "Leseni ya Kuendesha gari (Driving License)"}
	],
	[THAILAND_CODE]: [
		{
			key: "บัตรประชาชน (ID Card)",
			sides: 2
		},
		{key: "หนังสือเดินทางสำหรับบุคคลที่ไม่ใช่สัญชาติไทย (Passport)"}
	],
	[TRINIDAD_AND_TOBAGO_CODE]: [
		{key: "Pasaporte (Passport)"}
	],
	[TUNISIA_CODE]: [
		{key: "جواز السفر - مفضل (Passport)"},
		{
			key: "رخصة السائق (Driver's License)",
			sides: 2
		},
		{key: "بطاقة التعريف الوطنية (National ID)"}
	],
	[TURKEY_CODE]: [
		{key: "Pasaport - tercih edilir (Passport)"},
		{key: "Nüfus Cüzdanı (National ID)"},
		{key: "Ehliyet (Driver's License)"}
	],
	[UNITED_ARAB_EMIRATES_CODE]: [
		{key: "Emirates ID - photos of front and back of the physical Emirates ID card or the front and back of the Emirates ID from the ICP app are required"},
		{key: "Passport - a photo of the physical passport or the e-passport are required"},
		{key: "Residence visa - passport visa page, digital visa from ICP app or front and back of physical Emirates ID card (if issued after April 11th, 2022)"},
		{key: "Power of Attorney (Company Authorization)"}
	],
	[UNITED_KINGDOM_CODE]: [
		{key: "Passport - preferred"},
		{key: "Driver license"},
		{
			key: "Resident permit",
			sides: 2
		},
		{key: "Citizen Card"},
		{key: "Electoral ID"}
	],
	[UNITED_STATES_CODE]: [
		{key: "Passport - preferred"},
		{key: "Passport card"},
		{key: "Driver license"},
		{key: "State issued ID card"},
		{key: "Resident permit ID / U.S. Green Card"},
		{key: "Border crossing card"},
		{key: "Child ID card"},
		{key: "NYC card"},
		{key: "U.S. visa card"}
	],
	[URUGUAY_CODE]: [
		{key: "Pasaporte - preferido (Passport)"},
		{key: "Licencia de conducir (Driver's license)"},
		{
			key: "Cedula De Identidad (National ID)",
			sides: 2
		}, {
			key: "Cedula De Identidad para extranjeros (Resident Permit)",
			sides: 2
		}
	],
	[UZBEKISTAN_CODE]: [
		{key: "Pasport - afzal (Passport)"},
		{key: "Haydovchilik Guvohnomasi (Driving License)"}
	],
	[VIETNAM_CODE]: [
		{key: "Hộ chiếu - ưu tiên (Passport)"},
		{key: "Giấy phép lái xe (Driving License)"},
		{key: "căn cước công dân (National Identity Card)"}
	]
};

export const addressDocumentExamples = {
	[ARGENTINA_CODE]: [
		{
			key: "Documento Nacional de Identidad (DNI) (National ID)",
			sides: 2
		}
	],
	[AUSTRALIA_CODE]: [
		{
			key: "Driver Licence (Driver's license)",
			sides: 2
		}, {
			key: "Photo Card",
			sides: 2
		}, {
			key: "Tasmanian Government Personal Information Card",
			sides: 2
		}, {
			key: "ImmiCard",
			sides: 2
		},
		{key: "Bank statement - dated within 6 months"},
		{key: "Medicare document (Government health insurance document) - dated within 12 months"},
		{key: "Centrelink document (Government pension document) - dated within 12 months"},
		{key: "Utility bill - dated within 6 months"},
		{key: "Australian Taxation Office letter or assessment (Government tax document) - dated within 12 months"},
		{key: "Council rates or land valuation notice (Property tax document) - dated within 12 months"}
	],
	[AUSTRIA_CODE]: [
		{key: "Bestätigung der Meldung / Meldezettel (Residence registration) - innerhalb von 12 Monaten"},
		{key: "Rechnung für Strom oder Gas (Utility bill) - innerhalb von 6 Monaten"},
		{key: "Jahresabschlüsse (Financial statements)"},
	],
	// Belgium has 3 official languages.
	[BELGIUM_CODE]: [
		{key: "Attest van hoofdverblijfplaats / Certificat de résidence / Bescheinigung über den Hauptwohnort (Certificate of residence)"},
		{key: "Energierekening / Facture de service / Rechnung eines Versorgungsunternehmens (Utility bill) - dated within 6 months"},
		{key: "Bankafschrift / Relevé bancaire/Kontoauszug (Bank statement) - dated within 6 months"},
		{key: "Verblijfsvergunning / Permis de résidence / Aufenthaltsgenehmigung (Residence permit) - dated within 12 months"},
		{key: "Attest hypothecaire lening / État hypothécaire / Auszug aus dem Hypothekarkonto (Mortgage statement) - dated within 6 months"},
		{key: "Attest van hoofdverblijfplaats / Courrier de confirmation d'enregistrement à la maison communale / Bescheinigung über den Hauptwohnort (Letter certifying registration at local town hall) - dated within 12 months"},
		{key: "Pensioenoverzicht / Relevé de pension / Rentenbescheinigung (Pension statement) - dated within 6 months"},
	],
	[BOLIVIA_CODE]: [
		{
			key: "Cedula de identidad de Extranjero (Resident Permit)",
			sides: 2
		}
	],
	[BRAZIL_CODE]: [
		{key: "Conta de água, energia elétrica ou telefone (Water, electricity, or telephone bill)"},
		{key: "Extrato do cartão de crédito (Credit card statement)"},
		{key: "Declaração Anual de Imposto de Renda Pessoa Física (IRPF) (Annual declaration of Individual Income Tax (IRPF))"},
		{key: "Certidões, declarações ou documentos emitidos por entidades governamentais, como o Instituto Nacional do Seguro Social (INSS) ou a Receita Federal do Brasil (RFB) (Statements or documents issued by public entities, such as Social Security or the Federal Revenue Service)"},
		{key: "Comprovantes de Imposto Predial e Territorial Urbano (IPTU) e Imposto sobre a Propriedade de Veículos Automotores (IPVA) (Property tax (IPTU) and vehicle tax (IPVA) receipts)"},
		{key: "Extratos do Fundo de Garantia do Tempo de Serviço (FGTS) (FGTS (Guarantee Fund for Length of Service) statements)"},
	],
	[BULGARIA_CODE]: [
		{key: "Сметка за комунални услуги - в рамките на 6 месеца (Utility Bill)"},
		{key: "Банково извлечение - в рамките на 6 месеца (Bank statement)"},
		{key: "ипотечно извлечение - в рамките на 6 месеца (Mortgage statement)"}
	],
	[CANADA_CODE]: [
		{key: "Driver's licence / Permis de conduire"},
		{
			key: "Provincial or territorial issued photo identity cards / Cartes d'identité avec photo délivrées par une province ou un territoire",
			sides: 2
		},
		{key: "Insurance document / Documents d'assurance"},
		{key: "Investment account statement / Relevés de compte de placement"},
		{key: "Property tax statement / Relevé d'impôts fonciers"},
		{key: "Bank statement / Relevé de compte bancaire"},
		{key: "Utility bill / Facture de services publics - dated within 12 months"}
	],
	[CROATIA_CODE]: [
		{
			key: "Osobna iskaznica (ID card)",
			sides: 2
		},
		{key: "Potvrda o bankovnom računu - u roku od 6 mjeseci (Bank statement)"},
		{key: "Račun za komunalne usluge - u roku od 6 mjeseci (Utility bill)"},
	],
	[CYPRUS_CODE]: [
		{key: "Λογαριασμός κοινής ωφέλειας / Elektrik, su, doğal gaz faturası (Utility bill)"},
		{key: "Αντίγραφο κίνησης λογαριασμού / Hesap özeti (Bank statement)"},
		{key: "Αντίγραφο κίνησης λογαριασμού στεγαστικού δανείου / Konut kredisi ekstresi (Mortgage statement)"},
		{key: "Ειδοποίηση επιβολής φόρου (Tax bill)"},
		{key: "Βεβαίωση Εγγραφής / Tescil belgesi (Registration certificate)"}
	],
	[CZECH_REPUBLIC_CODE]: [
		{
			key: "Občanský průkaz (Identity card)",
			sides: 2
		},
		{key: "Faktura za služby - do 6 měsíců (Utility bill)"},
		{key: "Bankovní výpis - do 6 měsíců (Bank statement)"},
		{key: "Výpis z hypotéčního účtu - do 6 měsíců (Mortgage statement)"},
		{key: "Smlouva o pronájmu (Rental agreement)"},
		{key: "Výpis z katastru (Statement from property register)"},
		{key: "Smlouva o prodeji majetku (Contract for sale of property)"},
		{key: "Osvědčení o registraci (Registration Certificate)"},
		{key: "Doklad o bankovním účtu (Proof of Bank Account)"}
	],
	[DENMARK_CODE]: [
		{key: "Forsyningsregning - inden for 6 måneder (Utility bill)"},
		{key: "Kontoudtog (Bank statement) - do 6 měsíců (Bank statement)"},
		{key: "Realkreditopgørelse - do 6 měsíců (Mortgage statement)"},
		{key: "Lejekontrakt (Rental contract)"},
		{key: "Sundhedskort / sygesikringskort (Health insurance card)"},
		{key: "Skattevurdering (Tax assessment)"}
	],
	[DOMINICAN_REPUBLIC_CODE]: [
		{
			key: "Cedula de identidad y electoral (ID card)",
			sides: 2
		}, {
			key: "Cedula de identidad y electoral para extranjeros (Resident Permit)",
			sides: 2
		}
	],
	[ESTONIA_CODE]: [
		{key: "Elukoha registreerimine (Residence registration)"},
		{key: "Kommunaalarve - 6 kuu jooksul (Utility bill)"},
		{key: "Kontoväljavõte - 6 kuu jooksul (Bank statement)"},
		{key: "Bin/Waste Disposal Bill"}
	],
	[FINLAND_CODE]: [
		{key: "Hallituksen myöntämä asiakirja (Government issued document)"},
		{key: "Yleishyödykkeen lasku/Vatten- och elräkning - 6 kuukauden sisällä (Utility bill)"},
		{key: "Tiliote / Bankutdrag - 6 kuukauden sisällä (Bank statement)"},
		{key: "Asuntolainaselvitys / Inteckning - 6 kuukauden sisällä (Mortgage statement)"}
	],
	[FRANCE_CODE]: [
		{key: "Facture de téléphone - dans les 6 mois (Telephone/mobile bill)"},
		{key: "Facture d'électricité, de gaz ou d'eau - dans les 6 mois (Utility bill)"},
		{key: "Quittance de loyer (d'un organisme social ou d'une agence immobilière) - dans les 6 mois (Rent receipt from social or real estate agency)"},
		{key: "Titre de propriété (Title deed)"},
		{key: "Avis d'imposition - dans les 12 mois (Taxation notice)"},
		{key: "Avis de situation déclarative à l'impôt sur le revenu - dans les 12 mois (Certificate of non-taxation)"},
		{key: "Attestation de paiement de taxe d'habitation / Justificatif de paiement de taxe d'habitation - dans les 12 mois (Proof of housing tax)"},
		{key: "Attestation ou facture d'assurance du logement - dans les 6 mois (Proof of home insurance)"},
		{key: "Relevé de la Caf mentionnant les aides liées au logement - dans les 12 mois (Caf statement mentioning housing-related aid)"},
		{key: "Relevé bancaire - dans les 6 mois (Bank statement)"}
	],
	[GERMANY_CODE]: [
		{key: "Anmeldebestätigung / Meldebescheinigung (Proof of residence)"},
		{key: "Meldebescheinigung (Landlord letter)"},
		{key: "Betriebskostenabrechnung - innerhalb von 6 Monaten (Utility bill)"},
		{key: "Bankauszug - innerhalb von 6 Monaten (Bank statement)"},
		{key: "Darlehenskontoauszug - innerhalb von 6 Monaten (Mortgage statement)"},
		{key: "Amtlich ausgestelltes Dokument - innerhalb von 12 Monaten (Government issued document)"}
	],
	[GREECE_CODE]: [
		{key: "Βεβαίωση Διαμονής (Residence Certificate)"},
		{key: "Λογαριασμός κοινής ωφέλειας - εντός 6 μηνών (Utility bill)"},
		{key: "Αντίγραφο κίνησης λογαριασμού - εντός 6 μηνών (Bank statement)"},
		{key: "Κρατικό έγγραφο - εντός 12 μηνών (Government issued document)"},
		{key: "Αντίγραφο κίνησης λογαριασμού στεγαστικού δανείου (Mortgage statement)"}
	],
	[HONG_KONG_CODE]: [
		{
			key: "身份證 / Identity card",
			sides: 2
		},
		{key: "登記事項證明書 / Certificate of Registered Particulars"},
		{key: "水電費帳單 - 6个月内 / Utility bill - within 6 months"},
		{key: "銀行帳單 - 6个月内 / Bank statement - dated within 6 months"},
		{key: "電話費帳單/手機帳單 - 6个月内 / Telephone/mobile phone bill - dated within 6 months"},
		{key: "保險公司聲明 - 6个月内 / Insurance company statement - dated within 6 months"},
		{key: "政府或司法機關簽發的文件 - 6个月内 / Government or Judiciary issued document - dated within 6 months"},
		{key: "租約 - 6个月内 / Tenancy agreement - dated within 6 months"},
		{key: "財產買賣合同 - 6个月内 / Contract for sale of property - dated within 6 months"},
		{key: "大學帳單或信函 - 6个月内 / University bill or letter - dated within 6 months"}
	],
	[HUNGARY_CODE]: [
		{
			key: "Tartózkodási engedély (Residence permit)",
			sides: 2
		}, {
			key: "Lakcímigazolvány (Address Card)",
			sides: 2
		},
		{key: "Közüzemi számla - 6 hónapon belül (Utility bill)"},
		{key: "Bankszámlakivonat - 6 hónapon belül (Bank statement)"},
		{key: "Jelzálog-tanúsítvány - 6 hónapon belül (Mortgage statement)"}
	],
	[INDONESIA_CODE]: [
		{
			key: "Kartu Tanda Penduduk (Identity Card)",
			sides: 2
		},
		{key: "Surat Izin Mengemudi (Driver's license)"}
	],
	[IRELAND_CODE]: [
		{
			key: "Driver's license",
			sides: 2
		},
		{key: "Bank statement"},
		{key: "Utility bill - dated within 6 months"},
		{key: "Car Insurance Policy - dated within 6 months"},
		{key: "Home Insurance Policy - dated within 6 months"},
		{key: "Social insurance document - dated within 6 months"},
		{key: "Government issued document - dated within 12 months"},
		{key: "Tax credit certificate (TCC) - dated within 12 months"},
		{key: "Statement of Liability from Revenue - dated within 12 months"}
	],
	[ISRAEL_CODE]: [
		{key: "חשבון שירות - תאריך תוך 6 חודשים"},
		{key: "דף חשבון בנק - תאריך תוך 6 חודשים"}
	],
	[ITALY_CODE]: [
		{
			key: "Carta di identità / Carta d'identità (Identity card)",
			sides: 2
		},
		{key: "Certificato di residenza (Residence certificate)"},
		{key: "Bolletta - entro 6 mesi (Utility bill)"},
		{key: "Estratto conto bancario - entro 6 mesi (Bank statement)"}
	],
	[LATVIA_CODE]: [
		{key: "Komunālo pakalpojumu rēķins (Utility bill)"},
		{key: "Finanšu iestādes pārskats (Statement from a financial institution)"},
		{key: "Valsts iestādes izsniegta vēstule (Letter issued from a government institution)"}
	],
	[LIECHTENSTEIN_CODE]: [
		{key: "Betriebskostenabrechnung - innerhalb von 6 Monaten (Utility bill)"},
		{key: "Kontoauszug - innerhalb von 6 Monaten (Bank statement)"},
		{key: "Behördliches Schreiben (Government-issued letter)"},
		{key: "Staatsbürgerschaftsbestätigung/Wohnsitzbestätigung (Residence certificate)"}
	],
	[LITHUANIA_CODE]: [
		{key: "Komunalinių mokesčių sąskaita (Utility bill)"},
		{key: "Išrašas iš finansų institucijos (Statement from a financial institution)"},
		{key: "Vyriausybės institucijos laiškas (Letter issued from a government institution)"},
	],
	[LUXEMBOURG_CODE]: [
		{
			key: "Carte d'identité / Personalausweis (Identity card)",
			sides: 2
		},
		{key: "Facture d'un service public / Stromrechnung - innerhalb von 6 Monaten (Utility bill)"},
		{key: "Déclaration d'une institution financière / Erklärung eines Finanzinstituts - innerhalb von 6 Monaten (Statement from a financial institution)"},
		{key: "lettre émise par le gouvernement / Regierungsschreiben - innerhalb von 12 Monaten (Government issued letter)"},
		{key: "Papier d'identité qui dispose d'une adresse / ID-Dokument mit einer Adresse (ID document that has an address)"}
	],
	[MALTA_CODE]: [
		{key: "Kont tal-Bank (Bank statement)"},
		{key: "Kont tad-Dawl u Ilma (Utility Bill)"},
		{key: "Dikjarazzjoni ta' self (Mortgage statement)"}
	],
	[MEXICO_CODE]: [
		{key: "Recibo de servicios - dentro de 6 meses (Utility Bills)"},
		{key: "Estado de cuenta de una institución financiera - dentro de 6 meses (Statement from a financial institution)"},
		{key: "Aviso emitido por el gobierno - dentro de 12 meses (Government issued letter)"},
		{key: "Licencia de conducir (Driver license)"}
	],
	[NETHERLANDS_CODE]: [
		{key: "energierekening - binnen 6 maanden (Utility bill)"},
		{key: "verklaring van een financiële instelling - binnen 6 maanden (Statement from a financial institution)"},
		{key: "door de overheid verstrekte brief - binnen 12 maanden (Government issued letter)"},
		{key: "bewijs van inboedelverzekering (Proof of home insurance)"}
	],
	[NEW_ZEALAND_CODE]: [
		{key: "Passport"},
		{
			key: "Driver license",
			sides: 2
		},
		{key: "Utility bill"},
		{key: "Central Government Agency document"},
		{key: "Local Council/Government letter"},
		{key: "Signed rental tenancy agreement"},
		{key: "Electoral roll papers"},
		{key: "Insurance policy document"},
		{key: "Car registration notification/demand"},
		{key: "Financial statement"}
	],
	[NORWAY_CODE]: [
		{key: "Oppholdskort - innen 12 måneder (Resident permit ID)"},
		{key: "Vann- eller strømregning - innen 6 måneder (Utility bill)"},
		{key: "Kontoutskrift fra en finansinstitusjon - innen 6 måneder (Statement from a financial institution)"},
		{key: "Offentlig utstedt brev - innen 12 måneder (Government issued letter)"},
		{key: "Bostedsattest (Proof of Residence)"}
	],
	[POLAND_CODE]: [
		{key: "Dokument o zameldowaniu (Residence address certificate)"},
		{key: "Deklaracja podatkowa (Tax return)"},
		{key: "Dokument o zameldowaniu na pobyt czasowy (Temporary residence registration certificate)"},
		{key: "Dokument o zameldowaniu na pobyt czasowy (Social or medical insurance card issued by a government administration body)"},
		{key: "Rachunek za media (Utility bill)"},
		{key: "Zaświadczenie z instytucji finansowej (Statement from a financial institution)"},
		{key: "Pismo z instytucji rządowej (Letter issued from a government institution)"}
	],
	[PORTUGAL_CODE]: [
		{key: "Carta de condução (Driver license)"},
		{
			key: "Cartão de identidade (ID card)",
			sides: 2
		},
		{key: "Atestado de residência (Residence certificate)"},
		{key: "Fatura de serviços (Utility bill)"},
		{key: "Extrato bancário (Statement from a financial institution)"},
		{key: "Carta emitida pelo Governo (Government issued letter)"}
	],
	[ROMANIA_CODE]: [
		{key: "Factură pentru utilităţi (Utility Bill)"},
		{key: "Extras de cont (Bank statement)"},
		{key: "Declaraţie privitoare la ipotecă (Mortgage statement)"},
		{key: "Bank Confirmation Letter"}
	],
	[SINGAPORE_CODE]: [
		{key: "Digital identity document with address"},
		{
			key: "Government-issued identity document with address",
			sides: 2
		},
		{key: "Utility bill - dated within 3 months, mobile phone bills are not accepted"},
		{key: "Correspondence with government agencies - dated within 3 months"},
		{key: "Bank account statement - dated within 3 months"}
	],
	[SLOVAKIA_CODE]: [
		{key: "Občiansky preukaz (ID Card)"},
		{key: "Vodičský preukaz (Driver License)"},
		{key: "Účet za energie (Utility bill)"},
		{key: "Vyhlásenie finančnej inštitúcie (Statement from a financial institution)"},
		{key: "List vydaný vládou (Government issued letter)"}
	],
	[SLOVENIA_CODE]: [
		{key: "Osebna izkaznica (ID Card)"},
		{key: "Vozniško dovoljenje (Driver License)"},
		{key: "Račun za komunalne storitve (Utility Bill)"},
		{key: "Izpisek finančne ustanove (Statement from a financial institution)"},
		{key: "Pismo, ki ga izda vladni organ (Government issued letter)"}
	],
	[SPAIN_CODE]: [
		{key: "Factura de servicios públicos (Utility Bill)"},
		{key: "Extracto bancario o factura de teléfono móvil (Bank statement or phone invoice)"},
		{key: "Government issued letter"},
		{key: "Certificado de residencia (Residence certificate)"}
	],
	[SWEDEN_CODE]: [
		{
			key: "Nationellt ID-kort (National ID)",
			sides: 2
		},
		{key: "Hushållsräkning (Utility Bill)"},
		{key: "Bankkontoutdrag (Statement from a financial institution)"},
		{key: "Myndighetsdokument (Government issued letter)"},
		{key: "Uppehållstillståndskort (Residence permit card)"}
	],
	[SWITZERLAND_CODE]: [
		{key: "Wohnsitzbestätigung / Attestation de résidence / Certificato di domicilio (Residence certificate)"},
		{key: "Rechnung von Energieversorgern / Facture de services publics / Fattura di fornitori di energia (Utility Bill)"},
		{key: "Kontoauszug / Déclaration d'une institution financière / Dichiarazione di un istituto finanziario (Statement from a financial institution)"},
		{key: "Offizielles Schreiben einer Behörde / lettre émise par le gouvernement / Lettera rilasciata dal governo (Government issued letter)"}
	],
	[UNITED_KINGDOM_CODE]: [
		{key: "Driver license"},
		{key: "Utility Bill"},
		{key: "Statement from a financial institution"},
		{key: "Government issued letter"},
		{key: "Solicitors letter"},
		{key: "Council or housing association documentation"},
		{key: "Benefits Agency documentation"},
		{key: "Electoral Register entry"},
		{key: "NHS Medical Card"},
		{key: "GP letter of registration with the surgery"},
	]
}

