import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

import SubjectTopicPriceBreakdown from "pages/Tutoring/subPages/components/SubjectTopicPriceBreakdown";

import messageMap from "Utilities/MessageMaps";
import { calculateTutorAvgRating } from "pages/Tutoring/utilities/TutorProfileUtility";
import { TUTEE_USER } from "pages/Tutoring/utilities/TutoringRequestConstants";

import { getReviewsAPI } from "apis/controllers/tutoring/TutoringReviewController";

import greenCheckAsset from "assets/icons/common/green-check.svg";


function LearnMoreModal(props) {

	const tutorDetails = props.tutorDetails;

	const [averageRating, setAverageRating] = useState();

	useEffect(() => {
		showTutorAvgRating();
	}, []);

	function showTutorAvgRating() {
		const pathVariables = {
			ownerId: tutorDetails.tutorOwnerId,
			reviewFromWhichUserType: TUTEE_USER
		};
		getReviewsAPI(pathVariables, resp => {
			setAverageRating(calculateTutorAvgRating(resp));
		});
	}

	return (
		<div className="learn-more-about-tutor">
			<div className="tutor-picture-ratings">
				<img className="tutor-picture" src={tutorDetails.profilePictureUploadLocation}
					alt={messageMap("tutoring.tutorPicture", "image")} />
				<div className="average-rating">
					{averageRating}
				</div>

				{
					tutorDetails.trialPreference === "true"
					? (
						<div className="fifteen-minute-trial mobile-view">
							<img className="green-check" src={greenCheckAsset} alt={messageMap("tutoring.greenCheck", "image")} />
							{messageMap("tutoringPage.tutorList.modal.fifteenTrial", "generic")}
						</div>
					)
					: ""
				}
			</div>

			<div className="price-breakdown-trial">
				<SubjectTopicPriceBreakdown tutorFirstName={tutorDetails.firstName} tutorTimezone={tutorDetails.timezone}
					tutorTier={tutorDetails.tutorTier} schedule={tutorDetails.schedules}
					showBookOption={true} tutorProfileId={tutorDetails.tutorProfileId} tutorOwnerId={tutorDetails.tutorOwnerId}
					teachingRates={tutorDetails.teachingRates} subjectToTopicsMap={tutorDetails.subjectToTopicsMap}
					setModal={props.setModal} setModal1={props.setModal1} setAlert={props.setAlert} />

				{
					tutorDetails.trialPreference === "true"
					? (
						<div className="fifteen-minute-trial">
							<img className="green-check" src={greenCheckAsset} alt={messageMap("tutoring.greenCheck", "image")} />
							{messageMap("tutoringPage.tutorList.modal.fifteenTrial", "generic")}
						</div>
					)
					: ""
				}
			</div>
		</div>
	);
}

LearnMoreModal.propTypes = {
	tutorDetails: PropTypes.object.isRequired,
	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired
};

export default LearnMoreModal;
