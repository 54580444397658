export const tooltipMessageMap = {
	editVideos: {
		edit: "Edit",
		delete: "Delete Permanently",
		module: "Bundle into a module",
		class: "Bundle into a class"
	},
	uploadVideos: {
		mentalModel: "A mental model (a picture of how your video's concepts tie together) helps viewers accelerate their learning by as much as 50%.",
		topic: `Assign a topic to your video to dynamically associate it with 
						practice problems, informational text, and a node in our knowledge quests
						in the future.`,
	},
	account: {
		address: "Please only fill relevant fields.",
		country: "This cannot be changed later on."
	},
	financials: {
		stripeConnect: "Create Stripe Connect account to receive payouts.",
		stripeLinking: "Please create a Stripe Connect account to withdraw your earnings.",
		unverifiedStripeConnect: "Your Stripe Connect account is currently unverified",
		belowMinimumWithdrawal: "Your earnings must be above the following to withdraw: ",
		earnings: `These are your video earnings. Tutoring earnings are transferred directly to 
			you by Stripe. Please allow up to 6 business days for the payout to settle. If this is 
			your first payout, please allow up to 14 business days.`
	},
	tutor: {
		noSavedTutors: "No saved tutors found. Please save tutors from your past tutoring sessions.",
		hasNowConflict: "There is already a tutoring request scheduled for 'now'. Please delete or cancel this before requesting another request.",
		automaticMatching: "automatic matching gets better the more information you provide ",
		occupation: "Use your highest title that is relevant to the subject(s) you'll be teaching",
		stripeConnectPayments: "This needs to be finished to receive payments from clients.",
		fifteenMinuteTrial: "Allowing 15-minute trials automatically deducts 15 minutes from the total payment for first-time clients.",
		instantBooking: `Allowing instant booking enables learners to book tutoring requests with you, without 
			requiring an additional confirmation step from you. This simplifies the booking process for both tutors 
			and learners, which increases booking rates.`,
		fifteenMinuteTrialFilter: "Find tutors offering 15-minute trials on the first session",
		instantBookingFilter: "Find tutors offering immediate/on-demand booking.",
		inPersonFilter: "Finding in-person tutors depending on your address (not your current location).",
		schedule: "Your availability in your timezone.",
		blockedRateEditing: "Please finish all your scheduled and live tutoring sessions before editing your rates.",
		compare: "Select two tutors to compare"
	},
	stripe: {
		connect: {
			businessUrl: "The business's publicly available website, mobile application, or social media profile.",
			banking: {
				account: "The account number should have the following general format: ",
				routing: "The routing number should have the following general format: ",
			},
			documents: {
				d0: "We only allow the following file formats: .png, .jpg, .jpeg",
				d1: "Photos and scans of documents and IDs must be in color and in good quality",
				d2: "Identity and legal documents must not be expired",
				d3: "Documents must not be cropped and all borders must be visible",
				d4: "Screenshots are not acceptable",
				d5: "If the country of residence differs from the country of the account, a passport is required"
			}
		}
	}
};