import React, { useState, useEffect }  from "react";
import PropTypes from "prop-types";

import Dropdown from "templates/Dropdown";
import { SERVICES_KEY, LANGUAGE_KEY, SUBJECT_KEY, PRICE_KEY } from "./utilities/DropdownUtilities";

import {
	teachableSubjects, priceRanges,
	tutoringServices,
	tutoringServicesExtraInput, tutoringServicesTooltips
} from "pages/Profile/subPages/utilities/TutoringSubPageConstants";
import { spokenLanguages } from "Utilities/Constants/LanguageConstants";
import { SERVICES_FILTER, LANGUAGE_FILTER, SUBJECT_FILTER, PRICE_FILTER } from "./utilities/FilterUtility";
import messageMap from "Utilities/MessageMaps";

import filterAsset from "assets/icons/common/filter.svg";


function SideFilter(props) {

	const [sideFilterClassToggle, setSideFilterClassToggle] = useState("");
	const [filters, setFilters] = useState();

	useEffect(() => {
		createFilters();
	}, []);

	function createFilters() {
		const filterKeyMap = {
			[SERVICES_FILTER]: tutoringServices,
			[LANGUAGE_FILTER]: spokenLanguages,
			[SUBJECT_FILTER]: teachableSubjects,
			[PRICE_FILTER]: priceRanges
		};
		const attributesMap = {
			[SERVICES_FILTER]: SERVICES_KEY,
			[LANGUAGE_FILTER]: LANGUAGE_KEY,
			[SUBJECT_FILTER]: SUBJECT_KEY,
			[PRICE_FILTER]: PRICE_KEY
		};
		const optionToDelete = {
			[SERVICES_FILTER]: "chooseServices",
			[LANGUAGE_FILTER]: "chooseLanguage",
			[SUBJECT_FILTER]: "chooseSubject",
			[PRICE_FILTER]: "price"
		};
		const optionTooltipMap = {
			[SERVICES_FILTER]: tutoringServicesTooltips
		};
		const textInputOption = {
			[SERVICES_FILTER]: tutoringServicesExtraInput,
		};

		let dropdownFilters = [];
		props.filterCategories.forEach(key => {
			let copiedObject = structuredClone(filterKeyMap[key]);
			delete copiedObject[optionToDelete[key]];
			let newObject = {
				[key]: key[0].toUpperCase() + key.slice(1),
				...copiedObject
			};
			if (props.filterKeys.size > 0) {
				for (const [objKey, objValue] of Object.entries(newObject)) {
					if (!props.filterKeys.has(objKey) && key !== objKey) {
						delete newObject[objKey];
					}
				}
			}

			dropdownFilters.push(
				<div key={key}>
					<Dropdown customDropdownItemAttribute={attributesMap[key]} customContainerClass="tutoring-application no-right-margin"
						dropdownOptions={newObject}
						dropdownOptionsTooltip={optionTooltipMap[key]} dropdownOptionsWExtraInput={textInputOption[key]}
						filterMode={true} excludeFromList={key} purpose={props.purpose}
						dropdownItemClickHandler={props.filterHandler}
						hideAllDropdownsWhenToggling={false} showDropdownOptions={true}
						setAlert={props.setAlert} />
					<hr className="filter-divider" />
				</div>
			);
		});
		setFilters(dropdownFilters);

		if (props.showByDefault && window.innerWidth > 900) {
			setSideFilterClassToggle("show");
		}
	}

	function toggleSideFilter() {
		sideFilterClassToggle === "" ? setSideFilterClassToggle("show") : setSideFilterClassToggle("");
	}

	return (
		<div className="side-filter-component">
			<button className="hamburger-menu" onClick={toggleSideFilter}>
				<img src={filterAsset} alt={messageMap("filter", "image")} />
			</button>

			<div className={`side-filter ${sideFilterClassToggle}`}>
				<div className="filter-header">
					{messageMap("sideFilter.filterBy", "generic")}
				</div>

				<hr className="filter-divider" />

				{filters}
			</div>
		</div>
	);
}

SideFilter.defaultProps = {
	filterKeys: new Set(),
	showByDefault: false
};

SideFilter.propTypes = {
	showByDefault: PropTypes.bool,
	filterHandler: PropTypes.func.isRequired,
	purpose: PropTypes.string.isRequired,
	filterCategories: PropTypes.array.isRequired,
	filterKeys: PropTypes.object,

	// parent setters props
	setAlert: PropTypes.func
};

export default SideFilter;

