import React from "react";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

import defaultLinkAction from "Utilities/LinkActions";
import messageMap from "Utilities/MessageMaps";

import fourOfourErrorAsset from "assets/images/fourOfour/404_error.svg";

export default function FourOFour() {
	return (
		<div className="fourOFour">
			<Helmet>
				<title>{messageMap("fourOfour.title", "headerTag")}</title>
				<meta name="description" content={messageMap("fourOfour.description", "headerTag")}></meta>
				{/* prevent Googlebot from indexing the page */}
				<meta name="robots" content="noindex"/>
			</Helmet>

			<Link to="/tutoring/tutorsList" onClick={defaultLinkAction}>
				<img src={fourOfourErrorAsset}></img>
			</Link>
		</div>
	);
}