import messageMap from "Utilities/MessageMaps";

// Tutoring
export const REQUESTS_NEW_REQUEST = "newRequest";
export const REQUESTS_OPEN_REQUESTS = "openRequests";
// export const REQUESTS_PENDING_REQUESTS = "pendingRequests";
const TUTORING_REQUESTS_TABS_MAP = {
	[REQUESTS_NEW_REQUEST]: messageMap("tutoring.requests.newRequest", "pageMenuNavigation"),
	[REQUESTS_OPEN_REQUESTS]: messageMap("tutoring.requests.openRequests", "pageMenuNavigation"),
	// [REQUESTS_PENDING_REQUESTS]: messageMap("tutoring.requests.pendingRequests", "pageMenuNavigation")
};
export const SESSIONS_UPCOMING_SESSIONS = "upcomingSessions";
export const SESSIONS_LIVE_SESSIONS = "liveSessions";
export const SESSIONS_PAST_SESSIONS = "pastSessions";
const TUTORING_SESSIONS_TABS_MAP = {
	[SESSIONS_UPCOMING_SESSIONS]: messageMap("tutoring.sessions.upcomingSessions", "pageMenuNavigation"),
	[SESSIONS_LIVE_SESSIONS]: messageMap("tutoring.sessions.liveSessions", "pageMenuNavigation"),
	[SESSIONS_PAST_SESSIONS]: messageMap("tutoring.sessions.pastSessions", "pageMenuNavigation")
};
export const PROFILE_OVERVIEW = "overview";
export const PROFILE_REVIEWS = "reviews";
export const PROFILE_SCHEDULED_SESSIONS = "scheduledSessions";
export const PROFILE_LIVE_SESSIONS = "liveSessions";
export const PROFILE_PAST_SESSIONS = "pastSessions";
const TUTORING_PROFILE_TABS_MAP = {
	[PROFILE_OVERVIEW]: messageMap("tutoring.profile.overview", "pageMenuNavigation"),
	[PROFILE_REVIEWS]: messageMap("tutoring.profile.reviews", "pageMenuNavigation"),
	[PROFILE_SCHEDULED_SESSIONS]: messageMap("tutoring.profile.scheduledSessions", "pageMenuNavigation"),
	// commenting this out for now. I don't think there's any real use for this
	// [PROFILE_LIVE_SESSIONS]: messageMap("tutoring.profile.liveSessions", "pageMenuNavigation"),
	[PROFILE_PAST_SESSIONS]: messageMap("tutoring.profile.pastSessions", "pageMenuNavigation")
};
export const TUTORS_TAB = "tutors";
export const TUTEES_TAB = "tutees";
const TUTORING_USER_TYPE_TABS_MAP = {
	[TUTEES_TAB]: messageMap("tutoring.faq.tutees", "pageMenuNavigation"),
	[TUTORS_TAB]: messageMap("tutoring.faq.tutors", "pageMenuNavigation")
};

export const LEARNERS_TAB = "learners";
const LANDING_PAGE_TABS_MAP = {
	[LEARNERS_TAB]: messageMap("home.learners", "pageMenuNavigation"),
	[TUTORS_TAB]: messageMap("home.tutors", "pageMenuNavigation")
};

//************** Map Exports ************//
export const LANDING_PAGE_TABS = "landingPageTabs";
export const TUTORING_SESSIONS_TABS = "tutoringSessionsTabs";
export const TUTORING_REQUESTS_TABS = "tutoringRequestTabs";
export const TUTORING_PROFILE_TABS = "tutoringProfileTabs";
export const TUTORING_FAQ_TABS = "tutoringFAQTabs";
export const TUTORING_POLICIES_TABS = "tutoringPoliciesTabs";
export const WHY_EXER_TABS = "whyExerTabs";

export const TABS_MAP = {
	[LANDING_PAGE_TABS]: LANDING_PAGE_TABS_MAP,
	[TUTORING_SESSIONS_TABS]: TUTORING_SESSIONS_TABS_MAP,
	[TUTORING_REQUESTS_TABS]: TUTORING_REQUESTS_TABS_MAP,
	[TUTORING_PROFILE_TABS]: TUTORING_PROFILE_TABS_MAP,
	[TUTORING_FAQ_TABS]: TUTORING_USER_TYPE_TABS_MAP,
	[TUTORING_POLICIES_TABS]: TUTORING_USER_TYPE_TABS_MAP,
	[WHY_EXER_TABS]: TUTORING_USER_TYPE_TABS_MAP
};