import React, {useEffect, useState, useRef, Fragment} from "react";
import { useHistory, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";
import { setPaymentInfo } from "redux/actions/actionTypes";

import defaultLinkAction from "Utilities/LinkActions";
import messageMap from "Utilities/MessageMaps";
import { onKeyDown } from "Utilities/Accessibility";
import {checkHasExplicitWords} from "Utilities/Validators/ContentValidator.js";
import { 
	VIRTUAL_PLACE, IN_PERSON_PLACE,
	MATCHING_AUTOMATIC, MATCHING_AUTOMATIC_DIRECT, MATCHING_AUTOMATIC_POOL,
	MATCHING_MANUAL,
	ALL_TUTORS, SAVED_TUTORS, TUTEE_USER,
	OPEN_STATE, PENDING_STATE, SCHEDULED_STATE
} from "pages/Tutoring/utilities/TutoringRequestConstants";
import { parseNewRequestResponse } from "pages/Tutoring/utilities/TutorRequestUtility";
import { validateInPersonTutoringRadius } from "pages/Profile/subPages/utilities/TutoringSubPageUtilities";
import { 
	MODAL_CLOSE_TIMEOUT, SAVE_RELOAD_TIMEOUT
} from "Utilities/Constants/TimeoutConstants";
import { handleTutoringPaymentIntentResponse } from "pages/Checkout/CheckoutUtility";

import Spinner from "templates/Spinner";
import Alert, {ERROR, SUCCESS, INFORMATION} from "templates/Alert";
import Tooltip from "templates/Tooltip";
import Modal from "templates/Modal";
import SubjectTopicDropdowns from "pages/Profile/subPages/components/tutorComponents/SubjectTopicDropdowns";
import BookSessionModal from "pages/Tutoring/subPages/components/BookSessionModal";
import {askUserToSignUpFirst} from "templates/customModals/utilities/SignUpLoginUtilities";

import { 
	saveTutorRequestAPI, findTutorMatchAPI,
	editTutorRequestAPI, getTutorRequestsAPI
} from "apis/controllers/tutoring/SessionRequestController";
import { getAccountSettingsAPI } from "apis/controllers/person/AccountsController";
import { getUserChargeablePaymentMethodAPI } from "apis/controllers/person/PersonPaymentInfoController";
import { createPaymentIntentAPI } from "apis/controllers/transactions/PaymentsController";

import chevronAsset from "assets/icons/common/chevron.svg";


function NewRequest(props) {

	const ownerId = props.ownerId || localStorage.getItem("ownerId");
	const history = useHistory();

	const [hasFirstAndLastName, setHasFirstAndLastName] = useState(false),
		[hashChargeablePaymentMethods, setHashChargeablePaymentMethods] = useState(false),
		[subjectsToTopicsMap, setSubjectsToTopicsMap] = useState(props.subjectsToTopicsMap),
		[urgencyPreference, setUrgencyPreference] = useState(props.urgency),
		[requestDuration, setRequestDuration] = useState(props.urgencyDuration),
		[placePreference, setPlacePreference] = useState(props.place),
		[tutorMatchingPreference, setTutorMatchingPreference] = useState(),
		[requestsGroupPreference, setRequestsGroupPreference] = useState(props.requestsGroupPreference ? props.requestsGroupPreference : ALL_TUTORS),
		[description, setDescription] = useState(props.description ? props.description : ""),
		[nonCompletedRequestSchedules, setNonCompletedRequestSchedules] = useState(),
		[hasSavedTutors, setHasSavedTutors] = useState(false),
		[hasSetLocation, setHasSetLocation] = useState(false),
		[hasNowConflict, setHasNowConflict] = useState(false),
		[unableToBookNow, setUnableToBookNow] = useState(false),
		[cappedRequestLimit, setCappedRequestLimit] = useState(false),
		// generated DOM
		[spinner, setSpinner] = useState(),
		[inPersonDOM, setInPersonDOM] = useState(),
		[nowDurationDOM, setNowDurationDOM] = useState(),
		[requestGroupDOM, setRequestGroupDOM] = useState(),
		[chosenSessionDateDOM, setChosenSessionDateDOM] = useState(),
		[nowUrgencyTooltipDOM, setNowUrgencyTooltipDOM] = useState(),
		[nowUrgencyTooltipDOM1, setNowUrgencyTooltipDOM1] = useState(),
		[savedTutorsTooltipDOM, setSavedTutorsTooltipDOM] = useState();

	const virtualHelpRef = useRef(),
		inPersonHelpRef = useRef(),
		nowUrgencyRef = useRef(),
		laterUrgencyRef = useRef(),
		automaticMatchingRef = useRef(),
		manualMatchingRef = useRef(),
		chosenDateTime = useRef(props.urgency && props.urgency !== "now" ? new Date(Number(props.urgency)) : ""),
		inPersonDistanceModalRef = useRef(),
		durationNowMinutesRef = useRef(props.urgencyDuration),
		durationLaterMinutesRef = useRef(props.urgencyDuration),
		allTutorsRequestRef = useRef(),
		savedTutorsRequestRef = useRef();

	useEffect(() => {
		const callback = () => {
			getSavedTutors(populateEditProps);
			getPersonalRequests();
		};

		checkUserTutoringEligibility(callback);
	}, [props.ownerId]);


	// TODO: disable send button if not logged in and if there is no saved payment method
	function checkUserTutoringEligibility(callback) {
		getUserChargeablePaymentMethodAPI(ownerId, resp => {
			setHashChargeablePaymentMethods(resp);
			callback();
		});
	}

	function populateEditProps() {
		// set online / in-person radio
		setHelpPreference(props.place, false);
		if (props.place !== "virtual") {
			let inPersonDistance = props.place.split("_");
			validateInPersonDistance(inPersonDistance[1], false);
		}
		
		if (props.requestsGroupPreference) {
			setUrgencyChoice(props.urgency === "now" ? "now" : "later", false);

			if (props.urgency === "now") {
				setNowDurationDOM(
					<div>
						{`${messageMap("tutoringPage.request.modal.scheduleNow", "generic")} ${props.urgencyDuration} ${messageMap("tutoringPage.request.modal.minutes", "generic")}.`}
					</div>
				);
				nowUrgencyRef.current.checked = true;
			}
			else {
				setChosenSessionDateDOM(
					<div>
						{`${messageMap("tutoringPage.request.modal.scheduleAt", "generic")} ${chosenDateTime.current.toLocaleString()} ${messageMap("tutoringPage.request.modal.for", "generic")} ${props.urgencyDuration} ${messageMap("tutoringPage.request.modal.minutes", "generic")}.`}
					</div>
				);
				laterUrgencyRef.current.checked = true;
			}
		}

		setMatchingPreference(props.matchingPreference);
		setRequestsChoice(props.requestsGroupPreference);
	}

	function getSavedTutors(callback) {
		if (ownerId != null) {
			const payload = {
				ownerId: ownerId,
				fields: ["savedTutors"]
			};
			getAccountSettingsAPI(payload, resp => {
				setHasSetLocation(resp.location != null);

				if (resp.savedTutors != null && resp.savedTutors.length) {
					setHasSavedTutors(true);
				}
				if (resp.firstName && resp.lastName) {
					setHasFirstAndLastName(true);
					callback();
				}
			});
		}
	}

	function getPersonalRequests() {
		const pathVariables = {
			sessionStates: [OPEN_STATE, PENDING_STATE, SCHEDULED_STATE],
			sentOrReceived: "",
			intendedUser: TUTEE_USER
		};
		getTutorRequestsAPI(pathVariables, ownerId, resp => {
			let currentRequestsSchedules = [];
			let nonLiveAndNoneCompletedSessionsCount = 0;

			resp.forEach(request => {
				const requestUrgency = request.urgency;
				if (requestUrgency === "now") {
					currentRequestsSchedules.push("now");
				}
				else {
					for (let i = 0; i < request.duration / 15; ++i) {
						currentRequestsSchedules.push(Number(requestUrgency) + 60000 * 15 * i);
					}
				}
				if (request.state === OPEN_STATE) {
					++nonLiveAndNoneCompletedSessionsCount;
				}
			});

			if (currentRequestsSchedules.includes("now")) {
				setHasNowConflict(true);
			}
			else if (nonLiveAndNoneCompletedSessionsCount >= 10) {
				setCappedRequestLimit(true);
			}

			setNonCompletedRequestSchedules(currentRequestsSchedules);
		});
	}

	function setHelpPreference(preferenceChoice, showModal) {
		if (preferenceChoice === VIRTUAL_PLACE) {
			virtualHelpRef.current.checked = true;
			inPersonHelpRef.current.checked = false;

			setUnableToBookNow(false);
			setInPersonDOM(null);
			setPlacePreference(VIRTUAL_PLACE);
		}
		else if (preferenceChoice.includes(IN_PERSON_PLACE)) {
			if (!hasSetLocation) {
				inPersonHelpRef.current.checked = false;

				const alertMsg = (
					<Fragment>
						<p className="in-person">
							{messageMap("tutoringPage.tutorList.noAddressForInPerson1", "generic")}
						</p>
						<Link to="/profile?show=account&reveal=address">
							{messageMap("here.text", "button")}
						</Link>
						<p className="in-person">
							{messageMap("tutoringPage.tutorList.noAddressForInPerson2", "generic")}
						</p>
					</Fragment>
				);

				props.setAlert(
					<Alert type={ERROR} closeHandler={closeAlert} customClass="tutoring" 
						msg={alertMsg} />
				);
			}
			else {
				virtualHelpRef.current.checked = false;
				inPersonHelpRef.current.checked = true;
				nowUrgencyRef.current.checked = false;
	
				setUnableToBookNow(true);
				setNowDurationDOM(null);
	
				showModal && props.setModal(
					<Modal closeHandler={e => closeModal(e, true)} 
						submitHandler={e => validateInPersonDistance(inPersonDistanceModalRef.current.value, true)}
						modalClass="tutoring" submitText={messageMap("tutoring.modal.setSchedule", "button")}>
						<div className="in-person-option">
							<input type="number" min="5" max="75" step="5"
								className="additional-input" ref={inPersonDistanceModalRef}>
							</input>
							{messageMap("tutoringPage.request.miles", "generic")}
						</div>
					</Modal>
				);
			}
		}
	}
	function validateInPersonDistance(inPersonDistance, closeModal) {
		if (validateInPersonTutoringRadius(inPersonDistance, resetInPersonDistance, placeholderHandler, props.setAlert, closeAlert)) {
			setInPersonDOM(
				`${messageMap("tutoringPage.request.findInPerson1", "generic")} ${inPersonDistance} ${messageMap("tutoringPage.request.findInPerson2", "generic")}`
			);
			setPlacePreference(`inPerson_${inPersonDistance}_miles`);

			closeModal && props.setModal(null);
		}
	}
	function resetInPersonDistance() {
		inPersonDistanceModalRef.current.value = 5;
	}
	function placeholderHandler() {}

	function setMatchingPreference(preferenceChoice) {
		if (preferenceChoice === "automatic") {
			if (hasNowConflict) {
				automaticMatchingRef.current.checked = false;
			}
			else {
				automaticMatchingRef.current.checked = true;
				manualMatchingRef.current.checked = false;

				setTutorMatchingPreference(MATCHING_AUTOMATIC);
				setRequestGroupDOM(null);
			}
		}
		else if (preferenceChoice === "manual") {
			automaticMatchingRef.current.checked = false;
			manualMatchingRef.current.checked = true;

			setTutorMatchingPreference(MATCHING_MANUAL);
			setRequestGroupDOM(
				<div className="page-field block">
					<label className="page-field-label">
						<div className="div-label">
							{messageMap("tutoringPage.request.sendTo", "generic")}
						</div>
						*
					</label>
					<div className="inline-elements">
						<div className="multi-line-inlines">
							<div className="radio-checkbox-container">
								<input id="allTutorsRadio" className="page-field-value radio-input later-radio-input"
									type="radio"
									onClick={e => setRequestsChoice(ALL_TUTORS)}
									onKeyDown={e => onKeyDown(e, setRequestsChoice, [ALL_TUTORS], true)}
									ref={allTutorsRequestRef}>
								</input>
								<label htmlFor="allTutorsRadio" className="page-field-label request-group-label">
									{messageMap("tutoring.requests.allTutors", "labelPlaceholder")}
								</label>
							</div>
						</div>
						<div className="multi-line-inlines">
							<div className="radio-checkbox-container"
										onMouseOver={e => toggleMissingSavedTutorsTooltip("show")} onFocus={e => toggleMissingSavedTutorsTooltip("show")}
										onMouseLeave={e => toggleMissingSavedTutorsTooltip("hide")} onBlur={e => toggleMissingSavedTutorsTooltip("hide")}>
								<div>
									{savedTutorsTooltipDOM}
								</div>
								<input id="savedTutorsRadio" className={`page-field-value radio-input later-radio-input ${!hasSavedTutors && "not-allowed"}`}
									type="radio"
									onClick={e => setRequestsChoice(SAVED_TUTORS)}
									onKeyDown={e => onKeyDown(e, setRequestsChoice, [SAVED_TUTORS], true)}
									ref={savedTutorsRequestRef}>
								</input>
								<label htmlFor="savedTutorsRadio" className={`page-field-label request-group-label ${!hasSavedTutors && "not-allowed"}`}>
									{messageMap("tutoring.requests.savedTutors", "labelPlaceholder")}
								</label>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}

	function setUrgencyChoice(urgencyChoice, showModal) {
		if (urgencyChoice === "now") {
			if (hasNowConflict || unableToBookNow) {
				nowUrgencyRef.current.checked = false;

				setNowDurationDOM(null);
			}
			else {
				nowUrgencyRef.current.checked = true;
				laterUrgencyRef.current.checked = false;
	
				setChosenSessionDateDOM(null);
				showModal && showNowDurationModal();
			}
		}
		else if (urgencyChoice === "later") {
			nowUrgencyRef.current.checked = false;
			laterUrgencyRef.current.checked = true;

			setNowDurationDOM(null);
			showModal && showLaterSchedule();
		}

		setUrgencyPreference(urgencyChoice);
	}

	function setRequestsChoice(requestChoice) {
		let shouldSaveChoice = false;

		if (requestChoice === ALL_TUTORS) {
			allTutorsRequestRef.current.checked = true;
			savedTutorsRequestRef.current.checked = false;

			shouldSaveChoice = true;
		}
		else if (requestChoice === SAVED_TUTORS) {
			if (!hasSavedTutors) {
				savedTutorsRequestRef.current.checked = false;
			}
			else {
				allTutorsRequestRef.current.checked = false;
				savedTutorsRequestRef.current.checked = true;

				shouldSaveChoice = true;
			}
		}

		shouldSaveChoice && setRequestsGroupPreference(requestChoice);
	}

	function toggleMissingSavedTutorsTooltip(hideOrShow) {
		if (!hasSavedTutors) {
			setSavedTutorsTooltipDOM(
				hideOrShow === "show" ?
				<Tooltip classStr="tooltip-bottom-middle saved-tutors" subheader={messageMap("tutor.noSavedTutors", "tooltip")} />
				: null
			);
		}
	}
	
	function toggleNowUrgencyConflictTooltip(hideOrShow, whichTooltip) {
		if (hasNowConflict) {
			const tooltipMap = {
				0: setNowUrgencyTooltipDOM,
				1: setNowUrgencyTooltipDOM1
			};

			tooltipMap[whichTooltip](
				hideOrShow === "show" ?
				<Tooltip classStr="tooltip-bottom-middle now-urgency" subheader={messageMap("tutor.hasNowConflict", "tooltip")} />
				: null
			);
		}
	}

	function validateAndDisplayNowSchedule() {
		if (durationNowMinutesRef.current.value === "") {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} customClass="tutoring"
					msg={messageMap("schedule.missing.duration", "validation")} />
			);
		}
		else {
			setNowDurationDOM(
				<div>
					{`${messageMap("tutoringPage.request.modal.scheduleNow", "generic")} ${durationNowMinutesRef.current.value} ${messageMap("tutoringPage.request.modal.minutes", "generic")}.`}
				</div>
			);
			setRequestDuration(durationNowMinutesRef.current.value);
			
			if (props.mode === "editRequest") {
				hideModal("modal1");
			}
			else {
				hideModal("default");
			}
		}
	}
	
	function validateAndDisplayLaterSchedule() {
		setChosenSessionDateDOM(
			<div>
				{`${messageMap("tutoringPage.request.modal.scheduleAt", "generic")} ${chosenDateTime.current.toLocaleString()} ${messageMap("tutoringPage.request.modal.for", "generic")} ${durationLaterMinutesRef.current.value} ${messageMap("tutoringPage.request.modal.minutes", "generic")}.`}
			</div>
		);
		setRequestDuration(durationLaterMinutesRef.current.value);
		
		if (props.mode === "editRequest") {
			hideModal("modal1");
		}
		else {
			hideModal("default");
		}
	}

	function closeModal(e, resetUrgencyChoice) {
		if (e == null || (e != null && ["modal-block", "cancel", "icon", "close-button"].includes(e.target.className))) {
			if (props.mode === "editRequest") {
				hideModal("modal1");
			}
			else {
				hideModal("default");
			}

			if (resetUrgencyChoice) {
				laterUrgencyRef.current.checked = false;
				setUrgencyPreference(null);
			}
		}
	}
	function hideModal(modalType) {
		const modalMap = {
			default: props.setModal,
			modal1: props.setModal1
		};

		setTimeout(() => {
			modalMap[modalType](null);
		}, MODAL_CLOSE_TIMEOUT);
	}

	function showNowDurationModal() {
		const modalDom = (
			<Modal closeHandler={e => closeModal(e, true)} submitHandler={validateAndDisplayNowSchedule}
				modalClass="tutoring" submitText={messageMap("tutoring.modal.setSchedule", "button")}>
				<div className="schedule-option">
					<label htmlFor="duration" className="schedule-label">
						{messageMap("tutoringPage.request.modal.duration", "generic")}
					</label>
					<div className="arrowed-input-container">
						<input id="duration" className="schedule-value duration"
							type="number" min="15" max="240" step="15"
							ref={durationNowMinutesRef}>
						</input>
						<div className="inline-chevron-div">
							<div className="chevron-container">
								<img src={chevronAsset} alt={messageMap("chevron.up", "image")}
									className="chevron-up" tabIndex={0} role="button"
									onClick={e => updateNowDuration("increase")}
									onKeyDown={e => onKeyDown(e, updateNowDuration, ["increase"])}></img>
								<img src={chevronAsset} alt={messageMap("chevron.down", "image")}
									className="chevron-down" tabIndex={0} role="button"
									onClick={e => updateNowDuration("decrease")}
									onKeyDown={e => onKeyDown(e, updateNowDuration, ["decrease"])}></img>
							</div>
						</div>
						<div>
							{messageMap("tutoringPage.request.modal.minutes", "generic")}
						</div>
					</div>
				</div>
			</Modal>
		);

		if (props.mode === "editRequest") {
			props.setModal1(modalDom);
		}
		else {
			props.setModal(modalDom);
		}
	}

	function showLaterSchedule() {
		const modalDom = (
			<BookSessionModal closeModal={e => closeModal(e, true)} setAlert={props.setAlert}
				submitHandler={validateAndDisplayLaterSchedule} bookedTimes={nonCompletedRequestSchedules}
				// refs
				chosenDateTime={chosenDateTime} durationLaterMinutesRef={durationLaterMinutesRef} />
		);

		if (props.mode === "editRequest") {
			props.setModal1(modalDom);
		}
		else {
			props.setModal(modalDom);
		}
	}

	function updateNowDuration(durationDirection) {
		if ((durationDirection === "decrease" && Number(durationNowMinutesRef.current.value) === 15)
			|| (durationDirection === "increase" && Number(durationNowMinutesRef.current.value) === 240)) {
			return;
		}
		else if (durationNowMinutesRef.current.value <= 240 && durationNowMinutesRef.current.value >= 0) {
			if (durationDirection === "increase") {
				durationNowMinutesRef.current.value = Number(durationNowMinutesRef.current.value) + 15;
			}
			else if (durationDirection === "decrease") {
				durationNowMinutesRef.current.value = Number(durationNowMinutesRef.current.value) - 15;
			}
		}
	}

	function saveDescription(e) {
		const descriptionValue = e.target.value;

		if (checkHasExplicitWords(descriptionValue)) {
			e.target.value = "";

			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} customClass="tutoring"
					msg={messageMap("input.explicit.text", "validation")} />
			);
		}
		else {
			setDescription(descriptionValue);
		}
	}

	function closeAlert() {
		props.setAlert(null);
	}

	function saveTutorRequest() {
		if (ownerId == null) {
			askUserToSignUpFirst(props.setModal, props.setLoginModal);

			return;
		}

		if (cappedRequestLimit) {
			props.setAlert(
				<Alert type={INFORMATION} closeHandler={closeAlert} customClass="tutoring" 
					msg={messageMap("tutoringPage.request.limitReached", "generic")} />
			);

			return;
		}

		const errorMsgs = validateRequest();

		if (errorMsgs.length) {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} customClass="tutoring"
					msg={errorMsgs.join(" ")} timeout={20000}/>
			);
		}
		else if (!hasFirstAndLastName || !hashChargeablePaymentMethods) {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} customClass="tutoring"
					msg={messageMap("tutoringPage.request.alertFirstLastOrChargeablePaymentMethodMissing", "generic")} timeout={20000}/>
			);
		}
		else {
			const subject = Object.keys(subjectsToTopicsMap)[0];


			if (urgencyPreference === "later" && chosenDateTime.current === "") {
				props.setAlert(
					<Alert type={ERROR} closeHandler={closeAlert} customClass="tutoring"
						msg={messageMap("schedule.invalidAppointment", "validation")} />
				);
				return;
			}
			const urgency = urgencyPreference === "later" ? chosenDateTime.current.getTime() : urgencyPreference;

			let payload = {
				createdAt: Date.now(),
				timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				ownerId: ownerId,
				subject: subject,
				topic: subjectsToTopicsMap[subject],
				place: placePreference,
				urgency: urgency,
				duration: Number(requestDuration),
				matchingPreference: tutorMatchingPreference,
				receiverPreference: requestsGroupPreference,
				description: description
			};

			if (props.mode === "newRequest") {
				setSpinner(
					<Spinner key="newRequest" />
				);				

				if (tutorMatchingPreference === MATCHING_AUTOMATIC) {
					findTutorMatch(payload, callSaveTutorRequestAPI, payForSessionBeforeSavingTutorRequest)
				}
				else {
					callSaveTutorRequestAPI(payload);
				}
			}
			else if (props.mode === "editRequest") {
				payload["id"] = props.requestId;

				editTutorRequestAPI(payload, resp => {
					let alertType;
					let alertMsgs = [];

					if (resp === "tutor.requests.edited.noDifference") {
						alertType = INFORMATION;
						alertMsgs.push(messageMap(resp, "api"));
					}
					else if (resp === "tutor.requests.edited.failed") {
						alertType = ERROR;
						alertMsgs.push(messageMap(resp, "api"));
					}
					else {
						alertType = SUCCESS;
						resp.split("_").forEach(msg => {
							alertMsgs.push(messageMap(msg, "api"));
						});
						alertMsgs.push(messageMap("tutor.requests.edited.pageReload", "api"));
					}

					props.setAlert(
						<Alert type={alertType} closeHandler={closeAlert} customClass="tutoring" 
							msg={alertMsgs.join(" ")} />
					);
					hideModal("default");

					getPersonalRequests();

					if (alertType === SUCCESS) {
						setTimeout(() => {
							window.location.reload();
						}, SAVE_RELOAD_TIMEOUT);
					}
				});
			}
		}
	}
	function findTutorMatch(payload, callback, directMatchCallback) {
		findTutorMatchAPI(payload, resp => {
			setSpinner(null);

			if (resp["SUCCESS"]) {
				let titleText = messageMap(resp["SUCCESS"], "api");

				if (resp["SESSION_COST"] != null) {
					const tutorName = resp["TUTOR_NAME"];
					const tutorId = resp["TUTOR_ID"];

					titleText += tutorName;
					// just pass the tutor's ID identified from the API
					payload["sentTo"] = [tutorId];
					payload["matchingPreference"] = MATCHING_AUTOMATIC_DIRECT;

					props.setModal(
						<Modal modalClass="tutoring automatic-request" title={titleText}
							closeHandler={closeModal}
							submitHandler={e => directMatchCallback(payload, resp["SESSION_COST"], tutorId, tutorName, resp["TUTOR_OWNER_ID"])}
							submitText={messageMap("yes.text", "button")} cancelText={messageMap("no.text", "button")} >
							<div className="direct-results">
								<div>
									{messageMap("tutor.requests.automatic.confirmation.successDirect1", "api")}
									<p className="price">
										{`$${resp["SESSION_COST"].toPrecision(4)}.`}
									</p>
								</div>
								<div>
									{messageMap("tutor.requests.automatic.confirmation.successDirect2", "api")}
								</div>
							</div>
						</Modal>
					);
				}
				else if (resp["TUTOR_POOL"] != null) {
					// just pass the pool of tutor IDs identified from the API
					payload["sentTo"] = resp["TUTOR_POOL"];
					payload["matchingPreference"] = MATCHING_AUTOMATIC_POOL;

					props.setModal(
						<Modal modalClass="tutoring automatic-request" title={titleText}
							closeHandler={closeModal} submitHandler={e => callback(payload)}
							submitText={messageMap("yes.text", "button")} cancelText={messageMap("no.text", "button")} />
					);
				}
			}
			else {
				payload["matchingPreference"] = MATCHING_MANUAL;
				payload["receiverPreference"] = ALL_TUTORS;

				props.setModal(
					<Modal modalClass="tutoring automatic-request" title={messageMap(resp["ERROR"], "api")}
						closeHandler={closeModal} submitHandler={e => callback(payload)}
						submitText={messageMap("yes.text", "button")} cancelText={messageMap("no.text", "button")} />
				);
			}
		});
	}

	function callSaveTutorRequestAPI(passedPayload) {
		props.setModal(null);
		setSpinner(
			<Spinner key="newRequest" />
		);
		saveTutoringRequest(passedPayload);
	}
	function payForSessionBeforeSavingTutorRequest(tutoringRequestPayload, sessionPrice, tutorId, tutorName, tutorOwnerId) {
		props.setModal(null);
		setSpinner(
			<Spinner key="newRequest" />
		);

		const paymentDescription = `${messageMap("payments.checkout.tutoringSession", "generic")} ${tutorName}`;
		const paymentPayload = {
			ownerId: props.ownerId,
			quantityPurchased: 1,
			paymentAmount: sessionPrice,
			paymentDescription: paymentDescription,
			paymentPurpose: "tutoringSession",
			isPaymentOffSession: true,
			tutorOwnerId: tutorOwnerId,
			// this is normally provided when the user pays for a tutoring session. But because we're 
			// requiring the user to pay first before creating the session, we'll just create the session
			// once the user pays for the cost.
			// sessionId: props.sessionId,
		};

		createPaymentIntentAPI(paymentPayload, intentResp => {
			handleTutoringPaymentIntentResponse(
				null, sessionPrice, props.isSubscriber, paymentDescription,
				tutorId, null,
				"tutoring_sessions", JSON.stringify(tutoringRequestPayload),
				intentResp,
				null, setSpinner, props.setPaymentInfo, history.push
			);

			if (intentResp.status === "succeeded") {
				tutoringRequestPayload["chargeId"] = intentResp.chargeId;
				tutoringRequestPayload["transferId"] = intentResp.transferId;

				saveTutoringRequest(tutoringRequestPayload);
			}
		});
	}

	function saveTutoringRequest(requestPayload) {
		props.setModal(null);
		saveTutorRequestAPI(requestPayload, resp => {
			setSpinner(null);
			parseNewRequestResponse(
				resp,
				history.push, props.reRenderList, props.setAlert, closeAlert
			);
		});
	}

	function validateRequest() {
		let errorsMsgs = [];

		if (subjectsToTopicsMap == null) {
			errorsMsgs.push(messageMap("schedule.missing.subjectTopic", "validation"));
		}
		if (urgencyPreference == null) {
			errorsMsgs.push(messageMap("schedule.missing.urgency", "validation"));
		}
		if (requestGroupDOM != null && requestsGroupPreference == null) {
			errorsMsgs.push(messageMap("schedule.missing.requestReceiver", "validation"));
		}
		if (description == null || description === "") {
			errorsMsgs.push(messageMap("schedule.missing.description", "validation"));
		}

		return errorsMsgs;
	}

	return (
		<Fragment>
			<Helmet>
				<title>{messageMap("requestTutoring.title", "headerTag")}</title>
				<meta name="description" content={messageMap("requestTutoring.description", "headerTag")}></meta>
			</Helmet>

			{spinner}

			<Fragment>
				<div className={`limits ${!cappedRequestLimit && "hide"}`}>
					{cappedRequestLimit && messageMap("tutoringPage.request.limitReached", "generic")}
				</div>
				{
					ownerId == null
					? (
						<div className="set-first-last-name-payment-method">
							{messageMap("tutoringPage.request.setFirstLastNamePaymentMethodFirst1", "generic")}
							<button className="sign-up-button"
								onClick={e => askUserToSignUpFirst(props.setModal, props.setModal1)}>
								{messageMap("here.text", "button")}
							</button>
							{messageMap("tutoringPage.request.setFirstLastNamePaymentMethodFirst2", "generic")}
						</div>
					)
					: (
						!hasFirstAndLastName && !hashChargeablePaymentMethods
						? (
							<div className="set-first-last-name-payment-method">
								<div>
									{messageMap("tutoringPage.request.firstLastOrChargeablePaymentMethodMissing1", "generic")}
									<Link to="/profile?show=account" className="profile-settings-nav-link"
										onClick={defaultLinkAction}>
										{`${messageMap("here.text", "button")}.`}
									</Link>
								</div>
								<div>
									{messageMap("tutoringPage.request.firstLastOrChargeablePaymentMethodMissing2", "generic")}
									<Link to="/profile?show=financials" className="profile-settings-nav-link"
										onClick={defaultLinkAction}>
										{`${messageMap("here.text", "button")}.`}
									</Link>
								</div>
							</div>
						)
						: (
							!hasFirstAndLastName && hashChargeablePaymentMethods
							? (
								<Fragment>
									<div className="set-first-last-name">
										{messageMap("tutoringPage.request.setFirstLastName", "generic")}
									</div>
									<Link to="/profile?show=account" className="profile-settings-nav-button"
										onClick={defaultLinkAction}>
										{messageMap("tutoring.request.goToProfile", "button")}
									</Link>
								</Fragment>
							)
							: (
								hasFirstAndLastName && !hashChargeablePaymentMethods
								&& (
									<Fragment>
										<div className="set-chargeable-payment-method">
											{messageMap("tutoringPage.request.setPaymentMethodFirst", "generic")}
										</div>
										<Link to="/profile?show=financials" className="profile-settings-nav-button"
											onClick={defaultLinkAction}>
											{messageMap("tutoring.request.goToProfile", "button")}
										</Link>
									</Fragment>
								)
							)
						)
					)
				}

				<SubjectTopicDropdowns customClass="block"
					defaultLabel={`${messageMap("tutoringPage.request.subject", "generic")} *`}
					separateTopicLabel={`${messageMap("tutoringPage.request.topic", "generic")} *`}
					setSubjectsToTopics={setSubjectsToTopicsMap} singleTopic={true} isApplication={false} useNewLine={true}
					// passed props for editing only
					preSelectedSubject={props.subject} preSelectedTopic={props.topic} />

				<div className="page-field block">
					<label className="page-field-label">
						<div className="div-label">
							{messageMap("tutoringPage.request.place", "generic")}
						</div>
						*
					</label>
					<div className="inline-elements urgency">
						<div className="inline-elements">
							<div className="multi-line-inlines">
								<div className="radio-checkbox-container">
									<input id="virtualHelp" type="radio"
										className="page-field-value radio-input later-radio-input"
										onClick={e => setHelpPreference(VIRTUAL_PLACE)}
										onKeyDown={e => onKeyDown(e, setHelpPreference, [VIRTUAL_PLACE], true)}
										ref={virtualHelpRef}>
									</input>
									<label htmlFor="virtualHelp" className="page-field-label later-radio-label">
										{messageMap("tutoring.requests.virtual", "labelPlaceholder")}
									</label>
								</div>
							</div>
						</div>
						<div className="inline-elements">
							<div className="multi-line-inlines">
								<div className="radio-checkbox-container">
									<input id="inPersonHelp" type="radio"
										className="page-field-value radio-input later-radio-input"
										onClick={e => setHelpPreference(IN_PERSON_PLACE, true)}
										onKeyDown={e => onKeyDown(e, setHelpPreference, [IN_PERSON_PLACE, true], true)}
										ref={inPersonHelpRef}>
									</input>
									<label htmlFor="inPersonHelp" className="page-field-label later-radio-label">
										{messageMap("tutoring.requests.inPerson", "labelPlaceholder")}
									</label>
								</div>
								<div>
									{inPersonDOM}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="page-field block urgency">
					<label className="page-field-label">
						<div className="div-label">
							{messageMap("tutoringPage.request.assistanceUrgency", "generic")}
						</div>
						*
					</label>
					<div className="inline-elements urgency">
						<div className="inline-elements">
							<div className="multi-line-inlines">
								<div className="radio-checkbox-container"
									onMouseOver={e => toggleNowUrgencyConflictTooltip("show", 0)} onFocus={e => toggleNowUrgencyConflictTooltip("show", 0)}
									onMouseLeave={e => toggleNowUrgencyConflictTooltip("hide", 0)} onBlur={e => toggleNowUrgencyConflictTooltip("hide", 0)}>
									<div>
										{nowUrgencyTooltipDOM}
									</div>
									<input id="nowRadio" className={`page-field-value radio-input later-radio-input ${(hasNowConflict || unableToBookNow) && "not-allowed"}`}
										type="radio"
										onClick={e => setUrgencyChoice("now", true)}
										onKeyDown={e => onKeyDown(e, setUrgencyChoice, ["now", true], true)}
										ref={nowUrgencyRef}></input>
									<label htmlFor="nowRadio" className={`page-field-label later-radio-label ${(hasNowConflict || unableToBookNow) && "not-allowed"}`}>
										{messageMap("tutoring.requests.now", "labelPlaceholder")}
									</label>
								</div>
								<div>
									{nowDurationDOM}
								</div>
							</div>
						</div>
						<div className="inline-elements">
							<div className="multi-line-inlines">
								<div className="radio-checkbox-container">
									<input id="laterRadio" className="page-field-value radio-input later-radio-input"
										type="radio"
										onClick={e => setUrgencyChoice("later", true)}
										onKeyDown={e => onKeyDown(e, setUrgencyChoice, ["later", true], true)}
										ref={laterUrgencyRef}>
									</input>
									<label htmlFor="laterRadio" className="page-field-label later-radio-label">
										{messageMap("tutoring.requests.later", "labelPlaceholder")}
									</label>
								</div>
								<div>
									{chosenSessionDateDOM}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="page-field block">
					<label className="page-field-label">
						<div className="div-label">
							{messageMap("tutoringPage.request.matching", "generic")}
						</div>
						*
					</label>
					<div className="inline-elements urgency matching">
						<div className="inline-elements">
							<div className="multi-line-inlines">
								<div className="radio-checkbox-container"
									onMouseOver={e => toggleNowUrgencyConflictTooltip("show", 1)} onFocus={e => toggleNowUrgencyConflictTooltip("show", 1)}
									onMouseLeave={e => toggleNowUrgencyConflictTooltip("hide", 1)} onBlur={e => toggleNowUrgencyConflictTooltip("hide", 1)}>
									<input id="automaticRadio" type="radio"
										className={`page-field-value radio-input later-radio-input ${hasNowConflict && "not-allowed"}`}
										onClick={e => setMatchingPreference("automatic")}
										onKeyDown={e => onKeyDown(e, setMatchingPreference, ["automatic"], true)}
										ref={automaticMatchingRef}></input>
									<label htmlFor="automaticRadio" className={`page-field-label later-radio-label ${hasNowConflict && "not-allowed"}`}>
										{messageMap("tutoring.requests.automatic", "labelPlaceholder")}
										<Link to="/profile?show=learner" className="profile-settings-nav-link"
											onClick={defaultLinkAction}>
											{messageMap("tutoring.request.learnerProfile", "button")}
										</Link>
										{")"}
									</label>
								</div>
								<div>
									{nowUrgencyTooltipDOM1}
								</div>
							</div>
						</div>
						<div className="inline-elements">
							<div className="multi-line-inlines">
								<div className="radio-checkbox-container">
									<input id="manualRadio" type="radio"
										className="page-field-value radio-input later-radio-input"
										onClick={e => setMatchingPreference("manual")}
										onKeyDown={e => onKeyDown(e, setMatchingPreference, ["manual"], true)}
										ref={manualMatchingRef}>
									</input>
									<label htmlFor="manualRadio" className="page-field-label later-radio-label">
										{messageMap("tutoring.requests.application", "labelPlaceholder")}
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>

				{requestGroupDOM}

				<div className="page-field block">
					<label className="page-field-label" htmlFor="additionalDetails">
						<div className="div-label">
							{messageMap("tutoringPage.request.assistanceDescription", "generic")}
						</div>
						*
					</label>
					<div className="inline-elements">
						<textarea id="additionalDetails" className="page-field-value textarea" type="text"
							required aria-required="true" maxLength={1000}
							onChange={saveDescription} value={description}
							placeholder={messageMap("tutoringPage.request.description", "generic")}>
						</textarea>
					</div>
				</div>

				<button className={`send-request-button ${cappedRequestLimit ? "not-allowed" : ""}`}
						onClick={saveTutorRequest}>
					{
						props.mode === "editRequest"
						? messageMap("save.edits", "button")
						: messageMap("tutoring.request.create", "button")
					}
				</button>
			</Fragment>
		</Fragment>
	);
}

NewRequest.defaultProps = {
	mode: "newRequest"
};

NewRequest.propTypes = {
	setAlert: PropTypes.func.isRequired,
	setModal: PropTypes.func,
	setModal1: PropTypes.func,
	setLoginModal: PropTypes.func,
	reRenderList: PropTypes.func.isRequired,
	mode: PropTypes.string,

	// editing props
	requestId: PropTypes.string,
	urgency: PropTypes.object,
	urgencyDuration: PropTypes.number,
	subjectsToTopicsMap: PropTypes.object,
	subject: PropTypes.string,
	topic: PropTypes.string,
	place: PropTypes.string,
	matchingPreference: PropTypes.string,
	requestsGroupPreference: PropTypes.string,
	description: PropTypes.string,

	// redux props
	ownerId: PropTypes.string,
	isSubscriber: PropTypes.bool.isRequired,
	setPaymentInfo: PropTypes.func.isRequired
};

export default connect(
	account,
	{setPaymentInfo}
)(NewRequest);

