// KEEP FIRST-LEVEL KEYS IN ALPHABETIC ORDER
export const buttonMessageMap = {
	buy: {
		text: "Buy"
	},
	cancel: {
		text: "Cancel"
	},
	checkout: {
		pay: "Pay"
	},
	clear: {
		text: "Clear",
		notifications: "Clear Notifications"
	},
	comment: {
		text: "Comment"
	},
	delete: {
		text: "Delete"
	},
	here: {
		text: "here"
	},
	go: {
		text: "Go"
	},
	learnMore: {
		text: "Learn more"
	},
	link: {
		text: "link"
	},
	navigate: {
		to: "Navigate To"
	},
	prompt: {
		skipAll: "Skip all prompts"
	},
	quotes: {
		continue: "Continue"
	},
	calendar: {
		continue: "Continue"
	},
	save: {
		edits: "Save edits",
		notes: "Save Notes",
		tutoringProfile: "Save profile"
	},
	saved: {
		bookmarks: "Saved Bookmarks",
		notes: "Saved Notes"
	},
	submit: {
		text: "Submit"
	},
	subscription: {
		free: "Sign up",
		pro: "Get started",
		enterprise: "Contact us",
		cancel: "Cancel subscription"
	},
	next: {
		text: "Next"
	},
	landing: {
		learner: {
			headerCTA: "Discover tutors",
			detail1: "Explore our tutors",
			detail2: "Let's learn",
			detail3: "Earn points",
			joinFree: "Join for free",
			viewPlans: "View learning plans"
		},
		tutor: {
			headerCTA: "Become a tutor",
			details: "Learn more",
			signUp: "Sign up"
		},
	},
	home: {
		joinCta: "Join now"
	},
	update: {
		text: "Update",
		firstName: "Update first name",
		lastName: "Update last name",
		username: "Update username",
		email: "Update email",
		password: "Update password"
	},
	financials: {
		addPayment: "Add payment method",
		changePayment: "Change payment method",
		createStripeConnectAccount: "Create account",
		updateStripeConnectAccount: "Update Connect account",
		changePayout: "Change payout method",
		withdrawEarnings: "Withdraw money",
	},
	stripeConnect: {
		card: "card",
		bank: "bank",
		uploadIdVerification: "Upload proof of ID",
		uploadAddressVerification: "Upload proof of address",
		createConnectAccount: "Create connect account",
		updateConnectAccount: "Update connect account",
		goToAccountProfile: "Go to account profile",
		goToTutorProfile: "Go to tutor profile",
		goToConnectProfile: "Go to Connect profile"
	},
	account: {
		// login
		changeUsername: "Change username",
		changePassword: "Change password",
		forgotPassword: "Forgot password?",
		sendRecoveryLink: "Email recovery link",
		updatePassword: "Update password",
		google: {
			signUp: "Sign up with Google",
			login: "Login with Google"
		},
		// personal information
		changeName: "Change name",
		changeNameKana: "Change name kana",
		changeNameKanji: "Change name kanji",
		changeDob: "Change date of birth",
		// contact information
		changeEmail: "Change email",
		changePhone: "Change phone number",
		// location
		changeAddress: "Change address"
	},
	tutoring: {
		activeProfile: {
			editProfile: "Edit tutoring profile",
			showProfile: "Show tutoring profile",
			hideProfile: "Hide tutoring profile"
		},
		noProfile: {
			becomeTutor: "Become a tutor",
			deleteProof: "Permanently delete proof of credibility?"
		},
		accountVerification: {
			unverified: "Unverified - send verification email"
		},
		chat: {
			deleteChannel: "Delete chat"
		},
		modal: {
			setPriceTitle: "Set price for: ",
			setPriceButton: "Set price",
			setPolicy: "Set policy",
			setSchedule: "Set",
			sendInterviewRequest: "Send interview request",
			change: "Change",
			sendRequest: "Send request",
			checkStripeVerification: "Check verification status"
		},
		dropdowns: {
			country: "Choose a country",
			bankCountry: "Bank's country",
			language: "Choose a language",
			disability: "Choose a disability",
			price: "Price",
			services: "Choose services",
			subject: "Choose a subject",
			cancellation: "Time range",
			schedule: "Choose a day",
			filter: {
				fifteenTrial: "15-minute trial",
				inPersonTutoring: "In-person tutoring",
				within: "Within",
				miles: "mi",
				kilometers: "km",
				instantBookingAvailable: "Instant booking",
				lessThanTen: "Less than $10",
				betweenTenAndTwenty: "$10 - $19.99",
				betweenTwentyAndFifty: "$20 - $49.99",
				moreThanFifty: "More than $50"
			}
		},
		screeningQuestions: {
			download: "Download questions",
			upload: "Upload response"
		},
		request: {
			goToProfile: "Go to profile settings",
			create: "Create request",
			send: "Send request",
			delete: "Delete request",
			edit: "Edit request",
			hire: "Hire",
			apply: "Apply",
			accept: "Accept",
			decline: "Decline",
			remove: "Remove",
			learnerProfile: "profile"
		},
		session: {
			cancel: "Cancel session",
			join: "Join session",
			leave: "Leave session",
			report: "Report",
			sendReport: "Send report",
			giveReview: "Give review",
			postReview: "Post review"
		},
		readMore: "Read more",
		tutorList: {
			learnMore: "Learn more",
			createRequest: "Create request",
			compare: "Compare tutors",
			changeCapacity: "Change capacity",
			back: "Back",
			schedule: "Schedule"
		},
		savedTutors: {
			signInToViewSavedTutors: "Please sign in to view your saved tutors.",
			saveTutors: "Save trusted tutors"
		}
	},
	groups: {
		addMember: "Add member",
		copyCode: "Copy code",
		createGroup: "Create group",
		join: "Join",
		leaveGroup: "Leave group",
		shinyNeurons: "Shiny Neurons",
		removeAccount: "Remove account"
	},
	knowledgeTree: {
		search: {
			placeholder: "Search topic"
		},
		view: {
			save: "Save view",
			show: "Show",
			delete: "Delete"
		},
		quest: {
			resume: "Resume Quest",
			start: "Start Quest"
		}
	},
	editVideoPage: {
		filter: "Filter",
		saveChanges: "Save Changes",
		videos: {
			edit: "Edit Video",
			delete: "Delete Video"
		},
		modules: {
			bundle: "Bundle Into Module",
			create: "Create Module",
			edit: "Edit Module",
			delete: "Delete Module",
			update: "Update Module"
		}
	},
	uploadVideoPage: {
		back: "< Back",
		grades: "Grade School",
		degrees: "Degrees",
		jobs: "Jobs"
	},
	learn: {
		details: "Details",
		mentalModel: {
			buttonLocked: "Mental Model (finish video to unlock)",
			buttonUnlocked: "Mental Model",
			download: "Download Model"
		},
		notes: "Notes",
	list: "View all notes"
	},
	practice: {
		text: "Practice",
		answer: "Answer",
		hint: "Hint",
		review: "Review"
	},
	links: {
		video: "Videos",
		knowledgeTree: "Knowledge Quests",
		topicsInfoList: "Topics",
		tutoring: "Tutoring",
		practice: "Practice room",
		profileSettings: "Profile settings",
		upload: "Upload knowledge",
		editVideos: "Edit videos",
		exerShop: "Exer shop",
		groups: "Groups",
		logOut: "Log out",
		signUp: "Sign Up",
		login: "Login",
		footer: {
			exploreTutor: "Explore tutors",
			becomeATutor: "Become a tutor",
			videos: "Videos",
			articles: "Articles",
			practice: "Practice problems",
			aboutUs: "About us",
			contactUs: "Contact us",
			// this doesn't really belong here, but placing it here because it's alongside links
			reportIssue: "Report an issue",
			subscriptions: "Subscriptions",
			tutorPolicy: "Tutor Policy",
			tuteePolicy: "Tutee Policy",
			faq: "FAQ",

			termsAndConditions: "Terms & Conditions",
			privacy: "Privacy Policy",
		}
	},
	spinner: {
		action: {
			delete: "Deleting",
			upload: {
				text: "Uploading",
				duration: "This can take a few minutes..."
			},
			update: "Updating"
		},
		fetch: {
			loading: "Loading your video",
			getting: "Getting your video(s)"
		},
		processing: {
			payment: "Processing payment."
		}
	},
	yes: {
		text: "Yes",
		remove: "Yes, remove"
	},
	no: {
		text: "No"
	}
};