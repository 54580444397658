export const headerTagMessageMap = {
	/*************************** Landing **************************/
	landingForLearners: {
		title: "Online Tutoring for Learners | Exer Institute",
		description: `With our online tutoring, you can improve your grades, catch up 
			and even surpass your class, accelerate your learning, get homework help and 
			test preparation, and even opt for high-dosage tutoring.`
	},
	landingForTutors: {
		title: "Online Tutoring for Tutors | Exer Institute",
		description: `Tutor online anytime and receive up to 95% of your posted rate. Instead 
			of offering a flat tutoring rate, set different rates per subject.`
	},
	/*************************** Socials **************************/
	groups: {
		title: "Groups | Exer Institute",
		description: "Groups that you're a member of."
	},
	/*************************** Profiles **************************/
	profile: {
		title: "Profile | Exer Institute",
		description: "Update your username, email, password, and additional settings."
	},
	/*************************** Topics & metadata **************************/
	knowledgeQuests: {
		title: "Knowledge Quests | Exer Institute",
		description: "Go on exciting knowledge quests that expands your understanding of the world."
	},
	topics: {
		title: "Practice Category | Exer Institute",
		description: "Choose from our wide selection of exercises to grow those brain muscles. We're growing our practice questions for math, science, history, english, foreign language, economics, art, psychology, health & fitness, and much more."
	},
	topicsInfoList: {
		title: "Topics List | Exer Institute",
		description: "Choose from a growing selection of interactive articles demonstrating complex concepts in an easy-to-follow format."
	},
	/*************************** Practice questions **************************/
	practice: {
		title: "Practice Room | Exer Institute",
		description: "Grow those brain muscles while having fun with our gamified questions."
	},
	/*************************** Videos **************************/
	uploadVideo: {
		title: "Upload Video | Exer Institute",
		description: "Share your fact, insights, knowledge, and/or expertise with the world by uploading a video today."
	},
	editVideos: {
		title: "Edit Videos | Exer Institute",
		description: "Update your videos to ensure you reach your avid learners."
	},
	videoList: {
		title: "Video List | Exer Institute",
		description: "Grow and expand your horizon by acquiring skills and knowledge from some of the best teachers and coaches in the world. Learn to your heart's content."
	},
	/*************************** Tutoring **************************/
	tutoring: {
		title: "Tutoring | Exer Institute",
		description: "Find some of the best and affordable tutors in the world for math, chemistry, physics, economics, and computer/software engineering."
	},
	tutoringSessions: {
		title: "Tutoring Sessions | Exer Institute",
		description: "Easily track your scheduled, live, and past tutoring sessions."
	},
	requestTutoring: {
		title: "Request Tutoring | Exer Institute",
		description: "Easily schedule a tutoring session with 15 minutes, 30 minutes, 45 minutes, or an hour in length with some of the most qualified teachers in their fields."
	},
	tutoringRequests: {
		title: "Tutoring Requests | Exer Institute",
		description: "Find a list of tutoring requests that fits your expertise."
	},
	tutoringApplications: {
		title: "Tutoring Applications | Exer Institute",
		description: "Choose from a list of expert tutors that can take your learning to the next level."
	},
	savedTutors: {
		title: "Saved Tutors | Exer Institute",
		description: "Schedule follow-up tutoring sessions with tutors that has been proven to be your best fit."
	},
	tutorList: {
		title: "Tutor List | Exer Institute",
		description: "Find some of the most qualified teachers and tutors in their fields."
	},
	tutoringMessages: {
		title: "Tutoring Messages | Exer Institute",
		description: "Easily chat with tutors and tutees to ensure that your upcoming tutoring sessions are well-spent."
	},
	tutoringFAQs: {
		title: "Tutoring FAQs | Exer Institute",
		description: "Got any tutoring-related questions? We got your answers."
	},
	tutoringPolicies: {
		title: "Tutoring Policies | Exer Institute",
		description: "Learn how our tutor and tutee policies are ensuring a safe, transparent, and beneficial learning and teaching platform for everyone."
	},
	whyExerTutoring: {
		title: "Why Use Exer Tutoring | Exer Institute",
		description: "Find out why Exer Institute is the top tutoring platform."
	},
	/*************************** Financials & Transaction pages **************************/
	quotes: {
		title: "Purchase Shiny Neurons | Exer Institute",
		description: "Purchase shiny neurons to supercharge your journey."
	},
	checkout: {
		title: "Checkout | Exer Institute",
		description: "Start the checkout process to purchase shiny neurons."
	},
	savePaymentMethod: {
		title: "Save Payment Method | Exer Institute",
		description: "Save your preferred payment method to easily schedule tutoring sessions, handle refunds, and receive compensations."
	},
	paymentConfirmation: {
		title: "Payment Confirmation | Exer Institute",
		description: "Confirm the status of the checkout process."
	},
	stripeConnect: {
		title: "Stripe Connect | Exer Institute",
		description: "Create your Stripe Connect account to receive payouts."
	},
	subscription: {
		title: "Subscription | Exer Institute",
		description: "The best packages for any learning needs."
	},
	/*************************** Footer linked pages **************************/
	aboutUs: {
		title: "About Us | Exer Institute",
		description: "Discover what sets Exer Institute apart from the competition. Learn how we're revolutionizing online learning as we know. Become part of our movement that empowers educators around the world to create high quality learning content that touches and improves learner's lives. Based on the latest research in psychology, neuroscience, sociology, and pedagogy, we are ensuring that every learner gets the best chance to become whoever they want to be."
	},
	landingLearner: {
		title: "Online tutoring, educational videos, interactive articles, & practice problems | Exer Institute",
		description: "Find highly qualified and experienced tutors online. Watch educational videos. Learn from interactive articles. Gain mastery from practice problems."
	},
	landingTutor: {
		title: "Top tutoring rates, variable pricing per topic, & granular scheduling | Exer Institute",
		description: "Earn up to 95% of your tutoring rate per topic and maximize your availability and client retention with granular 15-minute interval scheduling."
	},
	fourOfour: {
		title: "404 Not Found | Exer Institute",
		description: "Url not found. Please go back to the root page."
	},
	/*************************** Policies **************************/
	termsNConditions: {
		title: "Terms & Conditions | Exer Institute",
		description: "Exer Institute's terms and conditions for platform use, including our contact information."
	},
	privacyPolicy: {
		title: "Privacy Policy | Exer Institute",
		description: "Exer Institute's user privacy policies and rights, including our content information."
	},
	tutorPolicy: {
		title: "Tutor Policy | Exer Institute",
		description: "Exer Institute's tutor policies, rights, and guidelines."
	},
	tuteePolicy: {
		title: "Tutee Policy | Exer Institute",
		description: "Exer Institute's tutee policies, rights, and guidelines."
	},
};