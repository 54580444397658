import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import Alert, { ERROR, SUCCESS } from "templates/Alert";
import Dropdown from "templates/Dropdown";
import CancellableDropdownValues from "./components/CancellableDropdownValues";
import Schedule from "./components/Schedule";

import messageMap from "Utilities/MessageMaps";
import { SAVE_RELOAD_TIMEOUT } from "Utilities/Constants/TimeoutConstants"
import { checkHasExplicitWords } from "Utilities/Validators/ContentValidator.js";
import { 
	transformCancellableStringToArray, transformCancellableDataToString,
	transformScheduleStringToObject, transformScheduleDataToString
} from "./utilities/TutoringSubPageUtilities";
import { fullCountriesList, countriesToAlphaCode, countryAlphaCodeToName } from "Utilities/Constants/CountriesConstants";
import { 
	GRADE,
	COUNTRY, 
	DISABILITIES, FLUENT_LANGUAGES, PREFERRED_STUDY_TIMES,
	HOBBIES_INTERESTS, HABITS_ROUTINES
} from "Utilities/Constants/MediaConstants";

import {
	saveLearnerProfileAPI, getLearnerProfileAPI
} from "apis/controllers/person/LearnerProfileController";


function LearnerSubPage(props) {

	const ownerId = props.ownerId || localStorage.getItem("ownerId");

	// input text
	const [gradeInputValue, setGradeInputValue] = useState(),
		[hobbiesInterestsInputValue, setHobbiesInterestsInputValue] = useState(),
		[habitsRoutinesInputValue, setHabitsRoutinesInputValue] = useState(),
		// dropdown values
		[countryValue, setCountryValue] = useState(props.country),
		[fluentLanguagesValue, setFluentLanguagesValue] = useState(),
		[schedulesValue, setSchedulesValue] = useState(),
		[disabilitiesValue, setDisabilitiesValue] = useState();
	
	// DOM states
	const [fluentLanguagesDOM, setFluentLanguagesDOM] = useState(),
		[learnerDisabilitiesDOM, setLearnerDisabilitiesDOM] = useState(),
		[schedulesDOM, setSchedulesDOM] = useState();

	useEffect(() => {
		getLearnerProfile();
	}, []);

	function getLearnerProfile() {
		const pathVariables = {
			learnerOwnerId: ownerId
		};
		getLearnerProfileAPI(pathVariables, resp => {
			const {
				grade,
				country,
				fluentLanguages, disabilities,
				preferredStudyTimes,
				hobbiesInterests, habitsRoutines
			} = resp;

			setGradeInputValue(grade);

			setCountryValue(country);
			const fluentLanguagesArray = transformCancellableStringToArray(fluentLanguages);
			setFluentLanguagesValue(fluentLanguagesArray);
			const disabilitiesArray = transformCancellableStringToArray(disabilities);
			setDisabilitiesValue(disabilitiesArray);
			const scheduleObject = transformScheduleStringToObject(preferredStudyTimes);
			setSchedulesValue(scheduleObject);

			setHobbiesInterestsInputValue(hobbiesInterests);
			setHabitsRoutinesInputValue(habitsRoutines);

			setFluentLanguagesDOM(
				<CancellableDropdownValues cancellableValues={fluentLanguagesArray} setCancellableValues={setFluentLanguagesValue}
					cancellableValueLimit={10} valueType="learnerLanguages" />
			);
			setLearnerDisabilitiesDOM(
				<CancellableDropdownValues cancellableValues={disabilitiesArray} setCancellableValues={setDisabilitiesValue}
					valueType="learnerDisabilities" />
			);
			setSchedulesDOM(
				<Schedule setModal={props.setModal} setAlert={props.setAlert}
					schedules={scheduleObject} setSchedules={setSchedulesValue}
					customTitle={messageMap("profilePage.learner.timeOfStudyOn", "generic")}
					customLabel={messageMap("profilePage.learner.timeOfStudy", "generic")}
					isRequired={false} showTooltip={false} />
			);
		});
	}

	function closeAlert() {
		props.setAlert(null);
	}

	function saveCountry(e) {
		const chosenCountry = e.target.getAttribute("countrykey");

		if (chosenCountry === "chooseCountry") {
			return;
		}

		setCountryValue(countriesToAlphaCode[chosenCountry]);
	}

	function saveInput(e, inputType, checkForExplicit) {
		const inputSetterMap = {
			grade: setGradeInputValue,
			hobbiesInterests: setHobbiesInterestsInputValue,
			habitsRoutines: setHabitsRoutinesInputValue
		};

		const inputValue = e.target.value;

		if (checkForExplicit && checkHasExplicitWords(inputValue)) {
			e.target.value = "";

			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("input.explicit.text", "validation")} />
			);
		}
		else {
			inputSetterMap[inputType](inputValue);
		}
	}

	function saveLearnerProfile(e) {
		let formDataPayload = {
			[GRADE]: gradeInputValue,
			[COUNTRY]: countryValue,
			[FLUENT_LANGUAGES]: transformCancellableDataToString(fluentLanguagesValue),
			[PREFERRED_STUDY_TIMES]: transformScheduleDataToString(schedulesValue),
			[DISABILITIES]: transformCancellableDataToString(disabilitiesValue),
			[HOBBIES_INTERESTS]: hobbiesInterestsInputValue,
			[HABITS_ROUTINES]: habitsRoutinesInputValue
		};

		let fieldsToValidate = [];
		for (const [key, value] of Object.entries(formDataPayload)) {
			(value || value === false) && fieldsToValidate.push(key);
		}

		saveLearnerProfileAPI({ownerId: ownerId}, formDataPayload, fieldsToValidate, resp => {
			let allMsgs = [];
			let alertType = "";

			if (resp["SUCCESS"]) {
				alertType = SUCCESS;
				resp["SUCCESS"].split("_").forEach(msg => {
					allMsgs.push(messageMap(msg, "api"));
				});
			}

			if (allMsgs.length) {
				props.setAlert(
					<Alert type={alertType} closeHandler={closeAlert} msg={allMsgs.join(" ")} />
				);
			}

			if (resp["SUCCESS"]) {
				setTimeout(() => {
					window.location.reload();
				}, SAVE_RELOAD_TIMEOUT);
			}
		});
	}

	return (
		<div className="edit-learner-profile">
			<h1 className="page-title">
				{messageMap("profile.learner", "subPageNavigation")}
			</h1>

			<div className="page-subheader">
				{messageMap("profilePage.learner.subheader", "generic")}
			</div>

			<div className="page-field">
				<label className="page-field-label" htmlFor="gradeYearFormLevel">
					{messageMap("profilePage.learner.grade", "generic")}
				</label>
				<div className="inline-elements">
					<input id="gradeYearFormLevel" className="page-field-value" type="text"
						placeholder={gradeInputValue ? gradeInputValue : messageMap("learner.profile.grade", "labelPlaceholder")}
						maxLength={75} onChange={e => saveInput(e, "grade", true)}
						value={gradeInputValue}>
					</input>
				</div>
			</div>

			<div className="page-field">
				<label className="page-field-label">
					<div className="div-label">
						{messageMap("profilePage.learner.country", "generic")}
					</div>
				</label>
				<div className="inline-elements">
					<Dropdown customDropdownItemAttribute="countrykey" customContainerClass="tutoring-application no-right-margin"
						dropdownOptions={fullCountriesList} dropdownItemClickHandler={saveCountry}
						preselectedKey={countryValue ? countryAlphaCodeToName[countryValue] : null} />
				</div>
			</div>

			{fluentLanguagesDOM}

			{schedulesDOM}

			{learnerDisabilitiesDOM}

			<div className="page-field">
				<label className="page-field-label" htmlFor="bio">
					<div className="div-label">
						{messageMap("profilePage.learner.hobbiesIntegers", "generic")}
					</div>
				</label>
				<div className="inline-elements">
					<textarea id="bio" className="page-field-value textarea" type="text"
						placeholder={hobbiesInterestsInputValue ? hobbiesInterestsInputValue : messageMap("profilePage.learner.hobbiesIntegers", "generic")}
						maxLength={500}
						onChange={e => saveInput(e, "hobbiesInterests", true)}
						value={hobbiesInterestsInputValue}>
					</textarea>
				</div>
			</div>

			<div className="page-field">
				<label className="page-field-label" htmlFor="bio">
					<div className="div-label">
						{messageMap("profilePage.learner.habitsRoutines", "generic")}
					</div>
				</label>
				<div className="inline-elements">
					<textarea id="bio" className="page-field-value textarea" type="text"
						placeholder={habitsRoutinesInputValue ? habitsRoutinesInputValue : messageMap("profilePage.learner.habitsRoutines", "generic")}
						maxLength={500}
						onChange={e => saveInput(e, "habitsRoutines", true)}
						value={habitsRoutinesInputValue}>
					</textarea>
				</div>
			</div>

			<button className="save-button" onClick={saveLearnerProfile}>
				{messageMap("save.edits", "button")}
			</button>
		</div>
	);
}

LearnerSubPage.propTypes = {
	country: PropTypes.string,

	// parent function handlers
	setAlert: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,

	// redux states
	ownerId: PropTypes.string.isRequired
};

export default connect(
	account, null
)(LearnerSubPage);
