import React, {useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";

import Dropdown from "templates/Dropdown";

import messageMap from "Utilities/MessageMaps";
import {disabilities} from "pages/Profile/subPages/utilities/TutoringSubPageConstants";
import { spokenLanguages } from "Utilities/Constants/LanguageConstants";

import closeAsset from "assets/icons/common/close.svg";


function CancellableDropdownValues(props) {

	const DROPDOWN_SETS = {
		languages: spokenLanguages,
		learnerLanguages: spokenLanguages,
		disabilities: disabilities,
		learnerDisabilities: disabilities
	};
	const DROPDOWN_KEYS = {
		languages: "languageKey",
		learnerLanguages: "learnerLanguages",
		disabilities: "disabilityKey",
		learnerDisabilities: "learnerDisabilities"
	};
	const DROPDOWN_DEFAULTS = {
		languages: "chooseLanguage",
		learnerLanguages: "chooseLanguage",
		disabilities: "chooseDisability",
		learnerDisabilities: "chooseDisability"
	};
	const DROPDOWN_LABEL = {
		languages: messageMap("profilePage.tutoring.modal.application.fluentLanguages", "generic"),
		learnerLanguages: messageMap("profilePage.learner.fluentLanguages", "generic"),
		disabilities: messageMap("profilePage.tutoring.modal.application.disabilities", "generic"),
		learnerDisabilities: messageMap("profilePage.learner.disabilities", "generic")
	};

	const [cancellableValues, setCancellableValues] = useState(),
		[hasHitMaxAllowedValues, setHasHitMaxAllowedValues] = useState(false);

	const cancellableValuesRef = useRef([]);

	useEffect(() => {
		setSavedValues();
	}, []);

	function setSavedValues() {
		if (props.cancellableValues && props.cancellableValues.length) {
			cancellableValuesRef.current = props.cancellableValues;

			const chosenCancellableValuesDOM = createCancellableValues();
			setCancellableValues(chosenCancellableValuesDOM);
		}
	}

	function showCancellableValues(e) {
		const cancellableValue = e.target.getAttribute(DROPDOWN_KEYS[props.valueType]);

		if (cancellableValue === DROPDOWN_DEFAULTS[props.valueType]) {
			return;
		}

		cancellableValuesRef.current = cancellableValuesRef.current.includes(cancellableValue) ? cancellableValuesRef.current : cancellableValuesRef.current.concat(cancellableValue);
		props.setCancellableValues(cancellableValuesRef.current);

		const chosenCancellableValuesDOM = createCancellableValues();
		setCancellableValues(chosenCancellableValuesDOM);

		cancellableValuesRef.current.length === props.cancellableValueLimit && setHasHitMaxAllowedValues(true);
	}

	function removeCancellableValue(cancellableValue) {
		cancellableValuesRef.current.splice(cancellableValuesRef.current.indexOf(cancellableValue), 1);
		props.setCancellableValues(cancellableValuesRef.current);

		const chosenCancellableValuesDOM = createCancellableValues();
		setCancellableValues(chosenCancellableValuesDOM);
		setHasHitMaxAllowedValues(false);
	}

	function createCancellableValues() {
		let chosenCancellableValuesDOM = [];
		const chosenCancellableValuesList = cancellableValuesRef.current;
		for (let i = 0; i < chosenCancellableValuesList.length; ++i) {
			const savedValue = chosenCancellableValuesList[i];

			chosenCancellableValuesDOM.push(
				<div key={savedValue} className="cancellable-values-container">
					<div className="cancellable-value">
						{savedValue}
					</div>
					<button className="remove-cancellable-value" onClick={e => removeCancellableValue(savedValue)}>
						<img className="x-icon" src={closeAsset} alt="ex icon"></img>
					</button>
				</div>
			);
		}

		return chosenCancellableValuesDOM;
	}

	return (
		<div className="page-field multi-lines">
			<label className="page-field-label">
				{DROPDOWN_LABEL[props.valueType]}
			</label>
			<div className="inline-elements">
				<div className="multi-line-inlines">
					<Dropdown customDropdownItemAttribute={DROPDOWN_KEYS[props.valueType]}
						customContainerClass={`tutoring-application no-right-margin ${hasHitMaxAllowedValues && "not-allowed"}`}
						dropdownOptions={DROPDOWN_SETS[props.valueType]} dropdownItemClickHandler={showCancellableValues} />
					{cancellableValues}
				</div>
			</div>
		</div>
	);
}

CancellableDropdownValues.propTypes = {
	valueType: PropTypes.string.isRequired,
	cancellableValues: PropTypes.array,
	cancellableValueLimit: PropTypes.number,
	setCancellableValues: PropTypes.func.isRequired
};

export default CancellableDropdownValues;

