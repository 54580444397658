import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";


function Timer(props) {

	const [seconds, setSeconds] = useState(0),
		[minutes, setMinutes] = useState(0),
		[hours, setHours] = useState(0);

	useEffect(() => {
		const intervalId =  setInterval(() => incrementTime(), 1000);

		return () => {
			clearInterval(intervalId);
		}
	}, []);

	function incrementTime() {
		let addedNewTime = Date.now() + 1000 - props.startingTime;
		const minutes = Math.floor((addedNewTime / 60000) % 60);

		setHours(Math.floor((addedNewTime / 3600000) % 24));
		setMinutes(minutes);
		setSeconds(Math.floor((addedNewTime / 1000) % 60));

		const actualMinutes = Math.floor((addedNewTime / 3600000) % 24) * 60 + minutes;
		// assumes that there is only 2 time triggers and that the second one would always be the session's duration
		// it's important that we rely on using the second time trigger as a minimum so that we don't make numerous duplicate calls
		if (props.timeTriggers.indexOf(actualMinutes) !== -1
		|| (props.timeTriggers[1] <= actualMinutes)) {
			props.timeTriggerHandler(actualMinutes);
		}
	}

	return (
		<div className="timer">
			<div className="time-number">
				{`${hours} ${messageMap("timer.hour", "generic")}`}
			</div>
			<div className="colon">
				:
			</div>
			<div className="time-number">
				{`${minutes} ${messageMap("timer.minute", "generic")}`}
			</div>
			<div className="colon">
				:
			</div>
			<div className="time-number">
				{`${seconds} ${messageMap("timer.second", "generic")}`}
			</div>
		</div>
	);
}

Timer.defaultProps = {
	startingTime: new Date()
};

Timer.propTypes = {
	startingTime: PropTypes.number,
	timeTriggers: PropTypes.array.isRequired,
	timeTriggerHandler: PropTypes.func.isRequired
};

export default Timer;
