import React, {useEffect, useState, useRef, Fragment} from "react";
import PropTypes from "prop-types";

import Dropdown from "templates/Dropdown";
import CancellableSubjectTopicProof from "./CancellableSubjectTopicProof";

import messageMap from "Utilities/MessageMaps";
import {
	teachableSubjects, topicsInSubjects, topicsDifficultyOrder
} from "pages/Profile/subPages/utilities/TutoringSubPageConstants";

export const PROOF_OF_KNOWLEDGE_N_EXPERIENCE = "qualify";

function SubjectTopicDropdowns(props) {

	const [priceableTopicCeiling, setPriceableTopicCeiling] = useState(),
		[newLineDropdown, setNewLineDropdown] = useState(),
		[subjectTopicUploads, setSubjectTopicUploads] = useState(props.subjectTopicExpProofs ? props.subjectTopicExpProofs : {}),
		[cancellableSubjectTopicProof, setCancellableSubjectTopicProof] = useState();

	useEffect(() => {
		setSavedValues();
	}, []);

	function setSavedValues() {
		if (props.preSelectedTopic) {
			if (props.useNewLine) {
				const topicLabel = (
					<label className="page-field-label">
						{props.separateTopicLabel}
					</label>
				);

				setNewLineDropdown(
					<Fragment>
						{topicLabel}
						<div className="inline-elements">
							<Dropdown key={props.preSelectedSubject} dropdownItemClickHandler={e => savePriceableTopics(e, topicsInSubjects[props.preSelectedSubject], props.preSelectedSubject)}
								customContainerClass="tutoring-application no-right-margin" dropdownOptions={topicsInSubjects[props.preSelectedSubject]}
								customDropdownItemAttribute="topTopic" preselectedKey={props.preSelectedTopic}/>
						</div>
					</Fragment>
				);
			}
			else {
				setPriceableTopicCeiling(
					<Dropdown key={props.preSelectedTopic} customDropdownItemAttribute="topTopic" customContainerClass="tutoring-application no-right-margin"
						dropdownItemClickHandler={e => savePriceableTopics(e, topicsInSubjects[props.preSelectedSubject], props.preSelectedSubject)}
						dropdownOptions={topicsInSubjects[props.preSelectedSubject]}
						preselectedKey={props.preSelectedTopic} />
				);
			}
		}
		if (subjectTopicUploads) {
			setCancellableSubjectTopicProof(
				createCancellableSubjectTopicProofInputs(subjectTopicUploads)
			);
		}
	}

	// is only used when tutor is applying for the first time
	function savePriceableTopics(e, topicsInSubject, subjectName) {
		const topTopic = e.target.getAttribute("topTopic");
		const topTopicDifficulty = topicsDifficultyOrder[subjectName][topTopic];
		let teachableTopics = {};

		for (const [key, value] of Object.entries(topicsInSubject)) {
			// the 'key === topTopic' part is so that we don't include topics with the same difficulty
				if (topicsDifficultyOrder[subjectName][key] < topTopicDifficulty || key === topTopic) {
				teachableTopics[key] = value;
			}
		}

		saveChosenTopics(subjectName, topTopic, teachableTopics);

		if (props.usage === PROOF_OF_KNOWLEDGE_N_EXPERIENCE) {
			let updatedSubjectTopic = subjectTopicUploads;
			updatedSubjectTopic[`${subjectName}-${topTopic}`] = teachableTopics[topTopic];
			setCancellableSubjectTopicProof(
				createCancellableSubjectTopicProofInputs(updatedSubjectTopic)
			);
		}
	}

	function createCancellableSubjectTopicProofInputs(subjectTopicUploadEntries) {
		let cancellableDivs = [];

		for (const [key, value] of Object.entries(subjectTopicUploadEntries)) {
			const subjectTopic = key.split("-");
			const subject = subjectTopic[0];
			const topic = subjectTopic[1];
			const previouslySavedLink = !value.includes(".") ? null : value;

			cancellableDivs.push(
				<CancellableSubjectTopicProof key={`${subject}-${topic}`}
					setModal={props.setModal}
					removeSubjectTopicProof={removeCancellableSubjectTopicProof}
					addSubjectTopicProof={addCancellableSubjectTopicProof}
					previouslySavedLink={previouslySavedLink} subjectTopicReviewStatus={props.subjectTopicReviewStatus}
					subject={subject} topic={topic} />
			);
		}

		return cancellableDivs;
	}
	function addCancellableSubjectTopicProof(subjectTopic, linkProof) {
		let updatedSubjectTopic = subjectTopicUploads;
		updatedSubjectTopic[subjectTopic] = linkProof;

		setSubjectTopicUploads(updatedSubjectTopic);

		props.setSubjectTopicExpProofs(updatedSubjectTopic);
	}
	function removeCancellableSubjectTopicProof(uploadKeyToRemove) {
		let updatedSubjectTopic = subjectTopicUploads;
		delete updatedSubjectTopic[uploadKeyToRemove];

		setCancellableSubjectTopicProof(createCancellableSubjectTopicProofInputs(updatedSubjectTopic));

		props.setSubjectTopicExpProofs(updatedSubjectTopic);
	}

	function saveChosenTopics(subjectName, topTopic, teachableTopics) {
		let updatedSubjectsToTeach = {
			[subjectName]: Object.keys(teachableTopics)
		};

		if (props.usage !== PROOF_OF_KNOWLEDGE_N_EXPERIENCE) {
			if (props.setSubjectsToTopics) {
				if (props.singleTopic) {
					updatedSubjectsToTeach = {
						[subjectName]: topTopic
					};
					props.setSubjectsToTopics(updatedSubjectsToTeach);
				}
				else {
					props.setSubjectsToTopics(updatedSubjectsToTeach);
				}
			}
		}
	}

	function showAvailableTopics(e) {
		const subjectName = e.target.getAttribute("subjectKey");

		if (subjectName === "chooseSubject") {
			return;
		}

		let topicLabel;
		if (props.separateTopicLabel) {
			topicLabel = (
				<label className="page-field-label">
					{props.separateTopicLabel}
				</label>
			);
		}

		// pre-select default initial topic
		saveChosenTopics(subjectName, Object.keys(topicsInSubjects[subjectName])[0], topicsInSubjects[subjectName]);

		if (props.useNewLine) {
			setNewLineDropdown(
				<Fragment>
					{topicLabel}
					<div className="inline-elements">
						<Dropdown key={subjectName} dropdownItemClickHandler={e => savePriceableTopics(e, topicsInSubjects[subjectName], subjectName)}
							customContainerClass="tutoring-application no-right-margin" dropdownOptions={topicsInSubjects[subjectName]}
							customDropdownItemAttribute="topTopic"/>
					</div>
				</Fragment>
			);
		}
		else {
			setPriceableTopicCeiling(
				<Fragment>
					{topicLabel}
					<Dropdown key={subjectName} dropdownItemClickHandler={e => savePriceableTopics(e, topicsInSubjects[subjectName], subjectName)}
						customContainerClass="tutoring-application no-right-margin" dropdownOptions={topicsInSubjects[subjectName]}
						customDropdownItemAttribute="topTopic"/>
				</Fragment>
			);
		}
	}

	return (
		<Fragment>
			<div>
				<div className={`page-field ${props.customClass && props.customClass}`}>
					<label className="page-field-label">
						{props.defaultLabel}
					</label>
					<div className="inline-elements">
						<div className="multi-line-inlines">
							<div className="inline-elements width-auto">
								<Dropdown customDropdownItemAttribute="subjectKey"
									customContainerClass={`tutoring-application ${props.disableSubjectDropdown && "not-allowed"} ${props.useNewLine && "no-right-margin"}`}
									dropdownOptions={teachableSubjects} useNewLine={true} dropdownItemClickHandler={showAvailableTopics}
									preselectedKey={props.preSelectedSubject} />
								{priceableTopicCeiling}
							</div>

							{cancellableSubjectTopicProof}
						</div>
					</div>
				</div>

				<div className={`page-field ${props.customClass && props.customClass}`}>
					{newLineDropdown}
				</div>
			</div>
		</Fragment>
	);
}

SubjectTopicDropdowns.defaultProps = {
	preSelectedSubject: null,
	defaultLabel: (
		<Fragment>
			<div className="div-label">
				{messageMap("profilePage.tutoring.modal.application.topicSubject", "generic")}
			</div>
			*
		</Fragment>
	),
	useNewLine: false
};

SubjectTopicDropdowns.propTypes = {
	// parent setter
	setModal: PropTypes.func,
	setSubjectsToTopics: PropTypes.func,
	setSubjectTopicExpProofs: PropTypes.func,
	subjectTopicExpProofs: PropTypes.object,
	subjectTopicReviewStatus: PropTypes.object,

	customClass: PropTypes.string,
	isModal: PropTypes.bool,
	usage: PropTypes.string,

	preSelectedTopic: PropTypes.string,
	preSelectedSubject: PropTypes.string,
	isApplication: PropTypes.bool.isRequired,
	disableSubjectDropdown: PropTypes.bool,

	defaultLabel: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object
	]),
	separateTopicLabel: PropTypes.string,
	useNewLine: PropTypes.bool,
	singleTopic: PropTypes.bool
};

export default SubjectTopicDropdowns;

