import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import Card from "templates/cards/Card";
import Search from "templates/Search";

import messageMap from "Utilities/MessageMaps";
import {getAllAvailableTopics} from "pages/Classes/TopicsInfoList/utilities/TopicsInfoUtilities";


function TopicsInfoList(props) {

	const history = useHistory();

	const [topicsInfoList, setTopicsInfoList] = useState(),
		[searchDom, setSearchDom] = useState(),
		[currentPage, setCurrentPage] = useState(0);

	useEffect(() => {
		createSearchLists();
	}, []);

	useEffect(() => {
		createTopicInfoCards();
	}, [currentPage]);


	function createSearchLists() {

	}

	function createTopicInfoCards() {
		const availableTopics = getAllAvailableTopics();

		let topicCards = [];
		availableTopics.forEach(topic => {
			const topicKey = Object.keys(topic)[0];
			const href = `/topic-info/${topic[topicKey].id.replaceAll(" ", "-")}?subject=${topic.subject}`;
			const linkState = {
				topicId: topic[topicKey].id,
				topicDetails: topic[topicKey],
				workbook: topic.workbook,
				subject: topic.subject,
				isOnJourney: false
			};

			topicCards.push(
				<Card key={topic[topicKey].id} domKey={topic[topicKey].id} href={href} linkState={linkState}
					thumbnailSrc={topic[topicKey].thumbnail} thumbnailAlt={topic[topicKey].thumbnailAlt}
					title={topic[topicKey].title} customTitle="title-font-size-20" customTitleContainer="title-container-margin-bottom-8"
					description={topic[topicKey].description}/>
			);
		});

		setTopicsInfoList(topicCards);
	}

	return (
		<div className="topics-info-list">
			<Helmet>
				<title>{messageMap("topicsInfoList.title", "headerTag")}</title>
				<meta name="description" content={messageMap("topicsInfoList.description", "headerTag")}></meta>
			</Helmet>

			<h1 className="title">
				{messageMap("topicsInfoListPage.title", "generic")}
			</h1>

			{searchDom}

			<div className="list">
				{topicsInfoList}
			</div>

		</div>
	)
}

export default TopicsInfoList;