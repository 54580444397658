/* eslint-disable no-template-curly-in-string */
import {LEARNER_PROFILE_FORM_DATA} from "Utilities/Constants/MediaConstants";

import request, {GET, POST} from "Utilities/Fetches";
import {getFormData} from "Utilities/Upload";
import {$replace} from "Utilities/Strings";

const BASE = "/services/learner/profile";

const SAVE_LEARNER_PROFILE = BASE + "/save?ownerId=${ownerId}";
const GET_LEARNER_PROFILE = BASE + "/get?learnerOwnerId=${learnerOwnerId}";


/**
 * @param {Object} pathVariables = {
 * 	ownerId: String
 * },
 * @param {Object} formDataPayload = {
 * 	grade: String,
 * 	country: String,
 * 	fluentLanguages: String,
 * 	preferredStudyTimes: String,
 * 	disabilities: String,
 * 	hobbiesInterests: String,
 * 	habitsRoutines: String
 * }
 */
export function saveLearnerProfileAPI(pathVariables, formDataPayload, relevantFields, callback) {
	const api = $replace(SAVE_LEARNER_PROFILE, pathVariables);

	const formData = getFormData(formDataPayload, relevantFields, LEARNER_PROFILE_FORM_DATA);
	request(api, POST, formData, callback, null, null, false);
}

/**
 * @param {Object} pathVariables = {
 * 	learnerOwnerId: String
 * }
 */
export function getLearnerProfileAPI(pathVariables, callback) {
	const api = $replace(GET_LEARNER_PROFILE, pathVariables);
	request(api, GET, null, callback);
}

