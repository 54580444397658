import React from "react";
import PropTypes from "prop-types";

import BenefitItem from "../components/BenefitItem";
import FooterCTA from "../components/FooterCTA";

import messageMap, {messageMapGeneric} from "Utilities/MessageMaps";

import upTo90Asset from "assets/images/home/upTo90.svg";
import pricePerTopicAsset from "assets/images/home/pricePerTopic.svg";
import fifteenSlotAsset from "assets/images/home/fifteenSlot.svg";
import tutorsVideoAsset from "assets/videos/tutorExplainer.mp4";


function LandingForTutors(props) {

	const detailsMap = [
		{
			header: messageMapGeneric("landing.tutors.detail1.header"),
			info: messageMapGeneric("landing.tutors.detail1.info"),
			linkText: messageMap("landing.tutor.details", "button"),
			linkPath: "/tutoring/tutorFAQs",
			imgSrc: upTo90Asset,
			imgAlt: messageMap("landing.tutor.upTo90", "image")
		}, {
			header: messageMapGeneric("landing.tutors.detail2.header"),
			info: messageMapGeneric("landing.tutors.detail2.info"),
			linkText: messageMap("landing.tutor.details", "button"),
			linkPath: "/tutoring/tutorsList",
			imgSrc: pricePerTopicAsset,
			imgAlt: messageMap("landing.tutor.pricePerTopic", "image")
		}, {
			header: messageMapGeneric("landing.tutors.detail3.header"),
			info: messageMapGeneric("landing.tutors.detail3.info"),
			linkText: messageMap("landing.tutor.details", "button"),
			linkPath: "/tutoring/tutorsList",
			imgSrc: fifteenSlotAsset,
			imgAlt: messageMap("landing.tutor.fifteenMinuteSlot", "image")
		}
	];

	return (
		<div className="more-details-body-container">
			{
				detailsMap.map((detail, key) =>
					<BenefitItem key={`learnerBenefit_${key}`} 
						header={detail.header} info={detail.info}
						linkText={detail.linkText} linkPath={detail.linkPath}
						imgSrc={detail.imgSrc} imgAlt={detail.imgAlt} />
				)
			}

			<div className="how-tutoring-works">
				<div className="how-tutoring-works-title">
					{messageMapGeneric("landing.videoTutoringHeader")}
				</div>
				<video className="video" controls controlsList="nodownload" disablePictureInPicture>
					<source src={tutorsVideoAsset} type="video/mp4"/>
					<track default kind="captions" srcLang="en"/>
				</video>
			</div>

			<FooterCTA header={messageMapGeneric("landing.tutors.footer.header")}
				subHeader={messageMapGeneric("landing.tutors.footer.subHeader")}
				signUpText={messageMap("landing.tutor.signUp", "button")}
				setSignUpModal={props.setSignUpModal} setLoginModal={props.setLoginModal} />
		</div>
	);
}

LandingForTutors.propTypes = {
	setSignUpModal: PropTypes.func.isRequired,
	setLoginModal: PropTypes.func.isRequired
};

export default LandingForTutors;