import React, { Fragment, useState, useRef } from "react";
import PropTypes from "prop-types";

import Tooltip from "templates/Tooltip";

import { validateInPersonTutoringRadius } from "pages/Profile/subPages/utilities/TutoringSubPageUtilities";
import { onKeyDown } from "Utilities/Accessibility";
import messageMap from "Utilities/MessageMaps";

import labelInfoAsset from "assets/icons/common/labelInfo.svg";


function DropdownOption(props) {

	const [additionalInput, setAdditionalInput] = useState(),
		[tooltip, setTooltip] = useState();

	const additionalInputValueRef = useRef();

	function selectionClickHandler(event) {
		if (props.inputTexts != null) {
			const isChecked = event.target.checked;
			if (isChecked || isChecked == null) {
				setAdditionalInput(
					<div className="additional-input-container">
						{
							props.inputTexts.inputHeader
							&& (
								<div className="input-header">
									{props.inputTexts.inputHeader}
								</div>
							)
						}
						<div>
							<input type="number" min={5} max={75} step={5}
								ref={additionalInputValueRef}
								className="additional-input" >
							</input>
							{
								props.inputTexts.inputText
								&& (
									<div className="input-text">
										{props.inputTexts.inputText}
									</div>
								)
							}
							<button className="go-button" onClick={passInputToItemClickHandler}
								{...props.customAttributes}
								domKey={props.domKey} origKey={props.origKey}>
								{messageMap("go.text", "button")}
							</button>
						</div>
					</div>
				);
			}
			else {
				setAdditionalInput(null);
				props.dropdownItemClickHandler(event, props.origKey, null);
			}
		}
		else {
			props.dropdownItemClickHandler(event);
		}
	}
	function passInputToItemClickHandler(e) {
		const inputValue = additionalInputValueRef.current.value;
		if (validateInPersonTutoringRadius(inputValue, resetInputValue, placeholderHandler, props.setAlert, closeAlert)) {
			props.dropdownItemClickHandler(e, props.origKey, inputValue);
		}
	}
	function resetInputValue() {
		additionalInputValueRef.current.value = 5;
	}
	function placeholderHandler() {}
	function closeAlert() {
		props.setAlert(null);
	}

	function toggleTooltip(showOrHide) {
		if (showOrHide === "show") {
			setTooltip(
				<Tooltip classStr="tooltip-bottom-left tutoring-filters" subheader={props.inputTooltip} />
			);
		}
		else {
			setTooltip(null);
		}
	}

	return (
		<Fragment>
			<div {...props.customAttributes} tabIndex={0} role="button"
				onClick={selectionClickHandler}
				onKeyDown={e => onKeyDown(e, selectionClickHandler, [e])}
				className="option-container">
				{props.innerHtml}
				{
					props.inputTooltip
					&& (
						<Fragment>
							<img className="label-info" src={labelInfoAsset} alt="information" tabIndex={0}
								onMouseOver={e => toggleTooltip("show")} onFocus={e => toggleTooltip("show")}
								onMouseLeave={e => toggleTooltip("hide")} onBlur={e => toggleTooltip("hide")} ></img>
							{tooltip}
						</Fragment>
					)
				}
			</div>

			{additionalInput}
		</Fragment>
	);
}

DropdownOption.defaultProps = {
};

DropdownOption.propTypes = {
	domKey: PropTypes.string.isRequired,
	origKey: PropTypes.string.isRequired,
	dropdownItemClickHandler: PropTypes.func.isRequired,
	innerHtml: PropTypes.any,
	customAttributes: PropTypes.object,
	inputTooltip: PropTypes.string,
	inputTexts: PropTypes.object,

	// parent setters props
	setAlert: PropTypes.func
};

export default DropdownOption;