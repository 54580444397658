import React, {useState, Fragment} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import {logInUser} from "redux/actions/actionTypes";

import {useGoogleLogin} from "@react-oauth/google";
import { 
	SIGN_UP_CONVERSION, recordConversion
} from "scripts/google/recordConversion";

import {checkHasExplicitWords} from "Utilities/Validators/ContentValidator.js";
import messageMap from "Utilities/MessageMaps";
import { correctFormFieldsInRed } from 'templates/utilities/ModalUtilities';
import { getTimezoneOffset } from 'templates/utilities/CalendarUtilities';
import { AUTO_RELOAD_TIMEOUT } from 'Utilities/Constants/TimeoutConstants';

import Alert, {SUCCESS, ERROR, INFORMATION} from "templates/Alert";
import Modal from "templates/Modal";
import Spinner from "templates/Spinner";
import {modalSchema} from "templates/schemas/navBarSchemas";
import TermsNConditionsModal from "templates/customModals/TermsNConditionsModal";
import PrivacyPolicyModal from "templates/customModals/PrivacyPolicyModal";

import {
	signUpAPI, getGoogleUserInfoAPI, usernameAvailabilityAPI
} from "apis/controllers/person/AccountsController";

import checkAsset from "assets/icons/home/check.svg";
import exAsset from "assets/icons/home/ex.svg";
import googleAsset from "assets/icons/thirdParty/google.svg";

/**
 * @description custom modal for Signing Up
 * @param {String} title modal's title
 * @param {String} subHeader? modal's subheader
 * @param {Function} closeModal for closing the modal
 */
function SignUpModal(props) {
	const history = useHistory();

	const [alert, setAlert] = useState(""),
		[anotherModal, setAnotherModal] = useState(""),
		[spinner, setSpinner] = useState("");

	const disclaimer = (
		<Fragment>
			<div>
				{messageMap("account.signUp.agreement.text", "generic")}
				<button className="terms-policy" onClick={() => openAnotherModal("terms")}>
					{messageMap("account.signUp.agreement.termOfUse", "generic")}
				</button>
				{messageMap("account.signUp.agreement.and", "generic")}
				<button className="terms-policy" onClick={() => openAnotherModal("privacy")}>
					{messageMap("account.signUp.agreement.privacyPolicy", "generic")}
				</button>
			</div>
		</Fragment>
	);

	const signUpModalSchema = modalSchema(props.closeModal, openAnotherModal, checkUsername)["signUp"];

	function signUp(signUpStates) {
		const usernameValue = signUpStates.signUpUser.value;

		if (checkHasExplicitWords(usernameValue)) {
			setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("input.explicit.input.username", "validation")}></Alert>
			);
			return;
		}

		const payload = {
			email: signUpStates.signUpEmail.value,
			password: signUpStates.signUpPass.value,
			username: signUpStates.signUpUser.value,
			accessKey: "",
			timezone: getTimezoneOffset()
		};
		const credentials = {
			username: signUpStates.signUpUser.value,
			password: signUpStates.signUpPass.value
		};

		setSpinner(<Spinner containerClass="signUp-spinner" />);

		signUpAPI(payload, credentials, data => {
			parseSignUpResponse(data, payload.email);
		});
	}

	function displaySignUpMessage(statusCode, email) {
		setSpinner(null);

		props.closeModal(null);
		let message = messageMap(statusCode, "api");
		message = message.replace("${email}", email);

		setAlert(
			<Alert closeHandler={closeAlert} type={SUCCESS} msg={message + " " +messageMap("accounts.update.reload", "api")}/>
		);
	}

	function displayErrorMessage(msg) {
		setSpinner(null);
		setAlert(
			<Alert closeHandler={closeAlert} type={ERROR} msg={messageMap(msg, "api")}/>
		);
	}

	function closeAlert () {
		setAlert(null);
	}

	function closeAnotherModal() {
		setAnotherModal(null);
	}

	function openAnotherModal(type) {
		const closeButtonStyle = {
			"position": "absolute",
			"marginLeft": "195px",
			"marginTop": "15px",
			"filter": "invert(1)"
		},
		modalContainerStyle = {
			"padding": "0px"
		};

		if (type === "terms") {
			setAnotherModal(
				<TermsNConditionsModal closeHandler={closeAnotherModal}/>
			);
		}
		else if (type === "privacy") {
			setAnotherModal(
				<PrivacyPolicyModal closeHandler={closeAnotherModal}/>
			);
		}
		else if (type === "signInPrivacy") {
			setAnotherModal(
				<Modal closeHandler={closeAnotherModal} closeType={"xButton"}
								closeButtonStyle={closeButtonStyle} modalContainerStyle={modalContainerStyle}>
					<div>
						{messageMap("account.signUp.privacy.data", "generic")}
					</div>
				</Modal>
			);
		}
	}

	function checkUsername(...args) {
		const target = args[0].target;
		usernameAvailabilityAPI({username: args[1].value}, (resp) => displayUsernameResultIcon(resp, target));
	}

	function displayUsernameResultIcon(resp, event) {
		// remove existing checks or exes icons
		event.parentNode.childNodes.forEach(dom => {
			if (dom.tagName === "IMG" && dom.className === "") {
				dom.remove();
			}
		});

		if (!resp) {
			// Apologies. I know this is an anti-pattern, but I don't know how to do this in the 'React' way
			let img = document.createElement("img")
			img.src = checkAsset;
			img.alt = "Check icon indicating that the username is available";
			img.style.display = "inline-block";
			img.style.marginLeft = "5px";
			img.style.height = "25px";
			event.parentNode.insertBefore(img, event.nextSibling);
		}
		else {
			// Apologies. I know this is an anti-pattern, but I don't know how to do this in the 'React' way
			let img = document.createElement("img")
			img.src = exAsset;
			img.alt = "Cross icon indicating that the username is not available";
			img.style.display = "inline-block";
			img.style.marginLeft = "5px";
			img.style.height = "25px";
			event.parentNode.insertBefore(img, event.nextSibling);
		}
	}

	const signInToGoogle = useGoogleLogin({
		onSuccess: (codeResponse) => googleSignUpSuccess(codeResponse),
		onError: (error) => googleLoginFailure
	});
	function googleLoginFailure(resp) {
		setAlert(
			<Alert type={ERROR} closeHandler={closeAlert}
				msg={messageMap("account.signUp.google.error", "generic") + resp} />
		);
	}
	function googleSignUpSuccess(resp) {
		getGoogleUserInfoAPI(resp.access_token, resp => {
			const payload = {
				email: resp.email,
				firstName: resp.given_name,
				lastName: resp.family_name,
				password: null,
				username: resp.name,
				accessKey: "",
				timezone: getTimezoneOffset(),
				googleId: resp.id
			};
			const credentials = {
				username: resp.name,
				password: null
			};

			setSpinner(<Spinner containerClass="signUp-spinner" />);
			signUpAPI(payload, credentials, resp => {
				parseSignUpResponse(resp, payload.email);
			});
		});
	}

	function parseSignUpResponse(resp, email) {
		let success = false;
		const statusCode = resp.statusCode;
		if (statusCode && statusCode.includes("failed")) {
			displayErrorMessage(statusCode);
		}
		else {
			recordConversion(SIGN_UP_CONVERSION);

			props.logInUser(resp);
			displaySignUpMessage(statusCode, email);
			const redirectLocation = props.redirectLocationAfterSignUp;
			if (success && redirectLocation) {
				setTimeout(() => history.push(redirectLocation), 0);// Message goes [Bzzt... Whirr...] if redirect isn't delayed.
			}
			setTimeout(() => {
				window.location.reload();
			}, AUTO_RELOAD_TIMEOUT);
		}
	}

	return (
		<div>
			<Modal stateProps={signUpModalSchema.stateProps}
				closeHandler={props.closeModal} submitHandler={signUp}
				subHeader={props.subHeader}
				title={props.title} titleStyle={{"width": "350px"}}
				inputs={signUpModalSchema.inputs} footer={disclaimer} errorMsg={correctFormFieldsInRed} 
				inputsToValidate={signUpModalSchema.stateProps.slice(0, signUpModalSchema.stateProps.length - 2)}>
				<div className="social-container">
					<button className="social-sign-up-in" onClick={signInToGoogle}>
						<img className="social-icon" src={googleAsset} alt={messageMap("thirdParty.google", "image")} />
						{messageMap("account.google.signUp", "button")}
					</button>
					<div className="or-container">
						{messageMap("account.signUp.or", "generic")}
					</div>
				</div>
			</Modal>
			{anotherModal}
			{alert}
			{spinner}
		</div>
	);
}

SignUpModal.propTypes = {
	title: PropTypes.string.isRequired,
	subHeader: PropTypes.any,
	closeModal: PropTypes.func.isRequired,
	redirectLocationAfterSignUp: PropTypes.string,

	// redux actions
	logInUser: PropTypes.func.isRequired
};

export default connect(
	null,
	{logInUser}
)(SignUpModal);