import React, { Fragment, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import DropdownOption from "./components/DropdownOption";

import { onKeyDown } from "Utilities/Accessibility";

import chevronAsset from "assets/icons/common/chevron.svg";


function Dropdown(props) {

	const [dropdownOptions, setDropdownOptions] = useState(),
		[selectedOption, setSelectedOption] = useState(),
		[selectedOptionAttribute, setSelectedOptionAttribute] = useState(),
		// specifically for filtering
		[checkedFilters, setCheckedFilters] = useState([]),
		[checkFilterValues, setCheckFilterValues] = useState({});

	const chevronRef = useRef(),
		dropdownOptionsRef = useRef();

	useEffect(() => {
		createDropdownOptions();
		props.preselectedKey && setPreselectedValue();
	}, []);

	function createDropdownOptions() {
		let dropdownItems = [];
		let selectedAttribute = {};
		let selectedOption;

		if (props.dropdownOptions) {
			Object.keys(props.dropdownOptions).forEach(element => {
				let customAttributes = {};
				if (props.customDropdownItemAttribute && !["key", "tabIndex", "role", "onClick", "onKeyDown"].includes(props.customDropdownItemAttribute)) {
					customAttributes = {
						[props.customDropdownItemAttribute.toLowerCase()]: element
					};
				}

				let innerHtml = props.dropdownOptions[element];
				let cleanedElement;
				if (props.filterMode) {
					cleanedElement = element.replaceAll(/[ &$-.]/g, "");
					for (let i = 0; i < cleanedElement.length; ++i) {
						// check if character is a number
						if (!isNaN(parseFloat(cleanedElement[i])) && isFinite(cleanedElement[i])) {
							cleanedElement = cleanedElement.replaceAll(cleanedElement[i], String.fromCharCode(96 + cleanedElement[i]));
						}
					}

					const idKey = `${cleanedElement}_${props.purpose}`;
					innerHtml = (
						<Fragment>
							{/* don't use id for <input/> and htmlFor <label/>, since it simulates a click, 
							which messes with props.dropdownItemClickHandler. To bypass this, we're relying on
							domkey for queryselectAll and keeping the original value in orgikey attribute */}
							<input type="checkbox" domkey={idKey} origkey={element}/>
							<label domkey={idKey} origkey={element}>
								{props.dropdownOptions[element]}
							</label>
						</Fragment>
					);
				}

				if ((props.filterMode && element !== props.excludeFromList)
						|| !props.filterMode) {

					let additionalOption = null;
					const dropdownOptionsWExtraInput = props.dropdownOptionsWExtraInput;
					if (dropdownOptionsWExtraInput != null && dropdownOptionsWExtraInput[element] != null) {
						additionalOption = dropdownOptionsWExtraInput[element];
					}

					let inputTooltip = null;
					const dropdownOptionsTooltip = props.dropdownOptionsTooltip;
					if (dropdownOptionsTooltip != null && dropdownOptionsTooltip[element] != null) {
						inputTooltip = dropdownOptionsTooltip[element];
					}

					dropdownItems.push(
						<DropdownOption key={element} domKey={`${cleanedElement}_${props.purpose}`} origKey={element}
							customAttributes={customAttributes}
							inputTexts={additionalOption} inputTooltip={inputTooltip}
							innerHtml={innerHtml} dropdownItemClickHandler={dropdownItemClickHandler}
							setAlert={props.setAlert} />
					);
				}
			});

			if (props.customDropdownItemAttribute && !["key", "tabIndex", "role", "onClick", "onKeyDown"].includes(props.customDropdownItemAttribute)) {
				selectedAttribute = {
					[props.customDropdownItemAttribute.toLowerCase()]: Object.keys(props.dropdownOptions)[0]
				};
			}

			selectedOption = Object.values(props.dropdownOptions)[0];
		}

		if (props.dropdownOptions) {
			selectedOption = Object.values(props.dropdownOptions)[0];
		}
		else {
			selectedOption = "";
		}

		setSelectedOptionAttribute(selectedAttribute);
		setSelectedOption(selectedOption);
		setDropdownOptions(dropdownItems);

		if (props.showDropdownOptions) {
			showDropdownOptions();
		}
	}

	function setPreselectedValue() {
		const selectedAttribute = {
			[props.customDropdownItemAttribute.toLowerCase()]: props.preselectedKey
		};
		const selectedOption = props.dropdownOptions[props.preselectedKey];

		setSelectedOptionAttribute(selectedAttribute);
		setSelectedOption(selectedOption);
	}

	function dropdownItemClickHandler(e, itemKey, additionalValue) {
		const target = e.target;

		let selectedOption;
		let selectedAttribute = {};
		if (props.customDropdownItemAttribute && !["key", "tabIndex", "role", "onClick", "onKeyDown"].includes(props.customDropdownItemAttribute)) {
			const customKey = target.getAttribute(props.customDropdownItemAttribute);
			selectedOption = props.dropdownOptions[customKey];

			selectedAttribute = {
				[props.customDropdownItemAttribute.toLowerCase()]: customKey
			};
		}
		else {
			selectedOption = target.innerText;
		}

		let copiedCheckedFilters = checkedFilters;
		let copiedCheckFilterValues = checkFilterValues;
		if (props.filterMode) {
			const domKey = target.getAttribute("domkey");
			const origKey = target.getAttribute("origKey");
			// non-React way, but it gets the job done really simply
			let inputEl = document.querySelectorAll(`input[domkey=${domKey}]`);

			if (copiedCheckedFilters.includes(origKey)) {
				copiedCheckedFilters.splice(copiedCheckedFilters.indexOf(origKey), 1);
				inputEl[0] && (inputEl[0].checked = false);
			}
			else {
				copiedCheckedFilters.push(origKey);
				inputEl[0] && (inputEl[0].checked = true);
			}

			setCheckedFilters(copiedCheckedFilters);

			if (itemKey != null && additionalValue != null) {
				copiedCheckFilterValues[itemKey] = additionalValue;
				setCheckFilterValues(copiedCheckFilterValues);
			}
		}

		if (!props.filterMode) {
			setSelectedOptionAttribute(selectedAttribute);
			setSelectedOption(selectedOption);
		}
		props.dropdownItemClickHandler && props.dropdownItemClickHandler(e, props.customDropdownItemAttribute, copiedCheckedFilters, copiedCheckFilterValues);
	}

	function toggleDropdownDisplay(e) {
		if (!props.customContainerClass.includes("not-allowed")) {
			const target = e.target;
			const attributeValue = target.getAttribute(props.customDropdownItemAttribute);
			if (props.filterMode
					&& ((!attributeValue && (["INPUT", "LABEL", "BUTTON"].includes(target.tagName)))
					|| (attributeValue && attributeValue !== props.excludeFromList && ["DIV", "BUTTON"].includes(target.tagName)))
				) {
				return;
			}
	
			if (dropdownOptionsRef.current.className === "dropdown-options hide") {
				if (props.hideAllDropdownsWhenToggling) {
					document.querySelectorAll(`div[class="dropdown-options"]`).forEach(openedDropdowns => {
						openedDropdowns.setAttribute("class", "dropdown-options hide");
					});
				}
	
				showDropdownOptions();
			}
			else {
				dropdownOptionsRef.current.className = "dropdown-options hide";
				chevronRef.current.className = "chevron-icon-down";
			}
		}
	}
	function showDropdownOptions() {
		dropdownOptionsRef.current.className = "dropdown-options";
		chevronRef.current.className = "chevron-icon-up";
	}

	return (
		<div className={`dropdown-container ${props.customContainerClass} ${props.filterMode && "filter-mode"}`}
			onClick={toggleDropdownDisplay} onKeyDown={e => onKeyDown(e, toggleDropdownDisplay, [e])}>
			<div className="dropdown-selector">
				<div ref={props.selectedOptionParentRef}  {...selectedOptionAttribute} >
					{selectedOption}
				</div>
				<img src={chevronAsset} alt="chevron" className="chevron-icon-down" ref={chevronRef}></img>
			</div>
			<div className="dropdown-options hide" ref={dropdownOptionsRef}>
				{dropdownOptions}
			</div>
		</div>
	);
}

Dropdown.defaultProps = {
	customContainerClass: "",
	dropdownOptions: {},
	filterMode: false,
	hideAllDropdownsWhenToggling: true,
	showDropdownOptions: false
};

Dropdown.propTypes = {
	preselectedKey: PropTypes.any,
	customContainerClass: PropTypes.string,
	dropdownOptions: PropTypes.object.isRequired,
	dropdownOptionsTooltip: PropTypes.object,
	dropdownOptionsWExtraInput: PropTypes.object,
	dropdownItemClickHandler: PropTypes.func,
	hideAllDropdownsWhenToggling: PropTypes.bool,
	showDropdownOptions: PropTypes.bool,
	customDropdownItemAttribute: PropTypes.string.isRequired,

	filterMode: PropTypes.bool,
	excludeFromList: PropTypes.string,
	purpose: PropTypes.string,

	// parent ref
	selectedOptionParentRef: PropTypes.object,

	// parent setter
	setAlert: PropTypes.func
};

export default Dropdown;


