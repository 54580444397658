import React, {useState, useRef, useEffect, Fragment} from 'react';
import PropTypes from "prop-types";

import { connect } from "react-redux";
import payingAccount from 'redux/selectors/payingAccountSelector';

import Alert, {SUCCESS, ERROR} from "templates/Alert";
import Modal from "templates/Modal";
import TextEditor from "templates/TextEditor";
import Tooltip from "templates/Tooltip";
import Topic from "pages/KnowledgeTree/Topic/Topic"
import Spinner from "templates/Spinner";

import {fileSizeConvert, MB, GB} from "Utilities/FileSizeConverter";
import {CATEGORIES} from "Utilities/Constants/MediaConstants";
import {imageValidator} from "Utilities/Validators/InputValidators";
import {checkHasExplicitWords} from "Utilities/Validators/ContentValidator.js";
import messageMap from "Utilities/MessageMaps";
import { onKeyDown, escape } from "Utilities/Accessibility";
import {createPrefixTree} from "Utilities/Algos";
import { videoProbe, validateVideoAndAudioCodecs } from 'Utilities/VideoUtilities';
import {getSubtreeFromId} from "diagrams/utilities/NetworkGenerator";
import { MODAL_CLOSE_TIMEOUT } from 'Utilities/Constants/TimeoutConstants';

import {
	VIDEO_TITLE, VIDEO_DURATION, VIDEO, THUMBNAIL, THUMBNAIL_DESCRIPTION,
	SUBTITLES, MENTAL_MODELS, PRICE, TOPIC_ID, TOPIC_TYPE, DESCRIPTION, CATEGORY
} from "Utilities/Constants/MediaConstants";

import getSubjectsMap, {getKeysToInitiallyHide} from "metadata/subjectsMap";

import {uploadVideoAPI, editUploadedVideoAPI} from "apis/controllers/video/VideoController";

import informationAsset from "assets/icons/alerts/information.svg";
import closeThinAsset from "assets/icons/common/close-thin.svg";
import { getPriceInShinyNeurons } from '../VideoListUtilities';
import { getFileFormatFromTargetFile } from 'Utilities/FileUtility';

/**
 * @description Serves as a component for uploading and editing video content. All
 * 							props are only used for editing video content
 * @param {String} videoId? // only used when editing an existing video
 * @param {String} videoTitle? // video's title
 * @param {String} videoFileName? // video file name, including its file extension
 * @param {String} thumbnailFileName? // thumbnail file name, including its file extension
 * @param {String} thumbnailDescription? // thumbnail description, for accessibility
 * @param {String} mentalModelFileName? // mentalModel file name, including its file extension
 * @param {String} subtitleFileName? // subtitle file name, including its file extension
 * @param {Number} price? // only used in paid videos
 * @param {String} videoDescription? // description of video content
 * @param {String} submitText? // custom text for submit button
 */
function VideoUpload(props) {

	const uploadOrEdit = props.purpose;

	const [spinner, setSpinner] = useState(""),
				[alert, setAlert] = useState(null),
				[alert1, setAlert1] = useState(null),
				// DOM setters
				[subjectSuggestionsDom, setSubjectSuggestionsDom] = useState(null),
				[subjectListDom, setSubjectListDom] = useState(null),
				[videoDescriptionDom, setVideoDescriptionDom] = useState(props.videoDescription ? props.videoDescription : null),
				[videoFileDisplay, setVideoFileDisplay] = useState(props.videoFileName ? props.videoFileName : messageMap("video.placeholder.files", "generic")),
				[thumbnailFileDisplay, setThumbnailFileDisplay] = useState(props.thumbnailFileName ? props.thumbnailFileName : messageMap("video.placeholder.files", "generic")),
				[thumbnailDescription, setThumbnailDescription] = useState(props.thumbnailDescription ? props.thumbnailDescription : messageMap("video.placeholder.thumbnail", "generic")),
				[subtitlesFileDisplay, setSubtitlesFileDisplay] = useState(props.subtitleFileName ? props.subtitleFileName : messageMap("video.placeholder.files", "generic")),
				[mentalModelFileDisplay, setMentalModelFileDisplay] = useState(props.mentalModelFileName ? props.mentalModelFileName : messageMap("video.placeholder.files", "generic")),
				[topicNameDisplay, setTopicNameDisplay] = useState(props.topicID ? props.topicID : messageMap("video.placeholder.topic", "generic")),
				[topicModal, setTopicModal] = useState(),
				[topicTooltip, setTopicTooltip] = useState(),
				[mentalModelTooltip, setMentalModelTooltip] = useState(),
				// actual files/values to upload
				[videoTitle, setVideoTitle] = useState(props.videoTitle ? props.videoTitle : null),
				[videoDuration, setVideoDuration] = useState(0),
				[uploadedVideo, setUploadedVideo] = useState(""),
				[uploadedThumbnail, setUploadedThumbnail] = useState(""),
				[uploadedThumbnailDescription, setUploadedThumbnailDescription] = useState(""),
				[uploadedSubtitles, setUploadedSubtitles] = useState(""),
				[uploadedMentalModel, setUploadedMentalModel] = useState([]),
				[price, setPrice] = useState(props.price ? props.price : null),
				[uploadVideoTopic, setUploadVideoTopic] = useState(null),
				[uploadVideoTopicType, setUploadVideoTopicType] = useState(null),
				[textEditorState, setTextEditorState] = useState(""),
				// texteditor explicit word counter
				[textEditorExplicitWordCount, setTextEditorExplicitWordCount] = useState(0),
				// for the buttons that'll show on top of video's subject input
				[selectedSubjectState, setSelectedSubjectState] = useState([]),
				[subjectAlphabetDictionary, setSubjectAlphabetDictionary] = useState(""),
				// metadata loader
				[metaContainerState, setMetaContainerState] = useState();

	const videoTitleRef = useRef(),
				videoFileRef = useRef(),
				thumbnailRef = useRef(),
				thumbnailDescriptionRef = useRef(),
				subtitleRef = useRef(),
				mentalModelRef = useRef(),
				subjectInputRef = useRef(),
				videoDescriptionRef = useRef();

	// DOM ref
	const subjectSuggestionsDropdownRef = useRef(),
				subjectDropdownRef = useRef(),
				// value ref
				// this also doubles as a reference on whether a key has children
				subjectParentListRef = useRef();
				const ownerId = useRef(props.ownerId || localStorage.getItem("ownerId"));

	// local values refs

	// static DOM-related effects, that will only need to be rendered once
	useEffect(() => {
		renderSubjectList();
		renderVideoDescription();
	}, [props.shinyNeuronsConversionRate]);


	function analyzeVideoTitle(e) {
		setVideoTitle(e.target.value);
		videoTitleRef.current.className = "input-text";
		explicitInputCheck(e);
	}

	// TODO: should detect and alert for offensive/inappropriate video content
	function analyzeVideo(e, acceptedFileFormats) {
		const videoFile = e.target.files[0];
		const fileFormat = getFileFormatFromTargetFile(videoFile);

		if (!acceptedFileFormats.includes(fileFormat)) {
			setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("img.fileType.notAccepted", "validation") + fileFormat}
					alertContainerStyle={{"top": "75px"}}></Alert>
			);
		}
		else if (videoFile !== undefined && videoFile !== null) {
			var videoFileReader = new FileReader();
			videoFileReader.onload = function(videoArrayBuffer) {
				videoProbe(videoArrayBuffer).then(res => {
					const errorMsgs = validateVideoAndAudioCodecs(res);

					if (errorMsgs.length) {
						const alertContainerStyle = {
							"top": "75px"
						};

						setAlert1(
							<Alert type={ERROR} closeHandler={closeAlert1} msg={errorMsgs}
											alertContainerStyle={alertContainerStyle}></Alert>
						);
						setVideoFileDisplay(messageMap("video.placeholder.files", "generic"));
						setVideoDuration(0);
					}
				}).catch(error => {
					console.log("error: ", error);
				});
			};

			videoFileReader.readAsArrayBuffer(videoFile);

			videoFileRef.current.className = "label-container";
			setAlert(null);
			setVideoFileDisplay(videoFile.name);

			if (videoFileRef.current.classList.contains("missing-file")) {
				videoFileRef.current.classList.remove("missing-file");
			}
			// need to silently load video on dom to analyze its metadata
			setMetaContainerState(<video src={URL.createObjectURL(videoFile)} preload="metadata" style={{display: "none"}}
																		onLoadedMetadata={videoMeta => checkVideoConstraints(videoMeta, videoFile)}></video>);
		}
	}

	function checkVideoConstraints(videoMeta, videoFile) {
		const videoDuration = videoMeta.target.duration / 60;
		let errorMsgs = "",
				hasViolations = false;

		if (videoFile.type !== "video/mp4") {
			errorMsgs += (messageMap("video.fileType.notAccepted", "validation") + " ");
			hasViolations = true;
		}
		if (videoDuration >= 10) {
			errorMsgs += (messageMap("video.lengthLimit.ten", "validation") + " ");
			hasViolations = true;
		}
		if (videoDuration <= 0.25) {
			errorMsgs += (messageMap("video.lengthLimit.fifteenSec", "validation") + " ");
			hasViolations = true;
		}
		
		if (128 < fileSizeConvert(videoFile.size, GB)) {
			errorMsgs += (messageMap("video.sizeLimit.over128GB", "validation") + " ");
			hasViolations = true;
		}

		if (hasViolations) {
			const alertContainerStyle = {
				"top": "75px"
			};

			setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={errorMsgs}
								alertContainerStyle={alertContainerStyle}></Alert>
			);
			setVideoFileDisplay(messageMap("video.placeholder.files", "generic"));
			setVideoDuration(0);
		}
		else {
			const unformattedTime = videoDuration.toString().match(/^.*\.\d{2}/g)[0];
			const minutes = unformattedTime.split(".")[0];
			let seconds = ("." + unformattedTime.split(".")[1]) * 60;
			seconds = Math.round(seconds);

			if (seconds.toString().length === 1) {
				seconds = "0" + seconds;
			}

			setUploadedVideo(videoFile);
			setVideoDuration(minutes + ":" + seconds);
		}
	}

	// TODO: should detect and alert for offensive/inappropriate images
	function analyzeThumbnail(e) {
		const uploadedThumbnail = e.target.files[0];

		if (uploadedThumbnail !== undefined && uploadedThumbnail !== null) {
			thumbnailRef.current.className = "label-container";
			setAlert(null);
			setThumbnailFileDisplay(uploadedThumbnail.name);
	
			// need to silently load image on dom to analyze its metadata
			setMetaContainerState(<img src={URL.createObjectURL(uploadedThumbnail)} style={{display: "none"}}
																onLoad={imgData => checkThumbnailConstraints(imgData, uploadedThumbnail)}></img>);
		}
	}

	function checkThumbnailConstraints(imgData, uploadedThumbnail) {
		const alertContainerStyle = {
			"top": "75px"
		};

		const {errorMsgs, hasViolations} = imageValidator(["jpeg", "png", "webp", "svg"], [640, null], null, 2, imgData, uploadedThumbnail);

		if (hasViolations) {
			setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={errorMsgs}
								alertContainerStyle={alertContainerStyle}></Alert>
			);
			setThumbnailFileDisplay(messageMap("video.placeholder.files", "generic"));
		}
		else {
			setUploadedThumbnail(uploadedThumbnail);
		}
	}

	function analyzeThumbnailDescription(e) {
		setUploadedThumbnailDescription(e.target.value);
		thumbnailDescriptionRef.current.className = "input-text";
		explicitInputCheck(e);
	}

	function analyzeSubtitles(e) {
		const subtitleFile = e.target.files[0];

		if (subtitleFile !== undefined && subtitleFile !== null) {
			setSubtitlesFileDisplay(subtitleFile.name);
	
			var subtitleFileReader = new FileReader();
			subtitleFileReader.onload = function(e) {
				extractSubtitleText(e, subtitleFile);
			};
			subtitleFileReader.readAsText(subtitleFile);
		}
	}

	// TODO: should detect and alert for offensive/inappropriate images
	function analyzeMentalModel(e) {
		if (e.target.files.length > 5) {
			const alertContainerStyle = {
				"top": "75px"
			};

			setMentalModelFileDisplay(messageMap("video.placeholder.files", "generic"));
			setAlert(
				<Alert type={ERROR} closeHandler={closeAlert}
								msg={messageMap("video.mentalModel.over5Files", "validation")}
								alertContainerStyle={alertContainerStyle}></Alert>
			);
			return;
		}

		let uploadedMentalModelFiles = [],
				uploadedMentalModelFilenames = [];
		[...e.target.files].map(file => {
			uploadedMentalModelFiles.push(file);
			uploadedMentalModelFilenames.push(file.name);
		});

		mentalModelRef.current.className = "label-container";
		setAlert(null);

		if (uploadedMentalModelFilenames.length) {
			setMentalModelFileDisplay(uploadedMentalModelFilenames.join(", "));
		}

		setUploadedMentalModel(uploadedMentalModelFiles);
	}

	// moved explicit validation in uploadContents(), since tinyMCE doesn't return event objects on its 'onEditorChange' prop
	function analyzeVideoDescription(text) {
		setTextEditorState(text);
		videoDescriptionRef.current.className = "file-container";

		if (text === "") {
			setTextEditorExplicitWordCount(0);
		}

		let tokenizedWords = text.split(" ");
		for (let i = 0; i < tokenizedWords.length; ++i) {
			if (tokenizedWords[i] && checkHasExplicitWords(tokenizedWords[i])) {
				let localTextEditorExplicitWordCount = textEditorExplicitWordCount + 1;
				setTextEditorExplicitWordCount(localTextEditorExplicitWordCount);
			}
		}
	}

	function updatePrice(e) {
		setPrice(e.target.value);
	}

	// I understand that this dynamic rendering design is complicated and hard to maintain, but 
	// we need it for the search functionality as the user types on the subjects input
	// TODO: move this and purely functional functions outside of this component definition
	/**
	 * @param {Object} obj map to create the dropdown from
	 * @param {Integer} depth node's level from root. Used for left spacing and display logic
	 * @param {String} parentKey reference for display logic, TODO: fix uniqueness issue
	 * @param {String} grandParentKey another reference for display logic, TODO: fix uniqueness issue
	 * @param {Array} keysToHide array of 1st-lvl child of current key
	 * @param {Boolean} hideAll for hiding children
	 * @param {Integer} clickedLevel for keeping track of clicked node's depth
	 * @param {String} keyToToggle reference of clicked node, TODO: fix uniqueness issue
	 * @param {String} keysParentToToggle reference of clicked node's parent, TODO: fix uniqueness issue
	 * @param {Object} chevDirectionState for keeping track of chevron states
	 * @returns 
	 */
	function createDropdown(obj, depth, parentKey = null, grandParentKey = null, keysToHide = [], hideAll = false, clickedLevel = 0,
													keyToToggle = null, keysParentToToggle = null, chevDirectionState = {}) {

		let subjectList = [];
		let keysToPossiblyExclude = [];

		for (const key in obj) {
			if (key !== "description") {
				keysToPossiblyExclude.push(key);
				let children = obj[key],
						subjectClassName = "subject",
						localHideAll = hideAll;

				if ((keysToHide.length && keysToHide.includes(key)) || localHideAll 
						|| (depth >= clickedLevel + 2 && grandParentKey === keyToToggle) // this is for showing just one level beneath the current key
						) {
					subjectClassName = "subject hide";
					localHideAll = true;
				}
				// covers same depth down
				if (depth === clickedLevel && key !== keyToToggle && keyToToggle != null 
						&& (parentKey === keysParentToToggle || parentKey !== keysParentToToggle)
						&& chevDirectionState[key] !== "up") {
					localHideAll = true;
				}

				let chevDirect = "down";
				if (chevDirectionState[key] !== null && chevDirectionState[key] !== undefined) {
					chevDirect = chevDirectionState[key];
				}
				else {
					chevDirectionState[key] = null;
				}

				const childKeys = Object.keys(children);
				let recursedChildren = [],
						innerText = key;
				if (childKeys.length && 
						( (childKeys.includes("description") && childKeys.length > 1)
							|| !childKeys.includes("description") ) ) {

					if (keyToToggle === key) {
						chevDirect = chevDirectionState[key] === "up" ? "down" : "up";
					}
					chevDirectionState[key] = chevDirect;
					localHideAll = chevDirectionState[key] === "down" ? true : false;
					recursedChildren = createDropdown(children, depth + 1, key, parentKey, keysToHide, localHideAll, 
																						clickedLevel, keyToToggle, keysParentToToggle, chevDirectionState);
					innerText = (
						<Fragment>
							<div className={`chevron-${chevDirect}`}></div>
							{innerText}
						</Fragment>
					);
				}

				const chevDirectionStateMap = recursedChildren[2];
				for (const chevKey in chevDirectionStateMap) {
					chevDirectionState[chevKey] = chevDirectionStateMap[chevKey];
				}

				const inlineStyle = {
								"marginLeft": `${depth * 20 + 10}px`
							},
							titleStyle = {
								"fontSize": ".9rem"
							},
							containerStyle = {
								"marginTop": "-55px",
								"maxWidth": "500px"
							};
				subjectList.push(
					<div key={`${key}-${parentKey}`} className={subjectClassName} role="button" tabIndex={0}
							onClick={e => hideList(recursedChildren.length && chevDirect === "up" ? recursedChildren[1] : [], children,
																		key, parentKey, grandParentKey, depth, chevDirectionState)}
							onKeyPress={e => escape(e, hideList, [recursedChildren.length && chevDirect === "up" ? recursedChildren[1] : [], children,
								key, parentKey, grandParentKey, depth, chevDirectionState])}>
						<div style={inlineStyle} className="subject-text" aria-describedby={`${innerText}Tooltip`}>
							{innerText}
						</div>
						{(
							Object.keys(obj[key]).includes("description")
							?
							(
								<Tooltip title={obj[key].description} titleStyle={titleStyle}
												containerStyle={containerStyle} classStr="tooltip-bottom-left"
												ariaReference={`${innerText}Tooltip`}/>
							)
							:
							""
						)}
					</div>
				);

				if (recursedChildren.length && recursedChildren[0].length) {
					subjectList = subjectList.concat(recursedChildren[0]);
				}

				// check if we're at the end of the key list to add "Other" and that this new input with key value isn't present
				// in the current subjectList
				let runningKeyList = [];
				subjectList.forEach(sub => {
					runningKeyList.push(sub.key);
				});
				const curObjKeyList = Object.keys(obj);
				if (curObjKeyList.indexOf(key) === curObjKeyList.length - 1 && !runningKeyList.includes(`other-${parentKey}`)) {
					subjectList.push(
						<div key={`other-${parentKey}`} className={subjectClassName}>
							<input className="other-subject" style={inlineStyle} type="text"
										placeholder="Other" maxLength="50"
										aria-describedby={`other${innerText}Tooltip`}
										onKeyPress={getOtherSubject}></input>
							<Tooltip title={`Other subject not included within ${parentKey}`} titleStyle={titleStyle}
												containerStyle={containerStyle} classStr="tooltip-bottom-left"
												ariaReference={`other${innerText}Tooltip`}/>
						</div>
					);
				}
			}
		}

		return [subjectList, keysToPossiblyExclude, chevDirectionState];
	}

	function showTopicModal() {
		let modalStyle = {};
		if (uploadOrEdit === "edit") {
			modalStyle = {
				left: 0
			};
		}

		setTopicModal(
			<Modal closeType="xButton" closeHandler={closeModal} modalStyle={modalStyle}>
				<Topic title="Topic" closeHandler={saveChosenTopic} componentType="modal" />
			</Modal>
		);
	}

	function closeModal(e) {
		const target = e.target;
		const customiconid = target.getAttribute("customiconid");
		if ( (["icon", "modal-block"].includes(target.className) && (target.className === "icon" && customiconid !== "validation")) 
					|| target.getAttribute("nodeid") != null) {
			hideModal();

			const nodeId = e.target.getAttribute("nodeid");
			const workbookname = e.target.getAttribute("workbookname");
			if (nodeId && workbookname) {
				saveChosenTopic(nodeId, "predefined", workbookname);
			}
		}
	}
	function hideModal() {
		setTimeout(() => {
			setTopicModal(null);
		}, MODAL_CLOSE_TIMEOUT);
	}

	function saveChosenTopic(nodeId, topicType = "predefined", workbookName) {
		hideModal();
		setUploadVideoTopic(nodeId);
		setUploadVideoTopicType(topicType);

		if (topicType !== "predefined") {
			setTopicNameDisplay(nodeId);
		}
		else {
			// NODE*: this is only possible because knowledge network gets rendered in <Topic/> component
			const results = getSubtreeFromId(nodeId, -1, workbookName)[0];
			setTopicNameDisplay(Object.values(results)[0].label);
		}
	}

	function renderSubjectList() {
		const subjectsMap = getSubjectsMap();

		// include games as a subject and tabletop and video games as sub subjects
		// include an other option option
		// possible values: up, down, null
		const dropdownArrayResults = createDropdown(subjectsMap, 0, null, null, getKeysToInitiallyHide());

		// TODO: create a subjects dictionary tree to auto fill dropdown list
		// 1.) Create a single dictionary tree containing the subjects object keys
		// 2.) As the user types, using the dictionary tree previously created, show matching suggestions as another dropdown
		// 3.) When the user clicks on a value in the dropdown or presses the "Enter" key while they're typing on the input,
		//		extract that object corresponding to the selected, or typed, key value from the getSubjectsMap list and feed it into createDropdown()
		//		a.) We want to render another tree, since we would favor further specificity if the user happen to choose a subject
		const chevMap = dropdownArrayResults[2];
		let lowerCaseStrings = [];
		let parentList = [];
		for (const key in chevMap) {
			const lowerCaseKey = key.toLowerCase();
			lowerCaseStrings.push(lowerCaseKey);

			if (chevMap[key] !== null) {
				parentList.push(lowerCaseKey);
			}
		}
		subjectParentListRef.current = parentList;

		let subject = {};
		createPrefixTree(lowerCaseStrings, subject);
		setSubjectAlphabetDictionary(subject);

		let subjectList = dropdownArrayResults[0];
		setSubjectListDom(subjectList);
	}

	function renderVideoDescription() {
		setVideoDescriptionDom(
			<textarea className="textarea" onChange={e => analyzeVideoDescription(e.target.value)}
				placeholder={props.videoDescription ? props.videoDescription : messageMap("video.placeholder.videoDescription", "generic")}>
			</textarea>
			// Commenting this out for now because it really holds up the website's rendering time
			// <TextEditor onChangeHandler={analyzeVideoDescription}
			// 	placeholder={props.videoDescription ? props.videoDescription : messageMap("video.placeholder.videoDescription", "generic")}>
			// </TextEditor>
		);
	}

	function showSuggestionResults(e) {
		const target = e.target;
		const subjectName = target.getAttribute("keyattr");
		const isAParent = target.getAttribute("isaparent");
		// get subject and call createDropdown with a modified subject tree based on chosen subject if the subject
		// has subjects within it
		subjectInputRef.current.value = "";

		if (isAParent === "true") {
			const parentsChildren = dfs(getSubjectsMap(), subjectName);
			setSubjectSuggestionsDom(createSuggestionDropdown(parentsChildren));
		}
		else {
			// create buttons!!!
			// LIMIT TO 5 subjects (some subjects belonging to a high-level subject are multi-disciplinary, so limit is 5)
			let chosenSubjects = [].concat(selectedSubjectState);
			if (chosenSubjects.length < 6) {
				chosenSubjects.push(
					<button key={subjectName} className="chosen-subject-button"
									onClick={removeChosenSubject}>
						{subjectName}
						<img className="ex-icon" src={closeThinAsset}
									alt={messageMap("closeThin", "image")}></img>
					</button>
				);

				setSelectedSubjectState(chosenSubjects);
			}
			else {
				// SHOW ALERT THAT THEY"RE ALREADY CHOSEN 3 SUBJECTS
				console.log("placeholder alert");
			}
		}
	}

	// depth-first-search
	function dfs(obj, key) {
		let parentsChildren = [];

		for (let curKey in obj) {
			if (key !== "description") {
				const curObj = obj[curKey];
				const curKeysChildren = Object.keys(curObj);

				if (curKey.toLowerCase() === key) {
					parentsChildren = parentsChildren.concat(curKeysChildren);
					break;
				}
				else if (curKeysChildren.length && 
					( (curKeysChildren.includes("description") && curKeysChildren.length > 1)
						|| !curKeysChildren.includes("description") )) {
					parentsChildren = parentsChildren.concat(dfs(curObj, key));
				}
			}
		}

		return parentsChildren;
	}

	function createSuggestionDropdown(wordList) {
		let suggestionsDom = [];

		wordList.forEach(wordMatch => {
			suggestionsDom.push(
				<div key={wordMatch} className="subject-suggestion" role="button" tabIndex={0}
							keyattr={wordMatch} isaparent={subjectParentListRef.current.includes(wordMatch) ? "true" : "false"}
							onClick={showSuggestionResults}
							onKeyPress={e => onKeyDown(e, showSuggestionResults, [e])}>
					{wordMatch}
				</div>
			);
		});

		return suggestionsDom;
	}

	function showSubjectListDropdown() {
		subjectDropdownRef.current.className = "subjects-dropdown";
	}

	function showSubjectSuggestionsDropdown() {
		subjectSuggestionsDropdownRef.current.className = "subjects-suggestion-dropdown";
	}

	function hideSubjectSuggestionsDropdown() {
		subjectSuggestionsDropdownRef.current.className = "subjects-suggestion-dropdown hide";
	}

	function hideDropdowns(e) {
		const target = e.target;
		const targetClassName = target.className;

		if (subjectDropdownRef.current.className === "subjects-dropdown"
				&& !["input-text subject", "subject-text", "subject", "chevron-up", "chevron-down", "other-subject"].includes(targetClassName)
				&& (target.tagName !== "H1" && target.parentNode.className !== "tooltip-bottom-left")) {
			subjectDropdownRef.current.className = "subjects-dropdown hide";
		}

		if (subjectSuggestionsDropdownRef.current.className === "subjects-suggestion-dropdown" && !["subject-suggestion"].includes(targetClassName)) {
			subjectSuggestionsDropdownRef.current.className = "subjects-suggestion-dropdown hide";
		}
	}

	// TODO: update this to show a list of grid buttons on top of the input as a way to indicate to the user what they're chosen so far
	function hideList(keysToHide, childrenToHide, keyToToggle, parentKey, grandParentKey, curDepth, chevDirectionState) {
		getChildrenArray(childrenToHide).forEach(key => {
			chevDirectionState[key] === "up" && (chevDirectionState[key] = "down");
		});

		let subjectList = createDropdown(getSubjectsMap(), 0, parentKey, grandParentKey, keysToHide,
																			false, curDepth, keyToToggle, parentKey, chevDirectionState);
		setSubjectListDom(subjectList[0]);
		showSubjectListDropdown();
	}

	function closeAlert() {
		setAlert(null);
	}

	function closeAlert1() {
		setAlert1(null);
	}

	function autoUpdateDropList(e) {
		const word = e.target.value.toLowerCase(),
					wordLength = word.length;
		let runningMatch = subjectAlphabetDictionary,
				i = 0,
				char = word[i];

		while (runningMatch[char] !== undefined && i < wordLength) {
			runningMatch = runningMatch[char];
			++i;
			char = word[i];
		}

		// populated dom suggestion
		if (runningMatch !== subjectAlphabetDictionary && i === wordLength) {
			setSubjectSuggestionsDom(createSuggestionDropdown(buildWordListFromFree(word, runningMatch)));
			showSubjectSuggestionsDropdown();
		}
		// empty/hidden dom suggestion
		else {
			hideSubjectSuggestionsDropdown();
			setSubjectSuggestionsDom(null);
		}
	}

	function removeChosenSubject(e) {
		const textToRemove = e.target.innerText;
		let foundMatch = false;
		let newArr = [];
		for (let i = 0; i < selectedSubjectState.length; ++i) {
			const curObj = selectedSubjectState[i];

			if (curObj.key !== textToRemove) {
				newArr.push(curObj);
			}
			else if (curObj.key === textToRemove) {
				foundMatch = true;
			}
		}

		if (!foundMatch) {
			let copy = [].concat(selectedSubjectState);
			copy.pop();
			setSelectedSubjectState(copy);
		}
		else {
			setSelectedSubjectState(newArr);
		}
	}

	function explicitInputCheck(e) {
		if (e.target.value && checkHasExplicitWords(e.target.value)) {
			e.target.value = "";
			const alertContainerStyle = {
				"top": "75px"
			};
			setAlert(
				<Alert type={ERROR} closeHandler={closeAlert}
					msg={messageMap("input.explicit.text", "validation")}
					alertContainerStyle={alertContainerStyle}/>
			);
		}
	}

	function getOtherSubject(e) {
		if (e.key === "Enter") {
			console.log("Received a key press: ", e.target.value);
		}
	}

	function extractSubtitleText(e, subtitleFile) {
		let textString = "";
		let results = e.target.result;
		let splitText = results.split("\n\n");
		splitText.forEach(textBlock => {
			let lineText = textBlock.slice(textBlock.indexOf("\n", 5)).split("\n");
			lineText.shift();
			lineText.forEach(text => {
				textString += text;
			});
		});

		let wordTokens = textString.split(" "),
				foundExplicitWords = false;
		const alertContainerStyle = {
			"top": "75px"
		};
		for (let i = 0; i < wordTokens.length; ++i) {
			if (wordTokens[i] && checkHasExplicitWords(wordTokens[i])) {
				setSubtitlesFileDisplay(messageMap("video.placeholder.files", "generic"));
				foundExplicitWords = true;
				setAlert(
					<Alert type={ERROR} closeHandler={closeAlert}
									msg={messageMap("input.explicit.content", "validation")}
									alertContainerStyle={alertContainerStyle}></Alert>
				);
				break;
			}
		}

		if (!foundExplicitWords) {
			subtitleRef.current.className = "label-container";
			setAlert(null);
			setUploadedSubtitles(subtitleFile);
		}
	}

	// YouTube fields when uploading a video
	// TODO: include radio buttons for indicating whether the video is made for kids to 
	// comply with the Children's Online Privacy Protection Act (COPPA): https://support.google.com/youtube/answer/9528076?hl=en
	// TODO: include radio buttons for indicating whether the video should be age restricted: https://support.google.com/youtube/answer/2950063?hl=en
	// TODO: include checkbox if video was made by accepting 3rd party assistance
	// We'll show viewers a message that tells them your video contains paid promotion: https://support.google.com/youtube/answer/154235?hl=en
	//	a.) Need to find a way to restrict this, since we wouldn't want 3rd parties providing inaccurate and/or false information
	function uploadContents() {
		const fieldsToValidate = getFieldsToValidate();

		if (textEditorExplicitWordCount && (!fieldsToValidate.length || fieldsToValidate.includes("description"))) {
			const alertContainerStyle = {
				"top": "75px"
			};

			setAlert(
				<Alert type={ERROR} closeHandler={closeAlert}
								customiconid="validation"
								msg={messageMap("input.explicit.textEditor.videoDescription", "validation")}
								alertContainerStyle={alertContainerStyle}></Alert>
			);

			return;
		}

		if (!validateContents(fieldsToValidate)) {
			return;
		}

		const spinnerContainerStyle = {
			"zIndex": "5",
			position: "absolute",
			margin: "auto",
			left: "0",
			right: "0"
		};
		if (uploadOrEdit === "upload") {
			setSpinner(
				<Spinner spinnerContainerStyle={spinnerContainerStyle}
								loadingText={messageMap("spinner.action.upload.text", "button")}
								durationText={messageMap("spinner.action.upload.duration", "button")}/>
			);
	
			const formDataPayload = {
				// for formData
				videoTitle: videoTitle,
				videoDuration: videoDuration,
				video: uploadedVideo,
				thumbnail: uploadedThumbnail,
				thumbnailDescription: uploadedThumbnailDescription,
				subtitles: uploadedSubtitles,
				mentalModels: uploadedMentalModel,
				price: price,
				topicID: uploadVideoTopic,
				topicType: uploadVideoTopicType,
				description: textEditorState,
				// for generating S3 key
				category: CATEGORIES.TEACH
			};
	
			uploadVideoAPI({ownerId: ownerId.current}, formDataPayload, resp => {
				parseResponseForSuccessAndErrors(resp, uploadOrEdit);

				if (resp["ERROR"] === null || resp["ERROR"] === undefined) {
					videoTitleRef.current.value = "";
					setVideoFileDisplay(messageMap("video.placeholder.files", "generic"));
					setThumbnailFileDisplay(messageMap("video.placeholder.files", "generic"));
					thumbnailDescriptionRef.current.value = "";
					setSubtitlesFileDisplay("");
					setMentalModelFileDisplay(messageMap("video.placeholder.files", "generic"));
					renderVideoDescription();
					setTopicNameDisplay(messageMap("video.placeholder.topic", "generic"));
					setUploadVideoTopic(null);
					setUploadVideoTopicType(null);
					setPrice(null);
				}

				setSpinner(null);
			});
		}
		else {
			setSpinner(<Spinner spinnerContainerStyle={spinnerContainerStyle} loadingText={messageMap("spinner.action.update", "button")}/>);

			const editPathVariables = {
				ownerId: ownerId.current,
				videoId: props.videoId
			};
			let formDataPayload = {
				category: CATEGORIES.TEACH
			};
			const fieldToValueMap = {
				videoTitle: videoTitle,
				videoDuration: videoDuration,
				video: uploadedVideo,
				thumbnail: uploadedThumbnail,
				thumbnailDescription: uploadedThumbnailDescription,
				mentalModels: uploadedMentalModel,
				price: price,
				topicID: uploadVideoTopic,
				topicType: uploadVideoTopicType,
				subtitles: uploadedSubtitles,
				description: textEditorState
			};

			fieldsToValidate.forEach(field => {
				formDataPayload[field] = fieldToValueMap[field];
			});
			fieldsToValidate.push(CATEGORY);
			
			editUploadedVideoAPI(editPathVariables, formDataPayload, fieldsToValidate, resp => {
				parseResponseForSuccessAndErrors(resp, uploadOrEdit);
				setSpinner(null);
			});
		}
	}

	function parseResponseForSuccessAndErrors(resp, uploadOrEdit) {
		let allMsgs = [];
		let msgType = "";
		const alertContainerStyle = {
			"top": "75px"
		};

		if (resp["SUCCESS"]) {
			msgType = SUCCESS;
			resp["SUCCESS"].split("_").forEach(msg => {
				allMsgs.push(messageMap(msg, "api"));
			});
		}
		else if (resp["ERROR"]) {
			msgType = ERROR;
			resp["ERROR"].split("_").forEach(msg => {
				allMsgs.push(messageMap(msg, "api"));
			});
		}

		if (allMsgs.length) {
			setAlert(
				<Alert type={msgType} closeHandler={closeAlert} customiconid={uploadOrEdit}
								msg={allMsgs.join(" ")}
								// setting this to 2 minutes since we're running a promotion. I'll set it to 15 seconds when the promotion ends.
								timeout={120000}
								alertContainerStyle={alertContainerStyle}></Alert>
			);
		}
	}

	// TODO: extend this check to include multiple videos and mental models
	function getFieldsToValidate() {
		// NOTE: an empty array means to validate all fields
		let fieldsToValidate = [];
		const propKeys = Object.keys(props);

		if (propKeys.length) {
			if (propKeys.includes(VIDEO_TITLE) && videoTitle !== null && props.videoTitle !== videoTitle) {
				fieldsToValidate.push(VIDEO_TITLE);
			}
			if (propKeys.includes("videoFileName") && uploadedVideo !== "") {
				fieldsToValidate.push(VIDEO);
				fieldsToValidate.push(VIDEO_DURATION);
			}
			if (propKeys.includes("thumbnailFileName") && uploadedThumbnail !== "") {
				fieldsToValidate.push(THUMBNAIL);
			}
			if (propKeys.includes("mentalModelFileName") && uploadedMentalModel.length !== 0) {
				fieldsToValidate.push(MENTAL_MODELS);
			}
			if (uploadVideoTopic != null) {
				fieldsToValidate.push(TOPIC_ID);
			}
			if (uploadVideoTopicType != null) {
				fieldsToValidate.push(TOPIC_TYPE);
			}
			if (propKeys.includes(THUMBNAIL_DESCRIPTION) && uploadedThumbnailDescription !== "") {
				fieldsToValidate.push(THUMBNAIL_DESCRIPTION);
			}
			if (propKeys.includes("subtitleFileName") && uploadedSubtitles !== "") {
				fieldsToValidate.push(SUBTITLES);
			}
			if (propKeys.includes("videoDescription") && textEditorState !== "") {
				fieldsToValidate.push(DESCRIPTION);
			}
			if (propKeys.includes(PRICE) && props.price !== price) {
				fieldsToValidate.push(PRICE);
			}
		}

		return fieldsToValidate;
	}

	function validateContents(fieldsToValidate) {
		let allFilled = true;
		let missingFields = [];

		if (videoTitle === null && (!fieldsToValidate.length || fieldsToValidate.includes("title"))) {
			allFilled = false;
			missingFields.push("video title");
			videoTitleRef.current.className = "missing-input";
		}

		if (videoFileDisplay === messageMap("video.placeholder.files", "generic") && (!fieldsToValidate.length || fieldsToValidate.includes("video"))) {
			allFilled = false;
			missingFields.push("missing video file");
			videoFileRef.current.className = "label-container missing-file";
		}

		if (uploadedThumbnail === "" && thumbnailFileDisplay === messageMap("video.placeholder.files", "generic") && (!fieldsToValidate.length || fieldsToValidate.includes("thumbnail"))) {
			allFilled = false;
			missingFields.push("video thumbnail");
			thumbnailRef.current.className = "label-container missing-file";
		}

		if (uploadedThumbnailDescription === "" && props.thumbnailDescription === null && (!fieldsToValidate.length || fieldsToValidate.includes("thumbnailDescription"))) {
			allFilled = false;
			missingFields.push("video thumbnail description");
			thumbnailDescriptionRef.current.className = "missing-input";
		}

		// if (uploadedMentalModel.length === 0 && (!fieldsToValidate.length || fieldsToValidate.includes("mentalModel"))) {
		// 	allFilled = false;
		// 	missingFields.push("video mental model");
		// 	mentalModelRef.current.className = "label-container missing-file";
		// }

		// if (!selectedSubjectState.length) {
		// 	allFilled = false;
		// 	missingFields.push("subject");
		// 	subjectInputRef.current.className = "file-container missing-description";
		// }

		if (textEditorState === "" && videoDescriptionDom === null && (!fieldsToValidate.length || fieldsToValidate.includes(DESCRIPTION))) {
			allFilled = false;
			missingFields.push("video description");
			videoDescriptionRef.current.className = "file-container missing-description";
		}

		if (missingFields.length) {
			const alertContainerStyle = {
				"top": "75px"
			};

			setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} customiconid="validation"
								msg={messageMap("upload.missing.fields", "api") + missingFields.join(", ")}
								alertContainerStyle={alertContainerStyle}></Alert>
			);
		}

		return allFilled;
	}

	// not sure if this is worth it in the long run, when we start getting bigger trees.
	// Although it's usually cleaner with recursive implementations, we often get better performance
	// with iterative designs. This function is for obtaining all the keys that have children, just so we can
	// change their chevrons appropriately to avoid double clicking sub keys that have children that 
	// were previously expanded down
	function getChildrenArray(childrenSubtree) {
		let children = [];

		if (typeof obj !== "string") {
			for (const key in childrenSubtree) {
				let childrenTreeSub = childrenSubtree[key];

				if (key !== "description") {

					const childKeys = Object.keys(childrenTreeSub);
					if (childKeys.length && 
						( (childKeys.includes("description") && childKeys.length > 1)
							|| !childKeys.includes("description") ) ) {

						children.push(key);
						children = children.concat(getChildrenArray(childrenTreeSub));
					}
				}
			}
		}

		return children;
	}

	function buildWordListFromFree(text, runningMatch) {
		let constructedWords = [];

		for (const key in runningMatch) {
			let runningWord = text + key;

			if (Object.keys(runningMatch[key]).length) {
				constructedWords = constructedWords.concat(buildWordListFromFree(runningWord, runningMatch[key]));
			}
			else {
				constructedWords.push(runningWord);
			}
		}

		return constructedWords;
	}

	function toggleTooltip(stateTo, fieldType) {
		const stateFunctionToFieldTypeMap = {
			mentalModel: setMentalModelTooltip,
			information: setTopicTooltip
		};

		if (stateTo === "show") {
			const containerStyle = {
				marginTop: fieldType === "mentalModel" ? "-113px" : "-133px",
				marginLeft: "-9px",
				width: "350px"
			}
			const subheaderToFieldTypeMap = {
				mentalModel: messageMap("uploadVideos.mentalModel", "tooltip"),
				information: messageMap("uploadVideos.topic", "tooltip")
			};

			stateFunctionToFieldTypeMap[fieldType](
				<Tooltip classStr="tooltip-bottom-left" containerStyle={containerStyle}
								subheader={subheaderToFieldTypeMap[fieldType]}>
				</Tooltip>
			);
		}
		else {
			stateFunctionToFieldTypeMap[fieldType](null);
		}
	}

	return (
		<div className={"video-upload-container"}
			// onClick={hideDropdowns} onKeyPress={hideDropdowns}
			>

			{spinner}

			{alert}
			{alert1}
			{topicModal}

			<div className="video-upload">
				<div className="file-container">
					<label>
						<input className="input-text" type="text"
									placeholder={props.videoTitle ? props.videoTitle : messageMap("video.placeholder.title", "generic")}
									minLength="4" maxLength="200" size="60" onChange={analyzeVideoTitle}
									ref={videoTitleRef}
									required="required" aria-required="true"/>
						<span>*</span>
					</label>
				</div>

				<div className="file-container">
					<label className="label-container" ref={videoFileRef}>
						{/* file types and use cases: https://www.computer.org/publications/tech-news/trends/8-best-video-file-formats-for-2020 */}
						{/* YT file formats: https://support.google.com/youtube/troubleshooter/2888402?hl=en */}
						{/* YT specifications: https://support.google.com/youtube/answer/4603579?hl=en */}
						{/* Only supporting mp4 for now. Investigate feasibility of supporting the following vide containers: , .mov, .avi, .avchd, .WebM, .mkv */}
						<input type="file" accept=".mp4"
							onChange={e => analyzeVideo(e, ["mp4"])}
							required="required" aria-required="true"/>
						{messageMap("video.upload.video", "generic")}
					</label>
					<span>*</span>
					<div className="filename">
						{videoFileDisplay}
					</div>
				</div>

				<div className="file-container">
					<label className="label-container" ref={thumbnailRef}>
						<input type="file" accept=".png, .jpg, .webp, .svg"
							onChange={e => analyzeThumbnail(e, ["png", "jpg", "webp", "svg"])}
							required="required" aria-required="true"/>
						{messageMap("video.upload.thumbnail", "generic")}
					</label>
					<span>*</span>
					<div className="filename">
						{thumbnailFileDisplay}
					</div>
				</div>

				<div className="file-container">
					<label>
						{/* TODO: include small tooltip on why this is required. "The description will be used as the 'alt' property for img for improved accessibility" */}
						<input className="input-text" type="text"
									placeholder={props.thumbnailDescription ? props.thumbnailDescription : messageMap("video.placeholder.thumbnail", "generic")}
									minLength="4" maxLength="250" size="60" onChange={analyzeThumbnailDescription}
									ref={thumbnailDescriptionRef}
									required="required" aria-required="true"/>
						<span>*</span>
					</label>
				</div>

				{/* <div className="file-container">
					<label className="label-container" ref={subtitleRef}> */}
						{/* TODO: test for more file types:https://support.google.com/youtube/answer/2734698?hl=en#zippy=%2Cbasic-file-formats%2Cadvanced-file-formats */}
						{/* <input type="file" accept=".vtt"
							onChange={e => analyzeSubtitles(e, ["vtt"])}/>
						{messageMap("video.upload.subtitles", "generic")}
					</label>
					<div className="filename">
						{subtitlesFileDisplay}
					</div>
				</div> */}

				<div className="file-container">
					<label className="label-container" ref={mentalModelRef}>
						<input type="file" accept=".png, .jpg, .webp, .svg" multiple
							onChange={e => analyzeMentalModel(e, ["png", "jpg", "webp", "svg"])}/>
						{messageMap("video.upload.mentalModel", "generic")}
					</label>
					<img className="information-icon" src={informationAsset}
								alt={messageMap("videoUpload.mentalModel", "image")}
								onMouseOver={e => toggleTooltip("show", "mentalModel")} onFocus={e => toggleTooltip("show", "mentalModel")}
								onMouseOut={e => toggleTooltip("hide", "mentalModel")} onBlur={e => toggleTooltip("hide", "mentalModel")}></img>
					{mentalModelTooltip}
					<div className="filename">
						{mentalModelFileDisplay}
					</div>
				</div>

				<div className="file-container">
					{selectedSubjectState}
				</div>

				{/*<div className="file-container">
					<input className="input-text subject" ref={subjectInputRef}
									onChange={autoUpdateDropList}
									onClick={showSubjectListDropdown}
									onKeyPress={e => onKeyDown(e, showSubjectListDropdown, [e], true)}
									maxLength="250" placeholder={"Video's subject"}></input>
					<span>*</span>
					<div className="subjects-suggestion-dropdown hide" ref={subjectSuggestionsDropdownRef}>
						{subjectSuggestionsDom}
					</div>
					<div className="subjects-dropdown hide" ref={subjectDropdownRef}>
						{subjectListDom}
					</div>
			</div>*/}

				<div className="file-container">
					<button className="label-container" onClick={showTopicModal}>
						{messageMap("video.upload.topic", "generic")}
					</button>
					<img className="information-icon" src={informationAsset}
								alt={messageMap("videoUpload.information", "image")}
								onMouseOver={e => toggleTooltip("show", "information")} onFocus={e => toggleTooltip("show", "information")}
								onMouseOut={e => toggleTooltip("hide", "information")} onBlur={e => toggleTooltip("hide", "information")}></img>
					{topicTooltip}
					<div className="filename">
						{topicNameDisplay}
					</div>
				</div>

				<div className="file-container">
					<label htmlFor="price">
						{messageMap("video.upload.pricing", "generic")}
						{": $"}
						<input className="price" placeholder={props.price} type="number" min="0" onChange={updatePrice} value={price}/>
						{price ? ("=" + getPriceInShinyNeurons(price, props.shinyNeuronsConversionRate, " shiny neurons")): ""}
					</label>
				</div>

				<div className="file-container" ref={videoDescriptionRef}>
					<div className="editor-container">
						{videoDescriptionDom}
					</div>
					<span className="text-editor">*</span>
				</div>

				{metaContainerState}

				<button className="submit-button" onClick={uploadContents}>
					{
						props.submitText
						?
						props.submitText
						:
						messageMap("submit.text", "button")
					}
				</button>
			</div>
		</div>
	);
}

VideoUpload.propTypes = {
	videoId: PropTypes.string,
	videoTitle: PropTypes.string,
	videoFileName: PropTypes.string,
	thumbnailFileName: PropTypes.string,
	thumbnailDescription: PropTypes.string,
	subtitleFileName: PropTypes.string,
	mentalModelFileName: PropTypes.string,
	topicID: PropTypes.string,
	price: PropTypes.number,
	videoDescription: PropTypes.string,
	submitText: PropTypes.string,
	purpose: PropTypes.string.isRequired,

	// redux props
	ownerId: PropTypes.string,
  shinyNeuronsConversionRate: PropTypes.number
};

export default connect(
	payingAccount
)(VideoUpload);