import {
	SET_OWNER_ID, REMOVE_OWNER_ID,
	SET_LOG_IN_TIMEOUT_START_TIME, CLEAR_LOG_IN_TIMEOUT, LOG_IN_TIMEOUT,
	SET_IP, SET_IP_LOCKED, SET_IP_CREATED_AT,
	SET_IS_SUBSCRIBER, UPDATE_SHINY_NEURONS_BALANCE
} from "redux/actions/accountConstants";

const initialState = {
	ownerId: null,
	googleId: null,
	username: null, 
	userFirstName: null,
	userLastName: null,
	authToken: null,
	isSubscriber: false,
	isInAGroup: false,
	isATutor: false,
	shinyNeurons: 0,
	timedOutStartTime: localStorage.getItem("logInTimeout") === null ? 0 : localStorage.getItem("logInTimeout"),
	timeOutDuration: LOG_IN_TIMEOUT,
	ip: null,
	ipLocked: false,
	ipCreatedAt: 0,
	location: null
};

const LOCAL_STORAGE_KEYS_NOT_COUNTING_OWNER_ID = [
	"authToken",
	"isInAGroup", "isSubscriber",
	"shinyNeurons", "username",
	"googleId"
];

// TODO: Fix the usage of local storage for timeout related dispatch.
export default function (state = initialState, action) {
	if (action.type === SET_OWNER_ID) {
		const payload = action.payload;
		localStorage.setItem("ownerId", payload.id);// For use after a redirect.
		LOCAL_STORAGE_KEYS_NOT_COUNTING_OWNER_ID.forEach((key) => localStorage.setItem(key, payload[key]));

		return {
			...state,
			ownerId: payload.id,
			googleId: payload.googleId,
			username: payload.username,
			userFirstName: payload.firstName,
			userLastName: payload.lastName,
			authToken: payload.authToken,
			shinyNeurons: payload.shinyNeurons,
			isInAGroup: payload.isInAGroup,
			isATutor: payload.userType.length > 1,
			isSubscriber: payload.isSubscriber,
			location: payload.location
		};
	}
	else if (action.type === REMOVE_OWNER_ID) {
		localStorage.clear();

		return {
			...state,
			ownerId: null,
			username: null,
			userFirstName: null,
			userLastName: null,
			authToken: null,
			shinyNeurons: 0,
			isInAGroup: false,
			isATutor: false,
			location: null
		};
	}
	else if (action.type === SET_LOG_IN_TIMEOUT_START_TIME) {
		const timedOutDuration = action.payload.duration;
		localStorage.setItem("logInTimeout", action.payload.startTime);
		setTimeout(() => {
			action.payload.callback();
		}, (timedOutDuration));

		return {
			...state,
			timeOutDuration: action.payload.duration,
			timedOutStartTime: action.payload.startTime
		};
	}
	else if (action.type === CLEAR_LOG_IN_TIMEOUT) {
		localStorage.setItem("logInTimeout", 0);
		return {
			...state,
			timedOutStartTime: 0
		};
	}
	else if (action.type === SET_IP) {
		return {
			...state,
			ip: action.payload.ip
		};
	}
	else if (action.type === SET_IP_LOCKED) {
		return {
			...state,
			ipLocked: action.payload.isLocked
		};
	}
	else if (action.type === SET_IP_CREATED_AT) {
		return {
			...state,
			ipCreatedAt: action.payload.createdAt
		};
	}
	else if (action.type === SET_IS_SUBSCRIBER) {
		return {
			...state,
			isSubscriber: action.payload
		};
	}
	else if (action.type === UPDATE_SHINY_NEURONS_BALANCE) {
		return {
			...state,
			shinyNeurons: action.payload.shinyNeurons
		};
	}
	else {
		return state;
	}
}