import React, {useEffect, useState, Fragment} from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import Alert, {INFORMATION} from "templates/Alert";
import Modal from "templates/Modal";
import ConfirmModal from "templates/customModals/ConfirmModal";

import TutorProfile from "pages/Profile/subPages/components/TutorProfile";

import messageMap from "Utilities/MessageMaps";
import { isValidIdentity } from "Utilities/Validators/IdentityValidators";
import { onKeyDown } from "Utilities/Accessibility";
import { promiseAll, GET, POST } from "Utilities/Fetches";
import { $replace } from "Utilities/Strings";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";

import {GET_TUTOR_PROFILE, toggleTutorProfileVisibilityAPI} from "apis/controllers/tutoring/TutorProfileController";
import {GET_ACCOUNT_SETTINGS} from "apis/controllers/person/AccountsController";

import ellipsisVerticalAsset from "assets/icons/common/ellipsis_vertical.svg";


function TutoringSubPage(props) {

	const identityIsValid = isValidIdentity(props.ownerId);
	const ownerId = props.ownerId || localStorage.getItem("ownerId");

	// picture files
	const [profilePicture, setProfilePicture] = useState(props.profilePictureUploadLocation),
		[firstName, setFirstName] = useState(props.firstName),
		[lastName, setLastName] = useState(props.lastName),
		[country, setCountry] = useState(props.country),
		// doc files
		[screeningDocument, setScreeningDocument] = useState(),
		[resumeDocument, setResumeDocument] = useState(),
		[teachingDocument, setTeachingDocument] = useState(),
		[professionalDocument, setProfessionalDocument] = useState(),
		[educationProofDocument, setEducationProofDocument] = useState(),
		// string values
		[linkedInUrl, setLinkedInUrl] = useState(),
		[webUrl, setWebUrl] = useState(),
		[subjectTopics, setSubjectTopics] = useState(),
		[subjectTopicProofs, setSubjectTopicProofs] = useState(),
		[subjectTopicReviewStatus, setSubjectTopicReviewStatus] = useState(),
		// display boolean states
		[profileActivityDisplay, setProfileActivityDisplay] = useState("inactive"), // if tutor is allowed to tutor
		[profileActionsDisplay, setProfileActionsDisplay] = useState("hide"), // for toggling profile actions via ellipsis button
		// TODO: use this as a means to suspend tutors
		[profileVisibility, setProfileVisibility] = useState("shown"), // to toggle between show/hide tutor profile options
		[profileIsPublic, setProfileIsPublic] = useState(false);

	// DOM state
	const [editTutorProfile, setEditTutorProfile] = useState();

	useEffect(() => {
		getProfiles();
	}, [props.ownerId]);

	function getProfiles() {
		const pathVariables = {
			tutorOwnerId: props.ownerId
		};

		const apiArr = [
			{
				api: $replace(GET_TUTOR_PROFILE, pathVariables),
				type: GET
			}, {
				api: GET_ACCOUNT_SETTINGS,
				type: POST,
				payload: {
					ownerId: ownerId
				}
			}
		];

		promiseAll(apiArr, resp => {
			const tutorProfile = resp[0].data;
			const personProfile = resp[1].data;

			const {
				allowedToTutor, isPublic, isHidden,
				occupation, bio,
				notificationPreference, trialPreference, instantBooking,
				inPersonTutoringRange,
				fluentLanguages, disabilityAccommodations,
				teachingRates,
				cancellationPolicies, schedules,
				subjectToTopicsMap, subjectTopicProofs, subjectTopicQualificationStatus,
				screeningQuestionsUploadLocation,
				resumeUploadLocationUploadLocation, teachingCertificationUploadLocation, professionalExperienceUploadLocation, educationProofUploadLocation,
				linkedInUrl, websiteUrl,
				acceptedAgreements, hasActiveTutoringSessions
			} = tutorProfile;
			const {
				accountVerified,
				firstName, lastName, phoneNumber,
				line1Address, line2Address, cityAddress, stateAddress, country,
				postalCodeAddress, postalAddressKana, postalAddressKanji,
				profilePictureUploadLocation,
				areRequiredPersonalInformationFilledForTutors,
				accountNumberSample, routingNumberSample
			} = personProfile;

			// used to refresh <TutorProfile />
			const objectHash = JSON.stringify(tutorProfile).concat(JSON.stringify(personProfile));

			let activity = allowedToTutor ? "active" : "inactive";
			setProfileActivityDisplay(activity);

			setProfileIsPublic(isPublic);
			setProfileVisibility(isHidden ? "hidden" : "shown");

			setProfilePicture(profilePictureUploadLocation);
			setFirstName(firstName);
			setLastName(lastName);
			setCountry(country);
			// file sets
			setScreeningDocument(screeningQuestionsUploadLocation);
			setResumeDocument(resumeUploadLocationUploadLocation);
			setTeachingDocument(teachingCertificationUploadLocation);
			setProfessionalDocument(professionalExperienceUploadLocation);
			setEducationProofDocument(educationProofUploadLocation);
			// string sets
			setLinkedInUrl(linkedInUrl);
			setWebUrl(websiteUrl);
			setSubjectTopics(subjectToTopicsMap);
			setSubjectTopicProofs(subjectTopicProofs);
			setSubjectTopicReviewStatus(subjectTopicQualificationStatus);

			let hasCompleteAddress = false;
			if (line1Address != null
				&& cityAddress != null && stateAddress != null
				&& country != null 
				&& (postalCodeAddress != null || postalAddressKana != null || postalAddressKanji != null)) {
				hasCompleteAddress = true;
			}

			setEditTutorProfile(
				<TutorProfile key={objectHash} setAlert={props.setAlert} closeModal={closeModal} setModal={props.setModal1}
					isApplication={activity === "inactive" ? true : false} refreshProfile={getProfiles}
					// person profile
					accountVerified={accountVerified}
					firstName={firstName} lastName={lastName} phoneNumber={phoneNumber}
					hasCompleteAddress={hasCompleteAddress}
					country={country}
					profilePictureUploadLocation={profilePictureUploadLocation}
					areRequiredPersonalInformationFilled={areRequiredPersonalInformationFilledForTutors}
					requiredFields={props.requiredFields}
					// tutor profile
					occupation={occupation} bio={bio}
					notificationPreference={notificationPreference} trialPreference={trialPreference} instantBooking={instantBooking}
					inPersonTutoringRange={inPersonTutoringRange}
					fluentLanguages={fluentLanguages} disabilityAccommodations={disabilityAccommodations}
					teachingRates={teachingRates}
					cancellationPolicies={cancellationPolicies} schedules={schedules}
					subjectTopics={subjectToTopicsMap} subjectTopicProofs={subjectTopicProofs} subjectTopicReviewStatus={subjectTopicQualificationStatus}
					screeningDocument={screeningQuestionsUploadLocation} resumeDocument={resumeUploadLocationUploadLocation}
					teachingDocument={teachingCertificationUploadLocation} professionalDocument={professionalExperienceUploadLocation}
					educationProofDocument={educationProofUploadLocation}
					linkedInUrl={linkedInUrl} webUrl={websiteUrl}
					accountNumberSample={accountNumberSample} routingNumberSample={routingNumberSample}
					hasActiveTutoringSessions={hasActiveTutoringSessions} />
			);
		});
	}

	function closeModal(e) {
		if (e == null || (e != null && ["modal-block", "cancel", "icon", "close-button", "cancel-button"].includes(e.target.className))) {
			setTimeout(() => {
				props.setModal(null);
			}, MODAL_CLOSE_TIMEOUT);
			getProfiles();
		}
	}

	function createTutoringProfile() {
		props.setModal(
			<Modal closeType="xButton" modalClass="become-tutor-modal" closeHandler={closeModal}
				title={messageMap("profilePage.tutoring.modal.becomeATutor", "generic")}
				submitText={messageMap("save.tutoringProfile", "button")}>

				<TutorProfile key={Date.now()} setAlert={props.setAlert} closeModal={closeModal} setModal={props.setModal1}
					isApplication={true} accountVerified={props.accountVerified}
					firstName={firstName} lastName={lastName} country={country}
					profilePictureUploadLocation={profilePicture}
					areRequiredPersonalInformationFilled={false}
					screeningDocument={screeningDocument} resumeDocument={resumeDocument}
					teachingDocument={teachingDocument} professionalDocument={professionalDocument}
					educationProofDocument={educationProofDocument}
					linkedInUrl={linkedInUrl} webUrl={webUrl}
					subjectTopics={subjectTopics} subjectTopicProofs={subjectTopicProofs} subjectTopicReviewStatus={subjectTopicReviewStatus} />

			</Modal>
		);
	}

	function closeAlert() {
		props.setAlert(null);
	}

	function toggleProfileOptions() {
		profileActionsDisplay === "hide" ? setProfileActionsDisplay("") : setProfileActionsDisplay("hide");
	}

	function controlTutorProfileVisibility(action) {
		const title = action === "hide" ? "tutorProfile.visibility.hide" : "tutorProfile.visibility.show";
		props.setModal(
			<ConfirmModal title={messageMap(title, "validation")}
				closeArgs={action} closeModal={toggleProfileVisibility}
				confirmType="verify" />
		);
	}

	function toggleProfileVisibility(e, action) {
		if (e !== null && ["cancel"].includes(e.target.className)) {
			closeModal(e);
		}
		else if (action != null) {
			toggleTutorProfileVisibilityAPI(ownerId, resp => {
				props.setAlert(
					<Alert type={INFORMATION} closeHandler={closeAlert} msg={messageMap(resp, "api")} />
				);
				setProfileVisibility(action === "hide" ? "hidden" : "shown");
				closeModal(e);
			});
		}
	}

	return (
		<div className={profileActivityDisplay === "inactive" ? "" : "edit-tutor-profile"}>
			<h1 className="page-title">
				{messageMap("profile.tutoring", "subPageNavigation")}
			</h1>

			<div className={`profile-ellipsis-options-container ${profileActivityDisplay === "inactive" ? "hide" : ""}`}>
				{
					profileIsPublic
					&& (
						<Fragment>
							<div className="ellipsis-container">
								<div></div>
								<div className="ellipsis-icon-container">
									<img className="ellipsis" src={ellipsisVerticalAsset} alt={messageMap("ellipsisVertical", "image")}
										tabIndex={0} role="button"
										onClick={toggleProfileOptions} onKeyDown={e => onKeyDown(e, toggleProfileOptions, [e])}/>
								</div>
							</div>
							<div className={`profile-ellipsis-options-flex ${profileActionsDisplay}`}>
								<div></div>
								<div className="profile-ellipsis-options">
									<button className={`ellipsis-option hide-action ${profileVisibility}`}
										onClick={e => controlTutorProfileVisibility("hide")}>
										{messageMap("tutoring.activeProfile.hideProfile", "button")}
									</button>
									<button className={`ellipsis-option show-action ${profileVisibility}`}
										onClick={e => controlTutorProfileVisibility("show")}>
										{messageMap("tutoring.activeProfile.showProfile", "button")}
									</button>
								</div>
							</div>
						</Fragment>
					)
				}
			</div>

			<div className="page-field">
				<div className={`tutor-profile-options applicant ${profileActivityDisplay}`}>
					<label className="page-field-label tutor-label">
						{messageMap("profilePage.tutoring.fields.tutorWithUs", "generic")}
					</label>
					<div className={`applicant`}>
						<button className="page-field update-field tutoring" onClick={createTutoringProfile}>
							{messageMap("tutoring.noProfile.becomeTutor", "button")}
						</button>
					</div>
				</div>

				<div className={`tutor-profile-options accepted ${profileActivityDisplay}`}>
					<div>
						{editTutorProfile}
					</div>
				</div>

			</div>
		</div>
	);
}

TutoringSubPage.propTypes = {
	accountVerified: PropTypes.bool.isRequired,
	requiredFields: PropTypes.arrayOf(PropTypes.string),
	accountNumberSample: PropTypes.string,
	routingNumberSample: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	country: PropTypes.string,
	profilePictureUploadLocation: PropTypes.string,
	// parent state setters
	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,

	// redux states
	ownerId: PropTypes.string.isRequired
};

export default connect(
	account, null
)(TutoringSubPage);

