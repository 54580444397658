import React, { useRef, useState, useEffect, Fragment } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { updateShinyNeuronsBalance } from 'redux/actions/actionTypes';
import account from "redux/selectors/accountSelector";

import Dropdown from "templates/Dropdown";
import Alert, { SUCCESS, ERROR } from "templates/Alert";
import Modal from "templates/Modal";
import Arithmetic from "./components/Math/Arithmetic";
import Matching from "./components/Math/Matching";
import StatBag from "./components/ui/StatBag";
import TopicNavigator from "pages/Classes/Components/TopicNavigator";
import Username from "templates/components/Username";
import PageSwitcher, { VIDEO_PAGE, PRACTICE_PAGE, INFO_PAGE } from "pages/Classes/Components/PageSwitcher";

import { getWolframAlphaStepByStepAnswerAPI } from "apis/controllers/thirdParty/APIKeyController";
import {
	getPersonProgressAPI, saveQuestExpPointsAPI,
	saveQuestLevelPercentageAPI, saveQuestLevelAPI,
	updateInventoryItemCountAPI, saveQuestProgressAPI
} from "apis/controllers/person/PersonProgressController";
import { purchaseHintAPI, purchaseAnswerAPI, purchaseLevelAPI } from "apis/controllers/transactions/ResourcePurchaseController";
import { getQuestProgressAPI } from "apis/controllers/person/PersonProgressController";

import messageMap from "Utilities/MessageMaps";
import { importShinyNeuron } from "Utilities/ShinyNeuronImages";
import { recurseToFindPreviousTopic, recurseToFindNextTopic } from "pages/Classes/Learn/LearnUtilities";
import {
	ID_TO_OBJECT_KEYS_MAP_MAP, findWorkbookFromSubject,
	generateKnowledgeNetwork, getQuestDetailsFromId
} from "diagrams/utilities/NetworkGenerator";
import { getRandomNumberBetween } from "pages/Classes/Practice/utilities/MathUtilities";
import {
	HARD, MEDIUM, EASY, LEVEL_EXP_DIFFERENCE,
	hardExpGain, mediumExpGain, easyExpGain, calculateExpForLevel,
	calculateHintsToReward, calculateAnswersToReward, calculateLevelToReward,
} from "pages/Classes/Practice/utilities/GameUtilities";
import {
	statBagModalClass, statBagModalContainerStyle, statBagCloseButtonStyle,
	statBagModalTitleStyle
} from "pages/Classes/Practice/utilities/PracticeUtilities";
import { askUserToSignUpFirst } from "templates/customModals/utilities/SignUpLoginUtilities";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";

import {
	CORRECT, WRONG,
	TRUE_FALSE, MULTIPLE_CHOICE, FILL_IN_THE_BLANK,
	HINTS, ANSWERS, LEVELS,
	LOCAL_HINT, LOCAL_ANSWER
} from "./constants/PracticeConstants";

import checkAsset from "assets/icons/practice/check.svg";
import crossAsset from "assets/icons/practice/cross.svg";
import partyPopperAsset from "assets/images/practice/celebration/party-popper.svg";
import bagAsset from "assets/images/practice/inventory/bag.svg";
import tutorialAsset from "assets/images/practice/inventory/tutorial.svg";
import answerAsset from "assets/images/practice/inventory/answer.svg";
import hintAsset from "assets/images/practice/inventory/hint.svg";
import levelAsset from "assets/images/practice/inventory/level.svg";

import topicCompleteAudioAsset from "assets/audio/practice/topic-complete.mp3";
import correctAnswerAudioAsset from "assets/audio/practice/correct-answer.wav";
import acquireItemAudioAsset from "assets/audio/practice/acquire-item.wav";
import openModalAudioAsset from "assets/audio/practice/open-modal.wav";
import closeModalAudioAsset from "assets/audio/practice/close-modal.wav";


function Practice(props) {

	// DOM states
	const [pageSwitcher, setPageSwitcher] = useState(),
		[topicNavigator, setTopicNavigator] = useState(),
		[alert, setAlert] = useState(),
		[modal, setModal] = useState(),
		[modal1, setModal1] = useState(),
		[generatedProblem, setGeneratedProblem] = useState(),
		[problemHint, setProblemHint] = useState(null),
		[correctAnswer, setCorrectAnswer] = useState(null),
		[exerciseMode, setExerciseMode] = useState(TRUE_FALSE),
		[personProgress, setPersonProgress] = useState(null),
		[questPoints, setQuestPoints] = useState(0),
		[questLevel, setQuestLevel] = useState(0),
		[pointsToLevelUp, setPointsToLevelUp] = useState(-1),
		[levelCompletion, setLevelCompletion] = useState(0),
		[savedHint, setSavedHint] = useState(null),
		[savedAnswer, setSavedAnswer] = useState(null),
		[errorCounter, setErrorCounter] = useState(0),
		[accountBlockText, setAccountBlockText] = useState(null),
		[isJourneyState, setIsJourneyState] = useState();

	// value refs
	const questTreeVideoBelongsToRef = useRef(),
		nextQuestionComponentRef = useRef(),
		answerCorrectnessComponentRef = useRef(WRONG),
		recordAnswerComponentRef = useRef(),
		finishedAllQuestionsComponentRef = useRef(false),
		practiceTopicIdRef = useRef(props.routerProp.match.params.topicId.replaceAll("-", " ")),
		practiceTopicDetailsRef = useRef(getStateProp("topicDetails")),
		practiceWorkbookRef = useRef(getStateProp("workbook")),
		practiceSubjectRef = useRef(getStateProp("subject")),
		isOnJourneyRef = useRef(getStateProp("isJourney") || false),
		questTopicDetailsToStartOrResume = useRef(),
		// dom refs
		accountBlockerRef = useRef(),
		expBarMeterRef = useRef(null),
		expBarMeterAudioRef = useRef(),
		openModalAudioRef = useRef(),
		closeModalAudioRef = useRef(),
		acquireItemAudioRef = useRef(),
		answerFeedbackRef = useRef(),
		answerFeedbackContainerRef = useRef(),
		answerButtonRef = useRef(),
		hintButtonRef = useRef(),
		nextButtonContainerRef = useRef(),
		submitButtonRef = useRef();

	const shinyNeuronAsset = importShinyNeuron(1);
	const ownerId = props.ownerId || localStorage.getItem("ownerId");
	const keyToAssetName = {
		tutorials: tutorialAsset,
		answers: answerAsset,
		hints: hintAsset,
		levels: levelAsset,
		shinyNeurons: shinyNeuronAsset
	};
	const keyToShinyNeuronsCost = {
		[HINTS]: "3 shiny neurons",
		[ANSWERS]: "10 shiny neurons",
		[LEVELS]: "19 shiny neurons"
	};
	const keyToPurchaseAPI = {
		[HINTS]: purchaseHintAPI,
		[ANSWERS]: purchaseAnswerAPI,
		[LEVELS]: purchaseLevelAPI
	}
	const optionValueToTextMap = {
		[TRUE_FALSE]: messageMap("practicePage.options.trueFalse", "generic"),
		[MULTIPLE_CHOICE]: messageMap("practicePage.options.multipleChoice", "generic"),
		[FILL_IN_THE_BLANK]: messageMap("practicePage.options.fillInTheBlank", "generic")
	};

	useEffect(() => {
		populateMissingProps(() => {
			saveTopicProgress();

			setExerciseModeOption();
			populateStatsAndInventory();

			generateProblem(TRUE_FALSE);
			renderPageSwitcher();
			generateKnowledgeNetwork("root", 1, 0, practiceWorkbookRef.current, "id", () => {
				checkPreviousAndNextTopicAvailability();
			});
		});
	}, [practiceTopicIdRef.current, practiceTopicDetailsRef.current, isJourneyState, props.ownerId]);

	function getStateProp(propName) {
		return props && props.routerProp && props.routerProp.location.state && props.routerProp.location.state[propName];
	}

	function populateMissingProps(callback) {
		if (!practiceTopicIdRef.current || !practiceTopicDetailsRef.current || !practiceWorkbookRef.current) {
			const urlLocation = window.location.href;
			const subjectRegEx = new RegExp("(?<=subject=).*");
			const subject = subjectRegEx.exec(urlLocation)[0];
			const workbook = findWorkbookFromSubject(subject);
			const populateCallback = (questDetails) => {
				practiceWorkbookRef.current = workbook;
				practiceSubjectRef.current = subject;
				practiceTopicIdRef.current = topicId;
				practiceTopicDetailsRef.current = questDetails[0][topicId];
				isOnJourneyRef.current = false;

				callback();
			};

			let topicId = practiceTopicIdRef.current;
			// only need to check one object map to see if knowledgeNetwork is already initialized
			if (!Object.keys(ID_TO_OBJECT_KEYS_MAP_MAP.GradeSchool).length
				&& !Object.keys(ID_TO_OBJECT_KEYS_MAP_MAP.Degrees).length
				&& !Object.keys(ID_TO_OBJECT_KEYS_MAP_MAP.Jobs).length) {
				generateKnowledgeNetwork("root", 1, 0, workbook, "id", () => {
					const questDetails = getQuestDetailsFromId(topicId)
					populateCallback(questDetails);
				});
			}
			else {
				const questDetails = getQuestDetailsFromId(topicId)
				populateCallback(questDetails);
			}
		}
		else {
			callback();
		}
	}

	function populateStatsAndInventory() {
		if (ownerId) {
			getPersonProgressAPI(ownerId, resp => {
				setPersonProgress(resp);

				if (resp) {
					let currentTotalExpPoints = resp.questExpPoints[practiceTopicDetailsRef.current.rootLabel];
					currentTotalExpPoints = currentTotalExpPoints == null ? 0 : currentTotalExpPoints;
					setQuestPoints(currentTotalExpPoints);

					let currentLevel = resp.questLevel[practiceTopicDetailsRef.current.rootLabel];
					currentLevel = currentLevel == null ? 1 : currentLevel;
					setQuestLevel(currentLevel);

					const totalExpForCurrentLevel = calculateExpForLevel(currentLevel);
					let currentLevelPercentageCompletion = resp.questLevelPercentage[practiceTopicDetailsRef.current.rootLabel];
					currentLevelPercentageCompletion = currentLevelPercentageCompletion == null ? 0 : currentLevelPercentageCompletion;
					setLevelCompletion(currentLevelPercentageCompletion);

					expBarMeterRef.current.style.width = `${currentLevelPercentageCompletion}%`;

					const totalExpForNextLevel = calculateExpForLevel(currentLevel + 1);
					const levelExpDifference = totalExpForNextLevel - totalExpForCurrentLevel;
					setPointsToLevelUp(levelExpDifference - (currentLevelPercentageCompletion / 100 * levelExpDifference));
				}
				else {
					const currentLevel = 1;

					setQuestPoints(0);
					setQuestLevel(currentLevel);

					const totalExpForCurrentLevel = calculateExpForLevel(currentLevel);
					let currentLevelPercentageCompletion = 0;
					setLevelCompletion(currentLevelPercentageCompletion);

					expBarMeterRef.current.style.width = `${currentLevelPercentageCompletion}%`;

					const totalExpForNextLevel = calculateExpForLevel(currentLevel + 1);
					const levelExpDifference = totalExpForNextLevel - totalExpForCurrentLevel;
					setPointsToLevelUp(levelExpDifference - (currentLevelPercentageCompletion / 100 * levelExpDifference));
				}

				if (isOnJourneyRef.current || isJourneyState) {
					accountBlockerRef.current.className = "account-blocker hide";
				}
				else {
					accountBlockerRef.current.className = "account-blocker";
					getQuestProgressAPI(ownerId, resp => {
						const subjectKeys = Object.keys(resp);
						if (subjectKeys.includes(practiceSubjectRef.current)) {
							const topicId = resp[practiceSubjectRef.current];
							const questDetails = getQuestDetailsFromId(topicId)
							questTopicDetailsToStartOrResume.current = {
								topicId: topicId,
								questDetails: questDetails[0][topicId]
							};
							setAccountBlockText(
								messageMap("practicePage.stats.resumeQuest", "generic")
							);
						}
						else {
							setAccountBlockText(
								messageMap("practicePage.stats.startQuest", "generic")
							);
						}
					});
				}
			});
		}
		else {
			accountBlockerRef.current.className = "account-blocker";
		}
	}

	function startOrResumeQuest() {
		if (ownerId) {
			practiceTopicIdRef.current = questTopicDetailsToStartOrResume.current.topicId;
			practiceTopicDetailsRef.current = questTopicDetailsToStartOrResume.current.questDetails;
			isOnJourneyRef.current = true;
			setIsJourneyState(true);
		}
		else {
			askUserToSignUpFirst(setModal, setModal1, closeModalCallback);
		}
	}

	function closeModalCallback(modalType) {
		if (modalType === "login") {
			isOnJourneyRef.current = true;
			setIsJourneyState(true);
		}
	}

	function updateUserStats() {
		const exerciseModeMap = {
			[TRUE_FALSE]: EASY,
			[MULTIPLE_CHOICE]: MEDIUM,
			[FILL_IN_THE_BLANK]: HARD
		};
		const exerciseDifficultyToPointCalculationMap = {
			[EASY]: easyExpGain,
			[MEDIUM]: mediumExpGain,
			[HARD]: hardExpGain
		};

		const practiceArgs = practiceTopicDetailsRef.current.practiceArgs.split("|");
		let topicLevel = practiceArgs.filter(word => word.includes("level"))[0];
		topicLevel = topicLevel.split("_")[1];

		const expGain = exerciseDifficultyToPointCalculationMap[exerciseModeMap[exerciseMode]](topicLevel, questLevel);

		let newQuestLevel = questLevel;
		const curPointsInLevel = (levelCompletion / 100 * LEVEL_EXP_DIFFERENCE) + expGain;
		// check for level up
		if (curPointsInLevel / LEVEL_EXP_DIFFERENCE >= 1) {
			newQuestLevel = questLevel + 1;
			increaseLevel(newQuestLevel);
		}

		updateLevelPercentage(curPointsInLevel);
		updateQuestExpPoints(questPoints + expGain);
	}

	function checkPreviousAndNextTopicAvailability() {
		const questDetails = getQuestDetailsFromId(practiceTopicIdRef.current);
		questTreeVideoBelongsToRef.current = questDetails[1];

		const previousTopic = getPreviousTopic();
		const nextTopic = getNextTopic();
		let hideNextTopicButton = "hide";
		let hidePrevTopicButton = "hide";

		if (nextTopic && nextTopic[1].practiceCategory && nextTopic[1].practiceID && nextTopic[1].practiceArgs) {
			hideNextTopicButton = null;
		}

		if (previousTopic && previousTopic[1].practiceCategory && previousTopic[1].practiceID && previousTopic[1].practiceArgs) {
			hidePrevTopicButton = null;
		}

		setTopicNavigator(
			<TopicNavigator goToPreviousTopic={goToPreviousTopic} goToNextTopic={goToNextTopic}
				showOrHideLeftButton={hidePrevTopicButton} showOrHideRightButton={hideNextTopicButton} />
		);
	}

	function getPreviousTopic() {
		return recurseToFindPreviousTopic(null, null, questTreeVideoBelongsToRef.current, practiceTopicIdRef.current);
	}

	function getNextTopic() {
		return recurseToFindNextTopic(null, null, questTreeVideoBelongsToRef.current, practiceTopicIdRef.current);
	}

	function goToPreviousTopic() {
		const previousTopic = getPreviousTopic();
		practiceTopicIdRef.current = previousTopic[0];
		practiceTopicDetailsRef.current = previousTopic[1];
		finishedAllQuestionsComponentRef.current = false;

		changeProblemMode(TRUE_FALSE);
	}

	function goToNextTopic() {
		const nextTopic = getNextTopic();
		practiceTopicIdRef.current = nextTopic[0];
		practiceTopicDetailsRef.current = nextTopic[1];
		finishedAllQuestionsComponentRef.current = false;

		hideModal("default");
		changeProblemMode(TRUE_FALSE);
	}

	function changeProblemMode(newExerciseMode) {
		setExerciseMode(newExerciseMode);
		resetForNextQuestion(true);
		generateProblem(newExerciseMode);
	}

	function setExerciseModeOption(event) {
		let newExerciseMode;
		if (event == null) {
			newExerciseMode = TRUE_FALSE;
		}
		else {
			const modeOptionKey = event.target.getAttribute("optionkey");
			newExerciseMode = modeOptionKey;
		}

		return newExerciseMode;
	}

	function renderPageSwitcher() {
		setPageSwitcher(
			<PageSwitcher key={practiceTopicIdRef.current} curPage={PRACTICE_PAGE} leftPage={INFO_PAGE} rightPage={VIDEO_PAGE}
				workbook={practiceWorkbookRef.current} subject={practiceSubjectRef.current}
				topicId={practiceTopicIdRef.current} isOnJourney={isOnJourneyRef.current} />
		);
	}

	function generateProblem(newExerciseMode) {
		setGeneratedProblem(
			practiceTopicDetailsRef.current.practiceCategory === "matching"
				? (
					<Matching exerciseMode={newExerciseMode} matchId={practiceTopicDetailsRef.current.practiceID}
						setGenerateNewQuestionFunc={recordNewQuestionGenerator}
						setUserAnswer={recordUserAnswer}
						answerCorrectness={answerCorrectnessComponentRef} setAnswerCorrectness={recordAnswerCorrectness}
						finishedAllQuestions={finishedAllQuestionsComponentRef} setFinishedAllQuestions={recordFinishedAllQuestions}
						setCorrectAnswer={recordCorrectAnswer}
						setProblemHint={recordHint} />
				)
				: (
					<Arithmetic setGenerateNewQuestionFunc={recordNewQuestionGenerator}
						setUserAnswer={recordUserAnswer}
						answerCorrectness={answerCorrectnessComponentRef} setAnswerCorrectness={recordAnswerCorrectness}
						// not being used, since WolframAlpha is for this
						setCorrectAnswer={recordCorrectAnswer}
						// TODO: integrate this in the flow
						setProblemHint={recordHint}
						orientation="vertical" />
				)
		);
	}

	function saveTopicProgress() {
		if (isOnJourneyRef.current) {
			const payload = {
				ownerId: ownerId,
				stringToStringMap: {
					[practiceTopicDetailsRef.current.rootLabel]: practiceTopicIdRef.current
				}
			};
			saveQuestProgressAPI(payload, resp => {
				// not showing <Alert/> because this can easily look spammy every time someone goes to the next topic
				console.log("Quest progress saved.: ", resp);
			});
		}
	}

	function recordNewQuestionGenerator(generatorFunction) {
		nextQuestionComponentRef.current = generatorFunction;
	}

	function recordUserAnswer(userAnswer) {
		recordAnswerComponentRef.current = userAnswer;
	}

	function recordHint(hint) {
		setProblemHint(hint);
	}

	function recordCorrectAnswer(answer) {
		setCorrectAnswer(answer);
	}

	function recordAnswerCorrectness(rightOrWrong) {
		answerCorrectnessComponentRef.current = rightOrWrong;
	}

	function recordFinishedAllQuestions(haveFinishedAllQuestions) {
		finishedAllQuestionsComponentRef.current = haveFinishedAllQuestions;
	}

	// TODO: fix this for Phase 2 Workout
	function resetForNextQuestion(clearDifficulty) {
		hideFeedback();
		nextButtonContainerRef.current.className = "next-problem-container hide";
		submitButtonRef.current.disabled = false;

		answerButtonRef.current.className = practiceTopicDetailsRef.current.practiceArgs.includes("no_answer") ? "help-button hide" : "help-button"
		hintButtonRef.current.className = practiceTopicDetailsRef.current.practiceArgs.includes("no_hint") ? "help-button hide" : "help-button";

		setErrorCounter(0);
		setSavedHint(null);
		setSavedAnswer(null);
		answerCorrectnessComponentRef.current = WRONG;
		setCorrectAnswer(null);
		setProblemHint(null);

		// const numToExerciseMode = {
		// 	1: TRUE_FALSE,
		// 	2: MULTIPLE_CHOICE,
		// 	3: FILL_IN_THE_BLANK
		// };
		// const upcomingExerciseMode = numToExerciseMode[getRandomNumberBetween(1, 3)];
		// setExerciseMode(upcomingExerciseMode);
		// generateProblem(upcomingExerciseMode);

		nextQuestionComponentRef.current(clearDifficulty);
	}

	function submitAnswer() {
		recordAnswerComponentRef.current();

		if (answerCorrectnessComponentRef.current === CORRECT) {
			answerFeedbackRef.current.src = checkAsset;
			showFeedback();

			if (finishedAllQuestionsComponentRef.current) {
				const modalContainerStyleSchema = {
					maxWidth: "450px",
					padding: "32px",
					height: "auto",
					backgroundColor: "#fff",
					borderRadius: "16px"
				};
				const titleStyle = {
					fontSize: "20px"
				};
				const closeButtonStyle = {
					marginTop: "-24px",
					marginRight: "-24px"
				};
				const nextButtonStyle = {
					fontSize: "14px",
					fontWeight: 600,
					border: "none",
					backgroundColor: "white",
					display: "block",
					margin: "auto",
					marginTop: "16px"
				};

				const nextTopic = getNextTopic();

				resetForNextQuestion(true);
				setModal(
					<Modal closeType={"xButton"} closeHandler={closeModal}
						modalContainerStyle={modalContainerStyleSchema} closeButtonStyle={closeButtonStyle}
						titleStyle={titleStyle}
						title={messageMap("practicePage.solvedAll", "generic")}>
						<img src={partyPopperAsset} alt="Confetti popping out of a party hat"
							style={{ height: "200px", width: "200px" }}></img>
						<audio src={topicCompleteAudioAsset} autoPlay={true}></audio>
						{
							nextTopic && nextTopic[1].practiceCategory && nextTopic[1].practiceID && nextTopic[1].practiceArgs
								? (
									<button style={nextButtonStyle} onClick={goToNextTopic}>
										{messageMap("learnPage.navigation.nextTopic", "generic")}
									</button>
								)
								: null
						}
					</Modal>
				);
			}
			else {
				nextButtonContainerRef.current.className = "next-problem-container";
			}

			submitButtonRef.current.disabled = true;
			expBarMeterAudioRef.current.play();
			isOnJourneyRef.current && updateUserStats();
		}
		else if (answerCorrectnessComponentRef.current === WRONG) {
			answerFeedbackRef.current.src = crossAsset;
			nextButtonContainerRef.current.className = "next-problem-container hide";

			showFeedback();
			submitButtonRef.current.disabled = false;

			if (errorCounter + 1 === 2 && hintButtonRef.current.className === "help-button") {
				hintButtonRef.current.className = "help-button hint";
			}
			if (errorCounter + 1 === 4 && answerButtonRef.current.className === "help-button") {
				answerButtonRef.current.className = "help-button answer";
			}

			setErrorCounter(errorCounter + 1);
		}
	}

	function showFeedback() {
		answerFeedbackContainerRef.current.className = "feedback";
	}

	function hideFeedback() {
		answerFeedbackContainerRef.current.className = "feedback hide";
	}

	function closeModal(e) {
		if (e != null && ["modal-block", "cancel", "fullRegistration", "icon", "close-button"].includes(e.target.className)) {
			closeModalAudioRef.current.play();
			hideModal("default");
		}
	}

	function closeModal1(e, args) {
		if (e != null && ["modal-block", "cancel", "fullRegistration", "icon", "close-button"].includes(e.target.className)) {
			hideModal("modal1");
		}
		else if (e === null && args === "loginSuccess") {
			hideModal("default");
			hideModal("modal1");
		}
	}

	function hideModal(modalType) {
		const modalMap = {
			default: setModal,
			modal1: setModal1
		};

		setTimeout(() => {
			modalMap[modalType](null);
		}, MODAL_CLOSE_TIMEOUT);
	}

	function closeAlert() {
		setAlert(null);
	}

	function purchaseItem(itemType, itemCost) {
		const payload = {
			ownerId: ownerId,
			resourceCost: itemCost
		};
		const pathVariable = {
			asItem: true
		};
		keyToPurchaseAPI[itemType](pathVariable, payload, resp => {
			const hasNoError = checkResponseForErrors(resp[0]);

			if (hasNoError === SUCCESS) {
				personProgress.inventory[itemType] = personProgress.inventory[itemType] + 1;
				setPersonProgress(personProgress);

				hideModal("default");

				props.updateShinyNeuronsBalance(props.shinyNeurons - itemCost);
				parseResourcePurchaseResponse(resp);

				acquireItemAudioRef.current.play();
			}
			else {
				parseResourcePurchaseResponse(resp)
			}
		});
	}

	function openShop() {
		if (ownerId) {
			const purchaseButtonStyle = {
				color: "white",
				backgroundColor: "#007F5C",
				borderRadius: "60px",
				border: "none",
				padding: "8px 32px",
				width: "160px"
			};

			let shopItems = [];
			[HINTS, ANSWERS, LEVELS].forEach(item => {
				const costDisplay = keyToShinyNeuronsCost[item];
				const itemCost = Number(costDisplay.split(" ")[0]);

				shopItems.push(
					<div key={item} style={{ display: "inline-block" }}>
						<StatBag value={keyToAssetName[item]}
							customContainerClass="shop-item"
							label={messageMap(`practicePage.bag.${item}`, "generic")} />
						<button style={purchaseButtonStyle} onClick={e => purchaseItem(item, itemCost)}>
							{costDisplay}
						</button>
					</div>
				);
			});

			let statBagModalContainerStyleModified = structuredClone(statBagModalContainerStyle);
			statBagModalContainerStyleModified.width = "initial";
			statBagModalContainerStyleModified.maxWidth = "592px";
			setModal(
				<Modal closeType={"xButton"} closeHandler={closeModal}
					title={messageMap("practicePage.shop.title", "generic")}
					modalStyle={statBagModalClass}
					modalContainerStyle={statBagModalContainerStyleModified} titleStyle={statBagModalTitleStyle}>
					<div style={{ display: "flex", justifyContent: "space-evenly" }}>
						{shopItems}
					</div>
				</Modal>
			);
			openModalAudioRef.current.play();
		}
		else {
			askUserToSignUpFirst(setModal, setModal1);
		}
	}

	function openBag() {
		if (ownerId) {
			let customContainerStyle = {
				display: "table-cell",
				paddingRight: "24px",
				paddingBottom: "24px"
			};

			let bagRows = [];
			let bagContents = [];
			if (personProgress && personProgress.inventory) {
				for (const [key, value] of Object.entries(personProgress.inventory)) {
					const actualValue = key === "shiny neurons" ? props.shinyNeurons : value;

					if (bagContents.length % 3 === 0 && bagContents.length) {
						bagRows.push(
							<div key={bagRows.length + 1 + "_row"} style={{ display: "table-row" }}>
								{bagContents}
							</div>
						);

						if (bagRows.length) {
							customContainerStyle.paddingBottom = "0";
						}

						bagContents = [];
						bagContents.push(
							<div key={key} style={customContainerStyle}>
								<StatBag value={keyToAssetName[key]} itemCount={actualValue}
									customContainerClass="bag-item"
									label={messageMap(`practicePage.bag.${key}`, "generic")} />
							</div>
						);
					}
					else {
						let customContainerStyleClone = structuredClone(customContainerStyle);
						if (bagContents.length === 2) {
							customContainerStyleClone.paddingRight = "0";
						}

						bagContents.push(
							<div style={customContainerStyleClone}>
								<StatBag key={key} value={keyToAssetName[key]} itemCount={actualValue}
									customContainerClass="bag-item"
									label={messageMap(`practicePage.bag.${key}`, "generic")} />
							</div>
						);
					}
				}
			}
			bagRows.push(
				<div key={bagRows.length + 1 + "_row"} style={{ display: "table-row" }}>
					{bagContents}
				</div>
			);

			setModal(
				<Modal closeType={"xButton"} closeHandler={closeModal}
					title={messageMap("practicePage.bag.text", "generic")}
					modalStyle={statBagModalClass}
					modalContainerStyle={statBagModalContainerStyle} titleStyle={statBagModalTitleStyle}>
					<div style={{ display: "table", margin: "auto" }}>
						{bagRows}
					</div>
				</Modal>
			);
			openModalAudioRef.current.play();
		}
		else {
			askUserToSignUpFirst(setModal, setModal1);
		}
	}

	function showLevelOptions() {
		if (ownerId) {
			let statBagModalContainerStyleCopy = structuredClone(statBagModalContainerStyle);
			statBagModalContainerStyleCopy.width = "300px";
			statBagModalContainerStyleCopy.padding = "32px";

			setModal(
				<Modal closeType={"xButton"} closeButtonStyle={statBagCloseButtonStyle}
					closeHandler={closeModal}
					title={messageMap("practicePage.useResources.level", "generic")} titleStyle={statBagModalTitleStyle}
					modalStyle={statBagModalClass}
					modalContainerStyle={statBagModalContainerStyleCopy}>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<StatBag value={shinyNeuronAsset}
							label={19 + messageMap("practicePage.bag.shinyNeurons", "generic")}
							customContainerClass="use-resource"
							clickHandler={e => purchaseResource(19, LEVELS)} />
						<StatBag value={levelAsset} itemCount={personProgress.inventory.levels}
							label={messageMap("practicePage.bag.levels", "generic")}
							customContainerClass="use-resource"
							clickHandler={e => executeItemAction(LEVELS, true)} />
					</div>
				</Modal>
			);
			openModalAudioRef.current.play();
		}
		else {
			askUserToSignUpFirst(setModal, setModal1);
		}
	}

	function showAnswerOptions() {
		if (ownerId) {
			if (savedAnswer) {
				setModal1(savedAnswer);
			}
			else {
				let statBagModalContainerStyleCopy = structuredClone(statBagModalContainerStyle);
				statBagModalContainerStyleCopy.width = "300px";
				statBagModalContainerStyleCopy.padding = "32px";

				setModal(
					<Modal closeType={"xButton"} closeButtonStyle={statBagCloseButtonStyle}
						closeHandler={closeModal}
						title={messageMap("practicePage.useResources.answer", "generic")} titleStyle={statBagModalTitleStyle}
						modalStyle={statBagModalClass}
						modalContainerStyle={statBagModalContainerStyleCopy}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<StatBag value={shinyNeuronAsset}
								label={10 + messageMap("practicePage.bag.shinyNeurons", "generic")}
								customContainerClass="use-resource"
								clickHandler={e => purchaseResource(10, ANSWERS)} />
							<StatBag value={answerAsset} itemCount={personProgress.inventory[ANSWERS]}
								label={messageMap("practicePage.bag.answers", "generic")}
								customContainerClass="use-resource"
								clickHandler={e => executeItemAction(ANSWERS, true)} />
						</div>
					</Modal>
				);
				openModalAudioRef.current.play();
			}
		}
		else {
			askUserToSignUpFirst(setModal, setModal1);
		}
	}

	function showHintOptions() {
		if (ownerId) {
			if (savedHint) {
				setModal1(savedHint);
			}
			else {
				let statBagModalContainerStyleCopy = structuredClone(statBagModalContainerStyle);
				statBagModalContainerStyleCopy.width = "300px";
				statBagModalContainerStyleCopy.padding = "32px";

				setModal(
					<Modal closeType={"xButton"} closeButtonStyle={statBagCloseButtonStyle}
						closeHandler={closeModal}
						title={messageMap("practicePage.useResources.hint", "generic")} titleStyle={statBagModalTitleStyle}
						modalStyle={statBagModalClass}
						modalContainerStyle={statBagModalContainerStyleCopy}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<StatBag value={shinyNeuronAsset}
								label={3 + messageMap("practicePage.bag.shinyNeurons", "generic")}
								customContainerClass="use-resource"
								clickHandler={e => purchaseResource(3, HINTS)} />
							<StatBag value={hintAsset} itemCount={personProgress.inventory[HINTS]}
								label={messageMap("practicePage.bag.hints", "generic")}
								customContainerClass="use-resource"
								clickHandler={e => executeItemAction(HINTS, true)} />
						</div>
					</Modal>
				);
				openModalAudioRef.current.play();
			}
		}
		else {
			askUserToSignUpFirst(setModal, setModal1);
		}
	}

	function purchaseResource(resourceCost, resourceType) {
		const payload = {
			ownerId: ownerId,
			resourceCost: resourceCost
		};
		const pathVariable = {
			asItem: true
		};

		if (resourceType === LEVELS) {
			keyToPurchaseAPI[LEVELS](pathVariable, payload, resp => {
				const hasNoError = checkResponseForErrors(resp[0]);

				if (hasNoError === SUCCESS) {
					executeItemAction(LEVELS, false, () => {
						props.updateShinyNeuronsBalance(props.shinyNeurons - resourceCost);
						parseResourcePurchaseResponse(resp);
					});
				}
				else {
					parseResourcePurchaseResponse(resp)
				}
			});
		}
		else if (resourceType === ANSWERS) {
			keyToPurchaseAPI[ANSWERS](pathVariable, payload, resp => {
				const hasNoError = checkResponseForErrors(resp[0]);

				if (hasNoError === SUCCESS) {
					executeItemAction(ANSWERS, false, () => {
						props.updateShinyNeuronsBalance(props.shinyNeurons - resourceCost);
						parseResourcePurchaseResponse(resp);
					});
				}
				else {
					parseResourcePurchaseResponse(resp)
				}
			});
		}
		else if (resourceType === HINTS) {
			keyToPurchaseAPI[HINTS](pathVariable, payload, resp => {
				const hasNoError = checkResponseForErrors(resp[0]);

				if (hasNoError === SUCCESS) {
					executeItemAction(HINTS, false, () => {
						props.updateShinyNeuronsBalance(props.shinyNeurons - resourceCost);
						parseResourcePurchaseResponse(resp);
					});
				}
				else {
					parseResourcePurchaseResponse(resp)
				}
			});
		}
	}

	function checkResponseForErrors(resp) {
		return resp.includes("transaction") || resp.includes("failed") || resp.includes("invalid") || resp.includes("insufficientShinyNeurons") ? ERROR : SUCCESS;
	}

	function parseResourcePurchaseResponse(resp) {
		let alertType = checkResponseForErrors(resp[0]) === ERROR ? ERROR : SUCCESS;

		let alertMsgs = "";
		if (alertType === ERROR) {
			resp.forEach(respCode => {
				alertMsgs += messageMap(respCode, "api") + " ";
			});
		}
		else {
			alertMsgs = messageMap(resp[0], "api");
		}
		setAlert(
			<Alert closeHandler={closeAlert} type={alertType} msg={alertMsgs} />
		);

		return alertType === ERROR;
	}

	function executeItemAction(itemType, consumeInventoryItem, callback) {
		if (itemType === HINTS) {
			if (consumeInventoryItem) {
				const currentHintInventoryCount = personProgress.inventory[HINTS];
				if (currentHintInventoryCount) {
					updateInventoryItemCount(HINTS, currentHintInventoryCount, () => {
						showHint();
						hideModal("default");
						callback && callback();

						acquireItemAudioRef.current.play();
					});
				}
				else {
					showInsufficientInventoryItemAlert(HINTS);
				}
			}
			else {
				showHint();
				hideModal("default");
				callback && callback();

				acquireItemAudioRef.current.play();
			}
		}
		else if (itemType === ANSWERS) {
			if (consumeInventoryItem) {
				const currentAnswerInventoryCount = personProgress.inventory[ANSWERS];
				if (currentAnswerInventoryCount) {
					updateInventoryItemCount(ANSWERS, currentAnswerInventoryCount, () => {
						showAnswer(() => {
							hideModal("default");
							callback && callback();

							acquireItemAudioRef.current.play();
						});
					});
				}
				else {
					showInsufficientInventoryItemAlert(ANSWERS);
				}
			}
			else {
				showAnswer(() => {
					hideModal("default");
					callback && callback();

					acquireItemAudioRef.current.play();
				});
			}
		}
		else if (itemType === LEVELS) {
			if (consumeInventoryItem) {
				const currentLevelInventoryCount = personProgress.inventory[LEVELS];
				if (currentLevelInventoryCount) {
					updateInventoryItemCount(LEVELS, currentLevelInventoryCount, () => {
						increaseLevel(questLevel + 1);
						hideModal("default");
						callback && callback();

						acquireItemAudioRef.current.play();
					});
				}
				else {
					showInsufficientInventoryItemAlert(LEVELS);
				}
			}
			else {
				increaseLevel(questLevel + 1);
				hideModal("default");
				callback && callback();

				acquireItemAudioRef.current.play();
			}
		}
	}

	function showHint() {
		const isLocalHint = practiceTopicDetailsRef.current.practiceArgs ? practiceTopicDetailsRef.current.practiceArgs.includes(LOCAL_HINT) : [];

		let hint;
		if (isLocalHint) {
			hint = problemHint;
		}
		else {
			// TODO: fix this to query from ChatGPT (if needed) - Phase 2
			hint = "HINT";
		}

		const hintStyle = {
			fontSize: "16px",
			textAlign: "left"
		};

		const hintDOM = (
			<Modal closeType={"xButton"} closeHandler={closeModal1}
				title={messageMap("practicePage.resourceResp.hint", "generic")}
				modalStyle={statBagModalClass}
				modalContainerStyle={statBagModalContainerStyle} titleStyle={statBagModalTitleStyle}>
				<div style={hintStyle}>
					{hint}
				</div>
			</Modal>
		);

		setModal1(hintDOM);
		setSavedHint(hintDOM);
	}

	function showAnswer(callback) {
		const isLocalAnswer = practiceTopicDetailsRef.current.practiceArgs.includes(LOCAL_ANSWER);

		if (isLocalAnswer) {
			showAnswerModal(
				(<div style={{ fontSize: "16px" }}>
					{correctAnswer}
				</div>), false
			);
		}
		else {
			// TODO: fix this for Phase 2
			const pathVariables = {
				input: "solve 3x - 7 = 11"
			};

			getWolframAlphaStepByStepAnswerAPI(ownerId, pathVariables, resp => {
				let podMap = {};
				resp.queryresult.pods.forEach(pod => {
					podMap[pod.id] = pod.subpods
				});

				showAnswerModal(
					(<img key="step-by-step" style={{ width: "auto", height: "auto" }}
						src={podMap["Result"][1].img.src} alt={podMap["Result"][1].img.alt}></img>)
					, true
				);
			});
		}

		function showAnswerModal(answerContents, isQueried) {
			let modifiedContainerStyle = structuredClone(statBagModalContainerStyle);
			if (isQueried) {
				modifiedContainerStyle.width = "550px";
			}

			const answerDOM = (
				<Modal closeType={"xButton"} closeHandler={closeModal1}
					title={messageMap("practicePage.resourceResp.answer", "generic")}
					modalStyle={statBagModalClass}
					modalContainerStyle={modifiedContainerStyle} titleStyle={statBagModalTitleStyle}>
					<div>
						{answerContents}
					</div>
				</Modal>
			);

			setModal1(answerDOM);
			setSavedAnswer(answerDOM);
			callback && callback();
		}
	}

	function updateInventoryItemCount(itemType, currentItemCount, callback) {
		const newItemCount = currentItemCount - 1;
		const payload = {
			ownerId: ownerId,
			stringToIntegerMap: {
				[itemType]: newItemCount
			}
		};
		updateInventoryItemCountAPI(payload, resp => {
			if (resp.includes("success")) {
				personProgress.inventory[itemType] = newItemCount;
				setPersonProgress(personProgress);

				callback();
			}
		});
	}

	function showInsufficientInventoryItemAlert(inventoryItem) {
		setAlert(
			<Alert closeHandler={closeAlert} type={ERROR} msg={messageMap(`practice.bag.notEnough.${inventoryItem}`, "validation")} />
		);
	}

	// what happens when a level up is purchased:
	// 1.) Increase level
	// 2.) Maintain the same percentage completion for experience bar
	// 3.) Don't update total points
	function increaseLevel(newQuestLevel) {
		const payload = {
			ownerId: ownerId,
			stringToIntegerMap: {
				[practiceTopicDetailsRef.current.rootLabel]: newQuestLevel
			}
		};
		saveQuestLevelAPI(payload, resp => {
			if (resp.includes("purchases.levels.success") || resp === "practice.levelIncrease.success") {
				setQuestLevel(newQuestLevel);
				// TODO: play level up sound
			}
		});
	}

	function updateQuestExpPoints(newTotalQuestPoints) {
		const payload = {
			ownerId: ownerId,
			stringToIntegerMap: {
				[practiceTopicDetailsRef.current.rootLabel]: newTotalQuestPoints
			}
		};
		saveQuestExpPointsAPI(payload, resp => {
			if (resp === "practice.expIncrease.success") {
				setQuestPoints(newTotalQuestPoints);
				setErrorCounter(0);
			}
		});
	}

	function updateLevelPercentage(curPointsInLevel) {
		let newLevelCompletionPercentage = (curPointsInLevel / LEVEL_EXP_DIFFERENCE) * 100;
		let totalPointsToLevelUp = LEVEL_EXP_DIFFERENCE - curPointsInLevel;
		if (curPointsInLevel / LEVEL_EXP_DIFFERENCE >= 1) {
			newLevelCompletionPercentage = ((curPointsInLevel - LEVEL_EXP_DIFFERENCE) / LEVEL_EXP_DIFFERENCE) * 100;
			totalPointsToLevelUp = LEVEL_EXP_DIFFERENCE - (curPointsInLevel - LEVEL_EXP_DIFFERENCE);
		}

		const payload = {
			ownerId: ownerId,
			stringToIntegerMap: {
				[practiceTopicDetailsRef.current.rootLabel]: newLevelCompletionPercentage
			}
		};
		saveQuestLevelPercentageAPI(payload, resp => {
			if (resp === "practice.levelPercentageIncrease.success") {
				const previousLevelCompletionPercentage = levelCompletion;
				setLevelCompletion(newLevelCompletionPercentage);
				setPointsToLevelUp(totalPointsToLevelUp);

				// this block animates the exp-meter
				expBarMeterRef.current.style.setProperty("--from-width", `${previousLevelCompletionPercentage}%`);
				expBarMeterRef.current.style.setProperty("--to-width", `${newLevelCompletionPercentage}%`);
				expBarMeterRef.current.style.width = `${newLevelCompletionPercentage}%`;
				expBarMeterRef.current.className = "exp-meter with-animate";
				setTimeout(() => {
					expBarMeterRef.current.className = "exp-meter";
				}, 1500);
			}
		});
	}


	return (
		<div>

			{alert}
			{modal}
			{modal1}

			<audio src={openModalAudioAsset} ref={openModalAudioRef}></audio>
			<audio src={closeModalAudioAsset} ref={closeModalAudioRef}></audio>

			<div className="practice-page">

				<Helmet>
					<title>{messageMap("practice.title", "headerTag")}</title>
					<meta name="description" content={messageMap("practice.description", "headerTag")}></meta>
				</Helmet>

				<h1 className="title">
					{practiceTopicDetailsRef.current && /(?<=[A-Z]\. ).*/.exec(practiceTopicDetailsRef.current.label)[0]}
				</h1>

				<div className="practice-container">

					<div className="exercise-container">
						{pageSwitcher}

						{/* Phase 3 Workout */}
						{/* <button>
							{messageMap("practice.review", "button")}
						</button> */}
						<div className="exercise-mode-container">
							<Dropdown customContainerClass="practice" dropdownItemClickHandler={e => changeProblemMode(setExerciseModeOption(e))}
								dropdownOptions={optionValueToTextMap} customDropdownItemAttribute="optionkey"/>

							{topicNavigator}
						</div>

						<div className="question-container">
							<div className="question-track-container">
								<div className="question-help-container">
									<button className={practiceTopicDetailsRef.current && practiceTopicDetailsRef.current.practiceArgs.includes("no_hint") ? "help-button hide" : "help-button"}
										ref={hintButtonRef} onClick={showHintOptions}>
										{messageMap("practice.hint", "button")}
									</button>
									<button className={practiceTopicDetailsRef.current && practiceTopicDetailsRef.current.practiceArgs.includes("no_answer") ? "help-button hide" : "help-button"}
										ref={answerButtonRef} onClick={showAnswerOptions}>
										{messageMap("practice.answer", "button")}
									</button>
								</div>
							</div>

							<div className="generated-problem-container">
								{generatedProblem}
							</div>
						</div>

						<div className="feedback-next-container">
							<div className="feedback-container">
								<button className="submit-button" ref={submitButtonRef} onClick={submitAnswer}>
									{messageMap("submit.text", "button")}
								</button>
								<div className="feedback hide" ref={answerFeedbackContainerRef}>
									<img src={checkAsset} alt={messageMap("checkMark", "image")} className="feedback-icon" ref={answerFeedbackRef}></img>
								</div>
							</div>

							<div className="next-problem-container hide" ref={nextButtonContainerRef}>
								<button className="next-button" onClick={e => resetForNextQuestion(false)}>
									{messageMap("next.text", "button")}
								</button>
							</div>
						</div>
					</div>

					<div className="stats-bag-container">
						<audio src={acquireItemAudioAsset} ref={acquireItemAudioRef}></audio>

						<div className="shop-container">
							<button className="shop" onClick={openShop}>
								{messageMap("practicePage.shop.text", "generic")}
							</button>
						</div>

						<div className="stats-bag-block">
							<div className="account-blocker" ref={accountBlockerRef}>
								<div className="sign-in-text">
									<button className="start-or-resume-quest" onClick={startOrResumeQuest}>
										{accountBlockText ? accountBlockText : messageMap("practicePage.stats.signUpQuest", "generic")}
									</button>
								</div>
							</div>

							<div className="contents--center">
								<div className="username-level-bag-container">
									<Username customClass="static" borderClass="game" />

									<div className="stat-bag-component-container">
										<StatBag value={questLevel} clickHandler={showLevelOptions} showPlus={true}
											label={messageMap("practicePage.stats.level", "generic")} />
										<StatBag value={bagAsset} alt={messageMap("practice.bag", "image")} clickHandler={openBag}
											showPlus={true} label={messageMap("practicePage.stats.bag", "generic")} />
									</div>
								</div>

								<div className="experience-container">
									<div className="exp-label">
										{messageMap("practicePage.stats.experience", "generic")}
									</div>
									<div className="exp-bar-container">
										<audio src={correctAnswerAudioAsset} ref={expBarMeterAudioRef}></audio>
										<div className="exp-meter" ref={expBarMeterRef}>
										</div>
									</div>

									<div className="level-up-exp-topic-container">
										<div>
											<div className="exp-to-level-up">
												{`${Math.round(pointsToLevelUp)} ${messageMap("practicePage.stats.pointsToLevelUp", "generic")}`}
											</div>
											<div className="total-exp">
												{`${Math.round(questPoints)} ${messageMap("practicePage.stats.totalPoints", "generic")}`}
											</div>
										</div>
										{/* Phase 3 Workout */}
										{/* <button className="topic-stats">
										{messageMap("practicePage.stats.topicStats", "generic")}
									</button> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	)
}


Practice.propTypes = {
	routerProp: PropTypes.object,

	// redux props
	ownerId: PropTypes.string,
	username: PropTypes.string,
	shinyNeurons: PropTypes.number,
	// redux action
	updateShinyNeuronsBalance: PropTypes.func
};


export default connect(
	account,
	{ updateShinyNeuronsBalance }
)(Practice);
