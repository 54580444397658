import React, {useEffect, useState, useRef, Fragment} from "react";
import PropTypes from "prop-types";

import Dropdown from "templates/Dropdown";
import Alert, {ERROR} from "templates/Alert";
import Modal from "templates/Modal";
import Tooltip from "templates/Tooltip";
import DayAvailability from "./DayAvailability";

import messageMap from "Utilities/MessageMaps";
import { convertMilitaryTimeTo12HrFormat } from "Utilities/Time";
import {daySchedules} from "pages/Profile/subPages/utilities/TutoringSubPageConstants";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";

import closeAsset from "assets/icons/common/close.svg";
import labelInfoAsset from "assets/icons/common/labelInfo.svg";


function Schedule(props) {

	const [schedules, setSchedules] = useState(props.schedules ? props.schedules : {}),
		[cancellableSchedule, setCancellableSchedule] = useState(),
		[scheduleTooltip, setScheduleTooltip] = useState();

	const unavailableInputRef = useRef(),
		availableInputRef = useRef();

	useEffect(() => {
		setSavedValues();
	}, []);

	function setSavedValues() {
		if (props.schedules) {
			setCancellableSchedule(createCancellableSchedule(props.schedules));
		}
	}

	function closeModal(e) {
		if (e == null || (e != null && ["modal-block", "cancel", "icon", "close-button"].includes(e.target.className))) {
			hideModal();
		}
	}
	function hideModal() {
		setTimeout(() => {
			props.setModal(null);
		}, MODAL_CLOSE_TIMEOUT);
	}

	function closeAlert() {
		props.setAlert(null);
	}

	function showCancellableSchedule(e) {
		const day = e.target.getAttribute("scheduleKey");

		if (day === "chooseDay") {
			return;
		}
		const title = props.customTitle ? props.customTitle : messageMap("profilePage.tutoring.modal.schedule.setScheduleTitle", "generic");

		props.setModal(
			<Modal closeType="xButton" closeHandler={closeModal} submitHandler={e => setDaySchedule(day)}
						modalClass="become-tutor-modal"
						title={`${title} ${day}`}
						submitText={messageMap("tutoring.modal.setSchedule", "button")}>
				<div className="multi-dom-line schedules">
					<div className="schedule-option margin-right-48">
						<input id="unavailable" className="page-field-value radio-input radio" type="radio"
							ref={unavailableInputRef}
							onChange={e => updateSchedule(e, day, "unavailable")}>
						</input>
						<label htmlFor="unavailable">
							{messageMap("tutoring.profile.unavailable", "labelPlaceholder")}
						</label>
					</div>

					<div className="schedule-option">
						<input id="available" className="page-field-value radio-input radio" type="radio"
							ref={availableInputRef}
							onChange={e => updateSchedule(e, day, "available")}>
						</input>
						<DayAvailability updateSchedule={updateSchedule} day={day} />
					</div>
				</div>
			</Modal>
		);
	}

	function updateSchedule(e, day, specifier, blockCount, toDelete) {
		let currentDaySchedule = schedules;
		if (currentDaySchedule[day] == null) {
			currentDaySchedule[day] = {};
		}

		if (specifier === "unavailable") {
			unavailableInputRef.current.checked = true;
			availableInputRef.current.checked = false;

			delete currentDaySchedule[day]["from"];
			delete currentDaySchedule[day]["to"];
			currentDaySchedule[day]["unavailable"] = true;

			setSchedules(currentDaySchedule);
			props.setSchedules(currentDaySchedule);
		}
		else {
			unavailableInputRef.current.checked = false;
			availableInputRef.current.checked = true;

			if (toDelete) {
				delete currentDaySchedule[day][blockCount];
			}
			else if (["from", "to"].includes(specifier)) {
				const minuteValue = Number(e.target.value.split(":")[1]);
				// don't exit after triggering this if-block, because the "submit" button in the modal is still 
				// clickable, and there's other checks that will need to be done at that point
				if (!isAFifteenMinuteMultiple(minuteValue)) {
					props.setAlert(
						<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("schedule.invalidStartingMinute", "validation")} />
					);
				}

				delete currentDaySchedule[day]["unavailable"];

				if (currentDaySchedule[day][blockCount] == null) {
					currentDaySchedule[day][blockCount] = {};
				}

				currentDaySchedule[day][blockCount][specifier === "from" ? "from" : "to"] = e.target.value;
				setSchedules(currentDaySchedule);
				props.setSchedules(currentDaySchedule);
			}
		}
	}

	function setDaySchedule(day) {
		let currentDaySchedule = schedules;
		let daySchedule = currentDaySchedule[day];

		if (daySchedule || (daySchedule != null && Object.keys(daySchedule).includes("unavailable"))) {
			const dayScheduleKeys = Object.keys(daySchedule);
			let noInvalidData = true;
			if (["0", "1", "2"].some(block => dayScheduleKeys.includes(block))) {
				let availabilityHashMap = {};
				let foundInvalidStartAndEndTimes = false;
				let foundDiscontinuity = false;
				let foundInvalidIncrement = false;
				let foundOverlappingTimes = false;
				for (var key in daySchedule) {
					if (["from", "to"].every(v => Object.keys(daySchedule[key]).includes(v))) {
						let from = daySchedule[key].from.split(":");
						from = Number(from[0]) * 60 + Number(from[1]);
						let to = daySchedule[key].to.split(":");
						to = Number(to[0]) * 60 + Number(to[1]);
	
						if (from > to) {
							foundInvalidStartAndEndTimes = true;
							noInvalidData = false;
							break;
						}
						else if (isAFifteenMinuteMultiple(from) && isAFifteenMinuteMultiple(to)) {
							for (let startingTime = from; startingTime < to; startingTime += 15) {
								if (availabilityHashMap[startingTime]) {
									foundOverlappingTimes = true;
									noInvalidData = false;
								}
								else {
									availabilityHashMap[startingTime] = true;
								}
							}
	
							if (foundOverlappingTimes) {
								break;
							}
						}
						else {
							foundInvalidIncrement = true;
							noInvalidData = false;
							break;
						}
					}
					else {
						foundDiscontinuity = true;
						noInvalidData = false;
						break;
					}
				}
	
				if (foundInvalidStartAndEndTimes) {
					props.setAlert(
						<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("schedule.invalidTime", "validation")} />
					);
				}
				else if (foundOverlappingTimes) {
					props.setAlert(
						<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("schedule.invalidOverlappingTimes", "validation")} />
					);
				}
				else if (foundInvalidIncrement) {
					props.setAlert(
						<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("schedule.invalidStartingMinute", "validation")} />
					);
				}
				else if (foundDiscontinuity) {
					props.setAlert(
						<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("schedule.invalidIncompleteTimes", "validation")} />
					);
				}
	
			}

			if (noInvalidData || Object.keys(daySchedule).includes("unavailable")) {
				hideModal();

				setCancellableSchedule(createCancellableSchedule(currentDaySchedule));
			}
		}
		else {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("schedule.unfilled", "validation")} />
			);
		}
	}

	function isAFifteenMinuteMultiple(value) {
		return value % 15 === 0;
	}

	function createCancellableSchedule(modifiableSchedule) {
		let scheduleDOMs = [];

		for (const [key, value] of Object.entries(modifiableSchedule)) {
			const isUnavailable = Object.keys(value).includes("unavailable");
			let dayBlocks = [];
			if (!isUnavailable) {
				let keyCount = Object.keys(value).length;
				for (const [keyBlock, valueBlock] of Object.entries(value)) {
					dayBlocks.push(
						<Fragment key={`${key}_${keyBlock}`}>
							<div className="day-times">
								{convertMilitaryTimeTo12HrFormat(valueBlock["from"])}
							</div>
							<div className="day-to">
								{messageMap("profilePage.tutoring.modal.schedule.to", "generic")}
							</div>
							<div className="day-times">
								{convertMilitaryTimeTo12HrFormat(valueBlock["to"])}
								{
									keyCount > 1 && ", "
								}
							</div>
						</Fragment>
					);

					--keyCount;
				}
			}
			let daySchedule = (
				isUnavailable
				?
				(
					<div className="day-schedule">
						{messageMap("profilePage.tutoring.modal.schedule.unavailable", "generic")}
					</div>
				)
				: dayBlocks
			);

			scheduleDOMs.push(
				<div key={key} className="schedule-container">
					<div className="day-schedule">
						{`${key}: `}
					</div>
					{daySchedule}
					<button className="remove-day-availability"
						onClick={e => removeScheduledDay(key)}>
						<img className="x-icon" src={closeAsset} alt="ex icon"></img>
					</button>
				</div>
			);
		}

		return scheduleDOMs;
	}

	function removeScheduledDay(day) {
		let currentSchedule = schedules;
		delete currentSchedule[day];

		setSchedules(currentSchedule);
		props.setSchedules(currentSchedule);
		setCancellableSchedule(createCancellableSchedule(currentSchedule));
	}

	function toggleScheduleTooltip(showOrHide) {
		if (showOrHide === "show") {
			setScheduleTooltip(
				<Tooltip classStr="tooltip-bottom-left schedule-tooltip"
					subheader={messageMap("tutor.schedule", "tooltip")} />
			);
		}
		else {
			setScheduleTooltip(null);
		}
	}

	return (
		<div className="page-field multi-lines">
			<label className="page-field-label align-to-form">
				<div className="div-label">
					{
						props.customLabel ?
						props.customLabel :
						messageMap("profilePage.tutoring.modal.application.schedule", "generic")
					}
				</div>
				{props.isRequired && "*"}
				{
					props.showTooltip &&
					(
						<Fragment>
							<img className="label-info" src={labelInfoAsset} alt="information" tabIndex={0}
								onMouseOver={e => toggleScheduleTooltip("show")} onFocus={e => toggleScheduleTooltip("show")}
								onMouseLeave={e => toggleScheduleTooltip("hide")} onBlur={e => toggleScheduleTooltip("hide")} ></img>
							{scheduleTooltip}
						</Fragment>
					)
				}
			</label>
			<div className="inline-elements">
				<div className="multi-line-inlines">
					<Dropdown customDropdownItemAttribute="scheduleKey" customContainerClass="tutoring-application no-right-margin"
						dropdownOptions={daySchedules} dropdownItemClickHandler={showCancellableSchedule}/>
					<div className="schedule-info">
						{messageMap("profilePage.tutoring.modal.schedule.unscheduledDays", "generic")}
					</div>
					<div>
						{cancellableSchedule}
					</div>
				</div>
			</div>
		</div>
	);
}

Schedule.defaultProps = {
	isRequired: true,
	showTooltip: true
};

Schedule.propTypes = {
	customTitle: PropTypes.string,
	customLabel: PropTypes.string,
	isRequired: PropTypes.bool,
	showTooltip: PropTypes.bool,

	// parent function handlers
	setModal: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,
	schedules: PropTypes.object,
	setSchedules: PropTypes.func.isRequired
};

export default Schedule;


