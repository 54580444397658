import React from "react";

import Alert, { ERROR } from "templates/Alert";

import {
	topicsInSubjects,
	SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY
} from "pages/Profile/subPages/utilities/TutoringSubPageConstants";
import messageMap from "Utilities/MessageMaps";


export function findSubjectFromTopic(topic) {
	let subject;

	for (const [key, value] of Object.entries(topicsInSubjects)) {
		if (Object.keys(value).includes(topic)) {
			subject = key;
			break;
		}
	}

	return subject;
}

export function validateInPersonTutoringRadius(value, resetRadiusValue, setInPersonTutoringRadiusValue, setAlert, closeAlert) {
	const enteredRadius = Number(value);
	let isValidValue = false;
	if (enteredRadius <= 75 && enteredRadius > 0) {
		setInPersonTutoringRadiusValue(enteredRadius);
		isValidValue = true;
	}
	else {
		setAlert(
			<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("tutorProfile.inPersonTutoringRadius", "validation")} />
		);
		resetRadiusValue();
	}

	return isValidValue;
}

export function transformCancellableStringToArray(fluentLanguagesStr) {
	return fluentLanguagesStr ? fluentLanguagesStr.split("|") : null;
}

export function transformCancellableDataToString(data) {
	let cancellableDataStr = "";
	if (data && data.length) {
		cancellableDataStr = data.join("|");
	}

	return cancellableDataStr;
}

export function transformScheduleStringToObject(scheduleString) {
	let scheduleObject = {};

	if (scheduleString && typeof scheduleString === "string") {
		scheduleString.split("|").forEach(daySchedule => {
			let dayAvailability = daySchedule.split(":");

			// day is unavailable
			if (dayAvailability.length === 2) {
				scheduleObject[dayAvailability[0]] = {
					unavailable: true
				}
			}
			else if (dayAvailability.length >= 4) {
				const removedDayEl = dayAvailability.shift();
				let normalizedFormat = [];
				dayAvailability.forEach(el => {
					if (el.includes("_")) {
						el = el.split("_");
					}
					else {
						el = [el];
					}
					normalizedFormat = normalizedFormat.concat(el);
				});

				let dayBlocks = {};
				for (let i = 0; i < normalizedFormat.length; i += 4) {
					dayBlocks[Math.floor(i / 4)] = {
						from: `${normalizedFormat[i]}:${normalizedFormat[i + 1]}`,
						to: `${normalizedFormat[i + 2]}:${normalizedFormat[i + 3]}`
					}
				}

				scheduleObject[removedDayEl] = dayBlocks;
			}
		});
	}

	return scheduleObject;
}

export function transformScheduleDataToString(schedulesValue) {
	let schedulesStr = "";
	if (Object.keys(schedulesValue).length) {
		let availableDays = [SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY];
		schedulesStr = [];

		for (const [key, value] of Object.entries(schedulesValue)) {
			const valueKeys = Object.keys(value);

			if (valueKeys.includes("unavailable")) {
				schedulesStr.push(`${key}:unavailable`);
			}
			// else if (["from", "to"].some(range => valueKeys.includes(range))) {
			else if (["0", "1", "2"].some(block => valueKeys.includes(block))) {
				let timeBlocks = "";
				valueKeys.forEach((block, index) => {
					let underscore = "";
					if (index < valueKeys.length - 1) {
						underscore = "_";
					}
					timeBlocks += (`${value[block]["from"]}_${value[block]["to"]}${underscore}`);
				});
				// schedulesStr.push(`${key}:${value["from"]}_${value["to"]}`);
				schedulesStr.push(`${key}:${timeBlocks}`);
			}

			availableDays.splice(availableDays.indexOf(key), 1);
		}

		availableDays.forEach(day => {
			schedulesStr.push(`${day}:unavailable`);
		});

		schedulesStr = schedulesStr.join("|");
	}
	
	return schedulesStr;
}
