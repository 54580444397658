import messageMap from "Utilities/MessageMaps";
import { convertArrayToObject } from "Utilities/ObjectUtility";


// Allowed tutoring countries based on Stripe support (https://docs.stripe.com/connect/cross-border-payouts)
// and English proficiency data (https://specials-images.forbesimg.com/imageserve/65e097698aa26bed8583038e/20240227-BestEnglishSpeakers/960x0.jpg?fit=scale)
// and https://www.ef.com/wwen/epi/ -> https://www.ef.com/assetscdn/WIBIwq6RdJvcD9bc8RMd/cefcom-epi-site/reports/2023/ef-epi-2023-english.pdf
// We're allowing applicants with at least "moderate proficiency" (score of at least 500) in English.

// North America
const CANADA = "Canada";
const UNITED_STATES = "United States";
// South America
// https://www.statista.com/statistics/1053066/english-proficiency-latin-america/#:~:text=Argentina%20scored%20560%20out%20of,the%20Latin%20American%20cities%20analyzed.
const ARGENTINA = "Argentina";
const HONDURAS = "Honduras";
const COSTA_RICA = "Costa Rica";
const URUGUAY = "Uruguay";
const BOLIVIA = "Bolivia";
const PARAGUAY = "Paraguay";
const EL_SALVADOR = "El Salvador";
const PERU = "Peru";
const CHILE = "Chile";
const GUATEMALA = "Guatemala";
const DOMINICAN_REPUBLIC = "Dominican Republic";
const VENEZUELA = "Venezuela";
const NICARAGUA = "Nicaragua";
// Europe
// https://www.weforum.org/agenda/2019/11/countries-that-speak-english-as-a-second-language/
const NETHERLANDS = "Netherlands";
const DENMARK = "Denmark";
const SWEDEN = "Sweden";
const NORWAY = "Norway";
const FINLAND = "Finland";
const LUXEMBOURG = "Luxembourg";
const AUSTRALIA = "Australia";
const AUSTRIA = "Austria";
const GIBRALTAR = "Gibraltar";
const GERMANY = "Germany";
const POLAND = "Poland";
const BELGIUM = "Belgium";
const SWITZERLAND = "Switzerland";
const PORTUGAL = "Portugal";
const CZECH_REPUBLIC = "Czech Republic";
const SERBIA = "Serbia";
const HUNGARY = "Hungary";
const ROMANIA = "Romania";
const SLOVAKIA = "Slovakia";
const BULGARIA = "Bulgaria";
const SPAIN = "Spain";
const BOSNIA_AND_HERZEGOVINA = "Bosnia and Herzegovina";
const ITALY = "Italy";
const FRANCE = "France";
const RUSSIA = "Russia";
const UKRAINE = "Ukraine";
const ALBANIA = "Albania";
const MOLDOVA = "Moldova";
const BELARUS = "Belarus";
const GEORGIA = "Georgia";
const ESTONIA = "Estonia";
const LITHUANIA = "Lithuania";
const GREECE = "Greece";
const CROATIA = "Croatia";
const IRELAND = "Ireland";
const LATVIA = "Latvia";
const LIECHTENSTEIN = "Liechtenstein";
const MALTA = "Malta";
const SLOVENIA = "Slovenia";
const UNITED_KINGDOM = "United Kingdom";
const ICELAND = "Iceland";
const MONACO = "Monaco";
const NORTH_MACEDONIA = "North Macedonia";
// Asia
// https://www.statista.com/statistics/1456015/asia-english-proficiency-ranking-by-country/#:~:text=Singapore%20scored%20631%20out%20of,score%20of%20416%20that%20year.
const SINGAPORE = "Singapore";
const PHILIPPINES = "Philippines";
const MALAYSIA = "Malaysia";
const HONG_KONG = "Hong Kong";
const SOUTH_KOREA = "South Korea";
const NEPAL = "Nepal";
const VIETNAM = "Vietnam";
const INDIA = "India";
const ISRAEL = "Israel";
const ARMENIA = "Armenia";
const MACAO = "Macao";
// Africa
// https://africa.businessinsider.com/local/lifestyle/5-best-english-speaking-countries-in-africa/pv1ks82
const TUNISIA = "Tunisia";
const SOUTH_AFRICA = "South Africa";
const SEYCHELLES = "Seychelles";
const KENYA = "Kenya";
const NIGERIA = "Nigeria";
const UGANDA = "Uganda";
const COTE_D_IVOIRE = "Côte d'Ivoire";
const ETHIOPIA = "Ethiopia";
// Oceania
const NEW_ZEALAND = "New Zealand";


// List of countries that we don't support
// North America
const ANGUILLA = "Anguilla";
const ANTIGUA_AND_BARBUDA = "Antigua and Barbuda";
const ARUBA = "Aruba";
const BAHAMAS = "The Bahamas";
const BARBADOS = "Barbados";
const BELIZE = "Belize";
const BERMUDA = "Bermuda";
const BONAIRE = "Bonaire";
const BRITISH_VIRGIN_ISLANDS = "British Virgin Islands";
const CAYMAN_ISLANDS = "Cayman Islands";
const CLIPPERTON_ISLANDS = "Clipperton Islands";
const CUBA = "Cuba";
const CURACAO = "Curacao";
const DOMINICA = "Dominica";
const FEDERAL_DEPENDENCIES_OF_VENEZUELA = "Federal Dependencies of Venezuela";
const GREENLAND = "Greenland";
const GRENADA = "Grenada";
const GUADELUPE = "Guadelupe";
const HAITI = "Haiti";
const JAMAICA = "Jamaica";
const MARTINIQUE = "Martinique";
const MEXICO = "Mexico";
const MONTSERRAT = "Montserrat";
const NUEVA_ESPARTA = "Nueva Esparta";
const PANAMA = "Panama";
const PUERTO_RICO = "Puerto Rico";
const SABA = "Saba";
const SAN_ANDRES_AND_PROVIDENCIA = "San Andres and Providencia";
const SAINT_BARTHELEMY = "Saint Barthelemy";
const SAINT_KITTS_AND_NEVIS = "Saint Kitts and Nevis";
const SAINT_LUCIA = "Saint Lucia";
const SAINT_MARTIN = "Saint Martin";
const SAINT_PIERRE_AND_MIQUELON = "Saint Pierre and Miquelon";
const SAINT_VINCENT_AND_GRENADINES = "Saint Vincent and Grenadines";
const SINT_EUSTATIUS = "Saint Eustatius";
const SINT_MAARTEN = "Sint Maarten";
const TRINIDAD_AND_TOBAGO = "Trinidad and Tobago";
const TURKS_AND_CAICOS_ISLANDS = "Turks and Caicos Islands";
const UNITED_STATES_VIRGIN_ISLANDS = "United States Virgin Islands";
// South America
const BRAZIL = "Brazil";
const COLOMBIA = "Colombia";
const ECUADOR = "Ecuador";
const FALKLAND_ISLANDS = "Falkland Islands";
const FRENCH_GUIANA = "French Guiana";
const GUYANA = "Guyana";
const SURINAME = "Suriname";
// Asia
const AFGHANISTAN = "Afghanistan";
const AZERBAIJAN = "Azerbaijan";
const BAHRAIN = "Bahrain";
const BANGLADESH = "Bangladesh";
const BHUTAN = "Bhutan";
const BRUNEI = "Brunei";
const CAMBODIA = "Cambodia";
const CYPRUS = "Cyprus";
const INDONESIA = "Indonesia";
const IRAN = "Iran";
const IRAQ = "Iraq";
const JAPAN = "Japan";
const JORDAN = "Jordan";
const KAZAKHSTAN = "Kazakhstan";
const KUWAIT = "Kuwait";
const KYRGYZSTAN = "Kyrgyzstan";
const LAOS = "Laos";
const LEBANON = "Lebanon";
const MALDIVES = "Maldives";
const MONGOLIA = "Mongolia";
const MYANMAR = "Myanmar";
const OMAN = "Oman";
const PAKISTAN = "Pakistan";
const PALESTINE = "Palestine";
const QATAR = "Qatar";
const SAUDI_ARABIA = "Saudi Arabia";
const SRI_LANKA = "Sri Lanka";
const SYRIA = "Syria";
const TAIWAN = "Taiwan";
const TAJIKISTAN = "Tajikistan";
const THAILAND = "Thailand";
const TIMOR_LESTE = "Timor-Leste";
const TURKEY = "Turkey";
const TURKMENISTAN = "Turkmenistan";
const UNITED_ARAB_EMIRATES = "United Arab Emirates";
const UZBEKISTAN = "Uzbekistan";
const YEMEN = "Yemen";
// Africa
const ALGERIA = "Algeria";
const CANARY_ISLANDS = "Canary Islands";
const PELAGIE_ISLANDS = "Pelagie Islands";
const CEUTA = "Ceuta";
const EGYPT = "Egypt";
const LIBYA = "Libya";
const MADERIA = "Madeira";
const MELILA = "Melila";
const MOROCCO = "Morocco";
const SUDAN = "Sudan";
const WESTERN_SAHARA = "Western Sahara";
const BURUNDI = "Burundi";
const COMOROS = "Comoros";
const DJIBOUTI = "Djibouti";
const ERITREA = "Eritrea";
const MADAGASCAR = "Madagascar";
const MALAWI = "Malawi";
const MAURITIUS = "Mauritius";
const MAYOTTE = "Mayotte";
const MOZAMBIQUE = "Mozambique";
const RWANDA = "Rwanda";
const SOMALIA = "Somalia";
const SOMALILAND = "Somaliland";
const SOUTH_SUDAN = "South Sudan";
const TANZANIA = "Tanzania";
const ZAMBIA = "Zambia";
const ZIMBABWE = "Zimbabwe";
const ANGOLA = "Angola";
const CAMEROON = "Cameroon";
const CENTRAL_AFRICAN_REPUBLIC = "Central African Republic";
const CHAD = "Chad";
const REPUBLIC_OF_THE_CONGO = "Republic of the Congo";
const DEMOCRATIC_REPUBLIC_OF_THE_CONGO = "Democratic Republic of the Congo";
const EQUATORIAL_GUINEA = "Equatorial Guinea";
const GABON = "Gabon";
const SAO_TOME_AND_PRINCIPE = "Sao Tome and Principe";
const BOTSWANA = "Botswana";
const ESWATINI = "Eswatini";
const LESOTHO = "Lesotho";
const NAMIBIA = "Namibia";
const BENIN = "Benin";
const BURKINA_FASO = "Burkina Faso";
const CAPE_VERDE = "Cape Verde";
const THE_GAMBIA = "The Gambia";
const GHANA = "Ghana";
const GUINEA = "Guinea";
const GUINEA_BISSAU = "Guinea Bissau";
const IVORY_COAST = "Ivory Coast";
const LIBERIA = "Liberia";
const MALI = "Mali";
const MAURITANIA = "Mauritania";
const NIGER = "Niger";
const SENEGAL = "Senegal";
const SIERRA_LEONE = "Sierra Leone";
const TOGO = "Togo";


export const fullCountriesList = {
	chooseCountry: messageMap("tutoring.dropdowns.country", "button"),
	[AFGHANISTAN]: AFGHANISTAN,
	[ALBANIA]: ALBANIA,
	[ALGERIA]: ALGERIA,
	[ANGOLA]: ANGOLA,
	[ANGUILLA]: ANGUILLA,
	[ANTIGUA_AND_BARBUDA]: ANTIGUA_AND_BARBUDA,
	[ARGENTINA]: ARGENTINA,
	[ARMENIA]: ARMENIA,
	[ARUBA]: ARUBA,
	[AUSTRALIA]: AUSTRALIA,
	[AUSTRIA]: AUSTRIA,
	[AZERBAIJAN]: AZERBAIJAN,
	[BAHAMAS]: BAHAMAS,
	[BAHRAIN]: BAHRAIN,
	[BANGLADESH]: BANGLADESH,
	[BARBADOS]: BARBADOS,
	[BELARUS]: BELARUS,
	[BELGIUM]: BELGIUM,
	[BELIZE]: BELIZE,
	[BENIN]: BENIN,
	[BERMUDA]: BERMUDA,
	[BHUTAN]: BHUTAN,
	[BOLIVIA]: BOLIVIA,
	[BONAIRE]: BONAIRE,
	[BOSNIA_AND_HERZEGOVINA]: BOSNIA_AND_HERZEGOVINA,
	[BOTSWANA]: BOTSWANA,
	[BRAZIL]: BRAZIL,
	[BRITISH_VIRGIN_ISLANDS]: BRITISH_VIRGIN_ISLANDS,
	[BRUNEI]: BRUNEI,
	[BULGARIA]: BULGARIA,
	[BURKINA_FASO]: BURKINA_FASO,
	[BURUNDI]: BURUNDI,
	[CAMBODIA]: CAMBODIA,
	[CAMEROON]: CAMEROON,
	[CANADA]: CANADA,
	[CAPE_VERDE]: CAPE_VERDE,
	[CAYMAN_ISLANDS]: CAYMAN_ISLANDS,
	[CENTRAL_AFRICAN_REPUBLIC]: CENTRAL_AFRICAN_REPUBLIC,
	[CEUTA]: CEUTA,
	[CHAD]: CHAD,
	[CHILE]: CHILE,
	[CLIPPERTON_ISLANDS]: CLIPPERTON_ISLANDS,
	[COLOMBIA]: COLOMBIA,
	[COMOROS]: COMOROS,
	[COSTA_RICA]: COSTA_RICA,
	[COTE_D_IVOIRE]: COTE_D_IVOIRE,
	[CROATIA]: CROATIA,
	[CUBA]: CUBA,
	[CURACAO]: CURACAO,
	[CZECH_REPUBLIC]: CZECH_REPUBLIC,
	[CYPRUS]: CYPRUS,
	[DEMOCRATIC_REPUBLIC_OF_THE_CONGO]: DEMOCRATIC_REPUBLIC_OF_THE_CONGO,
	[DENMARK]: DENMARK,
	[DJIBOUTI]: DJIBOUTI,
	[DOMINICA]: DOMINICA,
	[DOMINICAN_REPUBLIC]: DOMINICAN_REPUBLIC,
	[ECUADOR]: ECUADOR,
	[EGYPT]: EGYPT,
	[EL_SALVADOR]: EL_SALVADOR,
	[EQUATORIAL_GUINEA]: EQUATORIAL_GUINEA,
	[ERITREA]: ERITREA,
	[ESTONIA]: ESTONIA,
	[ETHIOPIA]: ETHIOPIA,
	[ESWATINI]: ESWATINI,
	[FALKLAND_ISLANDS]: FALKLAND_ISLANDS,
	[FEDERAL_DEPENDENCIES_OF_VENEZUELA]: FEDERAL_DEPENDENCIES_OF_VENEZUELA,
	[FINLAND]: FINLAND,
	[FRANCE]: FRANCE,
	[FRENCH_GUIANA]: FRENCH_GUIANA,
	[GABON]: GABON,
	[GEORGIA]: GEORGIA,
	[GERMANY]: GERMANY,
	[GHANA]: GHANA,
	[GIBRALTAR]: GIBRALTAR,
	[GREECE]: GREECE,
	[GREENLAND]: GREENLAND,
	[GRENADA]: GRENADA,
	[GUADELUPE]: GUADELUPE,
	[GUATEMALA]: GUATEMALA,
	[GUINEA]: GUINEA,
	[GUINEA_BISSAU]: GUINEA_BISSAU,
	[GUYANA]: GUYANA,
	[HAITI]: HAITI,
	[HONDURAS]: HONDURAS,
	[HONG_KONG]: HONG_KONG,
	[HUNGARY]: HUNGARY,
	[ICELAND]: ICELAND,
	[INDIA]: INDIA,
	[INDONESIA]: INDONESIA,
	[IRAN]: IRAN,
	[IRAQ]: IRAQ,
	[IRELAND]: IRELAND,
	[ISRAEL]: ISRAEL,
	[ITALY]: ITALY,
	[IVORY_COAST]: IVORY_COAST,

	[JAMAICA]: JAMAICA,
	[JAPAN]: JAPAN,
	[JORDAN]: JORDAN,
	[KAZAKHSTAN]: KAZAKHSTAN,
	[KENYA]: KENYA,
	[KUWAIT]: KUWAIT,
	[KYRGYZSTAN]: KYRGYZSTAN,
	[LAOS]: LAOS,
	[LATVIA]: LATVIA,
	[LEBANON]: LEBANON,
	[LESOTHO]: LESOTHO,
	[LIBERIA]: LIBERIA,
	[LIBYA]: LIBYA,
	[LIECHTENSTEIN]: LIECHTENSTEIN,
	[LITHUANIA]: LITHUANIA,
	[LUXEMBOURG]: LUXEMBOURG,
	[MADAGASCAR]: MADAGASCAR,
	[MADERIA]: MADERIA,
	[MALAWI]: MALAWI,
	[MALAYSIA]: MALAYSIA,
	[MALI]: MALI,
	[MALDIVES]: MALDIVES,
	[MALTA]: MALTA,
	[MARTINIQUE]: MARTINIQUE,
	[MAURITANIA]: MAURITANIA,
	[MAURITIUS]: MAURITIUS,
	[MAYOTTE]: MAYOTTE,
	[MELILA]: MELILA,
	[MEXICO]: MEXICO,
	[MOLDOVA]: MOLDOVA,
	[MONACO]: MONACO,
	[MONGOLIA]: MONGOLIA,
	[MONTSERRAT]: MONTSERRAT,
	[MOROCCO]: MOROCCO,
	[MOZAMBIQUE]: MOZAMBIQUE,
	[MYANMAR]: MYANMAR,
	[NAMIBIA]: NAMIBIA,
	[NEPAL]: NEPAL,
	[NETHERLANDS]: NETHERLANDS,
	[NEW_ZEALAND]: NEW_ZEALAND,
	[NICARAGUA]: NICARAGUA,
	[NIGER]: NIGER,
	[NIGERIA]: NIGERIA,
	[NORWAY]: NORWAY,
	[NORTH_MACEDONIA]: NORTH_MACEDONIA,
	[NUEVA_ESPARTA]: NUEVA_ESPARTA,
	[OMAN]: OMAN,
	[PAKISTAN]: PAKISTAN,
	[PALESTINE]: PALESTINE,
	[PANAMA]: PANAMA,
	[PARAGUAY]: PARAGUAY,
	[PELAGIE_ISLANDS]: PELAGIE_ISLANDS,
	[PERU]: PERU,
	[PHILIPPINES]: PHILIPPINES,
	[POLAND]: POLAND,
	[PORTUGAL]: PORTUGAL,
	[PUERTO_RICO]: PUERTO_RICO,
	[QATAR]: QATAR,
	[REPUBLIC_OF_THE_CONGO]: REPUBLIC_OF_THE_CONGO,
	[ROMANIA]: ROMANIA,
	[RWANDA]: RWANDA,
	[RUSSIA]: RUSSIA,

	[SABA]: SABA,
	[SAINT_BARTHELEMY]: SAINT_BARTHELEMY,
	[SAINT_KITTS_AND_NEVIS]: SAINT_KITTS_AND_NEVIS,
	[SAINT_LUCIA]: SAINT_LUCIA,
	[SAINT_MARTIN]: SAINT_MARTIN,
	[SAINT_PIERRE_AND_MIQUELON]: SAINT_PIERRE_AND_MIQUELON,
	[SAINT_VINCENT_AND_GRENADINES]: SAINT_VINCENT_AND_GRENADINES,
	[SAN_ANDRES_AND_PROVIDENCIA]: SAN_ANDRES_AND_PROVIDENCIA,
	[SAO_TOME_AND_PRINCIPE]: SAO_TOME_AND_PRINCIPE,
	[SAUDI_ARABIA]: SAUDI_ARABIA,
	[SENEGAL]: SENEGAL,
	[SERBIA]: SERBIA,
	[SEYCHELLES]: SEYCHELLES,
	[SIERRA_LEONE]: SIERRA_LEONE,
	[SINGAPORE]: SINGAPORE,
	[SINT_EUSTATIUS]: SINT_EUSTATIUS,
	[SINT_MAARTEN]: SINT_MAARTEN,
	[SLOVAKIA]: SLOVAKIA,
	[SLOVENIA]: SLOVENIA,
	[SOMALIA]: SOMALIA,
	[SOMALILAND]: SOMALILAND,
	[SOUTH_AFRICA]: SOUTH_AFRICA,
	[SOUTH_KOREA]: SOUTH_KOREA,
	[SOUTH_SUDAN]: SOUTH_SUDAN,
	[SPAIN]: SPAIN,
	[SRI_LANKA]: SRI_LANKA,
	[SUDAN]: SUDAN,
	[SURINAME]: SURINAME,
	[SWEDEN]: SWEDEN,
	[SWITZERLAND]: SWITZERLAND,
	[SYRIA]: SYRIA,
	[TAIWAN]: TAIWAN,
	[TAJIKISTAN]: TAJIKISTAN,
	[TANZANIA]: TANZANIA,
	[THAILAND]: THAILAND,
	[THE_GAMBIA]: THE_GAMBIA,
	[TIMOR_LESTE]: TIMOR_LESTE,
	[TOGO]: TOGO,
	[TRINIDAD_AND_TOBAGO]: TRINIDAD_AND_TOBAGO,
	[TUNISIA]: TUNISIA,
	[TURKEY]: TURKEY,
	[TURKS_AND_CAICOS_ISLANDS]: TURKS_AND_CAICOS_ISLANDS,
	[TURKMENISTAN]: TURKMENISTAN,
	[UGANDA]: UGANDA,
	[UKRAINE]: UKRAINE,
	[UNITED_ARAB_EMIRATES]: UNITED_ARAB_EMIRATES,
	[UNITED_KINGDOM]: UNITED_KINGDOM,
	[UNITED_STATES]: UNITED_STATES,
	[UNITED_STATES_VIRGIN_ISLANDS]: UNITED_STATES_VIRGIN_ISLANDS,
	[URUGUAY]: URUGUAY,
	[UZBEKISTAN]: UZBEKISTAN,
	[VENEZUELA]: VENEZUELA,
	[WESTERN_SAHARA]: WESTERN_SAHARA,
	[VIETNAM]: VIETNAM,
	[YEMEN]: YEMEN,
	[ZAMBIA]: ZAMBIA,
	[ZIMBABWE]: ZIMBABWE
};

// see comments at the very top of this file to learn why these countries are considered available
// We've dropped the requirement for English proficiency, since there's always exceptions and that
// we conduct tutor interviews anyway
export const availableCountries = [
	ALBANIA, ANTIGUA_AND_BARBUDA, ARGENTINA, ARMENIA, AUSTRALIA, AUSTRIA,
	BAHAMAS, BAHRAIN, BELGIUM, BENIN, BOLIVIA, BOSNIA_AND_HERZEGOVINA, BOTSWANA, BRAZIL, BRUNEI, BULGARIA,
	CAMBODIA, CANADA, CHILE, COLOMBIA, COSTA_RICA, COTE_D_IVOIRE, CROATIA, CYPRUS, CZECH_REPUBLIC,
	DENMARK, DOMINICAN_REPUBLIC,
	ECUADOR, EGYPT, EL_SALVADOR, ESTONIA, ETHIOPIA,
	FINLAND, FRANCE,
	THE_GAMBIA, GERMANY, GHANA, GREECE, GUATEMALA, GUYANA,
	HONG_KONG, HUNGARY,
	ICELAND, INDONESIA, IRELAND, ISRAEL, ITALY,
	JAMAICA, JAPAN, JORDAN,
	KENYA, KUWAIT,
	LATVIA, LIECHTENSTEIN, LITHUANIA, LUXEMBOURG,
	MACAO, MADAGASCAR, MALAYSIA, MALTA, MAURITIUS, MEXICO, MOLDOVA, MONACO, MONGOLIA, MOROCCO,
	NAMIBIA, NETHERLANDS, NEW_ZEALAND, NIGERIA, NORTH_MACEDONIA, NORWAY,
	OMAN,
	PANAMA, PARAGUAY, PERU, PHILIPPINES, POLAND, PORTUGAL,
	QATAR,
	ROMANIA, RWANDA,
	SAUDI_ARABIA, SENEGAL, SERBIA, SINGAPORE, SLOVAKIA, SLOVENIA, SOUTH_AFRICA, SOUTH_KOREA, SPAIN, SRI_LANKA, SAINT_LUCIA, SWEDEN, SWITZERLAND,
	TAIWAN, TANZANIA, THAILAND, TRINIDAD_AND_TOBAGO, TUNISIA, TURKEY,
	UNITED_ARAB_EMIRATES, UNITED_KINGDOM, UNITED_STATES, URUGUAY, UZBEKISTAN,
	VIETNAM
];
export const availableCountriesObject = convertArrayToObject(availableCountries);
// The following is purely for documentation-purposes and isn't integrated in the code
const droppedCountries = [
	BRAZIL, // only allows company entities, not individuals
	PAKISTAN, // verification requirements include indicating nationality, but Stripe API doesn't have it
	UNITED_ARAB_EMIRATES, // only allows company entities, not individuals
	SINGAPORE, // requires a lot of extra steps to create connect accounts
];
// referred from https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
export const ALBANIA_CODE = "AL";
export const ANTIGUA_AND_BARBUDA_CODE = "AG";
export const ARGENTINA_CODE = "AR";
export const ARMENIA_CODE = "AM";
export const AUSTRALIA_CODE = "AU";
export const AUSTRIA_CODE = "AT";
export const BAHAMAS_CODE = "BS";
export const BAHRAIN_CODE = "BH";
export const BELGIUM_CODE = "BE";
export const BENIN_CODE = "BJ";
export const BOLIVIA_CODE = "BO";
export const BOSNIA_AND_HERZEGOVINA_CODE = "BA";
export const BOTSWANA_CODE = "BW";
export const BRAZIL_CODE = "BR";
export const BRUNEI_CODE = "BN";
export const BULGARIA_CODE = "BH";
export const CAMBODIA_CODE = "KH"
export const CANADA_CODE = "CA";
export const CHILE_CODE = "CL";
export const COLOMBIA_CODE = "CO";
export const COSTA_RICA_CODE = "CR";
export const COTE_D_IVOIRE_CODE = "CI";
export const CROATIA_CODE = "HR";
export const CYPRUS_CODE = "CY";
export const CZECH_REPUBLIC_CODE = "CZ";
export const DENMARK_CODE = "DK";
export const DOMINICAN_REPUBLIC_CODE = "DO";
export const ECUADOR_CODE = "EC";
export const EGYPT_CODE = "EG";
export const EL_SALVADOR_CODE = "SV";
export const ESTONIA_CODE = "EW";
export const ETHIOPIA_CODE = "ET";
export const FINLAND_CODE = "FI";
export const FRANCE_CODE = "FR";
export const THE_GAMBIA_CODE = "GM";
export const GERMANY_CODE = "DE";
export const GHANA_CODE = "GH";
export const GREECE_CODE = "GR";
export const GUATEMALA_CODE = "GT";
export const GUYANA_CODE = "GY";
export const HONG_KONG_CODE = "HK";
export const HUNGARY_CODE = "HU";
export const ICELAND_CODE = "IS";
export const INDIA_CODE = "IN";
export const INDONESIA_CODE = "ID";
export const IRELAND_CODE = "IE";
export const ISRAEL_CODE = "IL";
export const ITALY_CODE = "IT";
export const JAMAICA_CODE = "JM";
export const JAPAN_CODE = "JP";
export const JORDAN_CODE = "JO";
export const KENYA_CODE = "KE";
export const KUWAIT_CODE = "KW";
export const LATVIA_CODE = "LV";
export const LIECHTENSTEIN_CODE = "FL";
export const LITHUANIA_CODE = "LT";
export const LUXEMBOURG_CODE = "LU";
export const MACAO_CODE = "MO";
export const MADAGASCAR_CODE = "MG";
export const MALAYSIA_CODE = "MY";
export const MALTA_CODE = "MT";
export const MAURITIUS_CODE = "MU";
export const MEXICO_CODE = "MX";
export const MOLDOVA_CODE = "MD";
export const MONACO_CODE = "MC";
export const MONGOLIA_CODE = "MN";
export const MOROCCO_CODE = "MA";
export const NAMIBIA_CODE = "NA";
export const NETHERLANDS_CODE = "NL";
export const NEW_ZEALAND_CODE = "NZ";
export const NIGERIA_CODE = "NG";
export const NORTH_MACEDONIA_CODE = "MK";
export const NORWAY_CODE = "NO";
export const OMAN_CODE = "OM";
export const PAKISTAN_CODE = "PK";
export const PANAMA_CODE = "PA";
export const PARAGUAY_CODE = "PY";
export const PERU_CODE = "PE";
export const PHILIPPINES_CODE = "PH";
export const POLAND_CODE = "PL";
export const PORTUGAL_CODE = "PT";
export const PUERTO_RICO_CODE = "PR";
export const QATAR_CODE = "QA";
export const ROMANIA_CODE = "RO";
export const RWANDA_CODE = "RW";
export const SAUDI_ARABIA_CODE = "SA";
export const SENEGAL_CODE = "SN";
export const SERBIA_CODE = "RS";
export const SINGAPORE_CODE = "SG";
export const SLOVAKIA_CODE = "SK";
export const SLOVENIA_CODE = "SI";
export const SOUTH_AFRICA_CODE = "ZA";
export const SOUTH_KOREA_CODE = "KR";
export const SPAIN_CODE = "ES";
export const SRI_LANKA_CODE = "LK";
export const SAINT_LUCIA_CODE = "WL";
export const SWEDEN_CODE = "SE";
export const SWITZERLAND_CODE = "CH";
export const TAIWAN_CODE = "TW";
export const TANZANIA_CODE = "TZ";
export const THAILAND_CODE = "TH";
export const TRINIDAD_AND_TOBAGO_CODE = "TT";
export const TUNISIA_CODE = "TN";
export const TURKEY_CODE = "TR";
export const UNITED_ARAB_EMIRATES_CODE = "AE";
export const UNITED_KINGDOM_CODE = "GB";
export const UNITED_STATES_CODE = "US";
export const URUGUAY_CODE = "UY";
export const UZBEKISTAN_CODE = "UZ";
export const VIETNAM_CODE = "VN";
export const countriesToAlphaCode = {
	[ALBANIA]: ALBANIA_CODE,
	[ANTIGUA_AND_BARBUDA]: ANTIGUA_AND_BARBUDA_CODE,
	[ARGENTINA]: ARGENTINA_CODE,
	[ARMENIA]: ARMENIA_CODE,
	[AUSTRALIA]: AUSTRALIA_CODE,
	[AUSTRIA]: AUSTRIA_CODE,
	[BAHAMAS]: BAHAMAS_CODE,
	[BAHRAIN]: BAHRAIN_CODE,
	[BELGIUM]: BELGIUM_CODE,
	[BENIN]: BENIN_CODE,
	[BOLIVIA]: BOLIVIA_CODE,
	[BOSNIA_AND_HERZEGOVINA]: BOSNIA_AND_HERZEGOVINA_CODE,
	[BOTSWANA]: BOTSWANA_CODE,
	[BRAZIL]: BRAZIL_CODE,
	[BRUNEI]: BRUNEI_CODE,
	[BULGARIA]: BULGARIA_CODE,
	[CAMBODIA]: CAMBODIA_CODE,
	[CANADA]: CANADA_CODE,
	[CHILE]: CHILE_CODE,
	[COLOMBIA]: COLOMBIA_CODE,
	[COSTA_RICA]: COSTA_RICA_CODE,
	[COTE_D_IVOIRE]: COTE_D_IVOIRE_CODE,
	[CROATIA]: CROATIA_CODE,
	[CYPRUS]: CYPRUS_CODE,
	[CZECH_REPUBLIC]: CZECH_REPUBLIC_CODE,
	[DENMARK]: DENMARK_CODE,
	[DOMINICAN_REPUBLIC]: DOMINICAN_REPUBLIC_CODE,
	[ECUADOR]: ECUADOR_CODE,
	[EGYPT]: EGYPT_CODE,
	[EL_SALVADOR]: EL_SALVADOR_CODE,
	[ESTONIA]: ESTONIA_CODE,
	[ETHIOPIA]: ETHIOPIA_CODE,
	[FINLAND]: FINLAND_CODE,
	[FRANCE]: FRANCE_CODE,
	[THE_GAMBIA]: THE_GAMBIA_CODE,
	[GERMANY]: GERMANY_CODE,
	[GHANA]: GHANA_CODE,
	[GREECE]: GREECE_CODE,
	[GUATEMALA]: GUATEMALA_CODE,
	[GUYANA]: GUYANA_CODE,
	[HONG_KONG]: HONG_KONG_CODE,
	[HUNGARY]: HUNGARY_CODE,
	[ICELAND]: ICELAND_CODE,
	[INDIA]: INDIA_CODE,
	[INDONESIA]: INDONESIA_CODE,
	[IRELAND]: IRELAND_CODE,
	[ISRAEL]: ISRAEL_CODE,
	[ITALY]: ITALY_CODE,
	[JAMAICA]: JAMAICA_CODE,
	[JAPAN]: JAPAN_CODE,
	[JORDAN]: JORDAN_CODE,
	[KENYA]: KENYA_CODE,
	[KUWAIT]: KUWAIT_CODE,
	[LATVIA]: LATVIA_CODE,
	[LIECHTENSTEIN]: LIECHTENSTEIN_CODE,
	[LITHUANIA]: LITHUANIA_CODE,
	[LUXEMBOURG]: LUXEMBOURG_CODE,
	[MACAO]: MACAO_CODE,
	[MADAGASCAR]: MADAGASCAR_CODE,
	[MALAYSIA]: MALAYSIA_CODE,
	[MALTA]: MALTA_CODE,
	[MAURITIUS]: MAURITIUS_CODE,
	[MEXICO]: MEXICO_CODE,
	[MOLDOVA]: MOLDOVA_CODE,
	[MONACO]: MONACO_CODE,
	[MONGOLIA]: MONGOLIA_CODE,
	[MOROCCO]: MOROCCO_CODE,
	[NAMIBIA]: NAMIBIA_CODE,
	[NETHERLANDS]: NETHERLANDS_CODE,
	[NEW_ZEALAND]: NEW_ZEALAND_CODE,
	[NIGERIA]: NIGERIA_CODE,
	[NORTH_MACEDONIA]: NORTH_MACEDONIA_CODE,
	[NORWAY]: NORWAY_CODE,
	[OMAN]: OMAN_CODE,
	[PAKISTAN]: PAKISTAN_CODE,
	[PANAMA]: PANAMA_CODE,
	[PARAGUAY]: PARAGUAY_CODE,
	[PERU]: PERU_CODE,
	[PHILIPPINES]: PHILIPPINES_CODE,
	[POLAND]: POLAND_CODE,
	[PORTUGAL]: PORTUGAL_CODE,
	[QATAR]: QATAR_CODE,
	[ROMANIA]: ROMANIA_CODE,
	[RWANDA]: RWANDA_CODE,
	[SAUDI_ARABIA]: SAUDI_ARABIA_CODE,
	[SENEGAL]: SENEGAL_CODE,
	[SERBIA]: SERBIA_CODE,
	[SINGAPORE]: SINGAPORE_CODE,
	[SLOVAKIA]: SLOVAKIA_CODE,
	[SLOVENIA]: SLOVENIA_CODE,
	[SOUTH_AFRICA]: SOUTH_AFRICA_CODE,
	[SOUTH_KOREA]: SOUTH_KOREA_CODE,
	[SPAIN]: SPAIN_CODE,
	[SRI_LANKA]: SRI_LANKA_CODE,
	[SAINT_LUCIA]: SAINT_LUCIA_CODE,
	[SWEDEN]: SWEDEN_CODE,
	[SWITZERLAND]: SWITZERLAND_CODE,
	[TAIWAN]: TAIWAN_CODE,
	[TANZANIA]: TANZANIA_CODE,
	[THAILAND]: THAILAND_CODE,
	[TRINIDAD_AND_TOBAGO]: TRINIDAD_AND_TOBAGO_CODE,
	[TUNISIA]: TUNISIA_CODE,
	[TURKEY]: TURKEY_CODE,
	[UNITED_ARAB_EMIRATES]: UNITED_ARAB_EMIRATES_CODE,
	[UNITED_KINGDOM]: UNITED_KINGDOM_CODE,
	[UNITED_STATES]: UNITED_STATES_CODE,
	[URUGUAY]: URUGUAY_CODE,
	[UZBEKISTAN]: UZBEKISTAN_CODE,
	[VIETNAM]: VIETNAM_CODE
};
export const countryAlphaCodeToName = {
	[ALBANIA_CODE]: ALBANIA,
	[ANTIGUA_AND_BARBUDA_CODE]: ANTIGUA_AND_BARBUDA,
	[ARGENTINA_CODE]: ARGENTINA,
	[ARMENIA_CODE]: ARMENIA,
	[AUSTRALIA_CODE]: AUSTRALIA,
	[AUSTRIA_CODE]: AUSTRIA,
	[BAHAMAS_CODE]: BAHAMAS,
	[BAHRAIN_CODE]: BAHRAIN,
	[BELGIUM_CODE]: BELGIUM,
	[BENIN_CODE]: BENIN,
	[BOLIVIA_CODE]: BOLIVIA,
	[BOSNIA_AND_HERZEGOVINA_CODE]: BOSNIA_AND_HERZEGOVINA,
	[BOTSWANA_CODE]: BOTSWANA,
	[BRAZIL_CODE]: BRAZIL,
	[BRUNEI_CODE]: BRUNEI,
	[BULGARIA_CODE]: BULGARIA,
	[CAMBODIA_CODE]: CAMBODIA,
	[CANADA_CODE]: CANADA,
	[CHILE_CODE]: CHILE,
	[COLOMBIA_CODE]: COLOMBIA,
	[COSTA_RICA_CODE]: COSTA_RICA,
	[COTE_D_IVOIRE_CODE]: COTE_D_IVOIRE,
	[CROATIA_CODE]: CROATIA,
	[CYPRUS_CODE]: CYPRUS,
	[CZECH_REPUBLIC_CODE]: CZECH_REPUBLIC,
	[DENMARK_CODE]: DENMARK,
	[DOMINICAN_REPUBLIC_CODE]: DOMINICAN_REPUBLIC,
	[ECUADOR_CODE]: ECUADOR,
	[EGYPT_CODE]: EGYPT,
	[EL_SALVADOR_CODE]: EL_SALVADOR,
	[ESTONIA_CODE]: ESTONIA,
	[ETHIOPIA_CODE]: ETHIOPIA,
	[FINLAND_CODE]: FINLAND,
	[FRANCE_CODE]: FRANCE,
	[THE_GAMBIA_CODE]: THE_GAMBIA,
	[GERMANY_CODE]: GERMANY,
	[GHANA_CODE]: GHANA,
	[GREECE_CODE]: GREECE,
	[GUATEMALA_CODE]: GUATEMALA,
	[GUYANA_CODE]: GUYANA,
	[HONG_KONG_CODE]: HONG_KONG,
	[HUNGARY_CODE]: HUNGARY,
	[ICELAND_CODE]: ICELAND,
	[INDIA_CODE]: INDIA,
	[INDONESIA_CODE]: INDONESIA,
	[IRELAND_CODE]: IRELAND,
	[ISRAEL_CODE]: ISRAEL,
	[ITALY_CODE]: ITALY,
	[JAMAICA_CODE]: JAMAICA,
	[JAPAN_CODE]: JAPAN,
	[JORDAN_CODE]: JORDAN,
	[KENYA_CODE]: KENYA,
	[KUWAIT_CODE]: KUWAIT,
	[LATVIA_CODE]: LATVIA,
	[LIECHTENSTEIN_CODE]: LIECHTENSTEIN,
	[LITHUANIA_CODE]: LITHUANIA,
	[LUXEMBOURG_CODE]: LUXEMBOURG,
	[MACAO_CODE]: MACAO,
	[MADAGASCAR_CODE]: MADAGASCAR,
	[MALAYSIA_CODE]: MALAYSIA,
	[MALTA_CODE]: MALTA,
	[MAURITIUS_CODE]: MAURITIUS,
	[MEXICO_CODE]: MEXICO,
	[MOLDOVA_CODE]: MOLDOVA,
	[MONACO_CODE]: MONACO,
	[MONGOLIA_CODE]: MONGOLIA,
	[MOROCCO_CODE]: MOROCCO,
	[NAMIBIA_CODE]: NAMIBIA,
	[NETHERLANDS_CODE]: NETHERLANDS,
	[NEW_ZEALAND_CODE]: NEW_ZEALAND,
	[NIGERIA_CODE]: NIGERIA,
	[NORTH_MACEDONIA_CODE]: NORTH_MACEDONIA,
	[NORWAY_CODE]: NORWAY,
	[OMAN_CODE]: OMAN,
	[PAKISTAN_CODE]: PAKISTAN,
	[PANAMA_CODE]: PANAMA,
	[PARAGUAY_CODE]: PARAGUAY,
	[PERU_CODE]: PERU,
	[PHILIPPINES_CODE]: PHILIPPINES,
	[POLAND_CODE]: POLAND,
	[PORTUGAL_CODE]: PORTUGAL,
	[QATAR_CODE]: QATAR,
	[ROMANIA_CODE]: ROMANIA,
	[RWANDA_CODE]: RWANDA,
	[SAUDI_ARABIA_CODE]: SAUDI_ARABIA,
	[SENEGAL_CODE]: SENEGAL,
	[SERBIA_CODE]: SERBIA,
	[SINGAPORE_CODE]: SINGAPORE,
	[SLOVAKIA_CODE]: SLOVAKIA,
	[SLOVENIA_CODE]: SLOVENIA,
	[SOUTH_AFRICA_CODE]: SOUTH_AFRICA,
	[SOUTH_KOREA_CODE]: SOUTH_KOREA,
	[SPAIN_CODE]: SPAIN,
	[SRI_LANKA_CODE]: SRI_LANKA,
	[SAINT_LUCIA_CODE]: SAINT_LUCIA,
	[SWEDEN_CODE]: SWEDEN,
	[SWITZERLAND_CODE]: SWITZERLAND,
	[TAIWAN_CODE]: TAIWAN,
	[TANZANIA_CODE]: TANZANIA,
	[THAILAND_CODE]: THAILAND,
	[TRINIDAD_AND_TOBAGO_CODE]: TRINIDAD_AND_TOBAGO,
	[TUNISIA_CODE]: TUNISIA,
	[TURKEY_CODE]: TURKEY,
	[UNITED_ARAB_EMIRATES_CODE]: UNITED_ARAB_EMIRATES,
	[UNITED_KINGDOM_CODE]: UNITED_KINGDOM,
	[UNITED_STATES_CODE]: UNITED_STATES,
	[URUGUAY_CODE]: URUGUAY,
	[UZBEKISTAN_CODE]: UZBEKISTAN,
	[VIETNAM_CODE]: VIETNAM
};