import React, {Fragment, useEffect, useState, useRef} from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { setPaymentInfo } from "redux/actions/actionTypes";
import account from "redux/selectors/accountSelector";

import Alert, {ERROR, SUCCESS} from "templates/Alert";
import Tooltip from "templates/Tooltip";
import Timer from "templates/Timer";
import Modal from "templates/Modal";
import ConfirmModal from "templates/customModals/ConfirmModal";
import TuteePolicy from "pages/Policies/TuteePolicy/TuteePolicy";
import TutorPolicy from "pages/Policies/TeacherPolicy/TutorPolicy";
import NewRequest from "pages/Tutoring/subPages/subMenus/NewRequest";
import TutorProfilePane from "pages/Tutoring/subPages/panes/TutorProfilePane";

import messageMap from "Utilities/MessageMaps";
import { $replace } from "Utilities/Strings";
import {checkHasExplicitWords} from "Utilities/Validators/ContentValidator.js";
import { calculateTutorAvgRating } from "pages/Tutoring/utilities/TutorProfileUtility";
import {topicsInSubjects} from "pages/Profile/subPages/utilities/TutoringSubPageConstants";
import { transformCancellationPoliciesStringToObject } from "pages/Tutoring/utilities/TutorProfileUtility";
import { promiseAll, POST, GET } from "Utilities/Fetches";
import { parseDecisionResponse } from "pages/Tutoring/utilities/TutorRequestUtility";
import { 
	TUTEE_USER, TUTOR_USER,
	OPEN_STATE, PENDING_STATE, SCHEDULED_STATE, LIVE_STATE, COMPLETED_STATE,
	DISPUTED_STATE, TUTEE_NO_SHOW_STATE, TUTOR_NO_SHOW_STATE,
	DISPUTE_RESOLVED_STATE,
	RECEIVED_DIRECTION, SENT_DIRECTION,
	ACCEPT_DECISION, DECLINE_DECISION, 
	stateToStateLabel,
	ALL_TUTORS, SAVED_TUTORS, DIRECT_TUTOR
} from "pages/Tutoring/utilities/TutoringRequestConstants";
import { monthMap } from "templates/utilities/CalendarUtilities";
import { ALERT_THIRTY_SECONDS_TIMEOUT } from "Utilities/Constants/TimeoutConstants";
import { OVER, BETWEEN, UNDER } from "pages/Profile/subPages/utilities/TutoringSubPageConstants";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";
import { handleTutoringPaymentIntentResponse } from "pages/Checkout/CheckoutUtility";

import { deleteTutorRequestAPI, applyToSessionRequestAPI,
	acceptTutorRequestApplicationAPI, declineTutorRequestApplicationAPI,
	acceptTutorRequestAPI, declineTutorRequestAPI,
	cancelTutorSessionAPI,
	joinTutorSessionAPI, checkOtherPartyPresenceAPI,
	leaveTutorSessionAPI,
	reportTutorSessionAPI
} from "apis/controllers/tutoring/SessionRequestController";
import { createReviewAPI } from "apis/controllers/tutoring/TutoringReviewController";
import { GET_TUTOR_PROFILE } from "apis/controllers/tutoring/TutorProfileController";
import { GET_ACCOUNT_SETTINGS } from "apis/controllers/person/AccountsController";
import { createPaymentIntentAPI } from "apis/controllers/transactions/PaymentsController";
import { GET_CONNECT_ACCOUNT_ADDITIONAL_REQUIREMENTS } from "apis/controllers/transactions/PayoutsController";

import calendarAsset from "assets/icons/common/calendar.svg";
import clockAsset from "assets/icons/common/clock.svg";
import userAsset from "assets/icons/common/user.svg";
import detailsAsset from "assets/icons/common/details.svg";
import locatoinAsset from "assets/icons/common/location.svg";
import resultsAsset from "assets/icons/common/results.svg";


function RequestItem(props) {

	const ownerId = props.ownerId || localStorage.getItem("ownerId");

	const history = useHistory();

	const [parsedUrgency, setParsedUrgency] = useState([]),
		[ratingTooltip, setRatingTooltip] = useState();

	const timeTriggersSeenRef = useRef({5: false, [props.duration]: false});
	const reportIssuesRef = useRef(new Set([])),
		reportDescriptionRef = useRef(""),
		ratingRef = useRef(0),
		ratingDescriptionRef = useRef(),
		verifiedConnectAccountRef = useRef(false),
		tutorProfileIsPublic = useRef(false);

	useEffect(() => {
		parseUrgency();

		getAccountStatus();
	}, []);

	function parseUrgency() {
		let urgency = props.urgency;

		if (props.urgency !== "now") {
			const origUrgency = new Date(Number(props.urgency));
			urgency = origUrgency.toLocaleString();

			urgency = urgency.split(" ");
			let hour = urgency[1].split(":");

			const date = `${monthMap[origUrgency.getMonth()]} ${origUrgency.getDate()}, ${origUrgency.getFullYear()}`;

			const duration = props.duration;
			const startingHour = Number(hour[0]);
			let startingMinutes = Number(hour[1]);
			const endingHour = startingHour + Math.floor(duration / 60);
			let endingMinutes = startingMinutes + (duration  % 60);
			endingMinutes = String(endingMinutes).length === 1 ? "0" + endingMinutes : endingMinutes;
			startingMinutes = String(startingMinutes).length === 1 ? "0" + startingMinutes : startingMinutes;

			const startingAmPm = urgency[2];
			const endingAmPm = endingHour >= 12 ? (startingAmPm === "PM" ? "AM" : "PM") : startingAmPm;
			const time = `${startingHour}:${startingMinutes} ${startingAmPm} - ${endingHour}:${endingMinutes} ${endingAmPm}`;
			urgency = [date, time];
		}

		setParsedUrgency(urgency);
	}

	function getAccountStatus() {
		const pathVariables = {
			tutorOwnerId: ownerId
		};
		const apiArr = [
			{
				api: GET_CONNECT_ACCOUNT_ADDITIONAL_REQUIREMENTS,
				type: POST,
				payload: ownerId
			}, {
				api: $replace(GET_TUTOR_PROFILE, pathVariables),
				type: GET
			}
		];

		promiseAll(apiArr, resp => {
			const verificationStatus = resp[0].data;

			let verified = false;
			if (verificationStatus.currentlyDue != null || verificationStatus.errors != null) {
				verified = verificationStatus.verificationStatus === "verified";
			}

			verifiedConnectAccountRef.current = verified;
			tutorProfileIsPublic.current = resp[1].data.isPublic;
		});
	}

	function editRequest() {
		props.setModal(
			<Modal closeHandler={closeModal} modalClass="tutoring new-request">
				<NewRequest setModal={props.setModal} setModal1={props.setModal1} setAlert={props.setAlert} 
					reRenderList={props.reRenderList} mode="editRequest"
					requestId={props.requestId} place={props.place}
					urgency={props.urgency} urgencyDuration={props.duration}
					subjectsToTopicsMap={{[props.subject]: props.topic}} subject={props.subject} topic={props.topic}
					matchingPreference={props.matchingPreference} requestsGroupPreference={props.receiverPreference}
					description={props.originalDescription} />
			</Modal>
		);
	}

	function confirmCancelSession() {
		const parsedCancellationPolicy = transformCancellationPoliciesStringToObject(props.cancellationPolicy, props.intendedUser);
		const underPenalty = parsedCancellationPolicy[UNDER][props.intendedUser === TUTEE_USER ? "fee" :"percentage"];
		const betweenPenalty = parsedCancellationPolicy[BETWEEN][props.intendedUser === TUTEE_USER ? "fee" : "percentage"];
		let cancellation1 = "";
		let cancellation2 = "";
		let cancellation3 = "";

		if (props.intendedUser === TUTEE_USER) {
			cancellation1 = `${messageMap("tutoringPage.session.modal.cancelling1a", "generic")} ${parsedCancellationPolicy[OVER]["hours"]} ${messageMap("tutoringPage.session.modal.cancelling1b", "generic")} ${messageMap("tutoringPage.session.modal.cancelling1ca", "generic")}`;
			cancellation2 = `${messageMap("tutoringPage.session.modal.cancelling2a", "generic")} ${parsedCancellationPolicy[BETWEEN][1]["hours"]} ${messageMap("tutoringPage.session.modal.cancelling2b", "generic")} ${parsedCancellationPolicy[BETWEEN][2]["hours"]} ${messageMap("tutoringPage.session.modal.cancelling2c", "generic")}} ${messageMap("tutoringPage.session.modal.cancelling2da", "generic")} ${100 - betweenPenalty}% ${messageMap("tutoringPage.session.modal.cancelling2ea", "generic")}`;
			cancellation3 = `${messageMap("tutoringPage.session.modal.cancelling3a", "generic")} ${parsedCancellationPolicy[UNDER]["hours"]} ${messageMap("tutoringPage.session.modal.cancelling3ba", "generic")} ${props.intendedUser === TUTEE_USER ? `${100 - underPenalty}%` : `$${underPenalty}`} ${messageMap("tutoringPage.session.modal.cancelling3ca", "generic")}`;
		}
		else {
			cancellation1 = messageMap("tutoringPage.session.modal.tuteeFullRefund", "generic");
		}

		const subheader = `${messageMap("tutoringPage.session.modal.cancelSubheader1", "generic")} ${props.otherPartyName} ${messageMap("tutoringPage.session.modal.cancelSubheader2", "generic")} ${parsedUrgency} ${messageMap("tutoringPage.session.modal.cancelSubheader3", "generic")} ${topicsInSubjects[props.subject][props.topic]}`;
		props.setModal(
			<Modal closeType="xButton" closeHandler={closeModal} submitHandler={cancelSession}
				modalClass="tutoring cancel-session"
				title={messageMap("tutoringPage.session.modal.titleCancelSession", "generic")}
				subHeader={subheader} 
				submitText={messageMap("tutoring.session.cancel", "button")}>
				<div className="reminder">
					{messageMap("tutoringPage.session.modal.reminder", "generic")}
				</div>
				{
					[cancellation1, cancellation2, cancellation3].map((cancellationPolicy, index) =>
						<div key={`cancellation_${index}`} className="cancel-text">
							{cancellationPolicy}
						</div>
					)
				}
			</Modal>
		);
	}

	function confirmDeleteRequest() {
		props.setModal(
			<Modal closeType="xButton" closeHandler={closeModal} title={messageMap("tutoringPage.request.modal.titleConfirmDeletion", "generic")}
					submitHandler={deleteRequest} submitText={messageMap("tutoring.request.delete", "button")}
					modalClass="tutoring delete-request">
				<div className="delete-text">
					{messageMap("tutoringPage.request.modal.deletion", "generic")}
					<p className="delete-text-topic">
						{topicsInSubjects[props.subject][props.topic]}
					</p>
				</div>
			</Modal>
		);
	}

	function closeModal(e) {
		if (e == null || (e != null && ["modal-block", "cancel", "icon", "close-button"].includes(e.target.className))) {
			hideModal("default");
		}
	}

	function closeModal1(e) {
		if (e == null || (e != null && ["modal-block", "cancel", "icon", "close-button"].includes(e.target.className))) {
			hideModal("modal1");
		}
	}

	function hideModal(modalType) {
		const modalMap = {
			default: props.setModal,
			modal1: props.setModal1
		};

		setTimeout(() => {
			modalMap[modalType](null);
		}, MODAL_CLOSE_TIMEOUT);
	}

	function cancelSession() {
		const pathVariables = {
			sessionRequestId: props.requestId,
			canceller: props.intendedUser
		};
		cancelTutorSessionAPI(pathVariables, ownerId, resp => {
			const alertType = resp.includes("success") ? SUCCESS : ERROR;
			showAlertAndReRender(alertType, resp);
		});
	}

	function deleteRequest() {
		const payload = {
			ownerId: ownerId,
			id: props.requestId
		};

		deleteTutorRequestAPI(payload, resp => {
			const alertType = resp === "tutor.requests.deleted.success" ? SUCCESS : ERROR;
			showAlertAndReRender(alertType, resp);
			props.refreshOpenRequestCount(Date.now());
		});
	}

	function showApplyToRequestModal() {
		if (!verifiedConnectAccountRef.current || !tutorProfileIsPublic.current) {
			props.setModal(
				<Modal closeType="xButton" closeHandler={closeModal}
					title={messageMap("tutoringPage.request.verifyStripeConnectAccountFirst", "generic")}
					modalClass="tutoring apply-to-request">
					<button className="check-verification" onClick={checkStripeConnectVerificationStatus}>
						{messageMap("tutoring.modal.checkStripeVerification", "button")}
					</button>
				</Modal>
			);
		}
		else {
			const applyTitle = `${messageMap("tutoringPage.request.modal.titleApplyToRequest1", "generic")} ${props.otherPartyName}${messageMap("tutoringPage.request.modal.titleApplyToRequest2", "generic")}`;

			props.setModal(
				<Modal closeType="xButton" closeHandler={closeModal}
						title={applyTitle}
						submitHandler={sendApplication} submitText={messageMap("tutoring.request.apply", "button")}
						modalClass="tutoring apply-to-request">
				</Modal>
			);
		}
	}

	function sendApplication() {
		const pathVariables = {
			sessionRequestId: props.requestId
		};
		applyToSessionRequestAPI(pathVariables, ownerId, resp => {
			const alertType = resp.includes("failed") ? ERROR : SUCCESS;
			showAlertAndReRender(alertType, resp, 30000);
			props.refreshOpenRequestCount(Date.now());
		});
	}

	function showAlertAndReRender(alertType, respCode, alertDuration = 10000) {
		props.setAlert(
			<Alert type={alertType} closeHandler={closeAlert} customClass="tutoring" 
				msg={messageMap(respCode, "api")} timeout={alertDuration} />
		);
		props.reRenderList();
		hideModal("default");
	}

	function closeAlert() {
		props.setAlert(null);
	}

	function showConfirmationModal(decision) {
		let confirmModalTitle;
		let modalClass = "";
		if (decision === ACCEPT_DECISION) {
			if (props.intendedUser === TUTEE_USER) {
				confirmModalTitle = messageMap("tutoringPage.request.modal.titleAcceptTutors", "generic");
			}
			else {
				confirmModalTitle = messageMap("tutoringPage.request.modal.titleAcceptTutee", "generic");
			}
		}
		else {
			if (props.intendedUser === TUTEE_USER) {
				confirmModalTitle = messageMap("tutoringPage.request.modal.titleDeclineTutors", "generic");
			}
			else {
				confirmModalTitle = messageMap("tutoringPage.request.modal.titleDeclineTutee", "generic");
			}
		}

		let sessionCostHeader = "";
		if (props.sessionPrice != null) {
			sessionCostHeader = `${messageMap("tutoringPage.request.modal.acceptSessionCost", "generic")}${props.sessionPrice.toFixed(2)}`;
		}
		else if (decision === ACCEPT_DECISION && props.intendedUser === TUTOR_USER
			&& props.receiverPreference === DIRECT_TUTOR) {
			sessionCostHeader = messageMap("tutoringPage.request.modal.acceptAutomaticDeletion", "generic");
			modalClass = "automatic-deletion";
		}
		props.setModal(
			<ConfirmModal modalClass={modalClass} title={confirmModalTitle} subHeader={sessionCostHeader}
				closeArgs={decision} closeModal={executeDecision} confirmType="ensure" />
		);
	}

	function showReviewDetails() {
		props.setModal(
			<Modal closeType="xButton" modalClass="tutoring new-request" closeHandler={closeModal} >
				<div style={{fontSize: "16px"}}>
					{props.reviewDescription}
				</div>
			</Modal>
		);
	}

	function executeDecision(blank, decision) {
		if ([ACCEPT_DECISION, DECLINE_DECISION].includes(decision)) {
			// NOTE*: if you're updating this block, you need to update the same block in TutoringCard & TutoringMessages
			if (props.intendedUser === TUTEE_USER && decision === ACCEPT_DECISION) {
				const paymentDescription = `${messageMap("payments.checkout.tutoringSession", "generic")} ${props.otherPartyName}`;

				const sessionPrice = props.sessionPrice;
				const sessionId = props.requestId;
				const payload = {
					ownerId: props.ownerId,
					paymentAmount: sessionPrice,
					quantityPurchased: 1,
					paymentDescription: paymentDescription,
					paymentPurpose: "tutoringSession",
					isPaymentOffSession: true,
					sessionId: sessionId,
					tutorOwnerId: props.tutorOwnerId
				};
				createPaymentIntentAPI(payload, resp => {
					handleTutoringPaymentIntentResponse(
						decision, sessionPrice, props.isSubscriber, paymentDescription,
						props.tutorId, sessionId,
						"tutoring_tutorsList", null,
						resp,
						saveDecision, null, props.setPaymentInfo, history.push
					);
				});
			}
			else {
				saveDecision(decision);
			}
		}
		else {
			closeModal();
		}
	}
	function saveDecision(decision) {
		const userDecisionMap = {
			[TUTEE_USER]: {
				[ACCEPT_DECISION]: acceptTutorRequestApplicationAPI,
				[DECLINE_DECISION]: declineTutorRequestApplicationAPI
			},
			[TUTOR_USER]: {
				[ACCEPT_DECISION]: acceptTutorRequestAPI,
				[DECLINE_DECISION]: declineTutorRequestAPI
			}
		};

		const pathVariables = {
			tutorProfileId: props.tutorId,
			sessionRequestId: props.requestId
		};

		userDecisionMap[props.intendedUser][decision](pathVariables, ownerId, resp => {
			const alertDetails = parseDecisionResponse(resp);

			props.setAlert(
				<Alert type={alertDetails.executionStatus} closeHandler={closeAlert} customClass="tutoring" 
					msg={alertDetails.msg} timeout={ALERT_THIRTY_SECONDS_TIMEOUT} />
			);

			if (alertDetails.executionStatus === SUCCESS) {
				props.reRenderList();
			}
		});
	}

	function checkStripeConnectVerificationStatus() {
		history.push("/profile?show=tutoring");
	}

	function showTutorProfilePane() {
		if (props.intendedUser === TUTEE_USER) {
			const pathVariables = {
				tutorOwnerId: props.tutorOwnerId
			};
	
			const apiArr = [
				{
					api: $replace(GET_TUTOR_PROFILE, pathVariables),
					type: GET
				}, {
					api: GET_ACCOUNT_SETTINGS,
					type: POST,
					payload: {
						ownerId: ownerId
					}
				}
			];
	
			promiseAll(apiArr, resp => {
				const tutorProfile = resp[0].data;
				const personProfile = resp[1].data;
	
				let isSaved = false;
				if (personProfile.savedTutors.includes(tutorProfile.tutorProfileId)) {
					isSaved = true;
				}
	
				props.setTutorProfilePane && props.setTutorProfilePane(
					<TutorProfilePane tutorDetails={tutorProfile} isSaved={isSaved} closePane={closePane} showBookOption={false}
					setModal={props.setModal} setModal1={props.setModal1} setAlert={props.setAlert} />
				);
			});
		}
	}

	function closePane() {
		props.setTutorProfilePane(null);
	}

	function confirmJoinSession() {
		const subheader = `${messageMap("tutoringPage.session.modal.joiningSubheader1", "generic")} ${props.otherPartyName} ${messageMap("tutoringPage.session.modal.joiningSubheader2", "generic")} ${parsedUrgency} ${messageMap("tutoringPage.session.modal.joiningSubheader3", "generic")} ${topicsInSubjects[props.subject][props.topic]}`;
		props.setModal(
			<Modal closeType="xButton" closeHandler={closeModal} submitHandler={joinSession}
				modalClass="tutoring join-session"
				title={messageMap("tutoringPage.session.modal.titleJoiningSession", "generic")}
				subHeader={subheader} 
				submitText={messageMap("tutoring.session.join", "button")}>
				<div className="reminder">
					{messageMap("tutoringPage.session.modal.reminder", "generic")}
				</div>
				{
					props.intendedUser === TUTEE_USER &&
					(
						<div className="join-text">
							{messageMap("tutoringPage.session.modal.joiningAdvice1", "generic")}
						</div>
					)
				}
				<div className="join-text">
					{messageMap("tutoringPage.session.modal.joiningAdvice2", "generic")}
				</div>
				<div className="join-text">
					{messageMap("tutoringPage.session.modal.joiningAdvice3", "generic")}
				</div>
				<div className="join-text">
					{messageMap("tutoringPage.session.modal.joiningAdvice4", "generic")}
				</div>
				<div className="footer-text">
					{messageMap("tutoringPage.session.modal.joiningAdvice5", "generic")}
				</div>
			</Modal>
		);
	}

	function joinSession() {
		const pathVariables = {
			sessionRequestId: props.requestId,
			joiner: props.intendedUser
		};
		joinTutorSessionAPI(pathVariables, ownerId, resp => {
			if (resp !== -1) {
				props.reRenderNextListStateHandler && props.reRenderNextListStateHandler(props.intendedUser, LIVE_STATE);
				props.reRenderList();
				window.open(props.zoomUrl, "_blank");
			}
			else {
				props.setAlert(
					<Alert type={ERROR} closeHandler={closeAlert} customClass="tutoring" 
						msg={messageMap("tutoringPage.session.cannotJoin", "generic")} />
				);
			}

			closeModal();
		});
	}

	function executeTimeTrigger(timeMark) {
		if (timeMark === 5 && !timeTriggersSeenRef.current[timeMark]) {
			const pathVariables = {
				sessionRequestId: props.requestId,
				joiner: props.intendedUser
			};
			checkOtherPartyPresenceAPI(pathVariables, resp => {
				if (resp === -1) {
					let newTimeTriggerState = structuredClone(timeTriggersSeenRef.current);
					newTimeTriggerState[timeMark] = true;
					timeTriggersSeenRef.current = newTimeTriggerState;

					props.setModal(
						<Modal closeType="xButton" closeHandler={closeModal} modalClass="tutoring leave-permission"
							submitHandler={leaveSession} submitText={messageMap("tutoring.session.leave", "button")}
							title={messageMap("tutoringPage.session.modal.titleNoShow", "generic")}>
							<div className="leave-text">
								{messageMap("tutoringPage.session.modal.failedToShowUp", "generic")}
							</div>
						</Modal>
					);
				}
			});
		}
		// assumes that there is only 2 time triggers and that the second one would always be session's duration
		// it's important that we rely on using props.duration as a minimum so that we don't make numerous duplicate calls
		if (props.duration <= timeMark && Object.keys(timeTriggersSeenRef.current).includes(timeMark)) {
			let newTimeTriggerState = structuredClone(timeTriggersSeenRef.current);
			newTimeTriggerState[timeMark] = true;
			timeTriggersSeenRef.current = newTimeTriggerState;
			leaveSession(true);
		}
	}

	function leaveSession(automaticLeaving) {
		const pathVariables = {
			sessionRequestId: props.requestId,
			leaver: props.intendedUser
		};
		leaveTutorSessionAPI(pathVariables, resp => {
			const alertType = resp.includes("failed") ? ERROR : SUCCESS;

			let msg = messageMap(resp, "api");
			if (automaticLeaving) {
				msg = messageMap("tutor.sessions.left.successAutomatic", "api");
			}

			props.setAlert(
				<Alert type={alertType} customClass="tutoring" closeHandler={closeAlert}
					msg={msg} />
			);

			closeModal();
			if (alertType === SUCCESS) {
				props.reRenderNextListStateHandler && props.reRenderNextListStateHandler(props.intendedUser, COMPLETED_STATE);
				props.reRenderList();
			}
		});
	}

	function giveReview() {
		props.setModal(
			<Modal closeHandler={closeModal} title={messageMap("tutoringPage.session.modal.giveReview", "generic")}
				submitHandler={createReview} submitText={messageMap("tutoring.session.postReview", "button")}
				modalClass="tutoring review-session" >

				<div className="review-text">
					{messageMap("tutoringPage.session.modal.rateSession", "generic")}
				</div>
				<div>
					<input type="number" max="5" min="0"
						className="review-rating" onChange={e => validateNumValue(e, 0, 5, ratingRef, "reviewLImit")}></input> / 5
				</div>
				<div className="review-text">
					{messageMap("tutoringPage.session.modal.rateDetails", "generic")}
				</div>
				<textarea placeholder={messageMap("tutoring.sessions.optional", "labelPlaceholder")}
					className="review-description" onChange={saveTextareaText}>
				</textarea>
			</Modal>
		);
	}

	function validateNumValue(e, min, max, ref, msgKey) {
		const ratingValue = e.target.value;
		if (min <= ratingValue && ratingValue <= max) {
			ref.current = ratingValue;
		}
		else {
			props.setAlert(
				<Alert type={ERROR} customClass="tutoring" closeHandler={closeAlert}
					msg={messageMap(`tutoring.${msgKey}`, "validation")} />
			);
		}
	}

	function saveTextareaText(e) {
		const text = e.target.value;

		if (!checkHasExplicitWords(text)) {
			ratingDescriptionRef.current = text;
		}
		else {
			props.setAlert(
				<Alert type={ERROR} customClass="tutoring" closeHandler={closeAlert}
					msg={messageMap("input.explicit.text", "validation")} />
			);
			e.target.value = "";
		}
	}

	function createReview() {
		const payload = {
			ownerId: ownerId,
			sessionId: props.requestId,
			rating: ratingRef.current,
			reviewComment: ratingDescriptionRef.current,
			reviewFromWhichUserType: props.intendedUser
		};
		createReviewAPI(payload, resp => {
			const alertType = resp.includes("failed") ? ERROR : SUCCESS;
			props.setAlert(
				<Alert type={alertType} customClass="tutoring" closeHandler={closeAlert}
					msg={messageMap(resp, "api")} />
			);

			if (alertType === SUCCESS) {
				closeModal();
				props.reRenderList();
			}
		});
	}

	function toggleReviewTooltip(hideOrShow) {
		setRatingTooltip(
			hideOrShow === "show" ?
			(
				<Tooltip classStr="tooltip-bottom-middle tutoring-review"
					subheader={
						props.currentUserRating == null && props.otherPartyRating == null
						? messageMap("tutoringPage.session.helpViaFeedback", "generic")
						: (
							props.currentUserRating == null && props.otherPartyRating != null
							&& messageMap("tutoringPage.session.seeOtherPartyReview", "generic")
						)
					} />
			)
			: ""
		);
	}

	function reportTutorDetails() {
		let infractions = [];
		["misconduct", "personalInfo", "money", "profanity", "other"].forEach(infraction => {
			infractions.push(
				<div key={infraction} className="infraction">
					<label htmlFor={infraction} className="infraction-text">
						<input id={infraction} type="checkbox" className="infraction-checkbox"
							onChange={e => includeExcludeInfraction(e, infraction)}></input>
						<span className="span-checkbox"></span>
						{messageMap(`tutoring.sessions.${infraction}`, "labelPlaceholder")}
					</label>
				</div>
			);
		});

		props.setModal(
			<Modal closeHandler={closeModal} title={`${messageMap("tutoringPage.session.modal.report", "generic")} ${props.otherPartyName}`}
					submitHandler={reportTutor} submitText={messageMap("tutoring.session.sendReport", "button")}
					modalClass="tutoring report-session">
				<div className="report-text">
					{messageMap("tutoringPage.session.modal.chooseFollowing", "generic")}
				</div>
				<div className="infractions-container">
					{infractions}
				</div>
				<div className="report-text">
					{messageMap("tutoringPage.session.modal.additionalDetails", "generic")}
				</div>
				<textarea placeholder={messageMap("tutoring.sessions.optional", "labelPlaceholder")}
					className="report-description" onChange={e => saveReportDescription(e.target.value)}>
				</textarea>
				<div className="report-text">
					<button className="guidelines-policies-button" onClick={showGuidelinesPolicies}>
						{messageMap("tutoringPage.session.modal.guidelinesPolicies", "generic")}
					</button>
				</div>
			</Modal>
		);
	}

	function includeExcludeInfraction(e, infraction) {
		e.target.checked ? reportIssuesRef.current.add(infraction) : reportIssuesRef.current.delete(infraction);
	}

	function saveReportDescription(text) {
		reportDescriptionRef.current = text;
	}

	function reportTutor() {
		const pathVariables = {
			sessionRequestId: props.requestId,
			reporter: props.intendedUser
		};
		const payload = {
			ownerId: ownerId,
			misconducts: Array.from(reportIssuesRef.current),
			additionalDetails: reportDescriptionRef.current
		};
		reportTutorSessionAPI(pathVariables, payload, resp => {
			const alertType = resp.includes("failed") ? ERROR : SUCCESS;
			props.setAlert(
				<Alert type={alertType} customClass="tutoring" closeHandler={closeAlert}
					msg={messageMap(resp, "api")} />
			);

			if (alertType === SUCCESS) {
				closeModal();
				props.reRenderNextListStateHandler && props.reRenderNextListStateHandler(props.intendedUser, COMPLETED_STATE);
				props.reRenderList();
			}
		});
	}

	function showGuidelinesPolicies() {
		props.setModal1(
			<Modal closeType="xButton" closeHandler={closeModal1}
				modalClass="tutoring">
					{
						props.intendedUser === TUTEE_USER ?
						<TuteePolicy contentType="modal" />
						: <TutorPolicy contentType="modal" />
					}
			</Modal>
		);
	}

	return (
		<Fragment>
			<div key={props.requestId} className="request-item">

				<div className="request-header">
					{`${topicsInSubjects[props.subject][props.topic]} `}
					{
						([SCHEDULED_STATE, LIVE_STATE, COMPLETED_STATE].includes(props.state) 
						|| (props.state === OPEN_STATE && props.intendedUser === TUTOR_USER))
						&& (
							<Fragment>
								{messageMap("tutoringPage.request.sessionWith", "generic")}
								<button onClick={showTutorProfilePane} className={`${props.intendedUser === TUTEE_USER ? "tutor-applicant" : "application-sent"}`}>
									{` ${props.otherPartyName}`}
								</button>
							</Fragment>
						)
					}
				</div>

				<div className="date-time-otherParty-applicantCount-container">
					<div className="date">
						<img className="calendar" src={calendarAsset} alt={messageMap("calendar", "image")} />
						{parsedUrgency.length && parsedUrgency !== "now" ? parsedUrgency[0] : parsedUrgency}
					</div>


					<div className="time">
						<img className="clock" src={clockAsset} alt={messageMap("clock", "image")}/>
						{
							parsedUrgency !== "now"
							? (
								parsedUrgency.length && parsedUrgency[1]
							)
							: (
								`${props.duration} ${messageMap("tutoringPage.request.modal.minutes", "generic")}`
							)
						}
					</div>

					{
						[OPEN_STATE, PENDING_STATE, SCHEDULED_STATE, LIVE_STATE, COMPLETED_STATE].includes(props.state)
						&& (
							<div className="otherParty">
								<img className="icon" src={userAsset} alt={messageMap("singleHuman", "image")}/>

								{
									[OPEN_STATE, PENDING_STATE].includes(props.state)
									? (
										props.intendedUser === TUTEE_USER
										? (
											props.setTutorProfilePane
											?
											(
												props.otherPartyName
											)
											: (
												<div className="item-component receiver">
													<div className="receiver-choice">
														{
															props.receiverPreference === ALL_TUTORS
															? messageMap("tutoringPage.request.allTutors", "generic") 
															: props.receiverPreference === SAVED_TUTORS
															? messageMap("tutoringPage.request.savedTutors", "generic")
															: props.otherPartyName
														}
													</div>
												</div>
											)
										)
										: (
											props.intendedUser === TUTOR_USER
											&& (
												props.sentOrReceived != null && props.sentOrReceived !== ""
												&& [RECEIVED_DIRECTION, SENT_DIRECTION].includes(props.sentOrReceived)
												? (
													props.sentOrReceived === RECEIVED_DIRECTION
													? (
														`${messageMap("tutoringPage.request.receivedFrom", "generic")} ${props.otherPartyName}`
													)
													: (
														`${messageMap("tutoringPage.request.sentTo", "generic")} ${props.otherPartyName}`
													)
												)
												: props.otherPartyName
											)
										)
									)
									: (props.otherPartyName)
								}
							</div>
						)
					}

					{
						props.state === LIVE_STATE &&
						(
							<div className="timer-container">
								<div>
									{messageMap("tutoringPage.session.timeElapsed", "generic")}
								</div>
								{/* 
									The '5' comes from our policy that, if the other party doesn't join the session within the first 5 minutes, 
									the user has the option to leave without penalty.
								*/}
								<Timer startingTime={props.urgency === "now" ? props.createdAt : props.urgency}
									timeTriggers={[5, props.duration]} timeTriggerHandler={executeTimeTrigger} />
							</div>
						)
					}
					{
						props.state === PENDING_STATE && props.intendedUser === TUTOR_USER && props.sentOrReceived === SENT_DIRECTION
						&& (
							<div className="item-component no-right-space accept-decline-container">
								{`${messageMap("tutoringPage.request.status", "generic")}: ${props.state}`}
							</div>
						)
					}
				</div>


				<div className="place-description-container">
					<div className="place">
						<img className="icon" src={locatoinAsset} alt={messageMap("location", "image")}/>
						{messageMap(`tutoringPage.request.${props.place.includes("virtual") ? "virtual" : "inPerson"}`, "generic")}
					</div>

					<div className="description">
						<img className="icon" src={detailsAsset} alt={messageMap("details", "image")}/>
						{props.description}
					</div>
				</div>

				{
					[COMPLETED_STATE, DISPUTED_STATE, DISPUTE_RESOLVED_STATE, TUTEE_NO_SHOW_STATE, TUTOR_NO_SHOW_STATE].includes(props.state)
					&& (
						<div className="status-container">
							<img className="icon" src={resultsAsset} alt={messageMap("status", "image")}/>
							{`${messageMap("tutoringPage.session.status", "generic")} ${stateToStateLabel[props.state]}`}
						</div>
					)
				}

				<div className="ratings-container">
					{
						[COMPLETED_STATE, DISPUTE_RESOLVED_STATE].includes(props.state) && !props.isForPublic && props.currentUserRating != null 
						&& (
							<Fragment>
								{
									props.otherPartyRating != null
									? (
										<Fragment>
											{
												calculateTutorAvgRating([
													{
														rating: props.otherPartyRating
													}
												])
											}
											<button className="read-more" onClick={showReviewDetails}>
												{messageMap("tutoring.readMore", "button")}
											</button>
										</Fragment>
									)
									: (
										props.otherPartyRating == null
										&& (
											messageMap("tutoringPage.session.pendingReview", "generic")
										)
									)
								}
							</Fragment>
						)
					}
				</div>

				<div className="request-actions-container">
					{
						[OPEN_STATE, PENDING_STATE].includes(props.state)
						&& (
							props.intendedUser === TUTEE_USER
							? (
								<Fragment>
									{
										props.setModal1
										&& (
											<button className="option" onClick={editRequest}>
												{messageMap("tutoring.request.edit", "button")}
											</button>
										)
									}
									<button className="option" onClick={confirmDeleteRequest}>
										{messageMap("tutoring.request.delete", "button")}
									</button>
								</Fragment>
							)
							: (
								props.intendedUser === TUTOR_USER
								&& (
									props.state === OPEN_STATE && ![RECEIVED_DIRECTION, SENT_DIRECTION].includes(props.sentOrReceived)
									? (
										<button className="apply" onClick={showApplyToRequestModal}>
											{messageMap("tutoring.request.apply", "button")}
										</button>
									)
									: (
										props.state === PENDING_STATE && props.sentOrReceived === RECEIVED_DIRECTION
										&& (
											<Fragment>
												{
													props.tutorAccepted ?
													(
														<div>
															{messageMap("tutoringPage.request.acceptedPending", "generic")}
														</div>
													)
													: (
														<Fragment>
															<button className="accept" onClick={e => showConfirmationModal(ACCEPT_DECISION)} >
																{
																	props.intendedUser === TUTEE_USER 
																	? messageMap("tutoring.request.hire", "button")
																	: messageMap("tutoring.request.accept", "button")
																}
															</button>
															<button className="decline" onClick={e => showConfirmationModal(DECLINE_DECISION)} >
																{messageMap("tutoring.request.decline", "button")}
															</button>
														</Fragment>
													)
												}
											</Fragment>
										)
									)
								)
							)
						)
					}
					{
						[SCHEDULED_STATE, LIVE_STATE].includes(props.state) 
						&& (
							!props.isForPublic ? (
								<Fragment>
									<button className={`join ${props.state === SCHEDULED_STATE ? "no-bottom-margin" : ""}`} onClick={confirmJoinSession}>
										{messageMap("tutoring.session.join", "button")}
									</button>

									{
										props.state === LIVE_STATE
										&& (
											<button className="leave" onClick={leaveSession} >
												{messageMap("tutoring.session.leave", "button")}
											</button>
										)
									}
									{
										SCHEDULED_STATE === props.state && props.setModal
										&& (
											<button className="option" onClick={confirmCancelSession}>
												{messageMap("tutoring.session.cancel", "button")}
											</button>
										)
									}
								</Fragment>
							)
							: (
								LIVE_STATE === props.state
								&& (
									<div className="topic-options">
										{
											props.setModal
											&& (
												<button className="option" onClick={reportTutorDetails}>
													{messageMap("tutoring.session.report", "button")}
												</button>
											)
										}
									</div>
								)
							)
						)
					}
					{
						[COMPLETED_STATE, DISPUTE_RESOLVED_STATE].includes(props.state) && !props.isForPublic
						&& (
							<Fragment>
								{
									props.currentUserRating == null && props.otherPartyRating != null
									&& (
										<Fragment>
											{ratingTooltip}
											<button className="leave-review" onClick={giveReview}
												onMouseOver={e => toggleReviewTooltip("show")} onFocus={e => toggleReviewTooltip("show")}
												onMouseLeave={e => toggleReviewTooltip("hide")} onBlur={e => toggleReviewTooltip("hide")}>
												{messageMap("tutoring.session.giveReview", "button")}
											</button>
										</Fragment>
									)
								}
							</Fragment>
						)
					}
				</div>
			</div>
		</Fragment>
	);
}

RequestItem.defaultProps = {
	intendedUser: TUTEE_USER,
	isForPublic: false
};

RequestItem.propTypes = {
	// parent state setter
	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func,
	setAlert: PropTypes.func.isRequired,
	setTutorProfilePane: PropTypes.func,

	// only used when users are viewing a tutor's public profile
	isForPublic: PropTypes.bool,

	// used for several use cases
	createdAt: PropTypes.string,
	state: PropTypes.string,
	intendedUser: PropTypes.string.isRequired,
	sentOrReceived: PropTypes.string,
	sessionPrice: PropTypes.number,
	requestId: PropTypes.string.isRequired,
	subject: PropTypes.string.isRequired,
	topic: PropTypes.string.isRequired,
	place: PropTypes.string.isRequired,
	urgency: PropTypes.string.isRequired,
	duration: PropTypes.number.isRequired,
	matchingPreference: PropTypes.string.isRequired,
	receiverPreference: PropTypes.string.isRequired,
	description: PropTypes.any.isRequired,
	originalDescription: PropTypes.string.isRequired,
	otherPartyName: PropTypes.string,
	tutorId: PropTypes.string,
	tutorOwnerId: PropTypes.string,
	tutorAccepted: PropTypes.bool,
	currentUserRating: PropTypes.number,
	otherPartyRating: PropTypes.number,
	reviewDescription: PropTypes.string,
	cancellationPolicy: PropTypes.string,

	// used for the sub pages for tutor sessions
	zoomUrl: PropTypes.string,
	tuteeJoinedTimestamp: PropTypes.number,
	tutorJoinedTimestamp: PropTypes.number,

	// callbacks
	reRenderList: PropTypes.func.isRequired,
	reRenderNextListStateHandler: PropTypes.func,
	refreshOpenRequestCount: PropTypes.func,

	// redux props
	ownerId: PropTypes.string.isRequired,
	isSubscriber: PropTypes.bool,
	setPaymentInfo: PropTypes.func.isRequired
};

export default connect(
	account,
	{ setPaymentInfo }
)(RequestItem);

