import React, {useEffect, useState, useRef, Fragment} from "react";
import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";
import { onKeyDown } from "Utilities/Accessibility";

import Tooltip from "templates/Tooltip";

import labelInfoAsset from "assets/icons/common/labelInfo.svg";


function LabelTwoRadioOptions(props) {

	const LABEL_MAP = {
		fifteenMinuteTrial: messageMap("profilePage.tutoring.modal.application.fifteenMinuteTrials", "generic"),
		instantBooking: messageMap("profilePage.tutoring.modal.application.instantBooking", "generic"),
		inPersonTutoring: messageMap("profilePage.tutoring.modal.application.inPersonTutoring", "generic"),
	};
	const IS_REQUIRED_MAP = {
		fifteenMinuteTrial: true,
		instantBooking: true,
		inPersonTutoring: true
	};
	const TOOLTIP_MAP = {
		fifteenMinuteTrial: messageMap("tutor.fifteenMinuteTrial", "tooltip"),
		instantBooking: messageMap("tutor.instantBooking", "tooltip")
	};

	const [labelOptionsTooltip, setLabelOptionsTooltip] = useState();

	const firstOptionRef = useRef(),
		secondOptionRef = useRef();

	useEffect(() => {
		setSavedValues();
	}, []);

	function setSavedValues() {
		if (props.radioChoice && props.radioChoice.length) {
			if (props.radioChoice === "true") {
				secondOptionRef.current.checked = false;
				firstOptionRef.current.checked = true;
			}
			else if (props.radioChoice === "false") {
				firstOptionRef.current.checked = false;
				secondOptionRef.current.checked = true;
			}
		}
		else {
			firstOptionRef.current.checked = false;
			secondOptionRef.current.checked = false;
		}
	}

	function setRadioChoice(radioChoice) {
		if (radioChoice === "yes") {
			secondOptionRef.current.checked = false;
			firstOptionRef.current.checked = true;
			props.setRadioChoice(true, firstOptionRef, secondOptionRef);
		}
		else if (radioChoice === "no") {
			firstOptionRef.current.checked = false;
			secondOptionRef.current.checked = true;
			props.setRadioChoice(false, firstOptionRef, secondOptionRef);
		}
	}

	function toggleLabelTooltip(showOrHide) {
		if (showOrHide === "show") {
			setLabelOptionsTooltip(
				<Tooltip classStr="tooltip-bottom-left label-two-radio-options-tooltip" subheader={TOOLTIP_MAP[props.labelOptionsType]} />
			);
		}
		else {
			setLabelOptionsTooltip(null);
		}
	}

	return (
		<div className="page-field">
			<label className="page-field-label">
				<div className="div-label">
					{LABEL_MAP[props.labelOptionsType]}
				</div>
				{
					IS_REQUIRED_MAP[props.labelOptionsType] && "*"
				}
				{
					TOOLTIP_MAP[props.labelOptionsType] &&
					(
						<Fragment>
							<img className="label-info" src={labelInfoAsset} alt="information" tabIndex={0}
								onMouseOver={e => toggleLabelTooltip("show")} onFocus={e => toggleLabelTooltip("show")}
								onMouseLeave={e => toggleLabelTooltip("hide")} onBlur={e => toggleLabelTooltip("hide")} ></img>
							{labelOptionsTooltip}
						</Fragment>
					)
				}
			</label>
			<div className="inline-elements">
				<div className="radio-checkbox-container">
					<input id={`firstOption_${props.labelOptionsType}`} className="page-field-value radio-input" type="radio"
						onClick={e => setRadioChoice("yes")}
						onKeyDown={e => onKeyDown(e, setRadioChoice, ["yes"], true)}
						ref={firstOptionRef}/>
					<label htmlFor={`firstOption_${props.labelOptionsType}`} className="page-field-label two-options-radio">
						{messageMap("tutoring.profile.yes", "labelPlaceholder")}
					</label>
				</div>

				<div className="radio-checkbox-container">
					<input id={`secondOption${props.labelOptionsType}`} className="page-field-value radio-input" type="radio" 
						onClick={e => setRadioChoice("no")}
						onKeyDown={e => onKeyDown(e, setRadioChoice, ["no"], true)}
						ref={secondOptionRef}/>
					<label htmlFor={`secondOption${props.labelOptionsType}`} className="page-field-label two-options-radio">
						{messageMap("tutoring.profile.no", "labelPlaceholder")}
					</label>
				</div>
			</div>
		</div>
	);
}

LabelTwoRadioOptions.propTypes = {
	labelOptionsType: PropTypes.string.isRequired,

	radioChoice: PropTypes.string,
	setRadioChoice: PropTypes.func.isRequired
};

export default LabelTwoRadioOptions;

