import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";
import { setTutoringSubNav } from "redux/actions/actionTypes";

import RequestList from "pages/Tutoring/subPages/subMenus/RequestList";
import TutorsList from "pages/Tutoring/subPages/TutorsList";

import Alert, {ERROR, SUCCESS} from "templates/Alert";

import defaultLinkAction from "Utilities/LinkActions";
import messageMap from "Utilities/MessageMaps";
import { isValidIdentity } from "Utilities/Validators/IdentityValidators";
import { TUTOR_USER } from "pages/Tutoring/utilities/TutoringRequestConstants";
import { 
	OPEN_STATE, PENDING_STATE,
	SENT_DIRECTION, RECEIVED_DIRECTION } from "pages/Tutoring/utilities/TutoringRequestConstants";
import { parseDecisionResponse } from "../utilities/TutorRequestUtility";
import { DEFAULT_TYPE, TUTOR_TYPE } from "pages/Profile/subPages/utilities/AccountUtility";
import { askUserToSignUpFirst } from "templates/customModals/utilities/SignUpLoginUtilities";
import { TUTORING_REQUESTS_TABS } from "templates/utilities/PageTabs";
import { ALERT_THIRTY_SECONDS_TIMEOUT } from "Utilities/Constants/TimeoutConstants";

import { getUserTypeAPI } from "apis/controllers/person/AccountsController";
import { acceptTutorRequestApplicationAPI } from "apis/controllers/tutoring/SessionRequestController";


function TutorApplications(props) {

	const identityIsValid = isValidIdentity(props.ownerId);
	const ownerId = props.ownerId || localStorage.getItem("ownerId");

	const [spinner, setSpinner] = useState();

	const [applicationsType, setApplicationsType] = useState(null);
	const [signUpFirstVisibility, setSignUpFirstVisibility] = useState(false);
	const [isTutor, setIsTutor] = useState(false),
		[toggableUser, setToggableUser] = useState(false),
		[userLabel, setUserLabel] = useState(),
		[emptyListMessage, setEmptyListMessage] = useState();

	const requestListTypeCounts = useRef({});

	useEffect(() => {
		checkPaymentRedirection(getUserType);
	}, []);

	// NOTE*: if you're updating this block, you need to update the same block in TutoringMessages & RequestItem
	function checkPaymentRedirection(callback) {
		const urlParams = new URLSearchParams(window.location.search);

		if (urlParams.get("tutorId") != null && urlParams.get("sessionId") != null) {
			const pathVariables = {
				tutorProfileId: urlParams.get("tutorId"),
				sessionRequestId: urlParams.get("sessionId")
			};
			acceptTutorRequestApplicationAPI(pathVariables, ownerId, resp => {
				const alertDetails = parseDecisionResponse(resp);

				props.setAlert(
					<Alert type={alertDetails.executionStatus} closeHandler={closeAlert} customClass="tutoring" 
						msg={alertDetails.msg} timeout={ALERT_THIRTY_SECONDS_TIMEOUT} />
				);

				callback();
			});
		}
		else {
			callback();
		}
	}
	function closeAlert() {
		props.setAlert(null);
	}

	function getUserType() {
		if (identityIsValid) {
			getUserTypeAPI(ownerId, resp => {
				if (resp.length === 2) {
					setApplicationsType(TUTOR_TYPE);
					setToggableUser(true);
					setIsTutor(true);
					setUserLabel(messageMap("tutoringPage.request.userType.tutor", "generic"));
				}
				else {
					setApplicationsType(DEFAULT_TYPE);
				}
			});
		}
		else {
			setSignUpFirstVisibility(true);
		}
	}

	function changeUserMode() {
		if (applicationsType === TUTOR_TYPE) {
			setApplicationsType(DEFAULT_TYPE);
			setUserLabel(messageMap("tutoringPage.request.userType.default", "generic"));
		}
		else {
			setApplicationsType(TUTOR_TYPE);
			setUserLabel(messageMap("tutoringPage.request.userType.tutor", "generic"));
		}
	}

	function indicateEmptyList(listType, count) {
		requestListTypeCounts.current[listType] = count;
		if (Object.keys(requestListTypeCounts.current).length === 2) {
			setEmptyListMessage(
				<div className="empty-list-container">
					<div className="empty-list">
						{messageMap("tutoringPage.request.noRequestsSentAndReceived1", "generic")}
						<Link to="/tutoring/tutorRequests" className="here-link navigate-here-link"
							onClick={navigateToSubPage}>
							{messageMap("here.text", "button")}
						</Link>
						{messageMap("tutoringPage.request.noRequestsSentAndReceived2", "generic")}
					</div>
				</div>
			);
		}
	}

	function navigateToSubPage() {
		defaultLinkAction();
		props.setTutoringSubNav(TUTORING_REQUESTS_TABS);
	}

	return (
		<div>
			<Helmet>
				<title>{messageMap("tutoringApplications.title", "headerTag")}</title>
				<meta name="description" content={messageMap("tutoringApplications.description", "headerTag")}></meta>
			</Helmet>

			{spinner}

			{
				toggableUser ?
				(
					<div className="toggle-user">
						<label className="switch">
							<input type="checkbox" onClick={changeUserMode} />
							<span className="slider round"></span>
						</label>
						{userLabel}
					</div>
				)
				: ""
			}
			{
				applicationsType === null
				? (
					<div className={`empty-list-container ${signUpFirstVisibility ? "" : "hide"}`}>
						<div className="empty-list">
							{`${messageMap("tutoringPage.tutorList.signUpFirstApplication", "generic")} `}
							<button className="sign-up-button"
								onClick={e => askUserToSignUpFirst(props.setModal, props.setModal1)}>
								{messageMap("link.text", "button")}
							</button>
						</div>
					</div>
				)
				: (
					isTutor
					? (
						<Fragment>
							<div className={`tutor-application ${applicationsType === TUTOR_TYPE ? "hide" : ""}`}>
								<TutorsList useFilter={false} purpose={"viewApplicants"} setAlert={props.setAlert}
									setModal={props.setModal} setModal1={props.setModal1} setSpinner={setSpinner} />
							</div>

							<div className={`tutor-application ${applicationsType === TUTOR_TYPE ? "" : "hide"}`}>
								<RequestList intendedUser={TUTOR_USER} listType={[OPEN_STATE, PENDING_STATE]} sentOrReceived={RECEIVED_DIRECTION}
									emptyListHandler={indicateEmptyList}
									setModal={props.setModal} setModal1={props.setModal1} setAlert={props.setAlert} />
							</div>

							<div className={`tutor-application ${applicationsType === TUTOR_TYPE ? "" : "hide"}`}>
								<RequestList intendedUser={TUTOR_USER} listType={[OPEN_STATE, PENDING_STATE]} sentOrReceived={SENT_DIRECTION}
									emptyListHandler={indicateEmptyList}
									setModal={props.setModal} setModal1={props.setModal1} setAlert={props.setAlert} />
							</div>

							<div className={`tutor-application ${emptyListMessage ? "" : "hide"} ${applicationsType === TUTOR_TYPE ? "" : "hide"}`}>
								{emptyListMessage}
							</div>
						</Fragment>
					)
					: (
						<TutorsList useFilter={false} purpose={"viewApplicants"} setAlert={props.setAlert}
							setModal={props.setModal} setModal1={props.setModal1} setSpinner={setSpinner} />
					)
				)
			}
		</div>
	);
}

TutorApplications.propTypes = {
	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,

	// redux props
	ownerId: PropTypes.string,
	setTutoringSubNav: PropTypes.func.isRequired
};

export default connect(
	account,
	{
		setTutoringSubNav
	}
)(TutorApplications);
