import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { setPaymentInfo } from "redux/actions/actionTypes";

import PropTypes from "prop-types";

import StripeCheckoutForm from "./StripeCheckoutForm";

import messageMap from "Utilities/MessageMaps";
import { getAlt, importShinyNeuron } from "Utilities/ShinyNeuronImages";
import { isValidIdentity } from "Utilities/Validators/IdentityValidators";
import { CART_ITEM_CATEGORY_KEY, CART_ITEM_DESCRIPTION_KEY } from './CheckoutUtility';
import { SUBSCRIPTION_CART_PARAM_VALUE } from 'pages/Subscription/SubscriptionUtilities';

import Alert, { INFORMATION } from "templates/Alert";

import { quoteAPI } from "apis/controllers/transactions/PaymentsController";
import { getStripePubKeyAPI } from 'apis/controllers/thirdParty/APIKeyController';


const CURRENCY_SYMBOL = "$";

function Checkout(props) {

	// Hooks
	const history = useHistory();

	// Constants
	// Cart Search Param Handling
	const cartParams = new URLSearchParams(window.location.search);
	const ownerId = props.account.ownerId;
	const validIdentity = isValidIdentity(ownerId);
	const cartItemCategory = cartParams?.get(CART_ITEM_CATEGORY_KEY);
	const isSubscription = cartItemCategory === SUBSCRIPTION_CART_PARAM_VALUE;

	const routerStates = props.routerProp.location.state;
	const paymentDescription = (props.payments || {}).description;
	const paymentPurpose = (props.payments || {}).purpose;
	const paymentDetails = (props.payments || {}).details;

	// States
	const [alert, setAlert] = useState(null);
	const [showShinyNeuronRows, setShowShinyNeuronRows] = useState(false);
	const [shinyNeuronRows, setShinyNeuronRows] = useState([]);
	const [cartItemImage, setCartItemImage] = useState((props.payments || {}).imgRef);
	const [cartItemImageAlt, setCartItemImageAlt] = useState((props.payments || {}).imgRef);
	const [price, setPrice] = useState((props.payments || {}).price);
	const [quantity, setQuantity] = useState((props.payments || {}).quantity);
	const [stripeCheckoutForm, setStripeCheckoutForm] = useState();

	const stripePubKeyRef = useRef();

	useEffect(() => {
		if (validIdentity) {
			if (routerStates != null && routerStates.stripePubKey != null) {
				stripePubKeyRef.current = routerStates.stripePubKey;
			}
			if (!isSubscription) {
				loadStripeCustomCheckout();
			}
			if (props.account.isSubscriber && paymentPurpose === "tutoringSession") {
				setAlert(
					<Alert type={INFORMATION} closeHandler={() => setAlert(null)} 
						msg={messageMap("payments.checkout.subscriptionDiscount", "generic")} />
				);
			}

			setCartItemImage(importShinyNeuron(cartItemImage));
			setCartItemImageAlt(getAlt(cartItemImageAlt));
		}
		else {
			history.push("/quotes");
		}
	}, []);

	function updateShinyNeuronsBalance(shinyNeuronCards, imgRefParam = cartItemImage) {
		let rows = [];

		shinyNeuronCards.forEach((element, idx) => {
			const relImgRef = idx + 1;
			const relImg = importShinyNeuron(relImgRef);
			const relAlt = getAlt(relImgRef);
			const isSelected = (relImgRef == imgRefParam) ? "selected" : null;
			const selectedText = isSelected ? " (" + messageMap("payments.checkout.selected", "generic") + ")" : "";

			function updateCart() {
				setCartItemImage(relImg);
				setCartItemImageAlt(relAlt);
				setPrice(element.price);
				setQuantity(element.quantity);
				updateShinyNeuronsBalance(shinyNeuronCards, relImgRef);
				renderStripeCheckoutForm(element.price, element.quantity);
			}

			rows.push(
				<button key={"shiny_neuron_" + relImgRef} className={`neuron-button ${isSelected && "selected"}`}
					onClick={updateCart} >
					<img src={relImg} alt={relAlt} className="neuron-image"></img>
					<div className="spaced-apart">
						<span className="shiny-neurons-price">
							{element.quantity} {messageMap("payments.checkout.shinyNeuronPack", "generic") + selectedText}
						</span>
						<span className="shiny-neurons-price">
							{`${CURRENCY_SYMBOL}${element.price}`}
						</span>
					</div>
				</button>
			);
		})

		setShinyNeuronRows(rows);
		renderStripeCheckoutForm(price, quantity);
	}

	function renderStripeCheckoutForm(newPrice, newQuantity) {
		setStripeCheckoutForm(
			<StripeCheckoutForm key={newPrice} price={Number(newPrice)} quantity={Number(newQuantity)} paymentDescription={paymentDescription}
				stripePubKey={stripePubKeyRef.current} paymentDetails={paymentDetails} paymentPurpose={paymentPurpose} ownerId={ownerId}
				// 5 props will only be non-null if user's payment method gets declined
				returnUrl={routerStates && routerStates.returnUrl} clientSecret={routerStates && routerStates.clientSecret}
				tutorId={routerStates && routerStates.tutorId} sessionId={routerStates && routerStates.sessionId}
				directTutoringRequestMatchingObject={routerStates && routerStates.directTutoringRequestMatchingObject} />
		);
	}

	function loadStripeCustomCheckout(callback) {
		getStripePubKeyAPI(resp => {
			stripePubKeyRef.current = resp;
			quoteAPI(response => updateShinyNeuronsBalance(response));
		});
	}

	function toggleShinyNeuronRows() {
		if (!["subscription", "tutoringSession"].includes(paymentPurpose)) {
			setShowShinyNeuronRows(!showShinyNeuronRows);
		}
	}

	return (
		<Fragment>
			<Helmet>
				<title>{messageMap("checkout.title", "headerTag")}</title>
				<meta name="description" content={messageMap("checkout.description", "headerTag")}></meta>
			</Helmet>

			{alert}

			{
				validIdentity &&
				<div className="checkout-container">
					<div className="cart-container">
						<button className={`neuron-button ${["subscription", "tutoringSession"].includes(paymentPurpose) && "no-options"}`}
							onClick={toggleShinyNeuronRows}>
							<img src={cartItemImage} alt={cartItemImageAlt} className="neuron-image"></img>
							<div className="spaced-apart">
								<span className="shiny-neurons-price">
									{quantity} {paymentDescription != null ? paymentDescription : messageMap("payments.checkout.shinyNeuronPack", "generic")}
								</span>
								<span className="shiny-neurons-price">
									{CURRENCY_SYMBOL}{price.toFixed(2)}
								</span>
							</div>
							{
								!["subscription", "tutoringSession"].includes(paymentPurpose) &&
								<div className={showShinyNeuronRows ? "expand-button chevron-up" : "expand-button chevron-down"}>
								</div>
							}
						</button>

						<div className={showShinyNeuronRows ? "edit-shiny-neurons-list" : "no-shiny-neurons-list"}>
							{showShinyNeuronRows && shinyNeuronRows}
						</div>

						<div className="spaced-apart subtotal-container">
							<div className="shiny-neurons-price">
								{messageMap("payments.checkout.subtotal", "generic")}
							</div>
							<div className="shiny-neurons-price">
								{CURRENCY_SYMBOL}{price.toFixed(2)}
							</div>
						</div>

						<div className="spaced-apart total-container">
							<span className="total-price">
								{messageMap("payments.checkout.total", "generic")}</span>
							<span>
								<span className="currency-symbol">
									{messageMap("payments.checkout.usd", "generic")}
								</span>
								<span className="total-price">
									{CURRENCY_SYMBOL}{price.toFixed(2)}
								</span>
							</span>
						</div>
					</div>

					<div className="payment-container">
						<div className="base-payment-form">
							{stripeCheckoutForm}
						</div>
					</div>
				</div>
			}
		</Fragment>
	);
}

function mapStateToProps(state) {
	const { account, payments } = state;
	return { account, payments };
}

Checkout.propTypes = {
	routerProp: PropTypes.object.isRequired,

	// redux props
	payments: PropTypes.object,
	account: PropTypes.object
};

export default connect(
	mapStateToProps,
	{ setPaymentInfo }
)(Checkout);