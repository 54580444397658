import messageMap from "Utilities/MessageMaps";


const DEAF = "Deafness";
// commenting this out for now because you need special hardware to handle this
// const BLIND = "Blindness";
const AUTISM = "Autism disorder";
const DOWN_SYNDROME = "Down syndrome";
const DYSLEXIA = "Dyslexia";
const DYSCALCULIA = "Dyscalculia";
const DYSGRAPHIA = "Dysgraphia";
const ADHD = "ADHD";

export const disabilities = {
	chooseDisability: messageMap("tutoring.dropdowns.disability", "button"),
	[DEAF]: DEAF,
	[AUTISM]: AUTISM,
	[DOWN_SYNDROME]: DOWN_SYNDROME,
	[DYSLEXIA]: DYSLEXIA,
	[DYSCALCULIA]: DYSCALCULIA,
	[DYSGRAPHIA]: DYSGRAPHIA,
	[ADHD]: ADHD
};

export const hourMap = {
	1: 1,
	2: 2,
	3: 3,
	4: 4,
	5: 5,
	6: 6,
	7: 7,
	8: 8,
	9: 9,
	10: 10,
	11: 11,
	12: 12
};
export const minsMap = {
	0: 0,
	15: 15,
	30: 30,
	45: 45
};
export const amPm = {
	am: "AM",
	pm: "PM"
};

const FIFTEEN_TRIAL = messageMap("tutoring.dropdowns.filter.fifteenTrial", "button");
const IN_PERSON = messageMap("tutoring.dropdowns.filter.inPersonTutoring", "button");
const INSTANT_BOOKING = messageMap("tutoring.dropdowns.filter.instantBookingAvailable", "button");
export const tutoringServices = {
	chooseServices: messageMap("tutoring.dropdowns.services", "button"),
	[FIFTEEN_TRIAL]: FIFTEEN_TRIAL,
	[INSTANT_BOOKING]: INSTANT_BOOKING,
	[IN_PERSON]: IN_PERSON
};
export const tutoringServicesExtraInput = {
	[IN_PERSON]: {
		inputHeader: messageMap("tutoring.dropdowns.filter.within", "button"),
		inputText: messageMap("tutoring.dropdowns.filter.miles", "button")
	}
};
export const tutoringServicesTooltips = {
	[FIFTEEN_TRIAL]: messageMap("tutor.fifteenMinuteTrialFilter", "tooltip"),
	[INSTANT_BOOKING]: messageMap("tutor.instantBookingFilter", "tooltip"),
	[IN_PERSON]: messageMap("tutor.inPersonFilter", "tooltip"),
};


// Price ranges
export const LESS_THAN_10 = messageMap("tutoring.dropdowns.filter.lessThanTen", "button");
export const BETWEEN_10_AND_20_EXCLUSIVE = messageMap("tutoring.dropdowns.filter.betweenTenAndTwenty", "button");
export const BETWEEN_25_AND_50_EXCLUSIVE = messageMap("tutoring.dropdowns.filter.betweenTwentyAndFifty", "button");
export const MORE_THAN_50 = messageMap("tutoring.dropdowns.filter.moreThanFifty", "button");

export const priceRanges = {
	price: messageMap("tutoring.dropdowns.price", "button"),
	[LESS_THAN_10]: LESS_THAN_10,
	[BETWEEN_10_AND_20_EXCLUSIVE]: BETWEEN_10_AND_20_EXCLUSIVE,
	[BETWEEN_25_AND_50_EXCLUSIVE]: BETWEEN_25_AND_50_EXCLUSIVE,
	[MORE_THAN_50]: MORE_THAN_50
};


// Subject maps
const MATH = "Math";
const PHYSICS = "Physics";
const CHEMISTRY = "Chemistry";
const ECONOMICS = "Economics";
const COMPUTER__SOFTWARE_ENGINEERING = "Computer & Software engineering";
const UX = "UX";

export const teachableSubjects = {
	chooseSubject: messageMap("tutoring.dropdowns.subject", "button"),
	[CHEMISTRY]: CHEMISTRY,
	[COMPUTER__SOFTWARE_ENGINEERING]: COMPUTER__SOFTWARE_ENGINEERING,
	[ECONOMICS]: ECONOMICS,
	[MATH]: MATH,
	[PHYSICS]: PHYSICS
	// [UX]: UX
};

export const topicsInSubjects = {
	[MATH]: {
		elementaryMath: messageMap("elementaryMath", "tutoring"),
		middleSchoolMath: messageMap("middleSchoolMath", "tutoring"),
		algebra: messageMap("algebra", "tutoring"),
		geometry: messageMap("geometry", "tutoring"),
		trigonometry: messageMap("trigonometry", "tutoring"),
		statistics: messageMap("statistics", "tutoring"),
		calculus: messageMap("calculus", "tutoring"),
		discreteMath: messageMap("discreteMath", "tutoring"),
		linearAlgebra: messageMap("linearAlgebra", "tutoring")
	},
	[PHYSICS]: {
		classicalMechanics: messageMap("classicalMechanics", "tutoring"),
		electromagnetism: messageMap("electromagnetism", "tutoring"),
		optics: messageMap("optics", "tutoring"),
		thermodynamics: messageMap("thermodynamics", "tutoring"),
		quantumMechanics: messageMap("quantumMechanics", "tutoring"),
		atomicPhysics: messageMap("atomicPhysics", "tutoring"),
		solidStatePhysics: messageMap("solidStatePhysics", "tutoring"),
		molecularPhysics: messageMap("molecularPhysics", "tutoring")
	},
	[CHEMISTRY]: {
		inorganic: messageMap("inorganic", "tutoring"),
		organic: messageMap("organic", "tutoring"),
		biochemistry: messageMap("biochemistry", "tutoring"),
		analyticalChemistry: messageMap("analyticalChemistry", "tutoring"),
		physicalChemistry: messageMap("physicalChemistry", "tutoring")
	},
	[ECONOMICS]: {
		macroeconomics: messageMap("macroeconomics", "tutoring"),
		microeconomics: messageMap("microeconomics", "tutoring")
	},
	[COMPUTER__SOFTWARE_ENGINEERING]: {
		computerProgramming: messageMap("computerProgramming", "tutoring"),
		lowLevelAssembly: messageMap("lowLevelAssembly", "tutoring"),
		digitalDesign: messageMap("digitalDesign", "tutoring"),
		algorithmsDataStructures: messageMap("algorithmsDataStructures", "tutoring"),
		databases: messageMap("databases", "tutoring"),
		operatingSystems: messageMap("operatingSystems", "tutoring"),
		compilerDesign: messageMap("compilerDesign", "tutoring")
	},
	// [UX]: {

	// }
};
// To reduce the need for tutors to qualify for so many topics if they know more advanced topics
// The higher the number, the more advanced the topic is
export const topicsDifficultyOrder = {
	[MATH]: {
		elementaryMath: 0,
		middleSchoolMath: 1,
		algebra: 2,
		geometry: 2,
		trigonometry: 3,
		statistics: 3,
		calculus: 4,
		discreteMath: 5,
		linearAlgebra: 5
	},
	[PHYSICS]: {
		classicalMechanics: 0,
		electromagnetism: 1,
		optics: 1,
		thermodynamics: 2,
		quantumMechanics: 3,
		atomicPhysics: 4,
		solidStatePhysics: 4,
		molecularPhysics: 5
	},
	[CHEMISTRY]: {
		inorganic: 0,
		organic: 1,
		biochemistry: 2,
		analyticalChemistry: 2,
		physicalChemistry: 2
	},
	[ECONOMICS]: {
		macroeconomics: 0,
		microeconomics: 0
	},
	[COMPUTER__SOFTWARE_ENGINEERING]: {
		computerProgramming: 0,
		lowLevelAssembly: 1,
		digitalDesign: 1,
		algorithmsDataStructures: 2,
		databases: 1,
		operatingSystems: 2,
		compilerDesign: 3
	},
};


export const OVER = "Over";
export const BETWEEN = "Between";
export const UNDER = "Under";

export const cancellationTimeRanges = {
	chooseTimeRange: messageMap("tutoring.dropdowns.cancellation", "button"),
	[OVER]: OVER,
	[BETWEEN]: BETWEEN,
	[UNDER]: UNDER
};


export const SUNDAY = "Sunday";
export const MONDAY = "Monday";
export const TUESDAY = "Tuesday";
export const WEDNESDAY = "Wednesday";
export const THURSDAY = "Thursday";
export const FRIDAY = "Friday";
export const SATURDAY = "Saturday";

export const daySchedules = {
	chooseDay: messageMap("tutoring.dropdowns.schedule", "button"),
	[SUNDAY]: SUNDAY,
	[MONDAY]: MONDAY,
	[TUESDAY]: TUESDAY,
	[WEDNESDAY]: WEDNESDAY,
	[THURSDAY]: THURSDAY,
	[FRIDAY]: FRIDAY,
	[SATURDAY]: SATURDAY
};


// Tutor tiers
export const LOW = "low";
export const MID = "mid";
export const HIGH = "high";


// Tutor notification preference
export const EMAIL = "email";
export const PHONE = "phone";