export const subPageNavigationMessageMap = {
	profile: {
		account: "User Profile",
		learner: "Learner Profile",
		tutoring: "Tutor Profile",
		subscription: "Subscription",
		groups: "Groups",
		financials: "Financials"
	},
	tutoring: {
		sessions: "Tutor sessions",
		requests: "Request a tutor",
		tutorRequests: "Tutor requests",
		applications: "Tutor applications",
		savedTutors: "Saved tutors",
		tutorsList: "Discover tutors",
		messages: "Messages",
		tutorFAQs: "FAQs",
		tutorPolicies: "Policies",
		whyExer: "Why Exer?"
	}
};