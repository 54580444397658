// KEEP FIRST-LEVEL KEYS IN ALPHABETIC ORDER
export const validationWordMap = {
	account: {
		profilePicture: {
			sameAsOld: "Profile picture is the same as the old.",
		},
		firstName: {
			sameAsOld: "First name is the same as the old.",
		},
		lastName: {
			sameAsOld: "Last name is the same as the old.",
		},
		firstNameKana: {
			sameAsOld: "First name kana is the same as the old.",
		},
		lastNameKana: {
			sameAsOld: "Last name kana is the same as the old.",
		},
		firstNameKanji: {
			sameAsOld: "First name kanji is the same as the old.",
		},
		lastNameKanji: {
			sameAsOld: "Last name kanji is the same as the old.",
		},
		email: {
			sameAsOld: "Email is the same as the old email.",
		},
		phone: {
			sameAsOld: "Phone number is the same as the old.",
		},
		emailConfirm: {
			noMatch: "Email address doesn't match."
		},
		dob: {
			sameAsOld: "Date of birth is the same as the old.",
		},
		username: {
			title: "Username must be",
			text: "Your username must be at least 5 characters long, maximum 20 characters long. Only alphanumeric characters."
		},
		forgotPassword: {
			invalidEmail: "Please enter a valid email address.",
			invalidPassword: `Your password must at least be 7 characters long, have at least 1 uppercase letter, have at 
				least 1 lowercase letter, have at least 1 number, and have at least 1 of the following special 
				characters '!@#$%^&*'`
		},
		password: {
			sameAsOld: "Password is the same as the old password.",
			text: `Your password must at least be 7 characters long, have at least 1 uppercase letter, have at 
				least 1 lowercase letter, have at least 1 number, and have at least 1 of the following special 
				characters '!@#$%^&*'`,
			title: "Passwords must",
			text1: "Be at least 7 characters long",
			text2: "Contain at least 1 uppercase letter",
			text3: "Contain at least 1 lowercase letter",
			text4: "Contain at least 1 number",
			text5: "Contain at least 1 one of the following special characters: !@#$%^&*"
		},
		passwordConfirm: {
			noMatch: "Password doesn't match."
		},
		usernamePassword: "Incorrect username/password",
		update: "Confirm Update"
	},
	tutorProfile: {
		visibility: {
			hide: "Confirm hiding",
			show: "Confirm showing"
		},
		credibilityProof: "Please provide a valid link for: ",
		inPersonTutoringRadius: "Please enter a value between 5 and 75 miles."
	},
	tutoring: {
		sessionLimit: "Cannot set the limit to less than 5 and more than 20.",
		reviewLImit: "Cannot rate less than 0 and more than 5."
	},
	action: {
		needLogin: "Please login first."
	},
	document: {
		fileSize: {
			over200KB: "Document file size cannot exceed 200KB."
		}
	},
	img: {
		dimensions: {
			overWidth: "Image width is over ",
			underWidth: "Image width is under ",
			overHeight: "Image height is over ",
			underHeight: "Image height is under ",
			pixelsOver: "pixels. Please find a smaller image.",
			pixelsUnder: "pixels. Please find a bigger image.",
		},
		fileSize: {
			overMemoryLimit1: "Image file size is more than ",
			overMemoryLimit2: "MB. Please consider compressing the file or reducing the dimensions to lower the file size."
		},
		fileType: {
			notAccepted: "Image file extension isn't supported: ",
			whatIsAccepted: "We only support the following file extensions: "
		},
		filename: {
			noSpaces: "No spaces allowed in image filename.",
			noSpecialCharacters: "No special characters allowed in image filename."
		}
	},
	input: {
		explicit: {
			text: "Detected explicit input",
			content: "Detected explicit content",
			textEditor: {
				videoDescription: "Detected explicit input in video description"
			},
			input: {
				username: "Detected explicit input in username"
			}
		},
		notNumber: "Invalid input. Please use numbers.",
		cancellationPolicy: {
			missingPolicies: "Incomplete cancellation policies. Please provide a policy for over, between, and under.",
			discontinuousHours: "Hourly discontinuous cancellation policies."
		},
		hours: {
			tooHigh: "Cannot set more than 100 hours."
		},
		percentage: {
			tooHigh: "Cannot set more than 100%."
		},
		phoneNumber: {
			missingNumber: "Please include your phone number before indicating this preference.",
			invalidNumber: "Found invalid character(s).",
			invalidCharacters: "Found one of the following invalid characters: '(', ')'",
			invalidFormat: "Found invalid format. Please use the format: (###)-###-####"
		},
		price: {
			invalidInput: "Invalid input. Please use numbers.",
			tooHighWhole: "Cannot set a price more than $999",
			tooHighDecimal: "Cannot set a price more than 99 cents"
		},
		knowledge: {
			viewName: "Missing view name"
		},
		module: {
			missing: "Please indicate the module's name."
		}
	},
	video: {
		action: {
			delete: "Confirm Deletion",
			deleteSubheader: "Deleting: "
		},
		codec: {
			audio: {
				invalid: "Invalid audio codec. We only accept mp4.",
				missing: "Could not detect any audio tracks. Please make sure that your video has audio."
			},
			video: {
				invalid: "Invalid video codec. We only accept 'mp4v', 'avc1', 'avc2', 'vp9', 'vp09', and 'av1'.",
				missing: "Could not detect any video tracks. Please make sure that you video has video."
			}
		},
		fileType: {
			notAccepted: "Video file extension isn't mp4."
		},
		lengthLimit: {
			ten: "Video length is over 10 minutes. Please break your video into multiple parts.",
			fifteenSec: "Video length is under 15 seconds. Please include more content."
		},
		sizeLimit: {
			over128GB: "Video file size exceeded 128GB. Please consider compressing the video, or changing its framerate, resolution, or bitrate."
		},
		mentalModel: {
			over5Files: "No more than 5 files."
		}
	},
	module: {
		memberCount: "You need at least 2 videos per module."
	},
	practice: {
		bag: {
			notEnough: {
				hints: "Not enough hints.",
				answers: "Not enough answers.",
				levels: "Not enough levels.",
			}
		}
	},
	schedule: {
		unfilled: "Please choose unavailable or set your time of availability.",
		invalidTime: "Invalid times. Please make sure that the ending time comes after the starting time.",
		invalidIncompleteTimes: "Invalid times. Please complete all time ranges.",
		invalidOverlappingTimes: "Invalid times. Time ranges are overlapping.",
		invalidEarlierDate: "Please choose a date and time at least 12 hours from now.",
		invalidStartingMinute: "Please choose 15-minute intervals when setting the time.",
		alreadyBooked: "This time slot is already booked. Please choose a different time.",
		invalidAppointment: "Schedule time is invalid",
		missing: {
			subjectTopic: "Please indicate the subject and/or topic that you need help in.",
			urgency: "Please indicate when you would want to schedule the session.",
			duration: "Please indicate how long the session would be.",
			requestReceiver: "Please indicate which group of tutors you would like to receive this tutoring request.",
			description: "Please provide a brief description of what you need help on."
		}
	},
	officialVerification: {
		notMatch: "The name on the agreement input does not match your name on the full name input."
	}
};