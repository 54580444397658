export const labelPlaceholderMessageMap = {
	yes: {
		text: "Yes"
	},
	no: {
		text: "No"
	},
	knowledgeTree: {
		view: {
			saved: "Views"
		}
	},
	search: "Search for anything",
	searchClasses: "Search for classes here...",
	email: "Email",
	username: "Username",
	password: {
		text: "Password",
		confirmText: "Confirm password",
		new: "New password",
	},
	accessKey: "Access key",
	feedback: "Feedback / suggestions",
	comment: "Add a comment...",
	account: {
		phone: "If available, please include your area code"
	},
	tutoring: {
		profile: {
			firstName: "First name",
			lastName: "Last name",
			fullName: "Full name",
			linkedIn: "LinkedIn profile url",
			website: "Personal website url",
			phone: "Phone",
			email: "Email",
			flatRate: "Flat rate",
			variableRate: "Variable rate",
			yes: "Yes",
			no: "No",
			unavailable: "Unavailable"
		},
		requests: {
			virtual: "Online",
			inPerson: "In-person",
			automatic: "Automatic (based on my ",
			application: "Let tutors apply to the request",
			now: "Now",
			later: "Schedule Later",
			allTutors: "All tutors",
			savedTutors: "Saved tutors"
		},
		sessions: {
			misconduct: "Sexual misconduct",
			personalInfo: "Shared personal contact information",
			money: "Exchanging money outside the platform",
			profanity: "Profanity and/or inappropriate language",
			other: "Other",
			optional: "Optional"
		}
	},
	learner: {
		profile: {
			grade: "Grade, year, or form",
		}
	},
	payouts: {
		card: "card",
		bank: "bank (checking or saving accounts only)",
		cardNumber: "card number",
		securityCode: "security code",
		checkingAccount: "checking account number",
		routingNumber: "routing number / SWIFT / BIC Code"
	},
};