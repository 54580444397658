import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";
import { SAVE_RELOAD_TIMEOUT } from "Utilities/Constants/TimeoutConstants";

import Alert, { SUCCESS, ERROR } from "templates/Alert";
import ConfirmModal from "templates/customModals/ConfirmModal";

import { 
	getSubscriptionAPI, cancelSubscriptionAPI 
} from "apis/controllers/SubscriptionController";


function SubscriptionSubPage(props) {

	const history = useHistory();

	function redirectToSubscriptionPage() {
		history.push("/subscription");
	}

	function showCancelSubscriptionConfirmModal() {
		const payload = {
			ownerId: props.ownerId
		};
		getSubscriptionAPI(payload, resp => {
			const currentSubscriptionEndingPeriod = resp.currentEndingPeriod;
			const endingPeriodDate = new Date(currentSubscriptionEndingPeriod * 1000);
			const subHeader = messageMap("subscription.account.cancelSubheader", "generic") + endingPeriodDate.toLocaleDateString();

			props.setModal(
				<ConfirmModal title={messageMap("subscription.account.cancelTitle", "generic")} 
					subHeader={subHeader} confirmType="ensure"
					closeModal={executeCancellation} closeArgs="proceedCancellation" />
			);
		});
	}
	function executeCancellation(e, cancel) {
		if (e == null || (e != null && ["modal-block", "cancel", "icon", "close-button"].includes(e.target.className))) {
			if (cancel === "proceedCancellation") {
				const payload = {
					ownerId: props.ownerId
				};
				cancelSubscriptionAPI(payload, resp => {
					const type = resp.includes("success") ? SUCCESS : ERROR;
		
					props.setAlert(
						<Alert closeHandler={props.closeAlert} type={type} msg={messageMap(resp, "api")} />
					);

					setTimeout(() => {
						window.location.reload();
					}, SAVE_RELOAD_TIMEOUT);
				});
			}

			props.setModal(null);
		}
	}

	return (
		<div>
			<h1 className="page-title">
				{messageMap("profile.subscription", "subPageNavigation")}
			</h1>

			<div className="page-field">
				<label className="page-field-label">
					<div className="subscription-plan-label">
						{messageMap("profilePage.subscription.fields.subscriptionPlan", "generic")}
					</div>
				</label>
				<div className="inline-elements">
					{
						props.isSubscriber ? (
							<Fragment>
								<div className="purchased-subscription">
									{messageMap("subscription.account.pro", "generic")}
								</div>
								<button className={"page-field update-field"} onClick={showCancelSubscriptionConfirmModal}>
									{messageMap("subscription.cancel", "button")}
								</button>
							</Fragment>
						)
						: (
							<button className={"page-field update-field"} onClick={redirectToSubscriptionPage}>
								{messageMap("learnMore.text", "button")}
							</button>
						)
					}
				</div>

			</div>
		</div>
	);
}

SubscriptionSubPage.propTypes = {
	isSubscriber: PropTypes.bool,

	// parent state setters
	setAlert: PropTypes.func.isRequired,
	closeAlert: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,

	// Redux props
	ownerId: PropTypes.string
};

export default connect(
	account
)(SubscriptionSubPage);

