import {combineReducers} from "redux";
import environment from "redux/reducers/environmentReducer";
import account from "redux/reducers/accountReducer";
import apiKeys from "redux/reducers/apiReducer";
import generic from "redux/reducers/genericReducer";
import metadata from "redux/reducers/metadataReducer";
import payments from "redux/reducers/paymentsReducer";
import knowledge from "redux/reducers/knowledgeReducer";
import subNavs from "redux/reducers/subNavReducer";

export default combineReducers({
	environment,
	account, 
	// apiKeys, 
	generic, metadata, payments, knowledge, subNavs
});